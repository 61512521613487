// @flow
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0%;
  width: 100%;
`;

export const Row = styled.div`
  margin-top: 34px;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
`;

export const FlexRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 120px;
  > * {
    display: inline-block;
  }
`;

export const SpinnerContainer = styled.div`
  flex: 1 1 0%;
  position: relative;
  height: 100%;
`;

export const Divider = styled.div`
  width: 1px;
  height: 98px;
  background-color: #d0dcea;
`;

export const Divider2 = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: solid 0.8px #d0dcea;
`;

export const BreadCrumb = styled.div`


`;

export const StatPiece = styled.div`
  flex: 1 1 0%;
  span {
    display: block;
    text-align: center;
  }
  > .stat-heading {
    font-size: 20px;
    font-weight: 500;
    color: ${props => props.color || '#576881'};
  }
  > .stat-data {
    margin-top: 12px;
    font-size: 55px;
    line-height: 70px;
    font-weight: 500;
    color: ${props => props.color || '#576881'};
  }
   > .stat-bill-heading {
     width: 70%;
     height: 18px;
     font-family: AppleSDGothicNeo;
     font-size: 18px;
     line-height: 1;
     letter-spacing: normal;
     color: #576881;
     display: inline-block;
     text-align: left;
  }
 
  > .stat-bill-data {
    width: 30%;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    text-align: right;
    color: ${props => props.color || '#576881'};
    display: inline-block;
  }
  > .stat-contract-heading {
     width: 25%;
     height: 18px;
     font-family: AppleSDGothicNeo;
     font-size: 18px;
     line-height: 1;
     letter-spacing: normal;
     color: #576881;
     display: inline-block;
     text-align: left;
  }
 
  > .stat-contract-data {
    width: 65%;
    font-size: 18px;
    font-family: AppleSDGothicNeo;
    line-height: 18px;
    font-weight: bold;
    text-align: left;
    color: ${props => props.color || '#576881'};
    display: inline-block;
  }
`;