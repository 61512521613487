// @flow
import styled from 'styled-components';
import { HoverStyledAnchorButton } from '../Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const PopupCloseButton = styled(HoverStyledAnchorButton)`
  > span {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    margin-right: 8px;
  }
  &:hover, &:focus {
    > span {
      color: #00bbf9;
    }
  }
  > img {
    height: 16px;
    width: 16px;
    object-fit: contain;
  }
`;

export const NoticeNavButton = styled(HoverStyledAnchorButton)`
  height: 45px;
  width: 45px;
  text-align: center;
  > img {
    height: 25px;
    width: 15px;
    object-fit: contain;
  }
`;

export const NoticeContainer = styled.div`
  flex: 1 1 0%;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const NoticeTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const NoticeTitle = styled.h3`
  color: #576881;
  font-size: 22px;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const NoticeTimestamp = styled.span`
  color: #94aabf;
  font-size: 12px;
`;

export const NoticeContentContainer = styled.div`
  overflow: auto;
  border: solid 1px #d0dcea;
  flex: 1 1 0%;
  padding: 0 10px;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.16);
    transition: all .1s ease-in-out;
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.36);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(66,82,110,0.56);
  }
`;

export const NoticeContent = styled.p`
  color: #576881;
  font-size: 14px;
  white-space: pre-wrap;
`;

export const DoNotShowContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5px;
`;