// @flow
import type { AccountFormState, User } from '../../types/accounts';

// client actions
const FORM_UPDATE: 'sd/ac/accountform/FORM_UPDATE' = 'sd/ac/accountform/FORM_UPDATE';
const FORM_CANCEL: 'sd/ac/accountform/FORM_CANCEL' = 'sd/ac/accountform/FORM_CANCEL';
const CLEAR_FORM: 'sd/ac/accountform/CLEAR_FORM' = 'sd/ac/accountform/CLEAR_FORM';
const ADD_USER: 'sd/ac/accountform/ADD_USER' = 'sd/ac/accountform/ADD_USER';
const EDIT_USER: 'sd/ac/accountform/EDIT_USER' = 'sd/ac/accountform/EDIT_USER';

type FormUpdateAction = {
  type: typeof FORM_UPDATE,
  payload: {
    key: string,
    value: string
  }
};

type FormCancelAction = {
  type: typeof FORM_CANCEL
}

type ClearFormAction = {
  type: typeof CLEAR_FORM
}

type AddUserAction = {
  type: typeof ADD_USER
};

type EditUserAction = {
  type: typeof EDIT_USER,
  payload: User
}

type AccountFormActions = (
  FormUpdateAction |
  FormCancelAction |
  ClearFormAction |
  AddUserAction |
  EditUserAction
);

const INITIAL_STATE: AccountFormState = {
  user: {
    name: '',
    email: '',
    role: 'U_NORMAL'
  },
  shown: false,
  mode: 'create',
};

export default function reducer(state: AccountFormState = INITIAL_STATE, action: AccountFormActions): AccountFormState {
  switch (action.type) {
    case FORM_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.key]: action.payload.value
        }
      };
    case FORM_CANCEL:
      return {
        ...state,
        user: INITIAL_STATE.user,
        shown: false,
        mode: 'create',
      };
    case CLEAR_FORM:
      return {
        ...state,
        user: INITIAL_STATE.user,
        mode: 'create',
      };
    case ADD_USER:
      return {
        ...state,
        user: INITIAL_STATE.user,
        shown: true,
        mode: 'create',
      };
    case EDIT_USER:
      return {
        ...state,
        user: {
          name: action.payload.name,
          email: action.payload.email,
          role: action.payload.role
        },
        shown: true,
        mode: 'update',
      }
    default: return state;
  }
}

const formUpdate = (key: string, value: string): FormUpdateAction => {
  return {
    type: FORM_UPDATE,
    payload: {
      key,
      value
    }
  };
};

const formCancel = (): FormCancelAction => {
  return {
    type: FORM_CANCEL
  };
};

const clearForm = (): ClearFormAction => {
  return {
    type: CLEAR_FORM
  };
};

const addUser = (): AddUserAction => {
  return {
    type: ADD_USER
  };
};

const editUser = (user: User): EditUserAction => {
  return {
    type: EDIT_USER,
    payload: user
  };
};

export const actionCreators = {
  formUpdate,
  formCancel,
  clearForm,
  addUser,
  editUser,
};