// @flow
import styled from 'styled-components';
import { Button, HoverStyledButton, HoverStyledAnchorButton, AnchorButton, EllipsisSpan } from '../../common';

export const TableControlButton = styled(HoverStyledButton)`
  height: 45px;
  width: 45px;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  &:not(:first-child) {
    margin-left: 8px;
  }
`;

export const TablePagingInfo = styled.span`
  font-size: 18px;
  line-height: 1.17;
  color: #586881;
  min-width: 40px;
  text-align: center;
  .current-page {
    color: #00bbf9;
  }
`;
export const PanelContentContainer = styled.div`
  padding: 13px 30px 30px;
  flex: 1 1 0%;
  > *:not(:first-child) {
    margin-top: 30px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RightButtonsContainer = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const DeleteButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  width: 100px;
  background-color: #94aabf;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const CreateButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  width: 100px;
  background-color: #576881;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const DeleteConfirmMessage = styled.p`
  font-size: 20px;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #000;
  > span {
    color: #fc5882;
  }
`;

export const PanelTitleContainer = styled.div`
  border-left: solid 6px #00bbf9;
  padding-left: 30px;
  height: 70px;
  flex: 0 0 70px;
  &::before {
    content: '';
    display: inline-block;
    overflow: hidden;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
  * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const HeaderContentContainer = styled.div`
  background-color: #fff;
  flex: 1;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  flex-direction: column;
  display: flex;
`;

export const PanelTitle = styled.span`
  font-size: 26px;
  font-weight: 600;
  color: #435268;
`;

export const BiillingDetailsContainer = styled.div`
  background-color: #00bbf9;
  position: relative;
  height: 80px;
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  > span {
    color: #fff;
    font-size: 18px;
    margin-left: 10px;
  }
`;

export const PatientEditDeleteButtonContainer = styled.div`
  position: absolute;
  top: -26px;
  right: 0px;
`;

export const PatientEditButton = styled(AnchorButton)`
  &:not(:first-child) {
    padding-left: 8px;
  }
  &:first-child::after {
    content: '';
    width: 1px;
    height: 10px;
    background-color: #bcc9d8;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
  }
  > span {
    font-size: 15px;
    color: #94aabf;
  }
  &:hover, &:focus {
    > span {
      color: #00bbf9;
    }
  }
`;

export const PatientDeleteButton = styled(Button)`
  background-color: transparent;
  &:not(:first-child) {
    padding-left: 8px;
  }
  &:first-child::after {
    content: '';
    width: 1px;
    height: 10px;
    background-color: #bcc9d8;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
  }
  > span {
    font-size: 15px;
    color: #94aabf;
  }
  &:hover, &:focus {
    > span {
      color: #00bbf9;
    }
  }
`;

export const PatientDetailDivider = styled.div`
  display: inline-block;
  flex: 0 0 1px;
  height: 16px;
  background-color: #fff;
  opacity: 0.5;
`;

export const BillingDetailPieceContainer = styled.div`
  display: flex;
  flex: 1 0 ${props => props.flexBasis || 0};
  flex-direction: column;
  padding-left: 10px;
  border-left: 1px solid #fff;
  height: 50px;
  justify-content: space-between;
  div {
    display: flex;
    flex: 0 0 19px;
  }
`;

export const PatientDetailPieceHeading = styled.span`
  color: #fff;
  font-size: 12px;
`;

export const BillingDetailPiece = styled(EllipsisSpan)`
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 16px;
  width: 100%;
  color: #fff;
  width: 0;
`;

export const RefreshButton = styled(Button)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  margin-left: 8px;
  height: 45px;
  width: 45px;
  > img {
    width: 22px;
    height: 20px;
    line-height: 45px;
    object-fit: contain;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-bottom: 15px;
`;

export const PopupTitleContainer = styled.div`
  height: 62px;
  flex: 0 0 62px;
  border-bottom: solid 2px #d0dcea;
  text-align: center;
  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
  }
  * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const PopupTitle = styled.span`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

export const PopupContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  font-size: 15px;
`;

export const PopupBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 30px;
`;

export const AutoPaymentInfoRow = styled.div`
  display: flex;
  flex: 0 0 45px;
  align-items: center;
  width: 100%;
  flex-direction: row;
  > span {
    font-size: 18px;
    line-height: 1;
    color: #576881;
    &.info-heading {
      flex: 0 0 100px;
    }
    &.info-data {
      flex: 1 1 0%;
      font-weight: bold;
    }
    &.info-notice {
      flex: 1 1 0%;
      font-size: 14px;
      opacity: 0.7;
    }
  }
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  flex: 1 1 0%;
`;

export const CardInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 40px;
  align-items: center;
  img {
    width: 6px;
    height: 6px;
    object-fit: contain;
  }
  > span {
    font-size: 18px;
    line-height: 1;
    color: #576881;
    &.info-heading {
      margin-left: 16px;
      flex: 0 0 100px;
    }
    &.info-data {
      flex: 1 1 0%;
      font-weight: bold;
    }
  }
`;

export const PopupFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  height: 45px;
  flex: 0 0 45px;
  justify-content: flex-end;
`;

export const UnsubscribeButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  width: 150px;
  background-color: #94aabf;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const ReceiptLink = styled(HoverStyledAnchorButton)`
  > span {
    color: #576881;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
  }
  &:hover, &:focus {
    > span {
      color: #00bbf9;
    }
  }
`;

export const CloseButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  width: 150px;
  background-color: #576881;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;