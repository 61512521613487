// @flow
import type { AppState } from '../../types';
import type { Dispatch } from 'redux';
import type { ClearCurrentUserAction } from '../auth/currentuser';
import type { ClearPatientSelectionAction } from './patientlist';
import type { AnlzRequestListState, AnlzRequest } from '../../types/analysis';
// module imports
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import { downloadAnlzResourceRest } from '../../utils/rest';
import { CLEAR_PATIENT_SELECTION } from './patientlist';
import { CLEAR_CURRENT_USER } from '../auth/currentuser';

// client actions
const SET_PAGING_INFO: 'sd/a/anlzrequestlist/SET_PAGING_INFO' = 'sd/a/anlzrequestlist/SET_PAGING_INFO';
const NEXT_PAGE: 'sd/a/anlzrequestlist/NEXT_PAGE' = 'sd/a/anlzrequestlist/NEXT_PAGE';
const PREV_PAGE: 'sd/a/anlzrequestlist/PREV_PAGE' = 'sd/a/anlzrequestlist/PREV_PAGE';
const SELECT_ANLZ_REQUEST: 'sd/a/anlzrequestlist/SELECT_ANLZ_REQUEST' = 'sd/a/anlzrequestlist/SELECT_ANLZ_REQUEST';
const DESELECT_ANLZ_REQUEST: 'sd/a/anlzrequestlist/DESELECT_ANLZ_REQUEST' = 'sd/a/anlzrequestlist/DESELECT_ANLZ_REQUEST';
const CLEAR_ANLZ_REQUEST_SELECTION: 'sd/a/anlzrequestlist/CLEAR_ANLZ_REQUEST_SELECTION' = 'sd/a/anlzrequestlist/CLEAR_ANLZ_REQUEST_SELECTION';
const CHECK_ANLZ_REQUESTS: 'sd/a/anlzrequestlist/CHECK_ANLZ_REQUESTS' = 'sd/a/anlzrequestlist/CHECK_ANLZ_REQUESTS';
const UNCHECK_ANLZ_REQUESTS: 'sd/a/anlzrequestlist/UNCHECK_ANLZ_REQUESTS' = 'sd/a/anlzrequestlist/UNCHECK_ANLZ_REQUESTS';
const RESET_LIST_STATE: 'sd/a/anlzrequestlist/RESET_LIST_STATE' = 'sd/a/anlzrequestlist/RESET_LIST_STATE';
// server actions
const DOWNLOAD_ANLZ_RESOURCE: 'sd/a/anlzrequestlist/DOWNLOAD_ANLZ_RESOURCE' = 'sd/a/anlzrequestlist/DOWNLOAD_ANLZ_RESOURCE';
const REFETCH_ANLZ_REQUESTS: 'sd/a/anlzrequestlist/REFETCH_ANLZ_REQUESTS' = 'sd/a/anlzrequestlist/REFETCH_ANLZ_REQUESTS';
const REFETCH_ANLZ_REQUESTS_DONE: 'sd/a/anlzrequestlist/REFETCH_ANLZ_REQUESTS_DONE' = 'sd/a/anlzrequestlist/REFETCH_ANLZ_REQUESTS_DONE';

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type DownloadAnlzResourceAction = {
  type: typeof DOWNLOAD_ANLZ_RESOURCE,
}

type SelectAnlzRequestAction = {
  type: typeof SELECT_ANLZ_REQUEST,
  payload: AnlzRequest
};

type ClearAnlzRequestSelectionAction = {
  type: typeof CLEAR_ANLZ_REQUEST_SELECTION
};

type CheckAnlzRequestsAction = {
  type: typeof CHECK_ANLZ_REQUESTS,
  payload: string[]
};

type UncheckAnlzRequestsAction = {
  type: typeof UNCHECK_ANLZ_REQUESTS,
  payload: string[]
};

type ResetListStateAction = {
  type : typeof RESET_LIST_STATE
}

type RefetchAnlzRequestsAction = {
  type: typeof REFETCH_ANLZ_REQUESTS
};

type RefetchAnlzRequestsDoneAction = {
  type: typeof REFETCH_ANLZ_REQUESTS_DONE
};

type AnlzRequestListActions = (
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  SelectAnlzRequestAction |
  ClearAnlzRequestSelectionAction |
  CheckAnlzRequestsAction |
  UncheckAnlzRequestsAction |
  RefetchAnlzRequestsAction |
  RefetchAnlzRequestsDoneAction |
  ResetListStateAction |
  DownloadAnlzResourceAction |
  ClearPatientSelectionAction |
  ClearCurrentUserAction
);

const INITIAL_STATE: AnlzRequestListState = {
  currentPage: 0,
  numPages: 0,
  refetch: false,
  selectedAnlzRequest: null,
  checkedAnlzRequests: [],
};

export default function reducer(state: AnlzRequestListState = INITIAL_STATE, action: AnlzRequestListActions): AnlzRequestListState {
  switch (action.type) {
    case SET_PAGING_INFO:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        numPages: action.payload.numPages
      };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      };
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      };
    case SELECT_ANLZ_REQUEST:
      return {
        ...state,
        selectedAnlzRequest: action.payload
      };
    case CLEAR_PATIENT_SELECTION:
    case CLEAR_ANLZ_REQUEST_SELECTION:
      return {
        ...state,
        selectedAnlzRequest: null
      };
    case CHECK_ANLZ_REQUESTS:
      return {
        ...state,
        checkedAnlzRequests: _.union(state.checkedAnlzRequests, action.payload)
      };
    case UNCHECK_ANLZ_REQUESTS:
      return {
        ...state,
        checkedAnlzRequests: _.difference(state.checkedAnlzRequests, action.payload)
      };
    case REFETCH_ANLZ_REQUESTS:
      return {
        ...state,
        refetch: true
      };
    case REFETCH_ANLZ_REQUESTS_DONE:
      return {
        ...state,
        refetch: false
      };
    case RESET_LIST_STATE:
    case CLEAR_CURRENT_USER: return INITIAL_STATE;
    case DOWNLOAD_ANLZ_RESOURCE: return state;
    default: return state;
  }
};

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const selectAnlzRequest = (request: AnlzRequest): SelectAnlzRequestAction => {
  return {
    type: SELECT_ANLZ_REQUEST,
    payload: request
  };
};

const clearAnlzRequestSelection = (): ClearAnlzRequestSelectionAction => {
  return {
    type: CLEAR_ANLZ_REQUEST_SELECTION
  };
};

const checkAnlzRequests = (requestIds: string[]): CheckAnlzRequestsAction => {
  return {
    type: CHECK_ANLZ_REQUESTS,
    payload: requestIds
  };
};

const uncheckAnlzRequests = (requestIds: string[]): UncheckAnlzRequestsAction => {
  return {
    type: UNCHECK_ANLZ_REQUESTS,
    payload: requestIds
  };
};

const refetchAnlzRequests = (): RefetchAnlzRequestsAction => {
  return {
    type: REFETCH_ANLZ_REQUESTS
  };
};

const refetchAnlzRequestsDone = (): RefetchAnlzRequestsDoneAction => {
  return {
    type: REFETCH_ANLZ_REQUESTS_DONE
  };
};

const resetListState = (): ResetListStateAction => {
  return {
    type: RESET_LIST_STATE
  };
};

const downloadAnlzResource = (sbjtId: string, actualName: string, visualName: string): ThunkAction => {
  return (dispatch: Dispatch<AnlzRequestListActions>, getState: () => AppState) => {
    const { access_token } = getState().auth.currentUser.tokens;
    downloadAnlzResourceRest(sbjtId, actualName, visualName, access_token);
  }
}

export const actionCreators = {
  setPagingInfo,
  nextPage,
  prevPage,
  downloadAnlzResource,
  selectAnlzRequest,
  clearAnlzRequestSelection,
  checkAnlzRequests,
  uncheckAnlzRequests,
  resetListState,
  refetchAnlzRequests,
  refetchAnlzRequestsDone
};