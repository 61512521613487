// @flow
// type imports
import type { AppState } from '../../../types';
import type { PatientDetails, PatientListFilter, Patient, Survey } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Query } from '@apollo/client/react/components';
import ReactTable from 'react-table';
import { SurveySelectForm } from '../SurveySelectForm';
import { ControlledPanel, Spinner, selectableTable, styledTable, SearchBar, checkableTable, ConfirmPopup } from '../../common';
import { patientListActions, patientFormActions } from '../../../ducks/analysis';
import analysisImages from '../../../resources/images/analysis';
import {
  TableControlButton,
  RegisterButton,
  RegisterButtonContainer,
  TablePagingInfo,
  DefaultCellSpan,
  RefreshButton,
  DeleteEditButton,
  DeleteConfirmMessage,
  CheckAllButton,
  SurveyResultButton
} from './styled';
import commonImages from '../../../resources/images/common';
import { Mutation } from '@apollo/client/react/components';
import { DELETE_SBJT_MUTATION, DELETE_SBJTS_MUTATION } from '../../../utils/graphql/mutations';
import { ALL_SBJT_IDS_QUERY } from '../../../utils/graphql/queries';

type OwnProps = {
  onPatientSelect: (patient: Patient) => void,
  onPatientSearch: () => void,
  onShowAll: () => void,
  onFetchData: (state: any, instance: any) => void,  // fetch data triggered by ReactTable
  onPatientDelete: () => any,
  loading: boolean,
  currentPage: number,
  numPages: number,
  noDataText: string,
  // TODO: create a type for subjects
  subjects: any[],  // list of subjects
  pageSize: number  // ReactTable pageSize
};

type StateProps = {
  selectedPatient: Patient | null,
  checkedPatients: string[],
  filter: PatientListFilter,
  downloading: boolean,
};

type ActionProps = typeof patientListActions;

// TODO: move PatientForm out to Analysis screen component
type Props = OwnProps & StateProps & ActionProps & {
  addPatient: typeof patientFormActions.addPatient
};

const SelectableTable = compose(styledTable, selectableTable, checkableTable)(ReactTable);

class PatientListPanelComponent extends React.Component<Props> {
  handleSearchTextChange(event: SyntheticEvent<HTMLInputElement>, currentFilter: PatientListFilter) {
    // clear date filter if set
    if (currentFilter.date) {
      this.props.clearFilter('date');
    }
    this.props.setFilter('keyword', event.currentTarget.value);
  }

  handleSearchSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    this.props.onPatientSearch();
  }

  render() {
    const {
      filter,
      subjects,
      loading,
      currentPage,
      numPages,
      pageSize,
      onFetchData,
      noDataText,
      selectedPatient,
      checkedPatients,
      onPatientDelete,
      downloading
    } = this.props;
    return (
      <ControlledPanel>
        <ControlledPanel.Header>
          <SearchBar
            leftButton={(
              <RegisterButton
                className='with-hover-overlay'
                onClick={(event) => {
                  event.preventDefault();
                  this.props.addPatient();
                }}
                href='#'
              >
                <span>등록</span>
              </RegisterButton>
            )}
            searchText={filter.keyword}
            placeholder='대상자의 이름 또는 코드를 입력하세요'
            showAllImgSrc={analysisImages.allPatients}
            onTextChange={(event: SyntheticEvent<HTMLInputElement>) => this.handleSearchTextChange(event, filter)}
            onSearchSubmit={(event: SyntheticEvent<HTMLFormElement>) => this.handleSearchSubmit(event)}
            onShowAll={(event) => {
              event.preventDefault();
              this.props.clearAllFilters();
              this.props.onShowAll();
            }}
          />
        </ControlledPanel.Header>
        <ControlledPanel.Content>
          <SelectableTable
            data={subjects}
            loading={loading}
            page={currentPage}
            pageSize={pageSize}
            minRows={0}
            manual
            onFetchData={onFetchData}
            onPageChange={(pageIndex) => {
              console.log('onPageChange')
            }}
            LoadingComponent={Spinner}
            pages={numPages}
            columns={[{
                Header: '이름',
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                accessor: 'name',
                minWidth: 140,
              }, {
                Header: '코드',
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                accessor: 'code',
                minWidth: 140
              }, {
                id: 'birthday',
                Header: '생년월일',
                accessor: rowData => rowData.birthday,
                minWidth: 160
              }, {
                id: 'tel',
                Header: '전화번호',
                accessor: rowData => rowData.tel || '-',
                minWidth: 180
              }, {
                Header: '성별',
                Cell: (rowInfo) => {
                  if (rowInfo.value === 'MALE') return 'M';
                  return 'F';
                },
                accessor: 'sex',
                minWidth: 65
              }, {
                id: 'manager',
                Header: '작성자',
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                accessor: rowData => rowData.manager || '-',
                minWidth: 138,
              }
            ]}
            showPagination={false}
            resizable={false}
            multiSort={false}
            sortable={false}
            selectedRow={selectedPatient}
            isRowCheckable={(rowData) => true}
            checkedRows={checkedPatients}
            onRowsCheck={(rowIds: string[]) => {
              this.props.checkPatients(rowIds);
            }}
            onRowsUncheck={(rowIds: string[]) => {
              this.props.uncheckPatients(rowIds);
            }}
            onRowSelect={(rowInfo: any) => {
              const selectedPatient: Patient = rowInfo.original;
              this.props.onPatientSelect(selectedPatient);
            }}
            noDataText={noDataText}
          />
        </ControlledPanel.Content>
        <ControlledPanel.Footer>
          <TableControlButton onClick={() => this.props.prevPage()}>
            <img src={commonImages.prev} alt='Previous' />
            <img className='hover-img' src={commonImages.prevHover} alt='Previous' />
          </TableControlButton>
          <TablePagingInfo><span className='current-page'>{currentPage + 1}</span>/{numPages}</TablePagingInfo>
          <TableControlButton onClick={() => this.props.nextPage()}>
            <img src={commonImages.next} alt='Next' />
            <img className='hover-img' src={commonImages.nextHover} alt='Next' />
          </TableControlButton>
          <Query
            query={ALL_SBJT_IDS_QUERY}
            variables={{
              page: 0,
              size: numPages * pageSize || 10,
            }}
          >
            {({ loading, data, error, refetch }) => {
              const buttonDisabled = loading || error || !data || !data.sbjtsInOrgz || data.sbjtsInOrgz.subjects.length < 1;
              return (
                <CheckAllButton
                  href='#'
                  className='with-hover-overlay'
                  disabled={buttonDisabled}
                  onClick={async (e) => {
                    e.preventDefault();
                    const latest = await refetch();
                    if (latest && latest.data && latest.data.sbjtsInOrgz) {
                      const { sbjtsInOrgz } = latest.data;
                      if (checkedPatients.length < sbjtsInOrgz.subjects.length) {
                        this.props.checkPatients(_.map(sbjtsInOrgz.subjects, subject => subject.id));
                      } else {
                        this.props.uncheckPatients(_.map(sbjtsInOrgz.subjects, subject => subject.id));
                      }
                    }
                  }}
                >
                  <span>전체 선택</span>
                </CheckAllButton>
              );
            }}
          </Query>
          <RegisterButtonContainer>
            {checkedPatients.length > 0 ? (
              <SurveySelectForm
                formWidth={360}
                trigger={
                  <SurveyResultButton
                    className='with-hover-overlay'
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {(downloading) ?
                      <Spinner loading={downloading} />
                      : <span>설문결과 다운로드</span>}
                  </SurveyResultButton>
                }
                onConfirm={(survey: Survey) => {
                  if (checkedPatients.length > 0 && survey) {
                    this.props.downloadSurveyResult(survey, checkedPatients);
                  }
                }}
                renderTitle={() => (
                  <React.Fragment>다운로드 하실 <span>설문유형</span>을 <span>선택</span>해주세요.</React.Fragment>
                )}
                userEmail={this.props.userEmail}
                mode='download'
              />
            ) : null}
            {checkedPatients.length > 0 ? (
              <Mutation
                mutation={DELETE_SBJTS_MUTATION}
                context={{
                  dataSourceType: 'api'
                }}
              >
                {(deleteSbjts, { loading, error }) => {
                  return (
                    <ConfirmPopup
                      trigger={
                        <DeleteEditButton
                          href='#'
                          className='with-hover-overlay'
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {(loading) ?
                            <Spinner loading={loading} />
                            : <span>삭제</span>}
                        </DeleteEditButton>
                      }
                      onConfirm={async () => {
                        try {
                          const deleteResult = await deleteSbjts({
                            variables: {
                              ids: checkedPatients
                            }
                          });
                          console.log(deleteResult);
                          if (deleteResult && deleteResult.data !== undefined && deleteResult.data.deleteSbjtsById > 0) {
                            this.props.uncheckPatients(checkedPatients);
                            if (selectedPatient && _.includes(checkedPatients, selectedPatient.id)) this.props.clearPatientSelection();
                            onPatientDelete();
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      <DeleteConfirmMessage>선택한 대상자를 <span>삭제</span>하시겠습니까?</DeleteConfirmMessage>
                    </ConfirmPopup>
                  )
                }}
              </Mutation>
            ) : null}
            <RefreshButton
              className='with-hover-overlay'
              onClick={(event) => {
                event.preventDefault();
                this.props.onPatientSearch();
              }}
              href='#'
            >
              <img src={commonImages.refresh} alt='refresh'/>
            </RefreshButton>
          </RegisterButtonContainer>
        </ControlledPanel.Footer>
      </ControlledPanel>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  return {
    selectedPatient: state.analysis.patientList.selectedPatient,
    checkedPatients: state.analysis.patientList.checkedPatients,
    filter: state.analysis.patientList.filter,
    downloading: state.analysis.patientList.downloading,
    userEmail: state.auth.currentUser.user_name,
  };
}

export const PatientListPanel = connect(mapStateToProps, {
  ...patientListActions,
  addPatient: patientFormActions.addPatient,
  editPatient: patientFormActions.editPatient,
})(PatientListPanelComponent);
