// @flow
// type imports
import type { AppState } from '../../types';
import type { Customer } from '../../types/customers';
// module imports
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Page,
  PanelGrid
} from '../../components/common';
import {
  CustomerListPanel,
  AnlzDateRangePanel,
  StatsChartPanel,
  CustomerAnlzRquestList
} from '../../components/customers';

type StateProps = {
  selectedCustomer: Customer | null
}

type Props = StateProps;

class CustomersComponent extends React.Component<Props> {
  render() {
    const { selectedCustomer } = this.props;
    return (
      <Page
        title='Customer'
        subtitle='등록된 고객의 분석 요청 현황 및 과금내역을 관리할 수 있습니다'
        contentMinWidth={1295}
      >
        <PanelGrid.Grid>
          <PanelGrid.Row style={{ height: '478px' }}>
            <PanelGrid.Col minWidth={1175}>
              <CustomerListPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
          {(selectedCustomer) ? (
            <React.Fragment>
              <PanelGrid.Row style={{ height: '350px'}}>
                <PanelGrid.Col minWidth={1175}>
                  <AnlzDateRangePanel selectedCustomer={selectedCustomer} />
                </PanelGrid.Col>
              </PanelGrid.Row>
              <PanelGrid.Row style={{ height: '350px'}}>
                <PanelGrid.Col minWidth={1175}>
                  <StatsChartPanel />
                </PanelGrid.Col>
              </PanelGrid.Row>
              <PanelGrid.Row style={{ height: '540px'}}>
                <PanelGrid.Col minWidth={1175}>
                  <CustomerAnlzRquestList />
                </PanelGrid.Col>
              </PanelGrid.Row>
            </React.Fragment>
          ) : null}
        </PanelGrid.Grid>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    selectedCustomer: state.customers.customerList.selectedCustomer
  };
};

export const Customers = connect(mapStateToProps, {})(CustomersComponent);