// @flow
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import {
  DateRangePickerContainer,
  DateRangePickerHeader,
  DateRangePickerInputContainer,
  DateRangePickerInput,
  MonthPickerContainer,
  YearPickerContainer,
  MonthPickerButton,
  DateRangePickerFooterContainer,
  SubmitButton,
  CancelButton
} from './styled';

type Props = {
  currentYear: number;
  startDate: moment,
  endDate: moment,
  selectedMonth: number | null,
  onSubmit?: (startDate: moment, endDate: moment) => void,
  onCancel?: () => void
}

type State = {
  currentYear: number,
  startDate: moment,
  endDate: moment,
  startDateStr: string,
  endDateStr: string,
  selectedMonth: ?number
}

class DateRangePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      currentYear,
      selectedMonth,
      startDate,
      endDate
    } = props;
    this.state = {
      currentYear,
      selectedMonth: selectedMonth || null,
      startDate,
      endDate,
      startDateStr: (startDate) ? startDate.format('YYYY.MM.DD') : '',
      endDateStr: (endDate) ? endDate.format('YYYY.MM.DD') : ''
    };
  }
  
  componentDidMount() {
    this.setState({ currentYear: this.props.currentYear });
  }

  decrementYear() {
    this.setState(() => { return { currentYear: this.state.currentYear - 1 }; });
  }
  
  incrementYear() {
    this.setState(() => { return { currentYear: this.state.currentYear + 1 }; });
  }

  onSubmitClick() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.startDate, this.state.endDate);
    }
  }

  onCancelClick() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onDateChange(key: string, targetEl: EventTarget) {
    if (targetEl instanceof HTMLInputElement) {
      let dateStr = targetEl.value;
      const momentKey = this.getMomentKey(key);
      this.setDateState(key, momentKey, dateStr, null);
    }
  }

  onMonthClick(year: number, month: number) {
    const startDate = moment([year, month - 1]);
    const endDate = moment(startDate).endOf('month');
    this.setState(() => {
      return {
        startDate,
        endDate,
        startDateStr: startDate.format('YYYY.MM.DD'),
        endDateStr: endDate.format('YYYY.MM.DD'),
        selectedMonth: month
      }
    });
  }

  onDateBlur(key: string, targetEl: EventTarget) {
    if (targetEl instanceof HTMLInputElement) {
      let dateStr = targetEl.value;
      const momentKey = this.getMomentKey(key);
      dateStr = moment(dateStr, 'YYYY.MM.DD').format('YYYY.MM.DD');
      this.setDateState(key, momentKey, dateStr, this.state.selectedMonth);
    }
  }

  setDateState(key: string, momentKey: string, dateStr: string, selectedMonth: ?number) {
    this.setState(() => {
      return {
        [key]: dateStr,
        [momentKey]: (momentKey === 'startDate') ? moment(dateStr, 'YYYY.MM.DD').startOf('day') : moment(dateStr, 'YYYY.MM.DD').endOf('day'),
        selectedMonth
      }
    });
  }

  getMomentKey(key: string): string {
    switch (key) {
      case 'startDateStr': return 'startDate';
      case 'endDateStr': return 'endDate';
      default: return '';
    }
  }

  renderMonthPickerBodyRow(currentYear: number, rowIdx: number) {
    const { selectedMonth, startDate } = this.state;
    return (
      <tr key={`${currentYear}_row_${rowIdx}`}>
        {_.map(_.range(1, 5, 1), (colIdx) => {
          const month = rowIdx * 4 + colIdx;
          return (
            <MonthPickerButton
              className='with-hover-overlay'
              key={`${currentYear}_${month}`}
              onClick={(e) => this.onMonthClick(currentYear, month)}
              isActive={selectedMonth === month && startDate.year() === currentYear}
            >
              {`${month}월`}
            </MonthPickerButton>
          );
        })}
      </tr>
    );
  }

  renderMonthPickerBody(currentYear: number) {
    return (
      <tbody>
        {_.map(_.range(0, 3, 1), (rowIdx) => {
          return this.renderMonthPickerBodyRow(currentYear, rowIdx);
        })}
      </tbody>
    );
  }

  render() {
    const {
      currentYear,
      startDate,
      endDate,
      startDateStr,
      endDateStr
    } = this.state;
    return (
      <DateRangePickerContainer>
        <DateRangePickerHeader>
          기간설정
        </DateRangePickerHeader>
        <DateRangePickerInputContainer>
          <div>
            <DateRangePickerInput
              placeholder='직접입력'
              value={startDateStr}
              onChange={(event) => this.onDateChange('startDateStr', event.target)}
              onBlur={(event) => this.onDateBlur('startDateStr', event.target)}
            />
          </div>
          <i className="dash" />
          <div>
            <DateRangePickerInput
              placeholder='직접입력'
              value={endDateStr}
              onChange={(event) => this.onDateChange('endDateStr', event.target)}
              onBlur={(event) => this.onDateBlur('endDateStr', event.target)}
            />
          </div>
        </DateRangePickerInputContainer>
        <MonthPickerContainer>
          <thead>
            <tr>
              <th colSpan={4}>
                <YearPickerContainer>
                  <a onClick={() => this.decrementYear()}><i className="arrow left"></i></a>
                  <span>{currentYear}</span>
                  <a onClick={() => this.incrementYear()}><i className="arrow right"></i></a>
                </YearPickerContainer>
              </th>
            </tr>
          </thead>
          {this.renderMonthPickerBody(currentYear)}
        </MonthPickerContainer>
        <DateRangePickerFooterContainer>
          <CancelButton
            className='with-hover-overlay'
            onClick={this.onCancelClick.bind(this)}
          >
            취소
          </CancelButton>
          <SubmitButton
            className='with-hover-overlay'
            onClick={this.onSubmitClick.bind(this)}
            disabled={
              !(startDate.isValid() && endDate.isValid())
              || endDate.diff(startDate, 'days') < 0
            }
          >
            조회
          </SubmitButton>
        </DateRangePickerFooterContainer>
      </DateRangePickerContainer>
    );
  }
}

export { DateRangePicker };