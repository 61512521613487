// @flow
import * as React from 'react';
import {
  Page,
  PanelGrid
} from '../../components/common';
import {
  CustomerPanel,
  AnalysisPanel,
  StatsChartPanel
} from '../../components/backdash';

class Backdash extends React.Component<{}> {
  render() {
    return (
      <Page
        title='Dashboard'
        subtitle='관리중인 고객 및 분석 요청 현황을 한눈에 볼 수 있습니다'
        contentMinWidth={1295}
      >
        <PanelGrid.Grid>
          <PanelGrid.Row style={{ height: '266px' }}>
            <PanelGrid.Col minWidth={635}>
              <AnalysisPanel />
            </PanelGrid.Col>
            <PanelGrid.Col minWidth={510}>
              <CustomerPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
          <PanelGrid.Row style={{ height: '931px'}}>
            <PanelGrid.Col minWidth={1175}>
              <StatsChartPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
        </PanelGrid.Grid>
      </Page>
    )
  }
}

export { Backdash };