// @flow
// module imports
import { combineReducers } from 'redux';
import billingItemDetailReducer, { actionCreators as billingItemDetailActions } from './billingItemDetail';
import * as DateRangeInput from "../common/daterangeinput";

const reducer = combineReducers({
    billingDateRange: DateRangeInput.createDateRangeInputReducer('billing'),
    billingItemDetail: billingItemDetailReducer,
});

export default reducer;

export {
    billingItemDetailActions
};
