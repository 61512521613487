// @flow
import * as React from 'react';
import { components } from 'react-select';
import commonImages from '../../../resources/images/common';
import styled from 'styled-components';

const IndicatorDiv = styled.div`
  border-top: 4.5px solid #94aabf;
  border-left: 3.4px solid transparent;
  border-right: 3.4px solid transparent;
  border-bottom: 0px solid transparent;
`;

const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <IndicatorDiv />
    </components.DropdownIndicator>
  );
};

const MenuListFooter = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;
  cursor: pointer;
  > img {
    object-fit: contain;
    width: 13px;
    height: 7px;
    display: inline-block;
    &.hover-img {
      display: none;
    }
  }
  &:hover {
    > img {
      display: none;
      &.hover-img {
        display: inline-block;
      }
    }
  }
`;

const LoadMore = styled.span`
  font-size: 13px;
  color: #bcc9d8;
`;

export const styledSelect = (WrappedComponent: React.ComponentType<any>) => {
  class EnhancedSelect extends React.Component<any> {
    // TODO: check pagingInfo to decide whether to render load-more button
    MenuList = (props: any) => {
      return (
        <components.MenuList {...props}>
          {props.children}
          {(this.props.moreOptionsAvailable) ?
          <MenuListFooter onClick={this.props.onMenuScrollToBottom}>
            <LoadMore>데이터를 더 불러올 수 있습니다</LoadMore>
            <img src={commonImages.more} alt='Load more' />
            <img className='hover-img' src={commonImages.moreHover} alt='Load more hover' />
          </MenuListFooter> : null}
        </components.MenuList>
      )
    }

    MultiValueLabel = (props: any) => {
      return components.MultiValueLabel && (
        <components.MultiValueLabel {...props}>
          {(this.props.getMultiValueLabel) ? this.props.getMultiValueLabel(props.data) : props.children}
        </components.MultiValueLabel>
      )
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          components={{
            ...this.props.components,
            DropdownIndicator,
            MenuList: (this.props.showLoadMore) ? this.MenuList : components.MenuList,
            MultiValueLabel: this.MultiValueLabel
          }}
          styles={{
            ...this.props.styles,
            control: (styles, state) => {
              return {
                ...styles,
                backgroundColor: '#fff',
                borderColor: this.props.borderColor || '#d0dcea',
                borderRadius: 0,
                minHeight: this.props.minHeight || styles.minHeight,
                fontSize: this.props.fontSize || styles.fontSize,
                lineHeight: this.props.lineHeight || styles.lineHeight,
              }
            },
            input: styles => ({
              ...styles,
              margin: 0,
              padding: 0,
            }),
            multiValue: styles => ({
              ...styles,
              backgroundColor: 'hsl(0,0%,95%)'
            }),
            menu: styles => ({
              ...styles,
              borderRadius: 0,
              marginTop: '2px',
              fontSize: this.props.fontSize || styles.fontSize,
            }),
            indicatorSeparator: styles => ({
              display: 'none'
            }),
            valueContainer: styles => ({
              ...styles,
              overflow: 'hidden',
              fontSize: this.props.fontSize || styles.fontSize,
            }),
            singleValue: styles => ({
              ...styles,
              margin: 0
            })
          }}
        />
      );
    }
  }
  return EnhancedSelect;
};