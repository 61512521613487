// @flow
export default {
  accounts: require('./ico-menu-accesscontrol.svg'),
  analysis: require('./ico-analysis.svg'),
  circle: require('./ico-circle.svg'),
  home: require('./ico-home.svg'),
  logout: require('./ico-logout.svg'),
  logoutHover: require('./ico-logout-hover.svg'),
  mypage: require('./ico-mypage.svg'),
  profile: require('./ico-profile.svg'),
  cal: require('./ico-cal.svg'),
  calHover: require('./ico-cal-hover.svg'),
  search: require('./ico-search.svg'),
  searchHover: require('./ico-search-hover.svg'),
  prev: require('./ico-previous.svg'),
  prevHover: require('./ico-previous-hover.svg'),
  next: require('./ico-next.svg'),
  nextHover: require('./ico-next-hover.svg'),
  check: require('./ico-check.svg'),
  checked: require('./ico-check-click.svg'),
  checkedPrimary: require('./ico-check-click-primary.svg'),
  download: require('./ico-download.svg'),
  downloadHover: require('./ico-download-hover.svg'),
  upload: require('./ico-upload.svg'),
  uploadHover: require('./ico-upload-hover.svg'),
  logo: require('./logo.svg'),
  more: require('./ico-more-default.svg'),
  moreHover: require('./ico-more-hover.svg'),
  popupClose: require('./ico-popup-close.svg'),
  popupCloseHover: require('./ico-popup-close-hover.svg'),
  refresh: require('./ico-refresh.svg'),
  refreshGray: require('./ico-refresh-gray.svg'),
  billing: require('./ico-billing.svg'),
  billingHover: require('./ico-billing-hover.svg'),
  billingWhite: require('./ico-billing-white.svg')
};