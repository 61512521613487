export default {
  callCenter: require('./ico-callcenter.svg'),
  homepage: require('./ico-homepage.svg'),
  email: require('./ico-inquiriesemail.svg'),
  map: require('./ico-map.svg'),
  faqOpen: require('./ico-open.svg'),
  faqOpenHover: require('./ico-open-hover.svg'),
  faqClose: require('./ico-close.svg'),
  faqCloseHover: require('./ico-close-hover.svg'),
};
