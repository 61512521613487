// @flow
// type imports
import type { AppState } from '../../../types';
import type { Patient, PatientListState } from '../../../types/analysis';
// module imports
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Query, Mutation } from '@apollo/client/react/components';
import { patientFormActions, patientListActions } from '../../../ducks/analysis';
import {
  PatientDetailsContainer,
  PatientDetailDivider,
  PatientDetailPiece,
  PatientEditDeleteButtonContainer,
  PatientEditButton,
  PatientDeleteButton,
  DeleteConfirmMessage,
  PatientDetailPieceContainer,
  PatientDetailPieceHeading
} from './styled';
import analysisImages from '../../../resources/images/analysis';
import { SBJT_BYID_QUERY, ALL_SBJTS_QUERY } from '../../../utils/graphql/queries';
import { Spinner, ConfirmPopup } from '../../common';
import { DELETE_SBJT_MUTATION } from '../../../utils/graphql/mutations';
import { PatientForm } from '../PatientForm';

type OwnProps = {
  selectedPatient: ?Patient,
  patientLoading: boolean,
};

type ActionProps = {
  editPatient: typeof patientFormActions.editPatient,
  clearPatientSelection: typeof patientListActions.clearPatientSelection
};

type Props = ActionProps & OwnProps;

class PatientDetailsHeaderComponent extends React.Component<Props> {
  render() {
    const {
      selectedPatient
    } = this.props;
    console.log(selectedPatient);
    if (!!selectedPatient) {
      const refetchQueries = [{
        query: SBJT_BYID_QUERY,
        variables: {
          id: selectedPatient.id
        }
      }];
      const patient = selectedPatient;
      const age = moment().diff(moment(patient.birthday, 'YYYY-MM-DD'), 'years');
      return (
        <PatientDetailsContainer>
          <PatientDetailPieceContainer flexBasis='140px'>
            <PatientDetailPieceHeading>이름(나이)</PatientDetailPieceHeading>
            <div><PatientDetailPiece>{patient.name}{`(만 ${age}세)`}</PatientDetailPiece></div>
          </PatientDetailPieceContainer>
          <PatientDetailPieceContainer flexBasis='80px'>
            <PatientDetailPieceHeading>대상자 코드</PatientDetailPieceHeading>
            <div><PatientDetailPiece>{patient.code}</PatientDetailPiece></div>
          </PatientDetailPieceContainer>
          <PatientDetailPieceContainer flexBasis='105px'>
            <PatientDetailPieceHeading>생년월일</PatientDetailPieceHeading>
            <div><PatientDetailPiece>{patient.birthday}</PatientDetailPiece></div>
          </PatientDetailPieceContainer>
          <PatientDetailPieceContainer flexBasis='25px'>
            <PatientDetailPieceHeading>성별</PatientDetailPieceHeading>
            <div><PatientDetailPiece>{(patient.sex === 'MALE') ? 'M' : 'F'}</PatientDetailPiece></div>
          </PatientDetailPieceContainer>
          <PatientDetailPieceContainer flexBasis='150px'>
            <PatientDetailPieceHeading>전화번호</PatientDetailPieceHeading>
            <div><PatientDetailPiece>{patient.tel || '-'}</PatientDetailPiece></div>
          </PatientDetailPieceContainer>
          <PatientDetailPieceContainer flexBasis='150px'>
            <PatientDetailPieceHeading>이메일</PatientDetailPieceHeading>
            <div><PatientDetailPiece>{patient.email || '-'}</PatientDetailPiece></div>
          </PatientDetailPieceContainer>
          <PatientDetailPieceContainer flexBasis='150px'>
            <PatientDetailPieceHeading>질병코드</PatientDetailPieceHeading>
            <div>
              <PatientDetailPiece
                customTitle={_.join(_.map(patient.indications, code => `${code.code} ${code.name}`), '\n')}
                alwaysShowTooltip
              >
                {_.join(_.map(patient.indications, code => `${code.code}`), ', ') || '정상인'}
              </PatientDetailPiece>
            </div>
          </PatientDetailPieceContainer>
          <PatientEditDeleteButtonContainer>
            <PatientEditButton
              href='#'
              onClick={(e) => {
                e.preventDefault();
                this.props.editPatient({
                  ...patient,
                  dob: patient.birthday,
                  sex: selectedPatient.sex.toLowerCase(),
                  handedness: (selectedPatient.handedness !== 'NONE') ? selectedPatient.handedness.toLowerCase() : '',
                  icdCodes: selectedPatient.indications
                });
              }}
            >
              <span>수정</span>
            </PatientEditButton>
            {/* <Mutation
              mutation={DELETE_SBJT_MUTATION}
              context={{
                dataSourceType: 'api'
              }}
              refetchQueries={refetchQueries}
            >
              {(deleteSbjt, { loading, error }) => {
                return (
                  <ConfirmPopup
                    trigger={
                      <PatientDeleteButton
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <span>삭제</span>
                      </PatientDeleteButton>
                    }
                    onConfirm={() => {
                      deleteSbjt({ variables: { id: patient.id }}).then((result) => {
                        if (result && result.data && result.data.deleteSbjtById) {
                          console.log('delete successful');
                          this.props.clearPatientSelection();
                        }
                      }).catch((error) => {
                        console.log(error);
                      });
                    }}
                  >
                    <DeleteConfirmMessage>대상자를 <span>삭제</span>하시겠습니까?</DeleteConfirmMessage>
                  </ConfirmPopup>
                )
              }}
            </Mutation> */}
          </PatientEditDeleteButtonContainer>
        </PatientDetailsContainer>
      );
    }
    return (
      <PatientDetailsContainer>
        <img src={analysisImages.patient} alt='Select a patient'/>
        <span>대상자를 선택하면 기록을 볼 수 있습니다</span>
      </PatientDetailsContainer>
    );
          
  }
}

export const PatientDetailsHeader = connect(undefined, {
  editPatient: patientFormActions.editPatient,
  clearPatientSelection: patientListActions.clearPatientSelection
})(PatientDetailsHeaderComponent);

/*
<PatientEditDeleteButtonContainer>
  <PatientEditButton
    href='#'
    onClick={(e) => {
      e.preventDefault();
      this.props.editPatient({
        id: patient.id,
        code: patient.code,
        name: patient.name,
        dob: moment(patient.birthday.toString(), 'YYYY.MM.DD').format('YYYY.MM.DD'),
        sex: patient.sex.toLowerCase(),
        handedness: (patient.handedness !== 'NONE') ? patient.handedness.toLowerCase() : '',
        phone: patient.tel,
        email: patient.email
      });
    }}
  >
    <span>수정</span>
  </PatientEditButton>
  <Mutation
    mutation={DELETE_SBJT_MUTATION}
    context={{
      dataSourceType: 'api'
    }}
    refetchQueries={refetchQueries}
  >
    {(deleteSbjt, { loading, error }) => {
      return (
        <ConfirmPopup
          trigger={
            <PatientDeleteButton
              href='#'
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <span>삭제</span>
            </PatientDeleteButton>
          }
          onConfirm={() => {
            deleteSbjt({ variables: { id: patient.id }}).then((result) => {
              if (result && result.data && result.data.deleteSbjtById) {
                console.log('delete successful');
                this.props.clearPatientSelection();
              }
            }).catch((error) => {
              console.log(error);
            });
          }}
        >
          <DeleteConfirmMessage>대상자를 <span>삭제</span>하시겠습니까?</DeleteConfirmMessage>
        </ConfirmPopup>
      )
    }}
  </Mutation>
</PatientEditDeleteButtonContainer>
*/