// @flow
import _ from 'lodash';
import * as React from 'react';
import ReactGA from 'react-ga';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import styled from 'styled-components';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, withRouter, Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as reducers from './ducks';
import { Sidebar } from './components/common';
import { Screens } from './screens';
import client from './utils/graphql/apollo-client';
import * as LocalStorageUtils from './utils/localStorage';
import ReactTooltip from 'react-tooltip';
import './App.css';
import 'react-table/react-table.css';
import Notice from "./Notice";

const Container = styled.div`
  height: 100%;
`;

class ScrollToTopComponent extends React.Component<any> {te
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollToTop = withRouter(ScrollToTopComponent);

class App extends React.Component<{}> {
  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GA_ID || 'UA-128956817-2', {
      debug: true
    });
  }
  
  render() {
    console.log('App Started !!!')
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(combineReducers(reducers), {
      auth: {
        currentUser: LocalStorageUtils.loadCurrentUser()
      }
    }, composeEnhancers(applyMiddleware(ReduxThunk)));
    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
            <ScrollToTop>
              <Container>
                <Notice />
                <ReactTooltip />
                <Route path='/:uri+' render={(props) => {
                  const firstRoute = props.match.params.uri && props.match.params.uri.split('/')[0];
                  const availableRoutes = ['home', 'customers', 'analysis', 'mypage', 'accounts', 'support', 'privacy', 'eula', 'billing'];
                  if (!_.includes(availableRoutes, firstRoute)) {
                    return <Redirect to='/' />;
                  }
                  return <Sidebar {...props} />;
                }} />
                <Route component={Screens} />
              </Container>
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
