// @flow
import type { LoginFormState } from '../../types/auth';
import * as LocalStorageUtils from '../../utils/localStorage';

const saveIdPwd = LocalStorageUtils.loadSaveIdPwdPref()
const savedIdPwd = LocalStorageUtils.loadSavedIdPwd()

const INITIAL_STATE: LoginFormState = saveIdPwd ? {
  username: savedIdPwd.id,
  password: savedIdPwd.pwd,
  saveIdPwd: true,
} : {
  username: '',
  password: '',
  saveIdPwd: false
};

const FORM_UPDATE: 'sd/auth/loginform/FORM_UPDATE' = 'sd/auth/loginform/FORM_UPDATE';
const SET_SAVE_ID_PWD: 'sd/auth/loginform/SET_SAVE_ID_PWD' = 'sd/auth/loginform/SET_SAVE_ID_PWD';
const RESET_LOGIN_FORM: 'sd/auth/loginform/RESET_LOGIN_FORM' = 'sd/auth/loginform/RESET_LOGIN_FORM';


type FormUpdateAction = {
  type: typeof FORM_UPDATE,
  payload: {
    key: string,
    value: string
  }
};

type SetSaveIdPwdAction = {
  type: typeof SET_SAVE_ID_PWD,
  payload: boolean
}

type ResetLoginFormAction = {
  type: typeof RESET_LOGIN_FORM
}

type LoginFormActions = FormUpdateAction;

export default function reducer(state: LoginFormState = INITIAL_STATE, action: LoginFormActions): LoginFormState {
  switch (action.type) {
    case FORM_UPDATE:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case SET_SAVE_ID_PWD:
      LocalStorageUtils.saveIdPwdPref(action.payload)
      if (!action.payload) {
        LocalStorageUtils.clearIdPwd()
      }
      return {
        ...state,
        saveIdPwd: action.payload
      }
    case RESET_LOGIN_FORM:
      const saveIdPwd = LocalStorageUtils.loadSaveIdPwdPref()
      const savedIdPwd = LocalStorageUtils.loadSavedIdPwd()
      return saveIdPwd ? {
        username: savedIdPwd.id,
        password: savedIdPwd.pwd,
        saveIdPwd: true,
      } : {
        username: '',
        password: '',
        saveIdPwd: false
      };
    default: return state;
  }
};

const formUpdate = (key: string, value: string): FormUpdateAction => {
  return {
    type: FORM_UPDATE,
    payload: {
      key,
      value
    }
  };
};

const setSaveIdPwd = (value: boolean): SetSaveIdPwdAction => {
  return {
    type: SET_SAVE_ID_PWD,
    payload: value
  }
}

const resetLoginForm = (): ResetLoginFormAction => {
  return {
    type: RESET_LOGIN_FORM
  }
}

export const actionCreators = {
  formUpdate,
  setSaveIdPwd,
  resetLoginForm
};