// @flow
import * as React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.label`
  position: relative;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: ${props => (props.fontStyle && props.fontStyle.size) || 13}px;
  color: ${props => (props.fontStyle && props.fontStyle.color) || '#435268'};
  line-height: 20px;
  padding-${props => props.checkboxAlign || 'left'}: 27px;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    right: 0;
    & ~ .check-mark {
      pointer-events: none;
      position: absolute;
      top: 0;
      ${props => props.checkboxAlign || 'left'}: 0;
      height: 20px;
      width: 20px;
      background: transparent;
      display: block;
      &.checked {
        display: none;
      }
    }
    &:checked {
      & ~ .check-mark:not(.checked) {
        display: none;
      }
      & ~ .check-mark.checked {
        display: block;
      }
    }
    &:not(:checked):focus {
      ~ .check-mark:not(.checked) {
        display: none;
      }
      ~ .check-mark.checked {
        display: block;
        opacity: 0.3;
      }
    }
  }
  &:hover input:not(:checked) {
    ~ .check-mark:not(.checked) {
      display: none;
    }
    ~ .check-mark.checked {
      display: block;
      opacity: 0.3;
    }
  }
`;

type Props = {
  name?: string,
  value?: string,
  disabled?: boolean,
  fontStyle?: {
    size?: number,
    color?: string
  },
  checked: boolean,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => any,
  children?: React.Node,
  checkboxAlign?: 'left' | 'right',
  stopClickPropagation?: boolean,
  checkedImgSrc?: any,
  uncheckedImgSrc?: any
}

export const Checkbox = ({ name, value, disabled, fontStyle, checked, onChange, uncheckedImgSrc, checkedImgSrc, stopClickPropagation, checkboxAlign, children }: Props) => {
  return (
    <CheckboxContainer
      onClick={(event) => {
        if (stopClickPropagation) event.stopPropagation();
      }}
      fontStyle={fontStyle}
      checkboxAlign={checkboxAlign}
    >
      <input
        type='checkbox'
        disabled={disabled}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <img className='check-mark' src={uncheckedImgSrc} alt='Unchecked' />
      <img className='check-mark checked' src={checkedImgSrc} alt='Checked' />
      {children}
    </CheckboxContainer>
  )
}