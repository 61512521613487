// @flow
import styled from 'styled-components';

export const Container = styled.div`
  border: solid 1px #d0dcea;
  background-color: #fff;
  padding: 3px 4px;
  width: 100%;
  max-width: 280px;
  height: 100%;
  display: flex;
  overflow: hidden;
  display: inline-block;
`;

export const SelectedCodesContainer = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: ${props => props.flexWrap || 'no-wrap'};
  align-items: center;
`;

export const CodeContainer = styled.div`
  border-radius: 2px;
  background-color: hsl(0,0%,95%);
  display: flex;
  flex-shrink: 0;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  user-select: none;
`;

export const CodeLabel = styled.div`
  border-radius: 2px;
  color: #222;
  font-size: 13px;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  cursor: default;
`;

export const RemoveCodeContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: #FFBDAD;
    color: #DE350B;
  }
`;

export const RemoveIconContainer = styled.svg`
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
`;

/*
<svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
*/