// @flow
// type imports
import type { AppState } from "../../../types";
import type {
  Patient,
  AnlzSourceListState,
  SurveySessionListState,
  AnlzRequestListState,
  Survey,
} from "../../../types/analysis";
// module imports
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Mutation } from "@apollo/client/react/components";
import { ControlledPanel, Spinner, ConfirmPopup } from "../../common";
import {
  TableControlButton,
  TablePagingInfo,
  RightButtonsContainer,
  PanelTitleContainer,
  HeaderContentContainer,
  PanelTitle,
  PanelContentContainer,
  DeleteButton,
  DeleteConfirmMessage,
  CreateButton,
  RefreshButton,
} from "./styled";
import commonImages from "../../../resources/images/common";
import {
  anlzSourceListActions,
  patientDetailTabsActions,
  surveySessionListActions,
  anlzRequestListActions,
  tempAnlzRequestListActions,
} from "../../../ducks/analysis";
import { PatientDetailsHeader } from "./PatientDetailsHeader";
import { AnlzSourceForm } from "../AnlzSourceForm";
import { SurveySelectForm } from "../SurveySelectForm";
import { PatientDetailTabs } from "../PatientDetailTabs";
import {
  ANLZ_SRCS_BY_SBJTID_QUERY,
  SURVEY_SESSIONS_QUERY,
  ANLZ_REQUESTS_BY_SBJTID_QUERY,
} from "../../../utils/graphql/queries";
import {
  DELETE_ANLZ_SRCS_MUTATION,
  DELETE_SURVEY_SESSIONS_MUTATION,
  CREATE_SURVEY_SESSION_MUTATION,
  DELETE_ANLZ_REQS_MUTATION,
  SEND_KAKAO_MESSAGE_MUTATION,
} from "../../../utils/graphql/mutations";
import { AnlzRequestForm } from "../AnlzRequestForm";
import {
  TALK_API_HOST,
  fetchTemperamentAnalysisAuthority,
} from "../../../utils/rest";
import axios from "axios";
import type { CurrentUserState } from "../../../types/auth";
import { TempAnlzRequestForm } from "../TempAnlzRequestForm";

type OwnProps = {
  selectedPatient: ?Patient,
  patientLoading: boolean,
};

type StateProps = {
  activeTab: string,
  anlzSourceList: AnlzSourceListState,
  surveySessionList: SurveySessionListState,
  anlzRequestList: AnlzRequestListState,
  userEmail: string,
  currentUser: CurrentUserState,
};

type ActionProps = {
  refetchAnlzSources: typeof anlzSourceListActions.refetchAnlzSources,
  refetchSurveySessions: typeof surveySessionListActions.refetchSurveySessions,
  refetchAnlzRequests: typeof anlzRequestListActions.refetchAnlzRequests,
  refetchTempAnlzRequests: typeof tempAnlzRequestListActions.refetchAnlzRequests,
  uncheckAnlzSources: typeof anlzSourceListActions.uncheckAnlzSources,
  anlzSrcNextPg: typeof anlzSourceListActions.nextPage,
  anlzSrcPrevPg: typeof anlzSourceListActions.prevPage,
  clearAnlzSourceSelection: typeof anlzSourceListActions.clearAnlzSourceSelection,
  uncheckSurveySessions: typeof surveySessionListActions.uncheckSurveySessions,
  clearSurveySessionSelection: typeof surveySessionListActions.clearSurveySessionSelection,
  surveyNextPg: typeof surveySessionListActions.nextPage,
  surveyPrevPg: typeof surveySessionListActions.prevPage,
  uncheckAnlzRequests: typeof anlzRequestListActions.uncheckAnlzRequests,
  clearAnlzRequestSelection: typeof anlzRequestListActions.clearAnlzRequestSelection,
  anlzReqNextPg: typeof anlzRequestListActions.nextPage,
  anlzReqPrevPg: typeof anlzRequestListActions.prevPage,
} & typeof patientDetailTabsActions;

type Props = StateProps & ActionProps & OwnProps;

type State = {
  surveyFormOpen: boolean,
  enabledTmpAnlz: boolean,
};

class PatientDetailPanelComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      surveyFormOpen: false,
      enabledTmpAnlz: false,
    };
  }
  componentDidMount() {
    fetchTemperamentAnalysisAuthority(
      this.props.currentUser.tokens.access_token
    ).then((res) => this.setState({ enabledTmpAnlz: res.data.isAllowed }));
  }

  componentWillUnmount() {
    this.props.setActiveTab("anlzSources");
  }

  renderFooterControls() {
    const { selectedPatient, activeTab, userEmail } = this.props;
    if (activeTab === "anlzSources") {
      const { currentPage, numPages, checkedAnlzSources, selectedAnlzSource } =
        this.props.anlzSourceList;
      const refetchQueries = [
        {
          query: ANLZ_SRCS_BY_SBJTID_QUERY,
          variables: {
            subjectId: selectedPatient && selectedPatient.id,
            page: currentPage,
            size: 10,
          },
        },
      ];
      return (
        <React.Fragment>
          <RightButtonsContainer>
            {checkedAnlzSources.length > 0 ? (
              <Mutation
                mutation={DELETE_SURVEY_SESSIONS_MUTATION}
                context={{
                  dataSourceType: "api",
                }}
                refetchQueries={refetchQueries}
              >
                {(
                  deleteSurveySessions,
                  { loading: surveyLoading, error: surveyError }
                ) => (
                  <Mutation
                    mutation={DELETE_ANLZ_SRCS_MUTATION}
                    context={{
                      dataSourceType: "api",
                    }}
                    refetchQueries={refetchQueries}
                  >
                    {(
                      deleteAnlzSrcs,
                      { loading: anlzSrcLoading, error: anlzSrcError }
                    ) => {
                      return (
                        <ConfirmPopup
                          trigger={
                            <DeleteButton
                              className="with-hover-overlay"
                              disabled={anlzSrcLoading || surveyLoading}
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                            >
                              {anlzSrcLoading || surveyLoading ? (
                                <Spinner
                                  loading={anlzSrcLoading || surveyLoading}
                                />
                              ) : (
                                <span>삭제</span>
                              )}
                            </DeleteButton>
                          }
                          onConfirm={async () => {
                            console.log(checkedAnlzSources);
                            const checkedFiles = _.filter(
                              checkedAnlzSources,
                              (sourceId) => _.startsWith(sourceId, "FILE_")
                            );
                            const checkedSurveys = _.filter(
                              checkedAnlzSources,
                              (sourceId) => _.startsWith(sourceId, "SURVEY_")
                            );
                            try {
                              if (checkedFiles.length > 0) {
                                const checkedFileIds = _.map(
                                  checkedFiles,
                                  (fileId) => fileId.split("_")[1]
                                );
                                const result = await deleteAnlzSrcs({
                                  variables: { srcIds: checkedFileIds },
                                });
                                console.log(result);
                                if (
                                  result &&
                                  result.data &&
                                  result.data.deleteAnlzSrcs
                                ) {
                                  this.props.uncheckAnlzSources(checkedFiles);
                                  this.props.clearAnlzSourceSelection();
                                }
                              }
                              if (checkedSurveys.length > 0) {
                                const checkedSurveyIds = _.map(
                                  checkedSurveys,
                                  (surveyId) => surveyId.split("_")[1]
                                );
                                const result = await deleteSurveySessions({
                                  variables: { sessionIds: checkedSurveyIds },
                                });
                                if (
                                  result.data &&
                                  result.data.deleteSurveySessions
                                ) {
                                  this.props.uncheckAnlzSources(checkedSurveys);
                                  this.props.clearAnlzSourceSelection();
                                }
                              }
                              this.props.refetchAnlzSources();
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <DeleteConfirmMessage>
                            측정 기록을 <span>삭제</span>하시겠습니까?
                          </DeleteConfirmMessage>
                        </ConfirmPopup>
                      );
                    }}
                  </Mutation>
                )}
              </Mutation>
            ) : null}
            <AnlzSourceForm
              selectedPatient={this.props.selectedPatient}
              userEmail={userEmail}
              onSubmitDone={(error) => {
                if (!error) {
                  this.props.refetchAnlzSources();
                  this.setState(() => ({
                    surveyFormOpen: true,
                  }));
                }
              }}
              currentUser={this.props.currentUser}
            />
            <Mutation
              mutation={CREATE_SURVEY_SESSION_MUTATION}
              context={{
                dataSourceType: "api",
              }}
              refetchQueries={refetchQueries}
            >
              {(createSurveySession, { loading, error }) => {
                return (
                  <Mutation
                    mutation={SEND_KAKAO_MESSAGE_MUTATION}
                    context={{
                      dataSourceType: "api",
                    }}
                  >
                    {(sendKakaoMessage) => {
                      return (
                        <React.Fragment>
                          <CreateButton
                            className="with-hover-overlay"
                            disabled={!selectedPatient}
                            onClick={(event) => {
                              event.preventDefault();
                              this.setState({
                                surveyFormOpen: true,
                              });
                            }}
                          >
                            {loading ? (
                              <Spinner loading={loading} />
                            ) : (
                              <span>설문작성</span>
                            )}
                          </CreateButton>
                          <SurveySelectForm
                            trigger={null}
                            patientTel={selectedPatient && selectedPatient.tel}
                            open={this.state.surveyFormOpen}
                            onClose={() =>
                              this.setState({ surveyFormOpen: false })
                            }
                            onConfirm={(
                              survey: Survey,
                              surveyMethod: "web" | "kakao"
                            ) => {
                              if (selectedPatient && survey) {
                                createSurveySession({
                                  variables: {
                                    subjectId: selectedPatient.id,
                                    surveyId: survey.id,
                                    expiredMin: 43200,
                                  },
                                })
                                  .then((result) => {
                                    if (
                                      result &&
                                      result.data &&
                                      result.data.createSurveySession
                                    ) {
                                      const surveyURL = `${
                                        process.env.REACT_APP_SURVEY_HOST ||
                                        "http://localhost:5000"
                                      }/take/${
                                        result.data.createSurveySession.token
                                      }`;
                                      if (surveyMethod === "web") {
                                        window.open(
                                          `${
                                            process.env.REACT_APP_SURVEY_HOST ||
                                            "http://localhost:5000"
                                          }/take/${
                                            result.data.createSurveySession
                                              .token
                                          }`,
                                          "_blank"
                                        );
                                      } else {
                                        const talkRequestURL = `${TALK_API_HOST}/request/kakao.json`;
                                        sendKakaoMessage({
                                          variables: {
                                            kakaoFriendTalkInput: {
                                              message:
                                                "[MINDD SCAN]\n아래 링크를 통해 설문지를 작성해주세요.\n\n[KLAR]\n재택에서 활용할 수 있는 셀프케어 앱\nKLAR도 이용해보세요!",
                                              phoneNumber: selectedPatient.tel
                                                .split("-")
                                                .join(""),
                                              wide: false,
                                              buttons: [
                                                {
                                                  name: "설문 시작하기",
                                                  type: "WL",
                                                  url: `${surveyURL}||${surveyURL}`,
                                                },
                                                {
                                                  name: "KLAR 앱 바로가기",
                                                  type: "AL",
                                                  url: "https://play.google.com/store/apps/details?id=com.ybrain.klar||https://apps.apple.com/kr/app/id1492006887",
                                                },
                                              ],
                                              imgUrl:
                                                "http://mud-kage.kakao.com/dn/Iyypf/btqDFdm43pm/uXj1KNv45qrGLJe4MZYkoK/img_l.png",
                                              imgLink: surveyURL,
                                            },
                                          },
                                        })
                                          .then((response) => {
                                            if (
                                              response &&
                                              response.data &&
                                              response.data.sendKakaoFriendTalk
                                            ) {
                                              const { status } =
                                                response.data
                                                  .sendKakaoFriendTalk;
                                              switch (status) {
                                                case "KKO_3008":
                                                  // invalid tel
                                                  alert(
                                                    "올바르지 않은 전화번호입니다. 전화번호 확인 후 다시 시도해주세요."
                                                  );
                                                  break;
                                                case "KKO_3018":
                                                  // recepient didn't add friend
                                                  alert(
                                                    '수신자가 "와이브레인" 채널을 추가하지 않았습니다. 다음 링크에서 채널을 추가하여 메시지를 수신할 수 있도록 안내해주세요. \nhttps://pf.kakao.com/_uYxlSxb'
                                                  );
                                                  break;
                                                default:
                                                  return;
                                              }
                                            }
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      }
                                    }
                                  })
                                  .catch((error) => console.log(error));
                              }
                            }}
                            userEmail={this.props.userEmail}
                            mode="start"
                          />
                        </React.Fragment>
                      );
                    }}
                  </Mutation>
                );
              }}
            </Mutation>
            <AnlzRequestForm
              mode={"anlzRequests"}
              trigger={
                <CreateButton
                  className="with-hover-overlay"
                  disabled={!selectedPatient}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <span>개별 분석</span>
                </CreateButton>
              }
              selectedPatient={selectedPatient}
            />
            <AnlzRequestForm
              mode={"compAnlzRequests"}
              trigger={
                <CreateButton
                  className="with-hover-overlay"
                  disabled={!selectedPatient}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <span>비교 분석</span>
                </CreateButton>
              }
              selectedPatient={selectedPatient}
            />
            {this.state.enabledTmpAnlz && (
              <TempAnlzRequestForm
                currentUser={this.props.currentUser}
                trigger={
                  <CreateButton
                    className="with-hover-overlay"
                    disabled={!selectedPatient}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <span>기질 분석</span>
                  </CreateButton>
                }
                selectedPatient={selectedPatient}
              />
            )}
            <RefreshButton
              className="with-hover-overlay"
              disabled={!selectedPatient}
              onClick={(event) => {
                event.preventDefault();
                this.props.refetchAnlzSources();
              }}
            >
              <img src={commonImages.refresh} alt="refresh" />
            </RefreshButton>
          </RightButtonsContainer>
        </React.Fragment>
      );
    } else if (activeTab === "tempAnlzRequests") {
      const { currentPage } = this.props.anlzRequestList;
      const refetchQueries = [
        {
          query: ANLZ_REQUESTS_BY_SBJTID_QUERY,
          variables: {
            subjectId: selectedPatient && selectedPatient.id,
            page: currentPage,
            size: 10,
          },
        },
      ];
      return (
        <React.Fragment>
          <RightButtonsContainer>
            <AnlzSourceForm
              selectedPatient={this.props.selectedPatient}
              userEmail={userEmail}
              onSubmitDone={(error) => {
                if (!error) {
                  this.props.refetchAnlzSources();
                  this.setState(() => ({
                    surveyFormOpen: true,
                  }));
                }
              }}
              currentUser={this.props.currentUser}
            />
            <Mutation
              mutation={CREATE_SURVEY_SESSION_MUTATION}
              context={{
                dataSourceType: "api",
              }}
              refetchQueries={refetchQueries}
            >
              {(createSurveySession, { loading, error }) => {
                return (
                  <Mutation
                    mutation={SEND_KAKAO_MESSAGE_MUTATION}
                    context={{
                      dataSourceType: "api",
                    }}
                  >
                    {(sendKakaoMessage) => {
                      return (
                        <React.Fragment>
                          <CreateButton
                            className="with-hover-overlay"
                            disabled={!selectedPatient}
                            onClick={(event) => {
                              event.preventDefault();
                              this.setState({
                                surveyFormOpen: true,
                              });
                            }}
                          >
                            {loading ? (
                              <Spinner loading={loading} />
                            ) : (
                              <span>설문작성</span>
                            )}
                          </CreateButton>
                          <SurveySelectForm
                            trigger={null}
                            patientTel={selectedPatient && selectedPatient.tel}
                            open={this.state.surveyFormOpen}
                            onClose={() =>
                              this.setState({ surveyFormOpen: false })
                            }
                            onConfirm={(
                              survey: Survey,
                              surveyMethod: "web" | "kakao"
                            ) => {
                              if (selectedPatient && survey) {
                                createSurveySession({
                                  variables: {
                                    subjectId: selectedPatient.id,
                                    surveyId: survey.id,
                                    expiredMin: 43200,
                                  },
                                })
                                  .then((result) => {
                                    if (
                                      result &&
                                      result.data &&
                                      result.data.createSurveySession
                                    ) {
                                      const surveyURL = `${
                                        process.env.REACT_APP_SURVEY_HOST ||
                                        "http://localhost:5000"
                                      }/take/${
                                        result.data.createSurveySession.token
                                      }`;
                                      if (surveyMethod === "web") {
                                        window.open(
                                          `${
                                            process.env.REACT_APP_SURVEY_HOST ||
                                            "http://localhost:5000"
                                          }/take/${
                                            result.data.createSurveySession
                                              .token
                                          }`,
                                          "_blank"
                                        );
                                      } else {
                                        const talkRequestURL = `${TALK_API_HOST}/request/kakao.json`;
                                        sendKakaoMessage({
                                          variables: {
                                            kakaoFriendTalkInput: {
                                              message:
                                                "[MINDD SCAN]\n아래 링크를 통해 설문지를 작성해주세요.\n\n[KLAR]\n재택에서 활용할 수 있는 셀프케어 앱\nKLAR도 이용해보세요!",
                                              phoneNumber: selectedPatient.tel
                                                .split("-")
                                                .join(""),
                                              wide: false,
                                              buttons: [
                                                {
                                                  name: "설문 시작하기",
                                                  type: "WL",
                                                  url: `${surveyURL}||${surveyURL}`,
                                                },
                                                {
                                                  name: "KLAR 앱 바로가기",
                                                  type: "AL",
                                                  url: "https://play.google.com/store/apps/details?id=com.ybrain.klar||https://apps.apple.com/kr/app/id1492006887",
                                                },
                                              ],
                                              imgUrl:
                                                "http://mud-kage.kakao.com/dn/Iyypf/btqDFdm43pm/uXj1KNv45qrGLJe4MZYkoK/img_l.png",
                                              imgLink: surveyURL,
                                            },
                                          },
                                        })
                                          .then((response) => {
                                            if (
                                              response &&
                                              response.data &&
                                              response.data.sendKakaoFriendTalk
                                            ) {
                                              const { status } =
                                                response.data
                                                  .sendKakaoFriendTalk;
                                              switch (status) {
                                                case "KKO_3008":
                                                  // invalid tel
                                                  alert(
                                                    "올바르지 않은 전화번호입니다. 전화번호 확인 후 다시 시도해주세요."
                                                  );
                                                  break;
                                                case "KKO_3018":
                                                  // recepient didn't add friend
                                                  alert(
                                                    '수신자가 "와이브레인" 채널을 추가하지 않았습니다. 다음 링크에서 채널을 추가하여 메시지를 수신할 수 있도록 안내해주세요. \nhttps://pf.kakao.com/_uYxlSxb'
                                                  );
                                                  break;
                                                default:
                                                  return;
                                              }
                                            }
                                          })
                                          .catch((error) => {
                                            console.log(error);
                                          });
                                      }
                                    }
                                  })
                                  .catch((error) => console.log(error));
                              }
                            }}
                            userEmail={this.props.userEmail}
                            mode="start"
                          />
                        </React.Fragment>
                      );
                    }}
                  </Mutation>
                );
              }}
            </Mutation>
            <AnlzRequestForm
              mode={"anlzRequests"}
              trigger={
                <CreateButton
                  className="with-hover-overlay"
                  disabled={!selectedPatient}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <span>개별 분석</span>
                </CreateButton>
              }
              selectedPatient={selectedPatient}
            />
            <AnlzRequestForm
              mode={"compAnlzRequests"}
              trigger={
                <CreateButton
                  className="with-hover-overlay"
                  disabled={!selectedPatient}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <span>비교 분석</span>
                </CreateButton>
              }
              selectedPatient={selectedPatient}
            />
            {this.state.enabledTmpAnlz && (
              <TempAnlzRequestForm
                currentUser={this.props.currentUser}
                trigger={
                  <CreateButton
                    className="with-hover-overlay"
                    disabled={!selectedPatient}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <span>기질 분석</span>
                  </CreateButton>
                }
                selectedPatient={selectedPatient}
              />
            )}
            <RefreshButton
              className="with-hover-overlay"
              disabled={!selectedPatient}
              onClick={(event) => {
                event.preventDefault();
                this.props.refetchTempAnlzRequests();
              }}
            >
              <img src={commonImages.refresh} alt="refresh" />
            </RefreshButton>
          </RightButtonsContainer>
        </React.Fragment>
      );
    }

    const { currentPage, numPages, checkedAnlzRequests, selectedAnlzRequest } =
      this.props.anlzRequestList;
    const refetchQueries = [
      {
        query: ANLZ_REQUESTS_BY_SBJTID_QUERY,
        variables: {
          subjectId: selectedPatient && selectedPatient.id,
          page: currentPage,
          size: 10,
        },
      },
    ];
    return (
      <React.Fragment>
        <TableControlButton onClick={() => this.props.anlzReqPrevPg()}>
          <img src={commonImages.prev} alt="Previous" />
          <img
            className="hover-img"
            src={commonImages.prevHover}
            alt="Previous"
          />
        </TableControlButton>
        <TablePagingInfo>
          <span className="current-page">{currentPage + 1}</span>/{numPages}
        </TablePagingInfo>
        <TableControlButton onClick={() => this.props.anlzReqNextPg()}>
          <img src={commonImages.next} alt="Next" />
          <img className="hover-img" src={commonImages.nextHover} alt="Next" />
        </TableControlButton>
        <RightButtonsContainer>
          {checkedAnlzRequests.length > 0 ? (
            <Mutation
              mutation={DELETE_ANLZ_REQS_MUTATION}
              context={{
                dataSourceType: "api",
              }}
              refetchQueries={refetchQueries}
            >
              {(deleteSurveySessions, { loading, error }) => {
                return (
                  <ConfirmPopup
                    trigger={
                      <DeleteButton
                        className="with-hover-overlay"
                        disabled={loading}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {loading ? (
                          <Spinner loading={loading} />
                        ) : (
                          <span>삭제</span>
                        )}
                      </DeleteButton>
                    }
                    onConfirm={() => {
                      deleteSurveySessions({
                        variables: { anlzReqIds: checkedAnlzRequests },
                      })
                        .then((result: any) => {
                          if (result.data && result.data.deleteAnlzReqs) {
                            if (
                              selectedAnlzRequest &&
                              _.includes(
                                checkedAnlzRequests,
                                selectedAnlzRequest.id
                              )
                            ) {
                              this.props.clearAnlzRequestSelection();
                            }
                            this.props.uncheckAnlzRequests(checkedAnlzRequests);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    <DeleteConfirmMessage>
                      분석 기록을 <span>삭제</span>하시겠습니까?
                    </DeleteConfirmMessage>
                  </ConfirmPopup>
                );
              }}
            </Mutation>
          ) : null}
          <AnlzSourceForm
            selectedPatient={this.props.selectedPatient}
            userEmail={userEmail}
            onSubmitDone={(error) => {
              if (!error) {
                this.props.refetchAnlzSources();
                this.setState(() => ({
                  surveyFormOpen: true,
                }));
              }
            }}
            currentUser={this.props.currentUser}
          />
          <Mutation
            mutation={CREATE_SURVEY_SESSION_MUTATION}
            context={{
              dataSourceType: "api",
            }}
            refetchQueries={refetchQueries}
          >
            {(createSurveySession, { loading, error }) => {
              return (
                <Mutation
                  mutation={SEND_KAKAO_MESSAGE_MUTATION}
                  context={{
                    dataSourceType: "api",
                  }}
                >
                  {(sendKakaoMessage) => {
                    return (
                      <React.Fragment>
                        <CreateButton
                          className="with-hover-overlay"
                          disabled={!selectedPatient}
                          onClick={(event) => {
                            event.preventDefault();
                            this.setState({
                              surveyFormOpen: true,
                            });
                          }}
                        >
                          {loading ? (
                            <Spinner loading={loading} />
                          ) : (
                            <span>설문작성</span>
                          )}
                        </CreateButton>
                        <SurveySelectForm
                          trigger={null}
                          patientTel={selectedPatient && selectedPatient.tel}
                          open={this.state.surveyFormOpen}
                          onClose={() =>
                            this.setState({ surveyFormOpen: false })
                          }
                          onConfirm={(
                            survey: Survey,
                            surveyMethod: "web" | "kakao"
                          ) => {
                            if (selectedPatient && survey) {
                              createSurveySession({
                                variables: {
                                  subjectId: selectedPatient.id,
                                  surveyId: survey.id,
                                  expiredMin: 43200,
                                },
                              })
                                .then((result) => {
                                  if (
                                    result &&
                                    result.data &&
                                    result.data.createSurveySession
                                  ) {
                                    const surveyURL = `${
                                      process.env.REACT_APP_SURVEY_HOST ||
                                      "http://localhost:5000"
                                    }/take/${
                                      result.data.createSurveySession.token
                                    }`;
                                    if (surveyMethod === "web") {
                                      window.open(
                                        `${
                                          process.env.REACT_APP_SURVEY_HOST ||
                                          "http://localhost:5000"
                                        }/take/${
                                          result.data.createSurveySession.token
                                        }`,
                                        "_blank"
                                      );
                                    } else {
                                      const talkRequestURL = `${TALK_API_HOST}/request/kakao.json`;
                                      sendKakaoMessage({
                                        variables: {
                                          kakaoFriendTalkInput: {
                                            message:
                                              "[MINDD SCAN]\n아래 링크를 통해 설문지를 작성해주세요.\n\n[KLAR]\n재택에서 활용할 수 있는 셀프케어 앱\nKLAR도 이용해보세요!",
                                            phoneNumber: selectedPatient.tel
                                              .split("-")
                                              .join(""),
                                            wide: false,
                                            buttons: [
                                              {
                                                name: "설문 시작하기",
                                                type: "WL",
                                                url: `${surveyURL}||${surveyURL}`,
                                              },
                                              {
                                                name: "KLAR 앱 바로가기",
                                                type: "AL",
                                                url: "https://play.google.com/store/apps/details?id=com.ybrain.klar||https://apps.apple.com/kr/app/id1492006887",
                                              },
                                            ],
                                            imgUrl:
                                              "http://mud-kage.kakao.com/dn/Iyypf/btqDFdm43pm/uXj1KNv45qrGLJe4MZYkoK/img_l.png",
                                            imgLink: surveyURL,
                                          },
                                        },
                                      })
                                        .then((response) => {
                                          if (
                                            response &&
                                            response.data &&
                                            response.data.sendKakaoFriendTalk
                                          ) {
                                            const { status } =
                                              response.data.sendKakaoFriendTalk;
                                            switch (status) {
                                              case "KKO_3008":
                                                // invalid tel
                                                alert(
                                                  "올바르지 않은 전화번호입니다. 전화번호 확인 후 다시 시도해주세요."
                                                );
                                                break;
                                              case "KKO_3018":
                                                // recepient didn't add friend
                                                alert(
                                                  '수신자가 "와이브레인" 채널을 추가하지 않았습니다. 다음 링크에서 채널을 추가하여 메시지를 수신할 수 있도록 안내해주세요. \nhttps://pf.kakao.com/_uYxlSxb'
                                                );
                                                break;
                                              default:
                                                return;
                                            }
                                          }
                                        })
                                        .catch((error) => {
                                          console.log(error);
                                        });
                                    }
                                  }
                                })
                                .catch((error) => console.log(error));
                            }
                          }}
                          userEmail={this.props.userEmail}
                          mode="start"
                        />
                      </React.Fragment>
                    );
                  }}
                </Mutation>
              );
            }}
          </Mutation>
          <AnlzRequestForm
            mode={"anlzRequests"}
            trigger={
              <CreateButton
                className="with-hover-overlay"
                disabled={!selectedPatient}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <span>개별 분석</span>
              </CreateButton>
            }
            selectedPatient={selectedPatient}
          />
          <AnlzRequestForm
            mode={"compAnlzRequests"}
            trigger={
              <CreateButton
                className="with-hover-overlay"
                disabled={!selectedPatient}
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <span>비교 분석</span>
              </CreateButton>
            }
            selectedPatient={selectedPatient}
          />
          {this.state.enabledTmpAnlz && (
            <TempAnlzRequestForm
              currentUser={this.props.currentUser}
              trigger={
                <CreateButton
                  className="with-hover-overlay"
                  disabled={!selectedPatient}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <span>기질 분석</span>
                </CreateButton>
              }
              selectedPatient={selectedPatient}
            />
          )}
          <RefreshButton
            className="with-hover-overlay"
            disabled={!selectedPatient}
            onClick={(event) => {
              event.preventDefault();
              this.props.refetchAnlzRequests();
            }}
          >
            <img src={commonImages.refresh} alt="refresh" />
          </RefreshButton>
        </RightButtonsContainer>
      </React.Fragment>
    );
  }

  render() {
    const { selectedPatient, activeTab } = this.props;
    console.log(activeTab);
    return (
      <ControlledPanel>
        <ControlledPanel.Header
          style={{
            flex: "0 0 193px",
          }}
        >
          <HeaderContentContainer>
            <PanelTitleContainer>
              <PanelTitle>대상자 측정기록</PanelTitle>
            </PanelTitleContainer>
            <PanelContentContainer>
              <PatientDetailsHeader selectedPatient={selectedPatient} />
            </PanelContentContainer>
          </HeaderContentContainer>
        </ControlledPanel.Header>
        <ControlledPanel.Content>
          {this.state.enabledTmpAnlz && (
            <PatientDetailTabs
              enabledTmpAnlz={true}
              selectedPatient={selectedPatient}
              selectedTab={activeTab}
              onTabChange={(tabKey) => {
                this.props.setActiveTab(tabKey);
              }}
            />
          )}
          {!this.state.enabledTmpAnlz && (
            <PatientDetailTabs
              enabledTmpAnlz={false}
              selectedPatient={selectedPatient}
              selectedTab={activeTab}
              onTabChange={(tabKey) => {
                this.props.setActiveTab(tabKey);
              }}
            />
          )}
        </ControlledPanel.Content>
        <ControlledPanel.Footer>
          {this.renderFooterControls()}
        </ControlledPanel.Footer>
      </ControlledPanel>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    anlzSourceList: state.analysis.anlzSourceList,
    surveySessionList: state.analysis.surveySessionList,
    anlzRequestList: state.analysis.anlzRequestList,
    activeTab: state.analysis.patientDetailTabs.activeTab,
    userEmail: state.auth.currentUser.user_name,
    currentUser: state.auth.currentUser,
  };
};

export const PatientDetailPanel = connect(mapStateToProps, {
  refetchAnlzSources: anlzSourceListActions.refetchAnlzSources,
  refetchSurveySessions: surveySessionListActions.refetchSurveySessions,
  refetchAnlzRequests: anlzRequestListActions.refetchAnlzRequests,
  refetchTempAnlzRequests: tempAnlzRequestListActions.refetchAnlzRequests,
  uncheckAnlzSources: anlzSourceListActions.uncheckAnlzSources,
  anlzSrcNextPg: anlzSourceListActions.nextPage,
  anlzSrcPrevPg: anlzSourceListActions.prevPage,
  clearAnlzSourceSelection: anlzSourceListActions.clearAnlzSourceSelection,
  uncheckSurveySessions: surveySessionListActions.uncheckSurveySessions,
  surveyNextPg: surveySessionListActions.nextPage,
  surveyPrevPg: surveySessionListActions.prevPage,
  clearSurveySessionSelection:
    surveySessionListActions.clearSurveySessionSelection,
  uncheckAnlzRequests: anlzRequestListActions.uncheckAnlzRequests,
  clearAnlzRequestSelection: anlzRequestListActions.clearAnlzRequestSelection,
  anlzReqNextPg: anlzRequestListActions.nextPage,
  anlzReqPrevPg: anlzRequestListActions.prevPage,
  ...patientDetailTabsActions,
})(PatientDetailPanelComponent);
