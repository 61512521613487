// @flow
import * as React from "react";
import { useState } from "react";
import "react-table/react-table.css";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import Video from "react-responsive-video";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const NOTICE_CONFIG_FILE = "notice/config.json";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  pagination: {
    alignSelf: "center",
  },
}));

const getStorageKey = (id) => "MINDD_SCAN_NOTICE_NOT_AGAIN(" + id + ")";

export default class Notice extends React.Component<{}> {
  constructor(props) {
    super(props);

    this.state = {
      notices: [],
    };
  }

  componentDidMount() {
    fetch(NOTICE_CONFIG_FILE)
      .then((response) => response.json())
      .then((data) => {
        const now = new Date();
        const notices = [];
        for (let notice of data.list) {
          const begin = new Date(notice.begin);
          const end = new Date(notice.end);
          if (begin < now && now < end) {
            notices.push(notice);
          }
        }

        this.setState({ notices: notices });
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.notices != null
          ? this.state.notices
              .reverse()
              .map((notice) => <NoticeDialog info={notice} />)
          : null}
      </React.Fragment>
    );
  }
}

function NoticeDialog(props) {
  const classes = useStyles();
  const info = props.info;
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [notAgain, setNotAgain] = useState(false);

  if (localStorage.getItem(getStorageKey(info.id)) == null) {
    localStorage.setItem(getStorageKey(info.id), false.toString());
  }

  const notAgainCache = localStorage.getItem(getStorageKey(info.id));
  const [open, setOpen] = React.useState(
    !(notAgainCache != null && notAgainCache.toLowerCase() === "true")
  );

  const handleClose = () => {
    localStorage.setItem(getStorageKey(info.id), notAgain.toString());
    setOpen(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setOpen(true);
    setPageCount(numPages);
  };

  const handleNotAgain = (event) => {
    setNotAgain(event.target.checked);
  };

  const handlePagination = (event, value) => {
    setPageIndex(value);
  };

  const pdf = () => {
    const pageWidth = 1080;
    let page = null;
    if (info.replacements != undefined) {
      for (let replacement of info.replacements) {
        if (replacement.pageNum === pageIndex) {
          page = <Video mp4={replacement.file} width={pageWidth} />;
        }
      }
    }

    if (page == null) {
      page = <Page pageNumber={pageIndex} width={pageWidth} />;
    }

    return (
      <React.Fragment>
        <DialogContent>
          <Box className={classes}>
            {pageCount > 0 ? null : (
              <Skeleton variant="rect" width={pageWidth} height={600} />
            )}
            <Document
              file={info.file}
              onLoadSuccess={onDocumentLoadSuccess}
              loading=""
            >
              {page}
            </Document>
          </Box>
        </DialogContent>
        <Box className={classes.pagination}>
          <Pagination
            count={pageCount}
            page={pageIndex}
            color="primary"
            onChange={handlePagination}
          />
        </Box>
        <Box textAlign={"center"} marginTop={1}>
          <Button
            download
            href={info.file}
            variant="outlined"
            color="primary"
            startIcon={<GetAppRoundedIcon />}
          >
            {info.downloadBtnName || "첨부 다운로드"}
          </Button>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <Dialog open={open} maxWidth={true}>
      {pdf()}
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={notAgain}
              onChange={handleNotAgain}
              name="notAgain"
              color="primary"
            />
          }
          label="다시 보지 않기"
        />
        <Button
          variant="contained"
          size="medium"
          color="primary"
          className={classes.margin}
          onClick={handleClose}
        >
          <Typography>닫기</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
