// @flow
import _ from 'lodash';
import * as React from 'react';
import {
  Container,
  ScrollContainer,
  BottomIndicator
} from './styled';
import ResizeObserver from 'resize-observer-polyfill';

type Props = {
  children?: React.Node,
  withBottomIndicator?: boolean,
  onScrollBottom?: (e: SyntheticEvent<*>) => any
}

type State = {
  scrolledToBottom: boolean
};

class ScrollView extends React.Component<Props, State> {
  _scrollContainer: React.ElementRef<any>;
  resizeObserver: ResizeObserver;

  constructor(props: Props) {
    super(props);
    this.state = {
      scrolledToBottom: true
    };
    this._scrollContainer = React.createRef();

  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.scrolledToBottom && this._scrollContainer.current.scrollTop < this._scrollContainer.current.scrollHeight - this._scrollContainer.current.offsetHeight) {
      this.setState({ scrolledToBottom: false });
    }
  }

  componentDidMount() {
    if (this._scrollContainer.current) {
      this._scrollContainer.current.addEventListener('scroll', this.handleScroll);
      this.resizeObserver = new ResizeObserver(entries => {
        if (this.state.scrolledToBottom && this._scrollContainer.current.scrollTop < this._scrollContainer.current.scrollHeight - this._scrollContainer.current.offsetHeight) {
          this.setState({ scrolledToBottom: false });
        }
      });
      this.resizeObserver.observe(this._scrollContainer.current);
      if (this._scrollContainer.current.scrollTop < this._scrollContainer.current.scrollHeight - this._scrollContainer.current.offsetHeight) {
        this.setState({ scrolledToBottom: false });
      }
    }
  }

  componentWillUnmount() {
    if (this._scrollContainer.current) {
      this._scrollContainer.current.removeEventListener('scroll', this.handleScroll);
      this.resizeObserver.unobserve(this._scrollContainer.current);
    }
  }

  handleScroll = _.throttle((event: SyntheticEvent<HTMLDivElement>) => {
    const {
      currentTarget
    } = event;
    if (currentTarget && currentTarget.scrollTop === currentTarget.scrollHeight - currentTarget.offsetHeight) {
      console.log(this.state);
      this.setState({ scrolledToBottom: true });
      if (this.props.onScrollBottom) this.props.onScrollBottom(event);
    } else if (this.state.scrolledToBottom){
      this.setState({ scrolledToBottom: false });
    }
  }, 16);

  render() {
    const {
      children,
      withBottomIndicator
    } = this.props;
    return (
      <Container>
        <ScrollContainer ref={this._scrollContainer}>
          {children}
        </ScrollContainer>
        {withBottomIndicator ? <BottomIndicator scrolledToBottom={this.state.scrolledToBottom} /> : null}
      </Container>
    )
  }
}

export { ScrollView };
