// @flow
// type imports
import type {LoginFormState, UserCredentials} from '../../types/auth';
// module imports
import * as React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Mutation} from '@apollo/client/react/components';
import {LoginForm} from '../../components/login';
import {currentUserActions, loginFormActions} from '../../ducks/auth';
import {LOGIN_MUTATION} from '../../utils/graphql/mutations';
import * as LocalStorageUtils from '../../utils/localStorage';

type StateProps = LoginFormState
type Props = typeof currentUserActions & StateProps & typeof loginFormActions;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(317deg, #5b77d4, #3aaee8 57%, #1777db);
  display: flex;
  align-items: center;
`;

class LoginScreen extends React.Component<Props> {
  render() {
    return (
      <Mutation mutation={LOGIN_MUTATION}>
        {(login, {loading, error, data }) => {
          console.log(error);
          return (
            <Container>
              <LoginForm
                loading={loading}
                error={error}
                onAgree={async (refreshToken: string) => {
                  const result = await login({
                    variables: {
                      userCredentials: {
                        clientId: 'mindd-scan-web',
                        clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET || 'vBAjNWS1qvILtUdrRiUE',
                        refreshToken,
                        grantType: 'refresh_token'
                      }
                    }
                  });
                  console.log(result);
                  if (result && result.data) {
                    this.props.setCurrentUser(result.data.authResponse);
                  }
                }}
                onLogin={(userCredentials: UserCredentials) => {
                  login({
                    variables: {
                      userCredentials: {
                        ...userCredentials,
                        clientId: 'mindd-scan-web',
                        clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET || 'vBAjNWS1qvILtUdrRiUE'
                      }
                    }
                  }).then((result) => {
                    console.log(result);
                    if (result && result.data) {
                      if (this.props.saveIdPwd) {
                        LocalStorageUtils.saveIdPwd(this.props.username, this.props.password)
                        this.props.formUpdate('password', '')
                      }
                      this.props.setCurrentUser(result.data.authResponse);
                    }
                  })
                  .catch((error) => {
                    this.props.formUpdate('password', '')
                    console.log(error);
                  })
                }}
              />
            </Container>
          );
        }}
      </Mutation>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.auth.loginForm
  };
}

export const Login = connect(mapStateToProps, {
  ...currentUserActions,
  ...loginFormActions
})(LoginScreen);
