// @flow
// type imports
import type { AppState } from '../../../types';
// module imports
import _ from 'lodash';
import * as React from 'react';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { patientFormActions } from '../../../ducks/analysis';
import {
  Container,
  RemoveIconContainer,
  CodeContainer,
  CodeLabel,
  RemoveCodeContainer,
  SelectedCodesContainer,
} from './styled';

const RemoveIcon = () => (
  <RemoveIconContainer height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
  </RemoveIconContainer>
);

type OwnProps = {

};

type StateProps = {
  selectedCodes: any[]
};

type ActionProps = {
  removeICDCode: typeof patientFormActions.removeICDCode
};

type Props = OwnProps & StateProps & ActionProps;

const ICDCodeInputComponent = ({ selectedCodes, removeICDCode }: Props) => {
  if (selectedCodes.length > 4) {
    return (
      <Popup
        on='hover'
        trigger={(
          <Container>
            <SelectedCodesContainer>
              {_.map(selectedCodes, (icdCode) => (
                <CodeContainer key={icdCode.id}>
                  <CodeLabel>{icdCode.code}</CodeLabel>
                  <RemoveCodeContainer onClick={() => removeICDCode(icdCode)}>
                    <RemoveIcon/>
                  </RemoveCodeContainer>
                </CodeContainer>
              ))}
              {selectedCodes.length < 1 ? (
                <CodeContainer>
                  <CodeLabel>정상인</CodeLabel>
                </CodeContainer>
              ) : null}
            </SelectedCodesContainer>
          </Container>
        )}
        contentStyle={{
          backgroundColor: '#fff',
          boxShadow: 'none',
          border: 'solid 1px #d0dcea',
          width: '280px',
          minHeight: '38px',
          padding: '4px',
          cursor: 'default'
        }}
        position='bottom left'
        arrow={false}
        offsetY={-38}
      >
        {close => (
          <SelectedCodesContainer flexWrap='wrap'>
            {_.map(selectedCodes, (icdCode) => (
              <CodeContainer key={icdCode.id}>
                <CodeLabel title={`${icdCode.code} ${icdCode.name}`}>{icdCode.code}</CodeLabel>
                <RemoveCodeContainer onClick={() => removeICDCode(icdCode)}>
                  <RemoveIcon/>
                </RemoveCodeContainer>
              </CodeContainer>
            ))}
          </SelectedCodesContainer>
        )}
      </Popup>
    );
  }
  return (
    <Container>
      <SelectedCodesContainer>
        {_.map(selectedCodes, (icdCode) => (
          <CodeContainer key={icdCode.id}>
            <CodeLabel title={`${icdCode.code} ${icdCode.name}`}>{icdCode.code}</CodeLabel>
            <RemoveCodeContainer onClick={() => removeICDCode(icdCode)}>
              <RemoveIcon/>
            </RemoveCodeContainer>
          </CodeContainer>
        ))}
        {selectedCodes.length < 1 ? (
          <CodeContainer>
            <CodeLabel>정상인</CodeLabel>
          </CodeContainer>
        ) : null}
      </SelectedCodesContainer>
    </Container>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    selectedCodes: state.analysis.patientForm.patientDetails.icdCodes
  };
};

export const ICDCodeInput: React.ComponentType<OwnProps> = connect(mapStateToProps, {
  removeICDCode: patientFormActions.removeICDCode
})(ICDCodeInputComponent);