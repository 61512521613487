// @flow
import { Query } from "@apollo/client/react/components";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import BillingYbrainInfo from "../../components/billing/BillingYbrainInfo";
import { BillingItemDetailPanel } from "../../components/billing/detail";
import BillingDetailBaseInfo from "../../components/billing/detail/BillingDetailBaseInfo";
import { BillingDetailPlainPanel } from "../../components/billing/detail/BillingDetailPlainPanel";
import {
  BillingDateRangeInput,
  Page,
  PanelGrid,
  Spinner,
} from "../../components/common";
import { SpinnerContainer } from "../../components/dashboard/CurrentStatePanels/styled";
import type { AppState } from "../../types";
import {
  BILLING_DATA_ALL_QUERY,
  BILLING_USAGE_BY_ORGZ,
  CONTRACT_DATA,
} from "../../utils/graphql/queries";

type OwnProps = {
  startDate: moment,
  endDate: moment,
  month: moment,
  match: any,
};

class BillingDetailComponent extends React.Component {
  refetchBills = null;
  refetchUsage = null;

  handleRefresh() {
    if (this.refetchBills) {
      this.refetchBills();
    }

    if (this.refetchUsage) {
      this.refetchUsage();
    }
  }

  findContractBill(totalBillingInfo, contract) {
    let monthlyBill = totalBillingInfo?.monthlyBills[0];

    return monthlyBill?.monthlyBillDetails.find(
      (v) => v.contractId === contract?.id
    );
  }

  makeRecords(billingRecord = [], contractBill) {
    const records = [];

    billingRecord.reduce((prev, record) => {
      prev.push(
        ...record.usageDetails
          .filter((detail) => {
            const urn = detail.contractURN.split(":");
            return (
              urn.length === 3 && Number(urn[2]) === contractBill?.contractId
            );
          })
          .map((detail) => {
            return {
              billingRecordId: record.billingRecordId,
              creationTime: record.creationTime,
              subjectCode: record.subjectCode,
              sessionName: record.sessionName,
              unitPriceWithVat: detail.unitPriceWithVat || 0,
            };
          })
      );
      return prev;
    }, records);

    return records;
  }

  render() {
    const { currentUser } = this.props;
    const year = +this.props.startDate.format("YYYY");
    const month = Number(this.props.startDate.format("MM"));
    const contractId = this.props.match.params.contractId;

    return (
      <Query
        query={CONTRACT_DATA}
        variables={{
          contractId: contractId,
        }}
        fetchPolicy="network-only"
      >
        {({ data }) => {
          const contract = data?.contract;
          return (
            <Query
              query={BILLING_DATA_ALL_QUERY}
              variables={{
                orgzId: this.props.orgzId,
                year,
                month,
              }}
              fetchPolicy="network-only"
            >
              {({ loading, data, refetch }) => {
                this.refetchBills = refetch;
                const contractBill = this.findContractBill(
                  data?.totalBillingInfo,
                  contract
                );

                return (
                  <Query
                    query={BILLING_USAGE_BY_ORGZ}
                    variables={{
                      orgzId: this.props.orgzId,
                      year,
                      month,
                    }}
                    fetchPolicy="network-only"
                  >
                    {({ data, refetch }) => {
                      this.refetchUsage = refetch;

                      const records = this.makeRecords(
                        data?.billingRecord,
                        contractBill
                      );

                      return (
                        <Page
                          useBreadcrumb
                          breadcrumbProps={{
                            matchStr: "/billing/detail",
                            pathPieceToString: (piece: string) => {
                              switch (piece) {
                                case "billing":
                                  return "Billing";
                                case "detail":
                                  return "Bill Details";
                                default:
                                  return piece;
                              }
                            },
                          }}
                          subtitle="상세 정산 내역을 확인해 보세요"
                          contentMinWidth={1175}
                        >
                          <PanelGrid.Grid>
                            <BillingDateRangeInput
                              parentStateSelector={(state) => state.billing}
                              minDate={moment(contract?.startDate, "YYYY-MM")}
                              maxDate={moment().add(1, "M")}
                              name="billing"
                            />
                            {loading ? (
                              <PanelGrid.Row style={{ height: "502px" }}>
                                <BillingDetailPlainPanel
                                  title={"정산내역"}
                                  headerBorderColor="#00bbf9"
                                  headerBackgroundColor="#f6f9fc"
                                  claimStatus={contractBill?.claimStatus}
                                  year={year}
                                  month={month}
                                  currentUser={currentUser}
                                  onRefresh={() => this.handleRefresh()}
                                >
                                  <SpinnerContainer>
                                    <Spinner />
                                  </SpinnerContainer>
                                </BillingDetailPlainPanel>
                              </PanelGrid.Row>
                            ) : (
                              <React.Fragment>
                                <PanelGrid.Row style={{ height: "502px" }}>
                                  <BillingDetailPlainPanel
                                    title={"정산내역"}
                                    count={contractBill?.billedUsageCount}
                                    headerBorderColor="#00bbf9"
                                    headerBackgroundColor="#f6f9fc"
                                    claimStatus={contractBill?.claimStatus}
                                    year={year}
                                    month={month}
                                    currentUser={currentUser}
                                    contractBill={contractBill}
                                    onRefresh={() => this.handleRefresh()}
                                  />
                                </PanelGrid.Row>
                                <PanelGrid.Row style={{ height: "480px" }}>
                                  <PanelGrid.Col minWidth={980}>
                                    <BillingItemDetailPanel
                                      loading={loading}
                                      billingItemDetails={records}
                                      noDataText={""}
                                    />
                                    );
                                  </PanelGrid.Col>
                                </PanelGrid.Row>
                                <PanelGrid.Row style={{ height: "320px" }}>
                                  <PanelGrid.Col minWidth={980}>
                                    {contract && (
                                      <BillingDetailBaseInfo
                                        contract={contract}
                                        orgzId={this.props.orgzId}
                                        contractBill={contractBill}
                                      />
                                    )}
                                  </PanelGrid.Col>
                                </PanelGrid.Row>
                              </React.Fragment>
                            )}
                            <PanelGrid.Row>
                              <PanelGrid.Col>
                                <BillingYbrainInfo />
                              </PanelGrid.Col>
                            </PanelGrid.Row>
                          </PanelGrid.Grid>
                        </Page>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    startDate: state.billing.billingDateRange.startDate,
    endDate: state.billing.billingDateRange.endDate,
    orgzId: state.auth.currentUser.orgz_id,
    currentUser: state.auth.currentUser,
  };
};

export const BillingDetails: React.ComponentType<OwnProps> = connect(
  mapStateToProps,
  {}
)(BillingDetailComponent);
