// @flow
// type imports
import type { AdminEmployeeInput } from '../../../types/accesscontrol';
// module imports
import * as React from 'react';
import Popup from 'reactjs-popup';
import {
  AcceptButton,
  CancelButton,
  FormTitleContainer,
  FormTitle,
  FormFooterContainer,
  ContentContainer,
  Form,
  FormBody,
  FormRow
} from './styled';

type Props = {
  trigger: React.Node,
  children?: React.Node,
  onCancel?: () => any,
  onSubmit?: (employee: AdminEmployeeInput) => any
};

type State = {
  name: string,
  email: string
};

class AdminAccountForm extends React.Component<Props, State> {
  _firstInput: ?HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      email: ''
    };
  }
  render() {
    const {
      children,
      onSubmit,
      onCancel,
      trigger
    } = this.props;
    return (
      <Popup
        trigger={trigger}
        on='click'
        closeOnDocumentClick
        onOpen={() => {
          if (this._firstInput) {
            this._firstInput.focus();
          }
          this.setState({ name: '', email: '' });
        }}
        modal
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          margin: 0,
          zIndex: 10000,
          flexDirection: 'column'
        }}
        contentStyle={{
          backgroundColor: '#fff',
          boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
          border: 'none',
          width: '440px',
          minHeight: '250px',
          padding: 0
        }}
      >
        {(close) => {
          return (
            <ContentContainer>
              <FormTitleContainer>
                <FormTitle>어드민등록</FormTitle>
              </FormTitleContainer>
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  if (onSubmit) onSubmit({ ...this.state, role: 'U_ADMIN' });
                  close();
                }}
              >
                <FormBody>
                  <FormRow>
                    <label className='required'>
                      이메일
                    </label>
                    <input
                      type='email'
                      name='email'
                      ref={(ref) => this._firstInput = ref}
                      value={this.state.email}
                      onChange={(event) => this.setState({ email: event.currentTarget.value })}
                      required
                    />
                  </FormRow>
                  <FormRow>
                    <label className='required'>
                      이름
                    </label>
                    <input
                      type='text'
                      name='name'
                      value={this.state.name}
                      onChange={(event) => this.setState({ name: event.currentTarget.value })}
                      autoComplete='off'
                      required
                    />
                  </FormRow>
                </FormBody>
                <FormFooterContainer>
                  <CancelButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      if (onCancel) onCancel();
                      close();
                    }}
                    href='#'
                  >
                    <span>취소</span>
                  </CancelButton>
                  <AcceptButton
                    className='with-hover-overlay'
                    type='submit'
                  >
                    <span>확인</span>
                  </AcceptButton>
                </FormFooterContainer>
              </Form>
            </ContentContainer>
          );
        }}
      </Popup>
    );
  }
}

export { AdminAccountForm };