// @flow
// type imports
import type { AppState } from '../../../types';
import type { Customer } from '../../../types/customers';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Query } from '@apollo/client/react/components';
import { ComposedAreaChart } from '../../common';
import {
  ChartSpinnerContainer,
  TooltipContent,
  Tick
} from './styled';
import { STAT_WEEKLY_ANLZ_BO_QUERY } from '../../../utils/graphql/queries';
import { Spinner } from '../../common';

type OwnProps = {};

type StateProps = {
  selectedCustomer: Customer | null
};

type Props = OwnProps & StateProps;

class WeekAnlzStatsComponent extends React.Component<Props, { activeTopDotY: number }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTopDotY: 0
    }
  }

  render() {
    const { selectedCustomer } = this.props;
    const datasetDefs = [
      { key: 'cpts', name: '분석 완료', color: '#00bbf9' },
      { key: 'srcs', name: '분석용 파일', color: '#576881' }
    ];
    return (
      <Query
        query={STAT_WEEKLY_ANLZ_BO_QUERY}
        pollInterval={5000}
        variables={{
          orgzId: selectedCustomer && selectedCustomer.orgz.id,
          from: moment().startOf('isoWeek').subtract(4, 'w').toISOString(true)
        }}
        context={{
          dataSourceType: 'api'
        }}
      >
        {({ loading, data, error }) => {
          if (data && data.boStatAnlzByZWeek) {
            console.log(data.boStatAnlzByZWeek);
            return (
              <ComposedAreaChart
                data={_.map(data.boStatAnlzByZWeek, (stat, idx) => {
                  const fromDate = moment(stat.statTime.from).format('MM/DD');
                  const toDate = moment(stat.statTime.to).format('MM/DD');
                  return {
                    ...stat,
                    name: `${idx+1}W(${fromDate}-${toDate})`
                  };
                })}
                chartMargin={{ top: 0, bottom: 25, left: 30, right: 50 }}
                datasetDefs={datasetDefs}
                tooltipContent={<TooltipContent />}
                height={280}
                tooltipOffsetX={-82}
                tooltipOffsetY={-120}
                xTick={<Tick />}
              />
            );
          }
          return <ChartSpinnerContainer><Spinner /></ChartSpinnerContainer>;
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    selectedCustomer: state.customers.customerList.selectedCustomer
  };
}

export const WeekAnlzStats = connect(mapStateToProps, {})(WeekAnlzStatsComponent);

/*
<Query
          query={STAT_MONTHLY_ANLZ_QUERY}
          pollInterval={5000}
          variables={{
            year: moment().startOf('year').toISOString(true),
            months: 12
          }}
          context={{
            dataSourceType: 'api'
          }}
        >
          {({ loading, data, error }) => {
            if (data && data.statAnlzByZMonth) {
              return (
                <ComposedBarChart
                  data={_.map(data.statAnlzByZMonth, (stat, idx) => {
                    return {
                      ...stat,
                      name: monthLabels[idx]
                    };
                  })}
                  datasetDefs={datasetDefs}
                  title='Month'
                  tooltipContent={<TooltipContent />}
                  height={419}
                  tooltipOffsetX={-82}
                  tooltipOffsetY={-120}
                />
              );
            }
            return <ChartSpinnerContainer><Spinner /></ChartSpinnerContainer>;
          }}
        </Query>
        */