import styled from 'styled-components';
import { AnchorButton } from '../Button';

export const Container = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const MessageContainer = styled.div`
  flex: 1 1 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  height: 45px;
  width: 100%;
  flex: 0 0 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const CancelButton = styled(AnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #94aabf;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const ConfirmButton = styled(AnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #576881;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;