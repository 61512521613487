// @flow
// module imports
import * as React from 'react';
import { SegmentedPanel } from '../../common';
import { WeekAnlzStats } from './WeekAnlzStats';
import { MonthAnlzStats } from './MonthAnlzStats';

class StatsChartPanel extends React.Component<{}> {
  render() {
    return (
      <SegmentedPanel
        title='분석용 파일 및 분석 완료 통계'
        segments={[{
          label: 'Week',
          key: 'week'
        },{
          label: 'Month',
          key: 'month'
        }]}
      >
        {({ activeSegment }) => {
          console.log(activeSegment);
          if (activeSegment === 'week') {
            return <WeekAnlzStats />;
          } else if (activeSegment === 'month') {
            return <MonthAnlzStats />;
          }
          return activeSegment;
        }}
      </SegmentedPanel>
    )
  }
}

export { StatsChartPanel };