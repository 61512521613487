// @flow
// type imports
import type { DataRow, ColumnMetadata, TableState, Page } from '../../types/common';
// module imports
import { DataSource } from '../../types/common';
import _ from 'lodash';

const SET_ROWS: 'sd/c/table/SET_ROWS' = 'sd/c/table/SET_ROWS';
const SET_COLUMNS: 'sd/c/table/SET_COLUMNS' = 'sd/c/table/SET_COLUMNS';
const SELECT_ROW: 'sd/c/table/SELECT_ROW' = 'sd/c/table/SELECT_ROW';
const FOCUS_ROW: 'sd/c/table/FOCUS_ROW' = 'sd/c/table/FOCUS_ROW';
const CHECK_ROWS: 'sd/c/table/CHECK_ROWS' = 'sd/c/table/CHECK_ROWS';
const UNCHECK_ROWS: 'sd/c/table/UNCHECK_ROWS' = 'sd/c/table/UNCHECK_ROWS';
const VIEW_NEXT_PAGE: 'sd/c/table/VIEW_NEXT_PAGE' = 'sd/c/table/VIEW_NEXT_PAGE';
const VIEW_PREV_PAGE: 'sd/c/table/VIEW_PREV_PAGE' = 'sd/c/table/VIEW_PREV_PAGE';

type SetRowsAction = {
  type: typeof SET_ROWS,
  name: string,
  payload: DataRow[]
};

type SetColumnsAction = {
  type: typeof SET_COLUMNS,
  name: string,
  payload: ColumnMetadata[]
};

type SelectRowAction = {
  type: typeof SELECT_ROW,
  name: string,
  payload: string
};

type FocusRowAction = {
  type: typeof FOCUS_ROW,
  name: string,
  payload: number
};

type CheckRowsAction = {
  type: typeof CHECK_ROWS,
  name: string,
  payload: string[]
};

type UncheckRowsAction = {
  type: typeof UNCHECK_ROWS,
  name: string,
  payload: string[]
};

type ViewNextPageAction = {
  type: typeof VIEW_NEXT_PAGE,
  name: string
};

type ViewPrevPageAction = {
  type: typeof VIEW_PREV_PAGE,
  name: string
};

type TableActions = (
  SetRowsAction |
  SetColumnsAction |
  SelectRowAction |
  FocusRowAction |
  CheckRowsAction |
  UncheckRowsAction |
  ViewNextPageAction |
  ViewPrevPageAction
)

const INITIAL_STATE: TableState = {
  dataSource: null,
  columns: [],
  selectedRowId: '',
  focusedRowIdx: -1,
  checkedRowIds: [],
  currentPage: -1,
  pages: []
};

export const createTableReducer = (tableName: string = '') => {
  return (state: TableState = INITIAL_STATE, action: TableActions): TableState => {
    const { name } = action;
    if (name !== tableName) return state;
    switch (action.type) {
      case SET_ROWS:
        const dataSource = DataSource.createDataSourceWithRows(action.payload);
        const pages = _.map(_.chunk(dataSource.rows, 20), (rows): Page => {
          return {
            rows: _.map(rows, (row) => row.rowId()),
            checkableRows: _.map(_.filter(rows, (row) => row.isCheckable && row.isCheckable()), (row) => row.rowId())
          };
        });
        return {
          ...state,
          currentPage: (pages.length > 0) ? ((state.currentPage < 0) ? 0 : state.currentPage) : -1,
          selectedRowId: '',
          focusedRowIdx: -1,
          checkedRowIds: [],
          pages,
          dataSource
        };
      case SET_COLUMNS:
        return {
          ...state,
          columns: action.payload
        };
      case SELECT_ROW:
        return {
          ...state,
          selectedRowId: action.payload
        };
      case FOCUS_ROW:
        return {
          ...state,
          focusedRowIdx: action.payload
        };
      case CHECK_ROWS:
        return {
          ...state,
          checkedRowIds: _.union(state.checkedRowIds, action.payload)
        }
      case UNCHECK_ROWS: {
        return {
          ...state,
          checkedRowIds: _.difference(state.checkedRowIds, action.payload)
        };
      }
      case VIEW_NEXT_PAGE:
        return {
          ...state,
          focusedRowIdx: -1,
          checkedRowIds: [],
          currentPage: (state.currentPage + 1 < state.pages.length) ? state.currentPage + 1 : state.pages.length - 1
        };
      case VIEW_PREV_PAGE:
        return {
          ...state,
          focusedRowIdx: -1,
          checkedRowIds: [],
          currentPage: (state.currentPage - 1 >= 0) ? state.currentPage - 1 : 0
        };
      default: return state;
    }
  }
};

const setRows = (tableName: string, dataRows: DataRow[]): SetRowsAction => {
  return {
    type: SET_ROWS,
    name: tableName,
    payload: dataRows
  };
};

const setColumns = (tableName: string, columns: ColumnMetadata[]): SetColumnsAction => {
  return {
    type: SET_COLUMNS,
    name: tableName,
    payload: columns
  };
};

const selectRow = (tableName: string, rowId: string): SelectRowAction => {
  return {
    type: SELECT_ROW,
    name: tableName,
    payload: rowId
  };
};

const focusRow = (tableName: string, rowIdx: number): FocusRowAction => {
  return {
    type: FOCUS_ROW,
    name: tableName,
    payload: rowIdx
  };
};

const checkRows = (tableName: string, rowIds: string[]): CheckRowsAction => {
  return {
    type: CHECK_ROWS,
    name: tableName,
    payload: rowIds
  };
};

const uncheckRows = (tableName: string, rowIds: string[]): UncheckRowsAction => {
  return {
    type: UNCHECK_ROWS,
    name: tableName,
    payload: rowIds
  };
};

const viewNextPage = (tableName: string): ViewNextPageAction => {
  return {
    type: VIEW_NEXT_PAGE,
    name: tableName
  };
};

const viewPrevPage = (tableName: string): ViewPrevPageAction => {
  return {
    type: VIEW_PREV_PAGE,
    name: tableName
  };
};

export const actionCreators = {
  setRows,
  setColumns,
  selectRow,
  focusRow,
  checkRows,
  uncheckRows,
  viewNextPage,
  viewPrevPage
};