// @flow
import * as React from 'react';
import styled from 'styled-components';

const RadioContainer = styled.label`
  position: relative;
  display: flex;
  flex: 0 1 ${props => props.width || 70}px;
  cursor: ${props => props.disabled ? 'normal' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1.0};
  padding-left: 26px;
  align-items: center;
  &:not(:first-child) {
    margin-left: 15px;
  }
  & input {
    position: absolute;
    opacity: 0;
    cursor: ${props => props.disabled ? 'normal' : 'pointer'};
    & ~ .radio-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      border: solid 1px #d0dcea;
      border-radius: 50%;
      &::after {
        content: '';
        position: absolute;
        display: none;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${(props) => props.color || '#00bbf9'};
      }
    }
    &:checked ~ .radio-mark::after {
      display: block;
    }
    &:not(:checked):focus ~ .radio-mark::after {
      display: block;
      opacity: 0.3;
    }
  }
  &:hover input:not(:checked) ~ .radio-mark::after {
    display: ${props => props.disabled ? 'none' : 'block'};
    opacity: 0.3;
  }
`;

type Props = {
  name?: string,
  value?: string,
  color?: string,
  disabled?: boolean,
  checked: boolean,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => any,
  children?: React.Node,
  required?: boolean,
  title?: string,
  width?: number
};

export const Radio = ({ name, value, checked, onChange, required, children, color, disabled, width, title }: Props) => {
  console.log(disabled);
  return (
    <RadioContainer color={color} width={width} disabled={disabled} title={title}>
      <input
        disabled={disabled}
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        required={required}
      />
      <span className='radio-mark'></span>
      {children}
    </RadioContainer>
  )
}