// @flow
import styled from 'styled-components';
import { HoverStyledButton, AnchorButton, Button, EllipsisSpan } from '../../common';

export const TableControlButton = styled(HoverStyledButton)`
  height: 45px;
  width: 45px;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
`;

export const TablePagingInfo = styled.span`
  font-size: 18px;
  line-height: 1.17;
  color: #586881;
  min-width: 40px;
  text-align: center;
  .current-page {
    color: #00bbf9;
  }
`;

export const DefaultCellSpan = styled(EllipsisSpan)`
  text-align: center;
  padding: 0 10px;
  width: 0;
  flex: 1 1 0%;
`;

export const RightButtonsContainer = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const RegisterCustomerButton = styled(AnchorButton)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 120px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const RefreshButton = styled(AnchorButton)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  margin-left: 8px;
  height: 45px;
  width: 45px;
  > img {
    width: 22px;
    height: 20px;
    line-height: 45px;
    object-fit: contain;
  }
`;

export const RegisterAdminButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  width: 120px;
  background-color: #94aabf;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const ConfirmMessage = styled.p`
  font-size: 20px;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #000;
  white-space: normal;
  span {
    color: #fc5882;
  }
`;