// @flow
import _ from 'lodash';
import * as React from 'react';
import {
  ResultContainer,
  ResponseRow,
  ChoicePrompt,
  ChoiceResponse
} from './styled';
import { Spinner } from '../../common';

type Props = {
  idHashCode: string,
  surveyResult: any,
  loading: boolean,
  error: any
};

class SurveyResults extends React.Component<Props> {
  render() {
    const {
      surveyResult,
      loading,
      error
    } = this.props;
    console.log(surveyResult);
    return (
      <ResultContainer>
        {(loading) ?
          <Spinner/> :
          ((!error && surveyResult) ? (
            (surveyResult.surveyResp && surveyResult.surveyResp.length > 0) ?
              _.map(surveyResult.surveyResp, (response, idx: number) => {
                const key = `${this.props.idHashCode}_${idx}`;
                switch (response.answerType) {
                  case 'CHOICE':
                    if (response.answer.length === 1) {
                      const choice = response.answer[0];
                      return (
                        <ResponseRow key={key}>
                          <ChoicePrompt>
                            <span>{`${(idx + 1 > 9) ? idx + 1 : `0${idx + 1}`}.`}</span>
                            <p>{response.prompt}</p>
                          </ChoicePrompt>
                          <ChoiceResponse>
                            <span>{`${(idx + 1 > 9) ? idx + 1 : `0${idx + 1}`}.`}</span>
                            <p>{(choice && !_.isNil(choice.score)) ? `${choice.score}점(${choice.text.split('(')[0].trim()})` : choice.text}</p>
                          </ChoiceResponse>
                        </ResponseRow>
                      );
                    } else {
                      return (
                        <ResponseRow key={key}>
                          <ChoicePrompt>
                            <span>{`${(idx + 1 > 9) ? idx + 1 : `0${idx + 1}`}.`}</span>
                            <p>{response.prompt}</p>
                          </ChoicePrompt>
                          <ChoiceResponse>
                            <span>{`${(idx + 1 > 9) ? idx + 1 : `0${idx + 1}`}.`}</span>
                            <p>{_.reduce(response.answer, (choiceStr, choice, cIdx: number) => {
                              if (!choiceStr) {
                                return `${(choice && !_.isNil(choice.score)) ? `${choice.score}점(${choice.text.split('(')[0].trim()})` : choice.text}`
                              }
                              return `${choiceStr}, ${(choice && !_.isNil(choice.score)) ? `${choice.score}점(${choice.text.split('(')[0].trim()})` : choice.text}`
                            }, '')}</p>
                          </ChoiceResponse>
                        </ResponseRow>
                      );
                    }
                  case 'ESSAY':
                    return (
                      <ResponseRow key={key}>
                        <ChoicePrompt>
                          <span>{`${(idx + 1 > 9) ? idx + 1 : `0${idx + 1}`}.`}</span>
                          <p>{response.prompt}</p>
                        </ChoicePrompt>
                        <ChoiceResponse>
                          <span>{`${(idx + 1 > 9) ? idx + 1 : `0${idx + 1}`}.`}</span>
                          <p>{!_.isNil(response.answer) ? response.answer : ''}</p>
                        </ChoiceResponse>
                      </ResponseRow>
                    );
                }
              }) : (
                <ResponseRow>
                  <ChoicePrompt>
                    <p>설문세트에 대한 질문이 없습니다. 하단의 버튼을 이용하여 각 설문의 답변을 확인할 수 있습니다.</p>
                  </ChoicePrompt>
                </ResponseRow>
              )
            ) :
            null
          )
        }
      </ResultContainer>
    );
  }
}

export { SurveyResults };