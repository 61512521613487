// @flow
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex: 1 1 0%;
  border: solid 1px #d0dcea;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 23px;
  flex: 0 0 23px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 13px;
  margin-top: 10px;
  &:not(:first-child) {
    margin-top: 4px;
  }
  > span {
    flex: 43 43 43px;
    color: #435268;
    font-size: 12px;
  }
  > div {
    font-size: 0;
    flex: 230 230 230px;
    height: 23px;
    margin-left: 11px;
  }
`;

export const CodeSearch = styled.div`
  position: relative;
  > input[type="text"] {
    font-size: 11px;
    padding: 0 20px 0 8px;
    width: 100%;
    height: 23px;
    border: solid 1px #ebf0f4;
    box-sizing: border-box;
    display: block;
    &:focus {
      outline: none;
      border: solid 1px #435268;
    }
    &::placeholder {
      color: #d0dcea
    }
  }
  > img {
    position: absolute;
    width: 10px;
    height: 10px;
    object-fit: contain;
    top: 6px;
    right: 7px;
  }
`;

export const CodeListContainer = styled.div`
  flex: 1 1 0%;
  min-height: 0;
  display: flex;
  width: 100%;
  margin-top: 10px;
`;