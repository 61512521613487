import * as React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client'
import { connect } from 'react-redux';
import { patientListActions, anlzRequestListActions } from '../../ducks/analysis';
import moment from 'moment';
import {
  Page,
  PanelGrid
} from '../../components/common';
import {
  PatientDetailPanel,
  PatientForm,
  AnlzSourceDetailPanel,
  AnlzRequestDetailPanel,
  SurveySessionDetailPanel
} from '../../components/analysis';
import {
  SBJT_BYID_QUERY
} from '../../utils/graphql/queries';
import {
  DELETE_ANLZ_SRCS_MUTATION,
  DELETE_SURVEY_SESSIONS_MUTATION,
  CREATE_SURVEY_SESSION_MUTATION,
  DELETE_ANLZ_REQS_MUTATION,
  SEND_KAKAO_MESSAGE_MUTATION
} from '../../utils/graphql/mutations';
import { useParams } from 'react-router-dom';

type OwnProps = {
};

type StateProps = PatientListState & {
  selectedAnlzSource: AnlzSource | null,
  selectedSurveySession: SurveySession | null,
  selectedAnlzRequest: AnlzRequest | null,
  activePatientDetailTab: string
};
type ActionProps = {
  downloadAnlzResource: typeof anlzRequestListActions.downloadAnlzResource
};

type State = {
  noDataText: string
};

type Props = OwnProps & StateProps & ActionProps;

// const ALL_CONTENT_SIZE = 2147483647;

// const { data: anlzSrcData, loading: anlzSrcLoading, error: anlzSrcError } = useQuery(ANLZ_SRCS_BY_SBJTID_QUERY, {
//   variables: {
//     subjectId: subjectId,
//     page: 0,
//     size: ALL_CONTENT_SIZE
//   },
//   fetchPolicy: 'network-only'
// });

const SubjectDetailComponent = (props: Props) => {
  const [noDataText, setNoDataText] = React.useState('');
  const { subjectId } = useParams();

  const { data: subjectData, loading: subjectLoading, error: subjectError, refetch } = useQuery(SBJT_BYID_QUERY, {
    variables: {
      id: subjectId
    },
    context: {
      dataSourceType: 'api'
    }
  });

  // TODO: handle subjectError

  console.log(subjectData);
  // TODO: update page title to be breadcrumb
  return (
    <Page
      useBreadcrumb
      breadcrumbProps={{
        matchStr: '/analysis/subject',
        pathPieceToString: (piece: string) => {
          switch (piece) {
            case 'analysis': return 'Analysis';
            case 'subject': return 'Subject Detail';
            default: return piece;
          }
        }
      }}
      subtitle='등록된 대상자의 측정기록 상태를 검색하고 분석을 요청하여 관리할 수 있습니다'
      contentMinWidth={1295}
    >
      <PanelGrid.Grid>
        <PanelGrid.Row style={{ height: '753px'}}>
          <PanelGrid.Col minWidth={1175}>
            <PatientDetailPanel selectedPatient={subjectData && subjectData.sbjtById} patientLoading={subjectLoading} />
            <PatientForm
              onMutationSuccess={(result) => {
                console.log(result);
                refetch();
              }}
            />
          </PanelGrid.Col>
        </PanelGrid.Row>
      </PanelGrid.Grid>
    </Page>
  );
}

export const SubjectDetail = SubjectDetailComponent;
