// @flow
import * as React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { PlainPanel, EllipsisSpan, Spinner, Radio } from '../../common';
import mypageImages from '../../../resources/images/mypage';
import { Container, PanelLogo, FooterButton, ProfileInfoContainer, ProfileInfoRow, SpinnerContainer } from './styled';
import { EMP_ME_QUERY } from '../../../utils/graphql/queries';
import { UPDATE_ME_MUTATION } from '../../../utils/graphql/mutations';
import { loadUserPreferences, setUserPreferences } from '../../../utils/localStorage';
import { connect } from 'react-redux';

type Props = {};

type State = {
  editing: boolean,
  surveyMethod: 'web' | 'kakao',
}

class ProfilePanelComponent extends React.Component<Props, State> {
  _firstInput: ?HTMLInputElement;
  _refetch: () => Promise<any>;

  constructor(props: Props) {
    super(props);
    const defaultSurveyMethod = loadUserPreferences(props.userEmail)['surveyMethod'] || 'web';
    this.state = {
      editing: false,
      surveyMethod: defaultSurveyMethod,
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.editing !== prevState.editing && this._firstInput) {
      this._firstInput.focus();
    }
  }

  renderProfileUpdateForm(data:any, editing: boolean) {
    if(editing) {
      return (
        <Mutation
          mutation={UPDATE_ME_MUTATION}
          context={{
            dataSourceType: 'auth'
          }}
        >
          {(updateMe, { loading, error }) => {
            console.log(loading);
            return (
              <React.Fragment>
                <form 
                  onSubmit={(event) => {
                    event.preventDefault();
                    const empIn = {
                      tel: this._firstInput && this._firstInput.value
                    };
                    updateMe({ variables: { empIn }}).then((result) => {
                      this._refetch();
                      this.setState({ editing: false });
                    }).catch((error) => {
                      console.log(error);
                    });
                  }}
                >
                  <ProfileInfoRow>
                    <img src={mypageImages.phone} alt='Phone'/>
                    <input defaultValue={data && data.empMe && data.empMe.tel} type='text' name='phone' ref={ref => this._firstInput = ref} />
                  </ProfileInfoRow>
                  <FooterButton
                    type='submit'
                    disabled={loading}
                  >
                    <img src={mypageImages.save} alt='Save'/>
                    <img src={mypageImages.saveHover} alt='Save hover' className='hover-img'/>
                  </FooterButton>
                </form>
                {(loading) ? <SpinnerContainer><Spinner /></SpinnerContainer> : null}
              </React.Fragment>
            );
          }}
        </Mutation>
      )
    }
    return (
      <ProfileInfoRow>
        <img src={mypageImages.phone} alt='Phone'/>
        <EllipsisSpan>{(data && data.empMe && data.empMe.tel) ? data.empMe.tel : '-'}</EllipsisSpan>
      </ProfileInfoRow>
    );
  }

  renderFooterButton(editing: boolean) {
    if (!editing) {
      return (
        <FooterButton
          onClick={() => this.setState({ editing: true })}
        >
          <img src={mypageImages.edit} alt='Edit setting'/>
          <img src={mypageImages.editHover} alt='Edit setting hover' className='hover-img'/>
        </FooterButton>
      );
    }
  }

  render() {
    const { surveyMethod } = this.state;
    return (
      <Query
        query={EMP_ME_QUERY}
        fetchPolicy='network-only'
        context={{
          dataSourceType: 'auth'
        }}
      >
        {({loading, error, data, refetch}) => {
          this._refetch = refetch;
          return (
            <PlainPanel
              title='프로필'
              headerBorderColor='#00bbf9'
              headerBackgroundColor='#fff'
            >
              <Container>
                <PanelLogo src={mypageImages.profilePanel} alt='Profile' />
                <ProfileInfoContainer>
                  <ProfileInfoRow>
                    <img src={mypageImages.username} alt='Name'/>
                    <EllipsisSpan>{(data && data.empMe) ? data.empMe.name : '-'}</EllipsisSpan>
                  </ProfileInfoRow>
                  <ProfileInfoRow>
                    <img src={mypageImages.email} alt='Email'/>
                    <EllipsisSpan>{(data && data.empMe) ? data.empMe.email : '-'}</EllipsisSpan>
                  </ProfileInfoRow>
                  <ProfileInfoRow>
                    <img src={mypageImages.hospitalname} alt='Hospital Name'/>
                    <EllipsisSpan>{(data && data.empMe && data.empMe.orgz) ? data.empMe.orgz.name : '-'}</EllipsisSpan>
                  </ProfileInfoRow>
                  <ProfileInfoRow>
                    <img src={mypageImages.code} alt='Hospital Name'/>
                    <EllipsisSpan>{(data && data.empMe && data.empMe.orgz) ? data.empMe.orgz.code : '-'}</EllipsisSpan>
                  </ProfileInfoRow>
                  {this.renderProfileUpdateForm(data, this.state.editing)}
                  <ProfileInfoRow>
                    <Radio
                      name='surveyMethod'
                      value='web'
                      width={140}
                      checked={surveyMethod === 'web'}
                      onChange={(event) => {
                        setUserPreferences(this.props.userEmail, 'surveyMethod', 'web');
                        this.setState({ surveyMethod: 'web' });
                      }}
                      required
                    >
                      웹으로 작성
                    </Radio>
                    <Radio
                      name='surveyMethod'
                      width={140}
                      value='kakao'
                      checked={surveyMethod === 'kakao'}
                      onChange={(event) => {
                        setUserPreferences(this.props.userEmail, 'surveyMethod', 'kakao');
                        this.setState({ surveyMethod: 'kakao' });
                      }}
                      required
                    >
                      카카오톡 전송
                    </Radio>
                  </ProfileInfoRow>
                </ProfileInfoContainer>
                {this.renderFooterButton(this.state.editing)}
                {(loading) ? <SpinnerContainer><Spinner /></SpinnerContainer> : null}
              </Container>
            </PlainPanel>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userEmail: state.auth.currentUser.user_name
  };
}

export const ProfilePanel = connect(mapStateToProps, {})(ProfilePanelComponent);
