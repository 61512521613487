import React from "react";
import UpdateNotiPopup from "./UpdateNotiPopup";

const Notification = ({ currentUser }) => {
  return (
    <>
      <UpdateNotiPopup currentUser={currentUser} />
    </>
  );
};

export default Notification;
