// @flow
// type imports
import type { CurrentUserState } from "../../../types/auth";
import type { AppState } from "../../../types";
import type {
  AnlzRequest,
  AnlzRequestListState,
  Patient,
} from "../../../types/analysis";

// module imports
import _ from "lodash";
import * as React from "react";
import moment from "moment";
import Popup from "reactjs-popup";
import { Query } from "@apollo/client/react/components";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Tooltip from "@material-ui/core/Tooltip";
import { compose } from "redux";
import { connect } from "react-redux";
import ReactTable from "react-table";
import {
  PanelContentContainer,
  SessionListContainer,
  PDFDownloadButton,
  ErrorMsgTooltipSpan,
  DetailContainer,
  ToolbarPaper,
  ToolbarButton,
  Spacer,
} from "./styled";
import {
  anlzRequestListActions,
  surveySessionListActions,
} from "../../../ducks/analysis";
import {
  ANLZ_REQUESTS_BY_SBJTID_QUERY,
  EMP_ME_QUERY,
} from "../../../utils/graphql/queries";
import analysisImages from "../../../resources/images/analysis";
import {
  selectableTable,
  checkableTable,
  styledTable,
  Spinner,
  PanelGrid,
  EllipsisSpan,
} from "../../common";
import { AnlzRequestDetailPanel } from "../AnlzRequestDetailPanel";
import MoreMenu from "./MoreMenu";

const SelectableTable = compose(
  styledTable,
  selectableTable,
  checkableTable
)(ReactTable);

type StateProps = AnlzRequestListState & {
  selectedPatient: Patient | null,
  currentUser: CurrentUserState,
};

type ActionProps = typeof anlzRequestListActions;

type Props = StateProps & ActionProps;

type State = {
  drawerOpen: boolean,
};

class AnlzRequestListComponent extends React.Component<Props, State> {
  _refetch: () => any;
  _listPageSize: number = 10;

  constructor(props: Props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.refetch) {
      console.log("refetch");
      this._refetch().then((result) => {
        const { nowPage, totalPages } = result.data.anlzReqPgBySbjtId.pageInfo;
        this.props.refetchAnlzRequestsDone();
        this.props.setPagingInfo(nowPage, totalPages);
      });
    }
    if (
      prevProps.selectedPatient &&
      this.props.selectedPatient &&
      prevProps.selectedPatient.id !== this.props.selectedPatient.id
    ) {
      this.props.clearAnlzRequestSelection();
    } else if (!this.props.selectedPatient) {
      this.props.clearAnlzRequestSelection();
    }
  }

  componentWillUnmount() {
    this.props.clearAnlzRequestSelection();
    this.props.resetListState();
  }

  formatAnlzType(anlzType: string): string {
    switch (anlzType) {
      case "INDIVIDUAL":
        return "개별";
      case "COMPARISON":
        return "비교";
      default:
        return anlzType;
    }
  }

  formatSummaryForIndividual(anlzSources, surveys) {
    let ret = "";
    if (anlzSources && anlzSources.length > 0) {
      ret = `파일: ${anlzSources[0].visualFile}`;
    } else {
      ret = `파일 없음`;
    }
    if (surveys.length > 0) {
      ret = `${ret}, 설문 ${surveys.length}개`;
    }
    return ret;
  }

  formatSummaryForComparison(anlzSources) {
    console.log(anlzSources);
    if (anlzSources && anlzSources.length === 2) {
      return `PRE: ${anlzSources[0].visualFile}, POST: ${anlzSources[1].visualFile}`;
    }
  }

  selectedAnlzReqTitle(anlzRequest: AnlzRequest) {
    if (!anlzRequest) return "";
    const { id, created, anlzType } = anlzRequest;
    return `${moment(created).format("YYYY-MM-DD HH:mm")} ${this.formatAnlzType(
      anlzType
    )}분석 (id: ${id})`;
  }

  renderDetailPanel(
    selectedPatient: ?Patient,
    selectedAnlzRequest: ?AnlzRequest
  ) {
    if (!selectedAnlzRequest || !selectedPatient) {
      return null;
    }
    return (
      <PanelGrid.Row style={{ minHeight: "540px" }}>
        <PanelGrid.Col minWidth={1175}>
          <AnlzRequestDetailPanel
            selectedPatient={selectedPatient}
            selectedAnlzRequest={selectedAnlzRequest}
            downloadAnlzResource={this.props.downloadAnlzResource}
          />
        </PanelGrid.Col>
      </PanelGrid.Row>
    );
  }

  render() {
    const {
      selectedPatient,
      selectedAnlzRequest,
      checkedAnlzRequests,
      currentPage,
      numPages,
      currentUser,
    } = this.props;
    
    return (
      <Query
        query={EMP_ME_QUERY}
        fetchPolicy="network-only"
        context={{
          dataSourceType: "auth",
        }}
      >
        {({ loading, error, data: empData, refetch }) => {
          return (
            <Query
              query={ANLZ_REQUESTS_BY_SBJTID_QUERY}
              context={{
                dataSourceType: "api",
              }}
              skip={!selectedPatient}
              fetchPolicy="network-only"
              variables={{
                subjectId: selectedPatient && selectedPatient.id,
                // anlzType: 'INDIVIDUAL',
                page: currentPage,
                size: this._listPageSize,
              }}
              onCompleted={(data) => {
                if (data && data.anlzReqPgBySbjtId) {
                  const { nowPage, totalPages } =
                    data.anlzReqPgBySbjtId.pageInfo;
                  this.props.setPagingInfo(nowPage, totalPages);
                }
              }}
            >
              {({ loading, error, data, refetch }) => {
                console.log(data);
                console.log(loading);
                console.log(error);
                this._refetch = refetch;
                return (
                  <>
                    <PanelContentContainer padding="13px 0px 45px 0px">
                      <SessionListContainer>
                        <SelectableTable
                          data={
                            !selectedPatient
                              ? []
                              : data && data.anlzReqPgBySbjtId
                              ? data.anlzReqPgBySbjtId.requests
                              : []
                          } // data requests
                          isRowCheckable={(rowData) =>
                            rowData.anlzStatus !== "PROCEEDING"
                          }
                          loading={!selectedPatient ? false : loading}
                          page={
                            data && data.anlzReqPgBySbjtId
                              ? data.anlzReqPgBySbjtId.pageInfo.nowPage
                              : currentPage
                          }
                          pageSize={this._listPageSize} // props pageSize
                          minRows={0}
                          manual
                          LoadingComponent={Spinner}
                          pages={
                            data && data.anlzReqPgBySbjtId
                              ? data.anlzReqPgBySbjtId.pageInfo.totalPages
                              : numPages
                          }
                          columns={[
                            {
                              id: "created",
                              Header: "분석 요청일",
                              minWidth: 150,
                              accessor: (rowData) =>
                                moment(rowData.created).format(
                                  "YYYY-MM-DD HH:mm"
                                ),
                            },
                            {
                              id: "type",
                              Header: "분석 유형",
                              minWidth: 80,
                              accessor: (rowData) =>
                                this.formatAnlzType(rowData.anlzType),
                            },
                            {
                              id: "summary",
                              minWidth: 400,
                              Header: "요약",
                              Cell: (rowInfo) => {
                                const { anlzType, anlzSources, surveys } =
                                  rowInfo.value;
                                switch (anlzType) {
                                  case "INDIVIDUAL":
                                    return (
                                      <EllipsisSpan>
                                        {this.formatSummaryForIndividual(
                                          anlzSources,
                                          surveys
                                        ) || "-"}
                                      </EllipsisSpan>
                                    );
                                  case "COMPARISON":
                                    return (
                                      <EllipsisSpan>
                                        {this.formatSummaryForComparison(
                                          anlzSources
                                        ) || "-"}
                                      </EllipsisSpan>
                                    );
                                }
                              },
                              accessor: (rowData) => ({
                                anlzType: rowData.anlzType,
                                anlzSources: rowData.anlzSources,
                                surveys: rowData.surveys,
                              }), // && rowData.anlzSources.length > 0 && rowData.anlzSources[0].visualFile,
                            },
                            {
                              id: "empName",
                              minWidth: 100,
                              Header: "분석 요청자",
                              Cell: (rowInfo) => (
                                <EllipsisSpan>{rowInfo.value}</EllipsisSpan>
                              ),
                              accessor: (rowData) => rowData.employeeName,
                            },
                            {
                              id: "anlzStatus",
                              minWidth: 150,
                              Header: "분석 결과",
                              Cell: (rowInfo) => {
                                const { anlzStatus, remarks } = rowInfo.value;
                                switch (anlzStatus) {
                                  case "REQUEST":
                                    return "분석 요청중";
                                  case "PROCEEDING":
                                    return "분석중";
                                  case "COMPLETE":
                                    return rowInfo.original.actualResultFile ? (
                                      <PDFDownloadButton
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          event.preventDefault();
                                          if (selectedPatient) {
                                            this.props.downloadAnlzResource(
                                              selectedPatient.id,
                                              encodeURIComponent(
                                                rowInfo.original
                                                  .actualResultFile
                                              ),
                                              rowInfo.original.visualResultFile
                                            );
                                          }
                                        }}
                                        href="#"
                                        download
                                      >
                                        <img
                                          src={analysisImages.pdf}
                                          alt="Download PDF"
                                        />
                                      </PDFDownloadButton>
                                    ) : (
                                      "분석완료"
                                    );
                                  case "CANCEL":
                                    return "분석취소";
                                  case "FAIL":
                                    return (
                                      <Popup
                                        trigger={<span>분석실패</span>}
                                        position="top center"
                                        contentStyle={{
                                          width: "auto",
                                          minWidth: "40px",
                                          maxWidth: "400px",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          justifyContent: "center",
                                        }}
                                        arrowStyle={{
                                          boxShadow:
                                            "1.2px 1.2px 1.5px 0 rgba(0,0,0,0.2)",
                                          borderBottom:
                                            "1px solid rgb(187,187,187)",
                                          borderRight:
                                            "1px solid rgb(187,187,187)",
                                          borderTop: "1px solid transparent",
                                          borderLeft: "1px solid transparent",
                                        }}
                                        on="hover"
                                      >
                                        {() => (
                                          <ErrorMsgTooltipSpan>
                                            {JSON.parse(remarks)["msg"]}
                                          </ErrorMsgTooltipSpan>
                                        )}
                                      </Popup>
                                    );
                                  default:
                                    return anlzStatus;
                                }
                              },
                              accessor: (rowData) => ({
                                remarks: rowData.remarks,
                                anlzStatus: rowData.anlzStatus,
                              }),
                            },
                            {
                              id: "menu",
                              minWidth: 100,
                              Header: "",
                              Cell: (rowInfo) => (
                                <MoreMenu
                                  anlz={rowInfo.value}
                                  patient={selectedPatient}
                                  empMe={empData?.empMe}
                                  orgId={currentUser.orgz_id}
                                />
                              ),
                              accessor: (rowData) => rowData,
                            },
                          ]}
                          showPagination={false}
                          resizable={false}
                          multiSort={false}
                          sortable={false}
                          selectedRow={selectedAnlzRequest}
                          checkedRows={checkedAnlzRequests}
                          onRowsCheck={(rowIds: string[]) => {
                            this.props.checkAnlzRequests(rowIds);
                          }}
                          onRowsUncheck={(rowIds: string[]) => {
                            this.props.uncheckAnlzRequests(rowIds);
                          }}
                          onRowSelect={(rowInfo: any) => {
                            const request: AnlzRequest = rowInfo.original;
                            this.props.selectAnlzRequest(request);
                            this.setState({ drawerOpen: true });
                          }}
                          noDataText=""
                        ></SelectableTable>
                      </SessionListContainer>
                    </PanelContentContainer>
                    <DetailContainer
                      width={1295}
                      variant="temporary"
                      anchor="right"
                      open={this.state.drawerOpen}
                      onClose={() => {
                        this.setState({ drawerOpen: false });
                        this.props.clearAnlzRequestSelection();
                      }}
                      // PaperProps={{
                      //   ref: containerRef
                      // }}
                    >
                      <ToolbarPaper>
                        <Toolbar>
                          <Tooltip title="닫기">
                            <ToolbarButton
                              onClick={() => {
                                this.props.clearAnlzRequestSelection();
                                this.setState({ drawerOpen: false });
                              }}
                              size="small"
                            >
                              <CloseSharpIcon />
                            </ToolbarButton>
                          </Tooltip>
                          <Typography variant="subtitle1" noWrap>
                            {this.selectedAnlzReqTitle(selectedAnlzRequest)}
                          </Typography>
                          <Spacer />
                        </Toolbar>
                      </ToolbarPaper>
                      <Divider />
                      <PanelGrid.Grid>
                        {this.renderDetailPanel(
                          selectedPatient,
                          selectedAnlzRequest
                        )}
                      </PanelGrid.Grid>
                    </DetailContainer>
                  </>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.analysis.anlzRequestList,
    currentUser: state.auth.currentUser,
  };
};

export const AnlzRequestList: React.ComponentType<{}> = connect(
  mapStateToProps,
  anlzRequestListActions
)(AnlzRequestListComponent);
