// @flow
import type { AppState } from '../../../types';
// module imports
import * as React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { HoverStyledAnchorButton } from '../../common';
import commonImages from '../../../resources/images/common';
import { Spinner } from '../Spinner';

const FormContainer = styled.form`
  height: 23px;
  width: 23px;
  display: inline-block;
  position: relative;
  margin: 0 0 0 8px;
`;

const HiddenFileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  right: 0;
`;

export const UploadButton = styled(HoverStyledAnchorButton)`
  position: absolute;
  left: 0;
  top: 0;
  height: 23px;
  width: 23px;
  > img {
    height: 23px;
    width: 23px;
    object-fit: contain;
  }
`;

type OwnProps = {
  disabled: boolean,
  endpointUrl: string,
  onSubmitDone: (error: any) => void,
  accept?: string
};

type StateProps = {
  accessToken: string
};

type Props = OwnProps & StateProps;

type State = {
  selectedFiles: FileList | null,
  loading: boolean,
  error: any
};

class CellFileFormComponent extends React.Component<Props, State> {
  _form: ?HTMLFormElement;
  _fileInput: ?HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFiles: null,
      loading: false,
      error: null
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.loading && !this.state.loading) {
      this.setState({ selectedFiles: null });
      this.props.onSubmitDone(this.state.error);
      if (this._form) {
        this._form.reset();
      }
    } else if (this.state.selectedFiles && this.state.selectedFiles.length === 1 && this._form && !this.state.loading) {
      if (typeof(Event) === 'function') {
        this._form.dispatchEvent(new Event('submit'));
      } else {
        const submitEvent = document.createEvent('Event');
        submitEvent.initEvent('submit', true, true);
        this._form.dispatchEvent(submitEvent);
      }
    }
  }

  render() {
    const {
      disabled,
      endpointUrl,
      accept,
      accessToken
    } = this.props;
    const {
      selectedFiles,
      loading
    } = this.state;
    return (
      <FormContainer
        ref={ref => this._form = ref}
        onSubmit={(event) => {
          console.log('file form submit')
          event.preventDefault();
          if (selectedFiles) {
            const data = new FormData();
            data.append('srcFile', selectedFiles.item(0));
            this.setState({ loading: true, error: null });
            axios.post(
              endpointUrl,
              data,
              {
                timeout: 60000,
                headers: {
                  'content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${accessToken}`
                },
              }
            ).then((response) => {
              console.log(response);
              this.setState({ loading: false });
            }).catch((error) => {
              console.log(error.response.data);
              this.setState({ loading: false, error });
            });
          }
        }}
      >
        <HiddenFileInput
          ref={ref => this._fileInput = ref}
          onChange={(event) => {
            console.log(event);
            this.setState({ selectedFiles: event.target.files });
          }}
          type='file'
          tabIndex={-1}
          accept={accept}
        />
        <UploadButton
          href='#'
          disabled={disabled || loading}
          onClick={(event) => {
            if (this._fileInput) {
              this._fileInput.click();
            }
            event.preventDefault();
          }}
        >
          {(loading) ? <Spinner /> : (
            <React.Fragment>
              <img src={commonImages.upload} alt='Upload' />
              <img src={commonImages.uploadHover} alt='Upload hover' className="hover-img"/>
            </React.Fragment>
          )}
        </UploadButton>
      </FormContainer>
    )
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    accessToken: state.auth.currentUser.tokens.access_token
  };
};

export const CellFileForm = connect(mapStateToProps, {})(CellFileFormComponent);