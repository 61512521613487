// @flow
import * as React from 'react';
import styled from 'styled-components';

const BaseButton = React.forwardRef((props: any, ref) => {
  return <button ref={ref} { ...props } onMouseDown={event => event.preventDefault()} />;
});

const BaseAnchorButton = React.forwardRef((props: any, ref) => {
  return <a ref={ref} { ...props } onMouseDown={event => event.preventDefault()}>{props.children}</a>;
});

export const Button = styled(BaseButton)`
  display: inline-block;
  cursor: pointer;
  font-size: 0;
  background: transparent;
  padding: 0;
  border: none;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    overflow: hidden;
  }
  > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const AnchorButton = styled(BaseAnchorButton)`
  display: inline-block;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    overflow: hidden;
  }
  &:focus {
    outline: none;
  }
  > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const HoverStyledButton = styled(Button)`
  > img, svg {
    display: inline-block;
    &.hover-img {
      display: none;
    }
  }
  &:hover, &:focus{
     > img, svg {
      display: none;
      &.hover-img {
        display: inline-block;
      }
    }
  }
`;

export const HoverStyledAnchorButton = styled(AnchorButton)`
  > img, svg {
    display: inline-block;
    &.hover-img {
      display: none;
    }
  }
  &:hover, &:focus {
    > img, svg {
      display: none;
      &.hover-img {
        display: inline-block;
      }
    }
  }
`;