// @flow
import styled from 'styled-components';
import { HoverStyledAnchorButton } from '../../common'

export const TooltipContentContainer = styled.div`
  width: 164px;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: 7px 7px 15px 0 rgba(0, 0, 0, 0.2);
`;

const TooltipDataContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    color: #fff;
    display: block;
    text-align: center;
    &.dataset-name {
      font-size: 15px;
      font-weight: 400;
    }
    &.dataset-value {
      font-size: 30px;
      font-weight: 400;
    }
  }
  &::after {
    content:"";
    position: absolute;
    width:0px;
    height:0px;
  }
`;

export const TooltipLeftContainer = styled(TooltipDataContainer)`
  background-color: ${props => props.backgroundColor || '#00bbf9'};
  &::after {
    right: 82px;
    bottom: -9px;
    border-top: 5px solid ${props => props.backgroundColor || '#00bbf9'};
    border-right: 3px solid ${props => props.backgroundColor || '#00bbf9'};
    border-bottom: 5px solid transparent;
    border-left: 3px solid transparent;
  }
`;

export const TooltipRightContainer = styled(TooltipDataContainer)`
  background-color: #576881;
  &::after {
    left: 82px;
    bottom: -9px;
    border-top: 5px solid #576881;
    border-right: 3px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 3px solid #576881;
  }
`;

export const TooltipSingleContainer = styled(TooltipDataContainer)`
  background-color: #4a90e2;
  &::after {
    left: 77px;
    bottom: -19px;
    border-top: 10px solid #4a90e2;
    border-right: 5px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 5px solid transparent;
  }
`;

export const ChartSpinnerContainer = styled.div`
  height: 419px;
  position: relative;
`;

export const ChartContainer = styled.div`
  height: 419px;
  position: relative;
`;

export const ChartDatePrevButton = styled(HoverStyledAnchorButton)`
  height: 20px;
  width: 20px;
  text-align: center;
  position: absolute;
  display: ${props => props.enabled ? 'inline-block' : 'none'};
  bottom: 32px;
  left: 115px;
  > img {
    height: 15px;
    width: 10px;
    object-fit: contain;
  }
`;

export const ChartDateNextButton = styled(HoverStyledAnchorButton)`
  height: 20px;
  width: 20px;
  text-align: center;
  position: absolute;
  display: ${props => props.enabled ? 'inline-block' : 'none'};
  bottom: 32px;
  right: 80px;
  > img {
    height: 15px;
    width: 10px;
    object-fit: contain;
  }
`;