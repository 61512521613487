// @flow
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import commonImages from "../../../resources/images/common";
import type { CurrentUserState } from "../../../types/auth";
import type { MonthlyBillingDetailInfo } from "../../../types/billing";
import { Checkbox } from "../../common/Checkbox";
import { RefreshButton, UtilBox } from "../styled";
import { Container } from "../../support/FAQPanel/styled";
import {
  Divider2,
  FlexRow,
  SpinnerContainer,
  StatPiece,
} from "../../dashboard/CurrentStatePanels/styled";
import { insertCommas } from "../../../utils/rest";
import { Spinner } from "../../common";
import { MonthlyBillDetail } from "../../../types/billing";

const PanelContainer = styled.div`
  width: 100%;
  flex: 1 1 0%;
  min-height: 0;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PanelHeader = styled.div`
  position: relative;
  background-color: ${(props) => props.headerBackgroundColor || "#f6f9fc"};
  padding-left: 28px;
  height: 70px;
  border-left: solid 6px ${(props) => props.headerBorderColor || "#00bbf9"};
  flex: 0 0 70px;
  * {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    content: "";
    display: inline-block;
    overflow: hidden;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
`;

const Title = styled.span`
  font-family: AppleSDGothicNeo;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.68px;
  color: #576881;
`;
const BillingCountText = styled.span`
  font-family: AppleSDGothicNeo;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.68px;
  color: #94aabf;
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 16px;
  flex: 1 1 0%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Divider = styled.div`
  width: 1px;
  height: 11px;
  opacity: 0.15;
  border: solid 1px #1c3241;
  margin-left: 10px;
  margin-right: 10px;
`;

const BillingDetailContents = ({ contractBill }) => {
  return (
    <Container
      style={{
        paddingTop: "48px",
        paddingBottom: "48px",
        paddingLeft: "40px",
        paddingRight: "40px",
      }}
    >
      <FlexRow>
        <StatPiece>
          <span
            className="stat-heading"
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "#576881",
            }}
          >
            납부 횟수
          </span>
          <span className="stat-data">
            {contractBill.billRound ? contractBill.billRound : 0}회
          </span>
        </StatPiece>
        <Divider />
        <StatPiece>
          <span className="stat-heading">사용 건수</span>
          <span className="stat-data">
            {contractBill.settledUsageCount
              ? contractBill.settledUsageCount
              : 0}
            건
          </span>
        </StatPiece>
        <StatPiece color="#00bbf9">
          <span className="stat-heading">청구 건수</span>
          <span className="stat-data">
            {contractBill.billedUsageCount ? contractBill.billedUsageCount : 0}
            건
          </span>
        </StatPiece>
        <Divider />
        <StatPiece color="#00bbf9">
          <span className="stat-heading">총 금액</span>
          <span className="stat-data">
            {contractBill.billedAmountWithVat
              ? insertCommas(String(contractBill.billedAmountWithVat))
              : 0}
            원
          </span>
        </StatPiece>
      </FlexRow>
      <FlexRow>
        <StatPiece>
          <Divider2 />
          <span className="stat-bill-heading">청구 금액</span>
          <span className="stat-bill-data">
            {insertCommas(
              String(
                contractBill.billedAmountWithVat -
                  contractBill.rentalWithVat -
                  contractBill.withdrawFeeWithVat -
                  contractBill.unsubscriptionPenaltyWithVat
              )
            )}
            원
          </span>
          <Divider2 />
          <span className="stat-bill-heading">월 임대료</span>
          <span className="stat-bill-data">
            {insertCommas(String(contractBill.rentalWithVat))}원
          </span>
          <Divider2 />
        </StatPiece>
        <StatPiece
          style={{
            marginLeft: "32px",
            marginRight: "40px",
          }}
        >
          <Divider2 />
          <span className="stat-bill-heading">계약 해지 위약금</span>
          <span className="stat-bill-data">
            {insertCommas(String(contractBill.unsubscriptionPenaltyWithVat))}원
          </span>
          <Divider2 />
          <span className="stat-bill-heading">기기 회수비</span>
          <span className="stat-bill-data">
            {insertCommas(String(contractBill.withdrawFeeWithVat))}원
          </span>
          <Divider2 />
        </StatPiece>
      </FlexRow>
      <span className="stat-heading">
        * 위의 모든 금액은 모두 VAT를 포함한 가격임
      </span>
    </Container>
  );
};

type Props = {
  headerBorderColor?: string,
  headerBackgroundColor?: string,
  headless?: boolean,
  title?: string,
  claimStatus: string,
  count: number,
  contractBill?: MonthlyBillDetail,
  onRefresh: () => void,
};

class BillingDetailItemsPanelComponent extends React.Component<Props> {
  render() {
    const {
      title,
      headless,
      headerBackgroundColor,
      headerBorderColor,
      count,
      claimStatus,
      contractBill,
      onRefresh,
    } = this.props;
    return (
      <PanelContainer>
        {headless ? null : (
          <PanelHeader
            headerBackgroundColor={headerBackgroundColor}
            headerBorderColor={headerBorderColor}
          >
            <Title>{title}</Title>
            <BillingCountText> ({count || 0}개)</BillingCountText>
            <Divider />
            <Checkbox
              checked={claimStatus === "CLAIMED"}
              onChange={(event) => {}}
              stopClickPropagation
              checkboxAlign="left"
              checkedImgSrc={commonImages.checkedPrimary}
              uncheckedImgSrc={commonImages.check}
            >
              {claimStatus === "CLAIMED" ? " 확정" : " 미확정"}
            </Checkbox>

            <UtilBox>
              <RefreshButton className="with-hover-overlay" onClick={onRefresh}>
                <img src={commonImages.refreshGray} alt="refresh" />
                <span>{moment().format("YYYY[-]MM[-]DD HH:mm:ss")} 동기화</span>
              </RefreshButton>
            </UtilBox>
          </PanelHeader>
        )}
        <ContentContainer>
          {contractBill && (
            <BillingDetailContents contractBill={contractBill} />
          )}
        </ContentContainer>
      </PanelContainer>
    );
  }
}

export const BillingDetailPlainPanel = BillingDetailItemsPanelComponent;
