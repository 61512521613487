// @flow
import type { CustomerAccessListFilterKeys, CustomerAccessListState } from '../../types/accesscontrol';
// module imports
import _ from 'lodash';

// client actions
const SET_PAGING_INFO: 'sd/acsCtl/customeraccesslist/SET_PAGING_INFO' = 'sd/acsCtl/customeraccesslist/SET_PAGING_INFO';
const NEXT_PAGE: 'sd/acsCtl/customeraccesslist/NEXT_PAGE' = 'sd/acsCtl/customeraccesslist/NEXT_PAGE';
const PREV_PAGE: 'sd/acsCtl/customeraccesslist/PREV_PAGE' = 'sd/acsCtl/customeraccesslist/PREV_PAGE';
const SELECT_CUSTOMER: 'sd/acsCtl/customeraccesslist/SELECT_CUSTOMER' = 'sd/acsCtl/customeraccesslist/SELECT_CUSTOMER';
const CLEAR_CUSTOMER_SELECTION: 'sd/acsCtl/customeraccesslist/CLEAR_CUSTOMER_SELECTION' = 'sd/acsCtl/customeraccesslist/CLEAR_CUSTOMER_SELECTION';
const SET_FILTER: 'sd/acsCtl/customeraccesslist/SET_FILTER' = 'sd/acsCtl/customeraccesslist/SET_FILTER';
const CLEAR_FILTER: 'sd/acsCtl/customeraccesslist/CLEAR_FILTER' = 'sd/acsCtl/customeraccesslist/CLEAR_FILTER';
// server actions
const REFETCH_CUSTOMERS: 'sd/acsCtl/customeraccesslist/REFETCH_CUSTOMERS' = 'sd/acsCtl/customeraccesslist/REFETCH_CUSTOMERS';
const REFETCH_CUSTOMERS_DONE: 'sd/acsCtl/customeraccesslist/REFETCH_CUSTOMERS_DONE' = 'sd/acsCtl/customeraccesslist/REFETCH_CUSTOMERS_DONE';

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type SelectCustomerAction = {
  type: typeof SELECT_CUSTOMER,
  payload: string
};

type ClearCustomerSelectionAction = {
  type: typeof CLEAR_CUSTOMER_SELECTION
};

type RefetchCustomersAction = {
  type: typeof REFETCH_CUSTOMERS
};

type RefetchCustomersDoneAction = {
  type: typeof REFETCH_CUSTOMERS_DONE
};

type SetFilterAction = {
  type: typeof SET_FILTER,
  payload: {
    key: CustomerAccessListFilterKeys,
    value: string
  }
};

type ClearFilterAction = {
  type: typeof CLEAR_FILTER,
  payload: {
    key: CustomerAccessListFilterKeys
  }
};

type AccountListActions = (
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  SelectCustomerAction |
  ClearCustomerSelectionAction |
  RefetchCustomersAction |
  RefetchCustomersDoneAction |
  SetFilterAction |
  ClearFilterAction
);

const INITIAL_STATE: CustomerAccessListState = {
  selectedCustomer: '',
  currentPage: 0,
  numPages: 0,
  refetch: false,
  filter: {
    keyword: ''
  }
};

export default function reducer(state: CustomerAccessListState = INITIAL_STATE, action: AccountListActions): CustomerAccessListState {
  switch (action.type) {
    case SET_PAGING_INFO:
      return {
        ...state,
        numPages: action.payload.numPages,
        currentPage: action.payload.currentPage
      };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      };
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      };
    case SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload
      };
    case CLEAR_CUSTOMER_SELECTION:
      return {
        ...state,
        selectedCustomer: INITIAL_STATE.selectedCustomer
      };
    case REFETCH_CUSTOMERS:
      return {
        ...state,
        refetch: true
      };
    case REFETCH_CUSTOMERS_DONE:
      return {
        ...state,
        refetch: false
      };
    case SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: action.payload.value
        }
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: INITIAL_STATE.filter[action.payload.key]
        }
      };
    default: return state;
  }
}

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const selectCustomer = (customerId: string): SelectCustomerAction => {
  return {
    type: SELECT_CUSTOMER,
    payload: customerId
  };
};

const clearCustomerSelection = (): ClearCustomerSelectionAction => {
  return {
    type: CLEAR_CUSTOMER_SELECTION
  };
};

const refetchCustomers = (): RefetchCustomersAction => {
  return {
    type: REFETCH_CUSTOMERS
  };
};

const refetchCustomersDone = (): RefetchCustomersDoneAction => {
  return {
    type: REFETCH_CUSTOMERS_DONE
  };
};

const setFilter = (key: CustomerAccessListFilterKeys, value: string): SetFilterAction => {
  return {
    type: SET_FILTER,
    payload: {
      key,
      value
    }
  };
};

const clearFilter = (key: CustomerAccessListFilterKeys): ClearFilterAction => {
  return {
    type: CLEAR_FILTER,
    payload: {
      key
    }
  };
};

export const actionCreators = {
  setPagingInfo,
  nextPage,
  prevPage,
  selectCustomer,
  clearCustomerSelection,
  refetchCustomers,
  refetchCustomersDone,
  setFilter,
  clearFilter
};