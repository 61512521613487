// @flow
import * as React from 'react';
import Popup from 'reactjs-popup';
import { Container, MessageContainer, ButtonContainer, CancelButton, ConfirmButton } from './styled';

type Props = {
  trigger: React.Node,
  children?: React.Node,
  onConfirm?: () => any
};

class ConfirmPopup extends React.Component<Props> {
  render() {
    const {
      children,
      onConfirm,
      trigger
    } = this.props;
    return (
      <Popup
        trigger={trigger}
        on='click'
        closeOnDocumentClick
        onClose={() => console.log('on close')}
        onOpen={() => console.log('on open')}
        modal
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          margin: 0,
          zIndex: 10000,
          flexDirection: 'column',
          cursor: 'default'
        }}
        contentStyle={{
          backgroundColor: '#fff',
          boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
          border: 'none',
          width: '358px',
          minHeight: '195px',
          padding: 0,
          cursor: 'default'
        }}
      >
        {(close) => {
          return (
            <Container>
              <MessageContainer>{children}</MessageContainer>
              <ButtonContainer>
                <CancelButton
                  className='with-hover-overlay'
                  onClick={(event) => {
                    event.preventDefault();
                    close();
                  }}
                  href='#'
                >
                  <span>취소</span>
                </CancelButton>
                <ConfirmButton
                  className='with-hover-overlay'
                  onClick={(event) => {
                    event.preventDefault();
                    if (onConfirm) onConfirm();
                    close();
                  }}
                  href='#'
                >
                  <span>확인</span>
                </ConfirmButton>
              </ButtonContainer>
            </Container>
          );
        }}
      </Popup>
    );
  }
}

export { ConfirmPopup };