import React from "react";
import { PlainPanel, Spinner } from "../../common";
import {
  FlexRow,
  SpinnerContainer,
  StatPiece,
} from "../../dashboard/CurrentStatePanels/styled";
import { Query } from "@apollo/client/react/components";
import styled from "styled-components";
import { Container } from "../../support/FAQPanel/styled";
import {
  AUTH_DATA_YBRAIN_EMPLOYEES,
  AUTH_DATA_YBRAIN_ORGANIZATION,
} from "../../../utils/graphql/queries";
import commonImages from "../../../resources/images/common";
const Oval = styled.img`
  margin-left: 10px;
  margin-right: 16px;
  margin-bottom: 4px;
`;

const typeToString = (type) => {
  switch (type) {
    case "SCAN":
      return "EEG";
    case "HRV":
      return "HRV";
    case "NEURO_GUIDE":
      return "NeuroGuide";
    default:
      return type;
  }
};

const BillingDetailBaseInfo = ({ contract, orgzId, contractBill }) => {
  return (
    <Query
      query={AUTH_DATA_YBRAIN_ORGANIZATION}
      variables={{
        orgzId: orgzId,
      }}
      fetchPolicy="network-only"
    >
      {({ data }) => {
        const orgManager = data?.organization?.mgmtOfficerDetails;

        return (
          <Query query={AUTH_DATA_YBRAIN_EMPLOYEES} fetchPolicy="network-only">
            {({ loading, data }) => {
              let contractManager;
              if (data) {
                let employeeMap = {};
                data.ybrainEmployees.forEach((employee) => {
                  employeeMap[employee.id] = employee;
                });

                const urn = contract.managerUrn?.split(":");
                if (urn && urn.length === 3) {
                  contractManager = employeeMap[urn[2]];
                }
                contractManager = contractManager || orgManager;
              }
              return (
                <PlainPanel title="기본 정보" headerBorderColor="#00bbf9">
                  <Container style={{ padding: "32px" }}>
                    {!contract && loading ? (
                      <SpinnerContainer>
                        <Spinner />
                      </SpinnerContainer>
                    ) : (
                      <React.Fragment>
                        <FlexRow style={{ minHeight: "22px" }}>
                          <StatPiece>
                            <Oval src={commonImages.circle} alt="Circle" />
                            <span className="stat-contract-heading">
                              계약 기간
                            </span>
                            <span className="stat-contract-data">
                              {contract.startDate} ~ {contract.endDate}
                            </span>
                          </StatPiece>
                        </FlexRow>
                        <FlexRow style={{ minHeight: "22px" }}>
                          <StatPiece>
                            <Oval src={commonImages.circle} alt="Circle" />
                            <span className="stat-contract-heading">
                              계약 번호
                            </span>
                            <span className="stat-contract-data">
                              {contractBill?.contractCode}
                            </span>
                          </StatPiece>
                        </FlexRow>
                        <FlexRow style={{ minHeight: "22px" }}>
                          <StatPiece>
                            <Oval src={commonImages.circle} alt="Circle" />
                            <span className="stat-contract-heading">
                              서비스 타입
                            </span>
                            <span className="stat-contract-data">
                              {typeToString(
                                contractBill?.contractRelationInfo.serviceType
                              )}
                            </span>
                          </StatPiece>
                        </FlexRow>
                        <FlexRow style={{ minHeight: "22px" }}>
                          <StatPiece>
                            <Oval src={commonImages.circle} alt="Circle" />
                            <span className="stat-contract-heading">
                              담당자
                            </span>
                            <span className="stat-contract-data">
                              {contractManager &&
                                contractManager.name +
                                  "(" +
                                  contractManager.email +
                                  ")"}
                            </span>
                          </StatPiece>
                        </FlexRow>
                      </React.Fragment>
                    )}
                  </Container>
                </PlainPanel>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default BillingDetailBaseInfo;
