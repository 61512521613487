// @flow
import type { AppState } from "../../types";
import type { Dispatch } from "redux";
import type {
  BillingDetailInfo,
  BillingRecordInfo,
  AnalysisReportInfo,
  ContractInfo,
  BillingKeyInfo,
} from "../../types/billing";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import moment from "moment";

const POST_BILLING_KEY: "sd/b/billingItemDetail/POST_BILLING_KEY" =
  "sd/b/billingItemDetail/POST_BILLING_KEY";
const POST_BILLING_KEY_SUCCESS: "sd/b/billingItemDetail/POST_BILLING_KEY_SUCCESS" =
  "sd/b/billingItemDetail/POST_BILLING_KEY_SUCCESS";
const POST_BILLING_KEY_FAILURE: "sd/b/billingItemDetail/POST_BILLING_KEY_FAILURE" =
  "sd/b/billingItemDetail/POST_BILLING_KEY_FAILURE";
const DELETE_BILLING_KEY: "sd/b/billingItemDetail/DELETE_BILLING_KEY" =
  "sd/b/billingItemDetail/DELETE_BILLING_KEY";
const DELETE_BILLING_KEY_SUCCESS: "sd/b/billingItemDetail/DELETE_BILLING_KEY_SUCCESS" =
  "sd/b/billingItemDetail/DELETE_BILLING_KEY_SUCCESS";
const DELETE_BILLING_KEY_FAILURE: "sd/b/billingItemDetail/DELETE_BILLING_KEY_FAILURE" =
  "sd/b/billingItemDetail/DELETE_BILLING_KEY_FAILURE";
const PATCH_RECEIPT: "sd/b/billingItemDetail/PATCH_RECEIPT" =
  "sd/b/billingItemDetail/PATCH_RECEIPT";
const PATCH_RECEIPT_SUCCESS: "sd/b/billingItemDetail/PATCH_RECEIPT_SUCCESS" =
  "sd/b/billingItemDetail/PATCH_RECEIPT_SUCCESS";
const PATCH_RECEIPT_FAILURE: "sd/b/billingItemDetail/PATCH_RECEIPT_FAILURE" =
  "sd/b/billingItemDetail/PATCH_RECEIPT_FAILURE";

type PostBillingKeyAction = {
  type: typeof POST_BILLING_KEY,
};

type PostBillingKeySuccessAction = {
  type: typeof POST_BILLING_KEY_SUCCESS,
};

type PostBillingKeyFailureAction = {
  type: typeof POST_BILLING_KEY_FAILURE,
};

type DeleteBillingKeyAction = {
  type: typeof DELETE_BILLING_KEY,
};

type DeleteBillingKeySuccessAction = {
  type: typeof DELETE_BILLING_KEY_SUCCESS,
};

type DeleteBillingKeyFailureAction = {
  type: typeof DELETE_BILLING_KEY_FAILURE,
};

type PatchReceiptAction = {
  type: typeof PATCH_RECEIPT,
};

type PatchReceiptSuccessAction = {
  type: typeof PATCH_RECEIPT_SUCCESS,
};

type PatchReceiptFailureAction = {
  type: typeof PATCH_RECEIPT_FAILURE,
};

type BillingActions =
  | PostBillingKeyAction
  | PostBillingKeySuccessAction
  | PostBillingKeyFailureAction
  | DeleteBillingKeyAction
  | DeleteBillingKeySuccessAction
  | DeleteBillingKeyFailureAction
  | PatchReceiptAction
  | PatchReceiptSuccessAction
  | PatchReceiptFailureAction;

const INITIAL_STATE: BillingDetailInfo = {
  billingKeyLoading: false,
  receiptLoading: false,
};

export default function reducer(
  state: BillingDetailInfo = INITIAL_STATE,
  action: BillingActions
) {
  switch (action.type) {
    case POST_BILLING_KEY:
    case DELETE_BILLING_KEY:
      return {
        ...state,
        billingKeyLoading: true,
      };
    case POST_BILLING_KEY_SUCCESS:
      alert("정기 결제 신청이 완료되었습니다.");
      return {
        ...state,
        billingKeyLoading: false,
      };
    case DELETE_BILLING_KEY_SUCCESS:
      alert("정기 결제를 성공적으로 해지하였습니다.");
      return {
        ...state,
        billingKeyLoading: false,
      };
    case POST_BILLING_KEY_FAILURE:
      alert("post billing key failed");
      return {
        ...state,
        billingKeyLoading: false,
      };
    case DELETE_BILLING_KEY_FAILURE:
      alert("delete billing key failed");
      return {
        ...state,
        billingKeyLoading: false,
      };
    case PATCH_RECEIPT:
      return {
        ...state,
        receiptLoading: true,
      };
    case PATCH_RECEIPT_SUCCESS:
      alert("결제가 정상 처리되었습니다.");
      return {
        ...state,
        receiptLoading: false,
      };
    case PATCH_RECEIPT_FAILURE:
      alert("patch receipt failed");
      return {
        ...state,
        receiptLoading: false,
      };
    default:
      break;
  }
  return state;
}

const postBillingKeyRest = (
  billingKeyInfo: BillingKeyInfo,
  accessToken: string
) => {
  return axios({
    url: `${
      process.env.REACT_APP_BILLING_API_HOST || "http://192.168.3.66:6002"
    }/api/v1/payment/billing-key`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: billingKeyInfo,
  });
};

const postBillingKey = (billingKeyInfo: BillingKeyInfo): ThunkAction => {
  return (dispatch: Dispatch<BillingActions>, getState: () => AppState) => {
    const { access_token } = getState().auth.currentUser.tokens;
    dispatch({ type: POST_BILLING_KEY });
    postBillingKeyRest(billingKeyInfo, access_token)
      .then((response) => {
        console.log(response);
        dispatch({ type: POST_BILLING_KEY_SUCCESS });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: POST_BILLING_KEY_FAILURE });
      });
  };
};

const deleteBillingKeyRest = (orgzId: number, accessToken: string) => {
  return axios({
    url: `${
      process.env.REACT_APP_BILLING_API_HOST || "http://192.168.3.66:6002"
    }/api/v1/payment/billing-key`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      orgzId,
    },
  });
};

const deleteBillingKey = (orgzId: number): ThunkAction => {
  return (dispatch: Dispatch<BillingActions>, getState: () => AppState) => {
    const { access_token } = getState().auth.currentUser.tokens;
    dispatch({ type: DELETE_BILLING_KEY });
    deleteBillingKeyRest(orgzId, access_token)
      .then((response) => {
        console.log(response);
        dispatch({ type: DELETE_BILLING_KEY_SUCCESS });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: DELETE_BILLING_KEY_FAILURE });
      });
  };
};

const patchReceiptRest = (receiptId: string, accessToken: string) => {
  return axios({
    url: `${
      process.env.REACT_APP_BILLING_API_HOST || "http://192.168.3.66:6002"
    }/api/v1/payment/receipt`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      receiptId,
    },
  });
};

const patchReceipt = (receiptId: string): ThunkAction => {
  return (dispatch: Dispatch<BillingActions>, getState: () => AppState) => {
    const { access_token } = getState().auth.currentUser.tokens;
    dispatch({ type: PATCH_RECEIPT });
    patchReceiptRest(receiptId, access_token)
      .then((response) => {
        console.log(response);
        dispatch({ type: PATCH_RECEIPT_SUCCESS });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: PATCH_RECEIPT_FAILURE });
      });
  };
};

export const actionCreators = {
  postBillingKey,
  deleteBillingKey,
  patchReceipt,
};
