// @flow
import type { AppState } from '../../../types';
import type { CurrentUserState } from '../../../types/auth';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import commonImages from '../../../resources/images/common';
import { HoverStyledAnchorButton } from '../Button';
import { currentUserActions, loginFormActions } from '../../../ducks/auth';
import { ApolloConsumer } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';

const Container = styled.div`
  min-height: 100%;
  padding-left: 150px;
  background-color: #e7ecf1;
  > div {
    margin: auto;
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const PageHeader = styled.div`
  padding-top: 52px;
  position: relative;
  > span {
    display: block;
  }
`;

const PageTitle = styled.span`
  color: #435268;
  font-size: 40px;
  font-weight: 800;
`;

const PageSubtitle = styled.span`
  color: #94aabf;
  font-size: 16px;
  font-weight: 400;
  margin-top: -1px;
`;

const RightButtonContainer = styled.div`
  position: absolute;
  right: 60px;
  top: 60px;
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 15px;
  }
`;

const ButtonDivider = styled.div`
  width: 1px;
  height: 12px;
  background-color: #d0dcea;
`;

const RightButton = styled(HoverStyledAnchorButton)`
  > span {
    color: #94aabf;
    font-size: 16px;
    font-weight: 400;
    margin-right: 8px;
  }
  &:hover, &:focus {
    > span {
      color: #576881;
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  padding-bottom: 52px;
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  a {
    color: #576881;
    font-size: 40px;
    font-weight: 800;
    &:hover {
      color: #00bbf9;
    }
  }
  span {
    color: #435268;
    font-size: 30px;
    font-weight: 800;
    white-space: pre;
  }
`;

type BreadcrumbProps = {
  matchStr: string,
  pathPieceToString: (piece: string) => string,
}

const Breadcrumb = ({ matchStr, pathPieceToString }: BreadcrumbProps) => {
  const match = useRouteMatch(matchStr);
  const { path } = match;
  const pathPieces = path.split('/').slice(1);
  const linkPaths = _.map(pathPieces, (pathPiece, idx: number) => {
    const pathSlice = pathPieces.slice(0, idx + 1);
    return _.reduce(pathSlice, (partialPath, piece) => `${partialPath}/${piece}`, '');
  });
  const elements = _.map(pathPieces, (piece, idx: number) => {
    if (idx < pathPieces.length - 1) {
      // return appropriate link
      return (<Link to={linkPaths[idx]}  key={idx}>{pathPieceToString(piece)}</Link>);
    }
    // return span
    return (<span  key={idx}>{` > ${pathPieceToString(piece)}`}</span>);
  });
  //const elems = 
  return (
    <BreadcrumbContainer>
      {elements}
    </BreadcrumbContainer>
  )
}

type ActionProps = typeof currentUserActions & typeof loginFormActions;

type StateProps = CurrentUserState;

type RightButtonModel = {
  title: string,
  onClick: () => any,
}

type OwnProps = {
  title: string,
  subtitle: string,
  contentMinWidth: number,
  useBreadcrumb?: boolean,
  breadcrumbProps?: BreadcrumbProps,
  rightButtons?: RightButtonModel[],
  children?: React.ChildrenArray<*>
}

type Props = ActionProps & OwnProps & CurrentUserState & StateProps;

class PageComponent extends React.Component<Props> {
  componentWillUnmount() {
    clearInterval(this._jwtExpiryInterval);
  }

  render() {
    const { title, subtitle, contentMinWidth, children, rightButtons, useBreadcrumb, breadcrumbProps, clearCurrentUser, resetLoginForm, authorities } = this.props;
    return (
      <ApolloConsumer>
        {client => (
          <Container style={{ minWidth: contentMinWidth + 150 }}>
            <PageHeader style={{ minWidth: contentMinWidth }}>
              {(!useBreadcrumb) ? (
                <PageTitle>
                  {title}
                </PageTitle>
              ) : (
                <Breadcrumb { ...breadcrumbProps } />
              )}
              <PageSubtitle>
                {subtitle}
              </PageSubtitle>
              <RightButtonContainer>
                {_.map(rightButtons, (rightButton) => {
                  return (
                    <RightButton
                      href='#'
                      key={rightButton.title}
                      onClick={(event) => {
                        event.preventDefault();
                        rightButton.onClick();
                      }}
                    >
                      <span>{rightButton.title}</span>
                    </RightButton>
                  );
                })}
              </RightButtonContainer>
            </PageHeader>
            <ContentContainer style={{ minWidth: contentMinWidth }}>
              {children}
            </ContentContainer>
          </Container>
        )}
      </ApolloConsumer>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return state.auth.currentUser;
}

export const Page: React.ComponentType<OwnProps> = connect(mapStateToProps, {
  ...currentUserActions,
  ...loginFormActions
})(PageComponent);