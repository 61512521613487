// @flow
import styled from 'styled-components';
import { AnchorButton, HoverStyledAnchorButton } from '../../common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex: 1 1 0%;
  width: 100%;
  min-height: 0;
  padding: 0 30px 30px;
  > div:not(:first-child):not(:last-child) {
    margin-top: 10px;
  }
`;

export const SessionInfoContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border: solid 1px #bcc9d8;
`;

export const SessionInfoSpan = styled.span`
  font-size: 18px;
  color: #576881;
  margin-left: 15px;
`;

export const InfoDivider = styled.div`
  margin: 0 20px;
  background-color: #bcc9d8;
  width: 1px;
  height: 17px;
`;

export const SessionAnlzResSpan = styled(SessionInfoSpan)`
  color: #00bbf9;
  margin: 0;
`;

export const AnlzRequestStatusContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 210px;
  background-color: #f6f8f9;
  padding: 60px 0 40px;
  > img {
    width: 53px;
    height: 51px;
    object-fit: contain;
    &.proceeding {
      width: 59px;
      height: 53px;
      object-fit: contain;
    }
  }
`;

export const StatusMessage = styled.span`
  margin-top: 15px;
  color: #576881;
  font-size: 22px;
  line-height: 37.5px;
`;

export const DownloadButton = styled(HoverStyledAnchorButton)`
  > span {
    text-decoration: underline;
    color: #576881;
    font-size: 22px;
    font-weight: 400;
    line-height: 37.5px;
  }
  &:hover, &:focus {
    > span {
      color: #00bbf9;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
`;

export const CancelButton = styled(AnchorButton)`
  text-align: center;
  width: 180px;
  height: 60px;
  border-radius: 1.5px;
  background-color: #576881;
  > span {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
  }
`;