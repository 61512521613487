// @flow
// type imports
import type { CurrentUserState } from '../types/auth';
import type { AppState } from '../types';
import type { Location } from 'react-router-dom';
// module imports
import _ from 'lodash';
import * as React from 'react';
import ReactGA from 'react-ga';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { currentUserActions } from '../ducks/auth';
import { Dashboard } from './dashboard';
import { Backdash } from './backdash';
import { Analysis } from './analysis';
import { Mypage } from './mypage';
import { Login } from './login';
import { Billings, BillingDetails } from './billing';
import { Accounts } from './accounts';
import { Customers } from './customers';
import * as LocalStorageUtils from '../utils/localStorage';
import { Support } from './support';
import { Privacy } from './privacy';
import { AccessControl } from './accesscontrol';
import { ApolloConsumer } from '@apollo/client';
import { EULA } from './eula';
import { SubjectDetail } from './analysis/SubjectDetail';

type StateProps = {
  currentUser: CurrentUserState
};
type OwnProps = {
  location: Location
};

type DispatchProps = {
  clearCurrentUser: typeof currentUserActions.clearCurrentUser,
  syncCurrentUser: typeof currentUserActions.syncCurrentUser
};

type Props = StateProps & OwnProps & DispatchProps;

class ScreensComponent extends React.Component<Props> {
  idleTimerId: TimeoutID;
  apolloClient: any;
  componentDidMount() {
    document.addEventListener('mousemove', this.activityHandler, false);
    document.addEventListener('mousedown', this.activityHandler, false);
    document.addEventListener('keypress', this.activityHandler, false);
    document.addEventListener('touchmove', this.activityHandler, false);
    window.addEventListener('storage', this.handleStorage, false);
    this.startIdleTimer();
  }

  startIdleTimer = () => {
    this.idleTimerId = setTimeout(() => {
      this.apolloClient.clearStore();
      this.props.clearCurrentUser();
    }, 3600000); // TODO: set this to an hour in ms
  };

  handleStorage = (e) => {
    switch(e.key) {
      case 'currentUser':
        this.props.syncCurrentUser(e.newValue && JSON.parse(e.newValue));
        return;
      case 'lastActivityTimestamp':
        this.resetIdleTimer();
        return;
      default: console.log(e);
    }
  };

  activityHandler = _.debounce((e) => {
    // save lastActivityTimestamp
    LocalStorageUtils.setLastActivityTimestamp();
    this.resetIdleTimer();
  }, 100, { leading: true, trailing: true });

  resetIdleTimer = () => {
    clearTimeout(this.idleTimerId);
    this.startIdleTimer();
  };

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.activityHandler, false);
    document.removeEventListener('mousedown', this.activityHandler, false);
    document.removeEventListener('keypress', this.activityHandler, false);
    document.removeEventListener('touchmove', this.activityHandler, false);
    window.removeEventListener('storage', this.handleStorage, false);
    clearTimeout(this.idleTimerId);
  }

  checkUserAgreements({ agreed, authorities }: CurrentUserState) {
    return (agreed || (_.filter(authorities, authority => _.startsWith(authority, 'Y_')).length > 0));
  }

  isUserFromYbrain({ authorities }: CurrentUserState) {
    return (_.filter(authorities, authority => _.startsWith(authority, 'Y_')).length > 0);
  }

  render() {
    const {
      currentUser
    } = this.props;
    return (
      <ApolloConsumer>
        {client => {
          this.apolloClient = client;
          return (
            <Switch>
              <Route exact path='/' render={(props) => {
                const searchParams = new URLSearchParams(props.location.search);
                const redirect = searchParams.get('redirect');
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  return <Redirect to={redirect || '/home'} />;
                }
                return <Login />;
              }} />
              <Route path='/home' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) {
                    if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                    return <Dashboard {...props} />;
                  }
                  return <Backdash {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
              <Route path='/customers' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.isUserFromYbrain(currentUser) && this.checkUserAgreements(currentUser)) {
                  return <Customers {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
              <Route path='/analysis/subject/:subjectId' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <SubjectDetail />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
              <Route path='/analysis' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <Analysis {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} exact />

              <Route path='/billing/detail/:contractId' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <BillingDetails {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />

              <Route path='/billing' render={(props) => {
                  if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                      if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                      return <Billings {...props} />;
                  }
                  return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} exact />

              <Route path='/mypage' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <Mypage {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
              <Route path='/accounts' render={(props) => {
                if (_.includes(currentUser.authorities, 'U_ADMIN') && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <Accounts {...props} />;
                } else if (this.isUserFromYbrain(currentUser) && this.checkUserAgreements(currentUser)) {
                  return <AccessControl {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
              <Route path='/support' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <Support {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
              <Route path='/privacy' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <Privacy {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
              <Route path='/eula' render={(props) => {
                if (currentUser.tokens.access_token !== '' && this.checkUserAgreements(currentUser)) {
                  if (!this.isUserFromYbrain(currentUser)) ReactGA.pageview(props.location.pathname);
                  return <EULA {...props} />;
                }
                return <Redirect to={`/?redirect=${props.location.pathname}`} />;
              }} />
            </Switch>
          );
        }}
      </ApolloConsumer>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentUser: state.auth.currentUser
  };
};

export const Screens = connect(mapStateToProps, {
  clearCurrentUser: currentUserActions.clearCurrentUser,
  syncCurrentUser: currentUserActions.syncCurrentUser
})(ScreensComponent);