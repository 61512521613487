// @flow
import _ from 'lodash';
import * as React from 'react';
import { PlainPanel, Spinner } from '../../common';
import { Query } from '@apollo/client/react/components';
import { FAQRow } from './FAQRow';
import { Container } from './styled';
import { ALL_FAQS_QUERY } from '../../../utils/graphql/queries';

class FAQPanel extends React.Component<{}> {
  render() {
    return (
      <PlainPanel
        title='자주하는 질문'
        headerBorderColor='#00bbf9'
        headerBackgroundColor='#fff'
      >
        <Container>
          <Query
            query={ALL_FAQS_QUERY}
            fetchPolicy='network-only'
            variables={{
              language: 'ko'
            }}
            context={{
              dataSourceType: 'api'
            }}
          >
            {({ loading, data, error }) => {
              console.log(data);
              if (loading) return <Spinner />;
              if (data && data.allFaqsByLang) {
                return (
                  <React.Fragment>
                    {_.map(data.allFaqsByLang, (faq) => (
                      <FAQRow key={`FAQ#${faq.id}`}question={faq.question} answer={faq.answer} />
                    ))}
                  </React.Fragment>
                )
              }
            }}
          </Query>
        </Container>
      </PlainPanel>
    );
  }
}

export { FAQPanel };