// @flow
import * as React from 'react';
import { AnalysisStats } from './AnalysisStats';
import { TabbedPanel } from '../../common';
import { CustomerStats } from './CustomerStats';

class StatsChartPanel extends React.Component<{}> {
  render() {
    return (
       <TabbedPanel selectedTab='analysis'>
        <TabbedPanel.Tab
          title='분석 통계'
          tabKey='analysis'
        >
          <AnalysisStats />
        </TabbedPanel.Tab>
        <TabbedPanel.Tab
          title='고객 통계'
          tabKey='patient'
        >
          <CustomerStats />
        </TabbedPanel.Tab>
      </TabbedPanel>
    )
  }
}

export { StatsChartPanel };
