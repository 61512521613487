// @flow
// type imports
import { ApolloConsumer } from "@apollo/client";
// module imports
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { Location } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { currentUserActions, loginFormActions } from "../../../ducks/auth";
import commonImages from "../../../resources/images/common";
import type { AppState } from "../../../types";
import type { CurrentUserState } from "../../../types/auth";
import Notification from "../../noti/Notification";
import { NoticeDisplay } from "../NoticeDisplay";
import {
  Container,
  ContentContainer,
  CopyRight,
  LogoLink,
  LogoutButton,
  NonNavLinkContainer,
  SidebarFooter,
  SidebarHeader,
  SidebarNavContainer,
  SidebarNavItem,
} from "./styled";

type StateProps = {
  currentUser: CurrentUserState,
};
type OwnProps = {
  location: Location,
};

type ActionProps = typeof currentUserActions & typeof loginFormActions;

type Props = StateProps & OwnProps & ActionProps;

class SidebarComponent extends React.Component<Props> {
  isHospitalUser() {
    return (
      _.filter(this.props.currentUser.authorities, (authority) =>
        _.startsWith(authority, "U_")
      ).length > 0
    );
  }

  render() {
    const { clearCurrentUser, resetLoginForm } = this.props;
    return (
      <React.Fragment>
        <Notification currentUser={this.props.currentUser} />
        <Container
          isBackoffice={
            _.filter(this.props.currentUser.authorities, (authority) =>
              _.startsWith(authority, "Y_")
            ).length > 0
          }
        >
          <ContentContainer>
            <SidebarHeader>
              <img src={commonImages.profile} alt="Profile" />
              <span>{this.props.currentUser.user_given_name}님</span>
              <ApolloConsumer>
                {(client) => (
                  <LogoutButton
                    href="#"
                    onClick={(event) => {
                      console.log("logout");
                      event.preventDefault();
                      client.clearStore();
                      clearCurrentUser();
                      resetLoginForm();
                    }}
                  >
                    <span>Logout</span>
                  </LogoutButton>
                )}
              </ApolloConsumer>
            </SidebarHeader>
            <SidebarNavContainer>
              <NavLink
                to="/home"
                activeStyle={{
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
                exact
              >
                <SidebarNavItem
                  style={{ borderTop: "1px solid rgba(255, 255, 255, 0.3)" }}
                >
                  <img src={commonImages.home} alt="Dashboard home" />
                  <span>Dashboard</span>
                </SidebarNavItem>
              </NavLink>
              {this.isHospitalUser() ? (
                <NavLink
                  to="/analysis"
                  activeStyle={{
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                >
                  <SidebarNavItem>
                    <img src={commonImages.analysis} alt="Analysis" />
                    <span>Analysis</span>
                  </SidebarNavItem>
                </NavLink>
              ) : (
                <NavLink
                  to="/customers"
                  activeStyle={{
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                >
                  <SidebarNavItem>
                    <img src={commonImages.analysis} alt="Customers" />
                    <span>Customers</span>
                  </SidebarNavItem>
                </NavLink>
              )}

              {_.includes(this.props.currentUser.authorities, "U_ADMIN") ||
              _.includes(this.props.currentUser.authorities, "U_NORMAL") ||
              _.includes(this.props.currentUser.authorities, "U_SUBJECT") ? (
                <NavLink
                  to="/billing"
                  activeStyle={{
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                >
                  <SidebarNavItem>
                    <img src={commonImages.billingWhite} alt="Billing" />
                    <span>Billing</span>
                  </SidebarNavItem>
                </NavLink>
              ) : null}
              <NavLink
                to="/mypage"
                activeStyle={{
                  backgroundColor: "rgba(0,0,0,0.2)",
                }}
              >
                <SidebarNavItem>
                  <img src={commonImages.mypage} alt="Mypage" />
                  <span>My Page</span>
                </SidebarNavItem>
              </NavLink>
              {_.includes(this.props.currentUser.authorities, "U_ADMIN") ||
              _.includes(this.props.currentUser.authorities, "Y_ADMIN") ||
              _.includes(this.props.currentUser.authorities, "Y_MANAGER") ||
              _.includes(this.props.currentUser.authorities, "Y_OPERATOR") ? (
                <NavLink
                  to="/accounts"
                  activeStyle={{
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                >
                  <SidebarNavItem>
                    <img src={commonImages.accounts} alt="Access control" />
                    <span>Access Control</span>
                  </SidebarNavItem>
                </NavLink>
              ) : null}
            </SidebarNavContainer>
            <SidebarFooter>
              <NonNavLinkContainer>
                <a
                  href="https://mindd.ybrain.com/"
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  MINDD 바로가기
                </a>
              </NonNavLinkContainer>
              <NonNavLinkContainer>
                <Link to="/support">고객센터</Link>
              </NonNavLinkContainer>
              <NonNavLinkContainer>
                <Link to="/eula">이용약관</Link>
              </NonNavLinkContainer>
              <NonNavLinkContainer>
                <Link to="/privacy">개인정보처리방침</Link>
              </NonNavLinkContainer>
              <LogoLink href="http://www.ybrain.com" target="_blank">
                <img src={commonImages.logo} alt="Ybrain" />
              </LogoLink>
              <CopyRight>
                Copyright © 2018 YBRAIN. ALL Rights reserved.
              </CopyRight>
            </SidebarFooter>
          </ContentContainer>
        </Container>
        <NoticeDisplay />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export const Sidebar = connect(mapStateToProps, {
  ...currentUserActions,
  ...loginFormActions,
})(SidebarComponent);
