// @flow
import type { AccountListState, AccountListFilterKeys, User } from '../../types/accounts';
// module imports
import _ from 'lodash';

// client actions
const SET_PAGING_INFO: 'sd/ac/accountlist/SET_PAGING_INFO' = 'sd/ac/accountlist/SET_PAGING_INFO';
const NEXT_PAGE: 'sd/ac/accountlist/NEXT_PAGE' = 'sd/ac/accountlist/NEXT_PAGE';
const PREV_PAGE: 'sd/ac/accountlist/PREV_PAGE' = 'sd/ac/accountlist/PREV_PAGE';
const CHECK_USERS: 'sd/ac/accountlist/CHECK_USERS' = 'sd/ac/accountlist/CHECK_USERS';
const UNCHECK_USERS: 'sd/ac/accountlist/UNCHECK_USERS' = 'sd/ac/accountlist/UNCHECK_USERS';
const SELECT_USER: 'sd/ac/accountlist/SELECT_USER' = 'sd/ac/accountlist/SELECT_USER';
const CLEAR_USER_SELECTION: 'sd/ac/accountlist/CLEAR_USER_SELECTION' = 'sd/ac/accountlist/CLEAR_USER_SELECTION';
const SET_FILTER: 'sd/ac/accountlist/SET_FILTER' = 'sd/ac/accountlist/SET_FILTER';
const CLEAR_FILTER: 'sd/ac/accountlist/CLEAR_FILTER' = 'sd/ac/accountlist/CLEAR_FILTER';
// server actions
const REFETCH_USERS: 'sd/ac/accountlist/REFETCH_USERS' = 'sd/ac/accountlist/REFETCH_USERS';
const REFETCH_USERS_DONE: 'sd/ac/accountlist/REFETCH_USERS_DONE' = 'sd/ac/accountlist/REFETCH_USERS_DONE';

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type CheckUsersAction = {
  type: typeof CHECK_USERS,
  payload: string[]
};

type UncheckUsersAction = {
  type: typeof UNCHECK_USERS,
  payload: string[]
};

type SelectUserAction = {
  type: typeof SELECT_USER,
  payload: User
};

type ClearUserSelectionAction = {
  type: typeof CLEAR_USER_SELECTION
};

type RefetchUsersAction = {
  type: typeof REFETCH_USERS
};

type RefetchUsersDoneAction = {
  type: typeof REFETCH_USERS_DONE
};

type SetFilterAction = {
  type: typeof SET_FILTER,
  payload: {
    key: AccountListFilterKeys,
    value: string
  }
};

type ClearFilterAction = {
  type: typeof CLEAR_FILTER,
  payload: {
    key: AccountListFilterKeys
  }
};

type AccountListActions = (
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  CheckUsersAction |
  UncheckUsersAction |
  RefetchUsersAction |
  RefetchUsersDoneAction |
  SetFilterAction |
  ClearFilterAction |
  SelectUserAction |
  ClearUserSelectionAction
);

const INITIAL_STATE: AccountListState = {
  checkedUsers: [],
  selectedUser: null,
  currentPage: 0,
  numPages: 0,
  refetch: false,
  filter: {
    keyword: ''
  }
};

export default function reducer(state: AccountListState = INITIAL_STATE, action: AccountListActions): AccountListState {
  switch (action.type) {
    case SET_PAGING_INFO:
      return {
        ...state,
        numPages: action.payload.numPages,
        currentPage: action.payload.currentPage
      };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      };
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      };
    case CHECK_USERS:
      return {
        ...state,
        checkedUsers: _.union(state.checkedUsers, action.payload)
      };
    case UNCHECK_USERS:
      return {
        ...state,
        checkedUsers: _.difference(state.checkedUsers, action.payload)
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case CLEAR_USER_SELECTION:
      return {
        ...state,
        selectedUser: null
      };
    case REFETCH_USERS:
      return {
        ...state,
        refetch: true
      };
    case REFETCH_USERS_DONE:
      return {
        ...state,
        refetch: false
      };
    case SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: action.payload.value
        }
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: INITIAL_STATE.filter[action.payload.key]
        }
      };
    default: return state;
  }
}

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const checkUsers = (userIds: string[]): CheckUsersAction => {
  return {
    type: CHECK_USERS,
    payload: userIds
  };
};

const uncheckUsers = (userIds: string[]): UncheckUsersAction => {
  return {
    type: UNCHECK_USERS,
    payload: userIds
  };
};

const selectUser = (user: User): SelectUserAction => {
  return {
    type: SELECT_USER,
    payload: user
  };
};

const clearUserSelection = (): ClearUserSelectionAction => {
  return {
    type: CLEAR_USER_SELECTION
  };
};

const refetchUsers = (): RefetchUsersAction => {
  return {
    type: REFETCH_USERS
  };
};

const refetchUsersDone = (): RefetchUsersDoneAction => {
  return {
    type: REFETCH_USERS_DONE
  };
};

const setFilter = (key: AccountListFilterKeys, value: string): SetFilterAction => {
  return {
    type: SET_FILTER,
    payload: {
      key,
      value
    }
  };
};

const clearFilter = (key: AccountListFilterKeys): ClearFilterAction => {
  return {
    type: CLEAR_FILTER,
    payload: {
      key
    }
  };
};

export const actionCreators = {
  setPagingInfo,
  nextPage,
  prevPage,
  checkUsers,
  uncheckUsers,
  selectUser,
  clearUserSelection,
  refetchUsers,
  refetchUsersDone,
  setFilter,
  clearFilter
};