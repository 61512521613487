// @flow
// type imports
import type { AppState } from '../../../types';
import type { PatientListFilter } from '../../../types/analysis';
// module import
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { patientListActions } from '../../../ducks/analysis';
import { StyledCalendar as Calendar, CalendarPanelFooter } from './styled';
import { ControlledPanel } from '../../common';
import analysisImages from '../../../resources/images/analysis';

type OwnProps = {
  onDayClick: (date: moment) => void;
}

type StateProps = PatientListFilter;

type ActionProps = typeof patientListActions;

type Props = OwnProps & StateProps & ActionProps;

class CalendarPanelComponent extends React.Component<Props> {
  render() {
    const {
      setFilter,
      clearFilter
    } = this.props;
    return (
      <ControlledPanel>
        <ControlledPanel.Content>
          <Calendar
            minDetail='month'
            maxDetail='month'
            formatMonthYear={(locale,date) => {
              return moment(date).format('Y.MM')
            }}
            locale='en-US'
            nextLabel={(
              <React.Fragment>
                <img src={analysisImages.calNext} alt='Cal-Next' />
                <img className='hover-img' src={analysisImages.calNextHover} alt='Cal-Next' />
              </React.Fragment>
            )}
            prevLabel={(
              <React.Fragment>
                <img src={analysisImages.calPrev} alt='Cal-Prev' />
                <img className='hover-img' src={analysisImages.calPrevHover} alt='Cal-Prev' />
              </React.Fragment>
            )}
            value={this.props.date && this.props.date.toDate()}
            onClickDay={(date: Date) => {
              // clear keyword filter if set
              if (this.props.keyword !== '') {
                clearFilter('keyword');
              }
              setFilter('date', moment(date));
              this.props.onDayClick(moment(date));
            }}
          />
          <CalendarPanelFooter>
            <span>*</span>
            <p>선택한 날짜에 생성된 대상자의 목록을 볼 수 있습니다.</p>
          </CalendarPanelFooter>
        </ControlledPanel.Content>
        <ControlledPanel.Footer></ControlledPanel.Footer>
      </ControlledPanel>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return state.analysis.patientList.filter;
}

export const CalendarPanel = connect(mapStateToProps, patientListActions)(CalendarPanelComponent);