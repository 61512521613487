// @flow
import type {DateRangeInputState} from '../../types/common';
import moment from 'moment';

const SET_DATE_RANGE: 'sd/c/daterangeinput/SET_DATE_RANGE' = 'sd/c/daterangeinput/SET_DATE_RANGE';
const SET_START_DATE: 'sd/c/daterangeinput/SET_START_DATE' = 'sd/c/daterangeinput/SET_START_DATE';
const SET_END_DATE: 'sd/c/daterangeinput/SET_END_DATE' = 'sd/c/daterangeinput/SET_END_DATE';

type SetDateRangeAction = {
    type: typeof SET_DATE_RANGE,
    name: string,
    payload: {
        startDate: moment,
        endDate: moment
    }
};

type SetStartDateAction = {
    type: typeof SET_START_DATE,
    name: string,
    payload: moment
};

type SetEndDateAction = {
    type: typeof SET_END_DATE,
    name: string,
    payload: moment
};



type DateRangeInputActions = (
    SetDateRangeAction |
    SetStartDateAction |
    SetEndDateAction
    );

export const INITIAL_STATE: DateRangeInputState = {
    startDate: moment().date(1).startOf('day'),
    endDate: moment().add(1, 'months').date(0).endOf('day'),
};

export const createDateRangeInputReducer = (pickerName: string = '') => {
    return (state: DateRangeInputState = INITIAL_STATE, action: DateRangeInputActions) => {
        const {name} = action;
        if (name !== pickerName) return state;
        switch (action.type) {
            case SET_DATE_RANGE:
                return {
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate
                };
            case SET_START_DATE:
                return {
                    ...state,
                    startDate: action.payload
                };
            case SET_END_DATE:
                return {
                    ...state,
                    endDate: action.payload
                };
            default:
                return state;
        }
    }
};

const setDateRange = (pickerName: string, startDate: moment, endDate: moment): SetDateRangeAction => {

    console.log("setDateRange pickerName:" + pickerName + " startDate: " + startDate + " endDate: "  +  endDate);
    return {
        type: SET_DATE_RANGE,
        name: pickerName,
        payload: {
            startDate,
            endDate
        }
    };
};

const setStartDate = (pickerName: string, date: moment): SetStartDateAction => {
    return {
        type: SET_START_DATE,
        name: pickerName,
        payload: date
    };
};

const setEndDate = (pickerName: string, date: moment): SetEndDateAction => {
    return {
        type: SET_END_DATE,
        name: pickerName,
        payload: date
    };
};

export const actionCreators = {
    setDateRange,
    setStartDate,
    setEndDate
};



























































































