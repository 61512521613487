// @flow
import * as React from 'react';
import Popup from 'reactjs-popup';

type RPState = {
  step: number,
  confirmStep: boolean,
  prevStep: () => void,
  nextStep: () => void,
  submit: (action: () => Promise<any>) => Promise<any>,
  confirm: () => void,
  cancel: () => void
};

type Props = {
  children: (rpState: RPState) => React.Node,
  numStep: number,
  confirmAfterSubmit?: boolean,
  modal?: boolean,
  trigger?: React.Node,
};

type State = {
  step: number,
  confirmStep: boolean
};

class MultiStepForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: 0,
      confirmStep: false
    };
  }

  prevStep = () => {
    const currentStep = this.state.step;
    this.setState(() => ({
      step: (currentStep - 1 >= 0) ? currentStep - 1 : currentStep
    }));
  }

  nextStep = () => {
    const currentStep = this.state.step;
    this.setState(() => ({
      step: (currentStep + 1 < this.props.numStep) ? currentStep + 1 : currentStep
    }));
  }

  render() {
    const {
      modal,
      trigger,
      confirmAfterSubmit
    } = this.props;
    if (modal) {
      return (
        <Popup
          modal
          trigger={trigger}
          on='click'
          closeOnDocumentClick={false}
          onClose={() => this.setState({ step: 0, confirmStep: false })}
          overlayStyle={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            margin: 0,
            zIndex: 10000,
            flexDirection: 'column'
          }}
          contentStyle={{
            backgroundColor: '#fff',
            boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
            border: 'none',
            width: '440px',
            minHeight: '195px',
            padding: 0
          }}
        >
          {(close) => {
            return this.props.children({
              ...this.state,
              prevStep: this.prevStep,
              nextStep: this.nextStep,
              submit: (action) => (confirmAfterSubmit) ?
                action().then((response) => {
                  this.setState({ confirmStep: true });
                  return response;
                }) :
                action().then((response) => {
                  close();
                  return response;
                }),
              confirm: () => {
                close();
              },
              cancel: () => {
                close();
              }
            });
          }}
        </Popup>
      );
    }
    // TODO: implement non-modal multi-step form when we need it
    return this.props.children({
      ...this.state,
      prevStep: this.prevStep,
      nextStep: this.nextStep,
      submit: (action) => action(),
      confirm: () => {
        // confirm submit
      },
      cancel: () => {
        // cancel form
      }
    });
  }
}

export { MultiStepForm };