// @flow
// module imports
import { combineReducers } from 'redux';
import patientListReducer, { actionCreators as patientListActions } from './patientlist';
import patientFormReducer, { actionCreators as patientFormActions } from './patientform';
import anlzSourceListReducer, { actionCreators as anlzSourceListActions} from './anlzsourcelist';
import anlzRequestListReducer, { actionCreators as anlzRequestListActions} from './anlzrequestlist';
import tempAnlzRequestListReducer, { actionCreators as tempAnlzRequestListActions} from './tempanlzrequestlist';
import surveySessionListReducer, { actionCreators as surveySessionListActions} from './surveysessionlist';
import patientDetailTabsReducer, { actionCreators as patientDetailTabsActions } from './patientdetailtabs';
import * as FileForm from '../common/fileform';

const reducer = combineReducers({
  patientList: patientListReducer,
  patientForm: patientFormReducer,
  anlzSourceList: anlzSourceListReducer,
  anlzRequestList: anlzRequestListReducer,
  tempAnlzRequestList: tempAnlzRequestListReducer,
  anlzSourceFileForm: FileForm.createFileFormReducer('anlzSourceFileForm'),
  surveySessionList: surveySessionListReducer,
  patientDetailTabs: patientDetailTabsReducer
});

export default reducer;

export {
  patientListActions,
  patientFormActions,
  anlzSourceListActions,
  anlzRequestListActions,
  tempAnlzRequestListActions,
  surveySessionListActions,
  patientDetailTabsActions
};
