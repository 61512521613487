import type { CurrentUserState } from "../../../types/auth";
import BootPay from "bootpay-js";
import moment from "moment";

const BootPayUtils = {
  requestBillingKey: (
    user: CurrentUserState,
    successCallback: (data: any) => void
  ) => {
    BootPay.request({
      price: 0,
      application_id: "5f4493962fa5c20027495d92",
      name: "MINDD SCAN 정기 결제",
      pg: "nicepay",
      method: "card_rebill",
      show_agree_window: 0,
      user_info: {
        username: user.user_given_name,
        email: user.user_name,
      },
      order_id: `MINDD-SCAN-${user.user_given_name}-${user.user_name}-${
        user.orgz_id
      }-${moment().toISOString()}`,
    })
      .error((data) => {
        alert(`정기 결제 신청 실패:\n${data.message}`);
        console.log(data);
      })
      .cancel((data) => {
        console.log(data);
      })
      .done((data) => {
        console.log(data);
        successCallback(data);
      });
  },
  payment: (
    billedAmountWithVat: number,
    startDate: moment,
    user: CurrentUserState,
    monthlyBillId: Number,
    successCallback: (receiptId: any) => void
  ) => {
    BootPay.request({
      price: `${billedAmountWithVat}`,
      application_id: "5f4493962fa5c20027495d92",
      name: `MINDD SCAN 스테이션 임대 (${startDate.format("YYYY.MM")})`,
      pg: "nicepay",
      show_agree_window: 0,
      user_info: {
        username: user.user_given_name,
        email: user.user_name,
      },
      params: {
        year: startDate.format("YYYY"),
        month: startDate.format("MM"),
      },
      order_id: monthlyBillId,
      // params: {callback1: '그대로 콜백받을 변수 1', callback2: '그대로 콜백받을 변수 2', customvar1234: '변수명도 마음대로'},
    })
      .error(function (data) {
        //결제 진행시 에러가 발생하면 수행됩니다.
        console.log(data);
      })
      .cancel(function (data) {
        //결제가 취소되면 수행됩니다.
        console.log(data);
      })
      .ready(function (data) {
        // 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니다.
        console.log(data);
      })
      .confirm(function (data) {
        //결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
        //주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
        console.log(data);
        var enable = true; // 재고 수량 관리 로직 혹은 다른 처리
        if (enable) {
          BootPay.transactionConfirm(data); // 조건이 맞으면 승인 처리를 한다.
        } else {
          BootPay.removePaymentWindow(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
        }
      })
      .close(function (data) {
        // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
        console.log(data);
      })
      .done(function (data) {
        //결제가 정상적으로 완료되면 수행됩니다
        //비즈니스 로직을 수행하기 전에 결제 유효성 검증을 하시길 추천합니다.
        console.log(data);
        successCallback(data.receipt_id);
      });
  },
};

export default BootPayUtils;
