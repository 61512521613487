// @flow
import * as React from 'react';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import commonImages from '../../../resources/images/common';
import { SearchBarContainer, ShowAllButton, SearchForm, SubmitButton } from './styled';

type Props = {
  leftButton: React.Node,
  searchText: string,
  placeholder: string,
  showAllImgSrc: string,
  onTextChange?: (event: SyntheticEvent<HTMLInputElement>) => any,
  onSearchSubmit?: (event: SyntheticEvent<HTMLFormElement>) => any,
  onShowAll?: (event: SyntheticEvent<HTMLAnchorElement>) => any
};

class SearchBar extends React.Component<Props> {
  render() {
    const {
      leftButton,
      searchText,
      placeholder,
      showAllImgSrc,
      onTextChange,
      onSearchSubmit,
      onShowAll
    } = this.props;
    return (
      <SearchBarContainer>
        {leftButton}
        <SearchForm
          onSubmit={(event: SyntheticEvent<HTMLFormElement>) => {
            if (onSearchSubmit) {
              onSearchSubmit(event);
            }
            event.preventDefault();
          }}
        >
          <input type='text' placeholder={placeholder} value={searchText} onChange={onTextChange} />
          <ShowAllButton
            onClick={(event) => {
              if (onShowAll) {
                onShowAll(event);
              }
              event.preventDefault();
            }}
            hidden={!searchText}
            href='#'
          >
            <CloseSharpIcon/>
            <CloseSharpIcon className='hover-img' style={{ color: '#00bbf9' }} />
          </ShowAllButton>
          <SubmitButton type='submit'>
            <img src={commonImages.search} alt='Search' />
            <img className='hover-img' src={commonImages.searchHover} alt='Search' />
          </SubmitButton>
        </SearchForm>
      </SearchBarContainer>
    )
  }
}

export { SearchBar };