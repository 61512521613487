// @flow
// type imports
import type { AppState } from '../../../types';
// module imports
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import Popup from 'reactjs-popup';
import commonImages from '../../../resources/images/common';
import {
  ContentContainer,
  PopupTitleContainer,
  PopupTitle,
  PopupContentContainer,
  PopupBodyContainer,
  PopupFooterContainer,
  AutoPaymentInfoRow,
  CardInfoContainer,
  CardInfoRow,
  CloseButton,
  UnsubscribeButton
} from './styled';

type OwnProps = {
  open: boolean,
  billingKeyInfo: any,
  onClose: () => any,
  onUnsubscribe: (contractId: number) => any,
}

export const AutoPaymentInfoPopup = ({ open, billingKeyInfo, onClose, onUnsubscribe }: OwnProps) => {
  console.log(billingKeyInfo);
  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={() => {
        console.log('payment history popup close');
        onClose();
      }}
      onOpen={() => {
        console.log('payment history popup open');
      }}
      overlayStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        margin: 0,
        zIndex: 10000,
      }}
      contentStyle={{
        backgroundColor: '#fff',
        boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
        border: 'none',
        width: '600px',
        height: '600px',
        padding: 0
      }}
    >
      {close => (
        <ContentContainer>
          <PopupTitleContainer>
            <PopupTitle>정기 결제 정보</PopupTitle>
          </PopupTitleContainer>
          <PopupContentContainer>
            <PopupBodyContainer>
              <AutoPaymentInfoRow>
                <span className="info-heading">상태</span>
                <span className="info-data">{!!billingKeyInfo ? '활성화' : '비활성화'}</span>
              </AutoPaymentInfoRow>
              <AutoPaymentInfoRow>
                <span className="info-heading">신청일</span>
                <span className="info-data">{billingKeyInfo && billingKeyInfo.cAt}</span>
              </AutoPaymentInfoRow>
              <AutoPaymentInfoRow>
                <span className="info-heading">카드 정보</span>
              </AutoPaymentInfoRow>
              <AutoPaymentInfoRow>
                <CardInfoContainer>
                  <CardInfoRow>
                    <img src={commonImages.circle} alt='Circle' />
                    <span className="info-heading">카드사</span>
                    <span className="info-data">{billingKeyInfo && billingKeyInfo.data && billingKeyInfo.data.cardName}</span>
                  </CardInfoRow>
                  <CardInfoRow>
                    <img src={commonImages.circle} alt='Circle' />
                    <span className="info-heading">카드 번호</span>
                    <span className="info-data">{billingKeyInfo && billingKeyInfo.data && billingKeyInfo.data.cardNo}</span>
                  </CardInfoRow>
                  <CardInfoRow>
                    <img src={commonImages.circle} alt='Circle' />
                    <span className="info-heading">만료일</span>
                    <span className="info-data">{billingKeyInfo && billingKeyInfo.eAt}</span>
                  </CardInfoRow>
                </CardInfoContainer>
              </AutoPaymentInfoRow>
              <AutoPaymentInfoRow>
                <span className="info-heading">결제일시</span>
                <span className="info-data">매월 25일 오전 10시</span>
              </AutoPaymentInfoRow>
              <AutoPaymentInfoRow>
                <span className="info-notice">당월 25일이 휴일인 경우 바로 이전 영업일에 결제됩니다.</span>
              </AutoPaymentInfoRow>
            </PopupBodyContainer>
            <PopupFooterContainer>
              <CloseButton
                className='with-hover-overlay'
                onClick={(event) => {
                  event.preventDefault();
                  close();
                }}
                href='#'
              >
                <span>닫기</span>
              </CloseButton>
              {billingKeyInfo && billingKeyInfo.orgzId ? (
                <UnsubscribeButton
                  className='with-hover-overlay'
                  onClick={(event) => {
                    event.preventDefault();
                    onUnsubscribe(billingKeyInfo.orgzId);
                  }}
                >
                  <span>자동 결제 해지</span>
                </UnsubscribeButton>
              ) : null}
            </PopupFooterContainer>
          </PopupContentContainer>
        </ContentContainer>
      )}
    </Popup>
  )
}