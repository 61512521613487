// @flow
// type imports
import type { AppState } from '../../../types';
// module imports
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import * as LocalStorageUtils from '../../../utils/localStorage';
import { NOTICES_QUERY } from '../../../utils/graphql/queries';
import {
  Container,
  NoticeNavButton,
  PopupCloseButton,
  NoticeContainer,
  NoticeTitle,
  NoticeContentContainer,
  NoticeContent,
  DoNotShowContainer,
  NoticeTitleContainer,
  NoticeTimestamp
} from './styled';
import commonImages from '../../../resources/images/common';
import { Checkbox } from '../Checkbox';


type StateProps = {
  userEmail: string
}

type Props = StateProps;

type State = {
  noticePrefs: {
    [noticeId: string]: {
      viewed: boolean,
      doNotDisplay: boolean 
    }
  },
  noticesToDisplay: any[],
  currentDispNoticeIdx: number,
  noticePopupShown: boolean,
}

class NoticeDisplayComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      noticePrefs: LocalStorageUtils.loadUserPreferences(props.userEmail)['noticePrefs'] || {},
      noticesToDisplay: [],
      currentDispNoticeIdx: 0,
      noticePopupShown: false
    }
  }

  render() {
    const {
      noticePrefs,
      noticesToDisplay,
      currentDispNoticeIdx
    } = this.state;
    const {
      userEmail
    } = this.props;
    return (
      <Query
        query={NOTICES_QUERY}
        onCompleted={(data) => {
          if (data && data.noticesByCurTime) {
            const notices = _.sortBy(data && data.noticesByCurTime, notice => notice.created);
            const noticesToDisplay = _.filter(notices, (notice) => !(noticePrefs[notice.id] && noticePrefs[notice.id].doNotDisplay));
            if (noticesToDisplay.length > 0 && !this.state.noticePopupShown) {
              this.setState({
                noticePopupShown: true,
                noticePrefs: {
                  ...noticePrefs,
                  [noticesToDisplay[0].id]: {
                    ...noticePrefs[noticesToDisplay[0].id],
                    viewed: true
                  }
                },
                noticesToDisplay,
                currentDispNoticeIdx: 0
              });
            }
          }
        }}
        context={{
          dataSourceType: 'api'
        }}
      >
        {({ loading, data, error }) => {
          console.log(data);
          const currentNotice = noticesToDisplay[currentDispNoticeIdx];
          return (
            <Popup
              open={this.state.noticePopupShown}
              closeOnDocumentClick
              onClose={() => LocalStorageUtils.setUserPreferences(userEmail, 'noticePrefs', noticePrefs)}
              onOpen={() => {}}
              overlayStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                margin: 0,
                zIndex: 10000,
              }}
              
              contentStyle={{
                backgroundColor: '#fff',
                boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
                border: 'none',
                width: '500px',
                height: '400px',
                padding: 0
              }}
            >
              {close => (
                <Container>
                  <NoticeNavButton
                    onClick={() => {
                      const nextIdx = (currentDispNoticeIdx - 1 + noticesToDisplay.length) % noticesToDisplay.length;
                      this.setState({
                        currentDispNoticeIdx: nextIdx,
                        noticePrefs: {
                          ...noticePrefs,
                          [noticesToDisplay[nextIdx].id]: {
                            ...noticePrefs[noticesToDisplay[nextIdx].id],
                            viewed: true,
                          }
                        }
                      });}}
                  >
                    <img src={commonImages.prev} alt='Prev' />
                    <img className='hover-img' src={commonImages.prevHover} alt='Prev' />
                  </NoticeNavButton>
                  {!!currentNotice ? (
                    <NoticeContainer>
                      <NoticeTitleContainer>
                        <NoticeTitle>{currentNotice.title}</NoticeTitle>
                        <NoticeTimestamp>{moment(currentNotice.created).format('YYYY.MM.DD HH:mm:ss')}</NoticeTimestamp>
                      </NoticeTitleContainer>
                      <NoticeContentContainer>
                        <NoticeContent>{currentNotice.content}</NoticeContent>
                      </NoticeContentContainer>
                      <DoNotShowContainer>
                        <Checkbox
                          checked={(noticePrefs[currentNotice.id] && noticePrefs[currentNotice.id].doNotDisplay)}
                          onChange={(event) => {
                            this.setState({
                              noticePrefs: {
                                ...noticePrefs,
                                [currentNotice.id]: {
                                  viewed: true,
                                  doNotDisplay: !(noticePrefs[currentNotice.id] && noticePrefs[currentNotice.id].doNotDisplay)
                                }
                              }
                            });
                          }}
                          stopClickPropagation
                          checkedImgSrc={commonImages.checkedPrimary}
                          uncheckedImgSrc={commonImages.check}
                        >
                          다시 보지 않기
                        </Checkbox>
                        <PopupCloseButton
                          href='#'
                          onClick={(e) => {
                            e.preventDefault();
                            close();
                          }}
                        >
                          <span>닫기</span>
                          <img src={commonImages.popupClose} alt="Close popup"/>
                          <img className='hover-img' src={commonImages.popupCloseHover} alt="Close popup hover"/>
                        </PopupCloseButton>
                      </DoNotShowContainer>
                    </NoticeContainer>
                  ) : <NoticeContainer/>}
                  <NoticeNavButton
                    onClick={() => {
                      const nextIdx = (currentDispNoticeIdx + 1) % noticesToDisplay.length;
                      this.setState({
                        currentDispNoticeIdx: nextIdx,
                        noticePrefs: {
                          ...noticePrefs,
                          [noticesToDisplay[nextIdx].id]: {
                            ...noticePrefs[noticesToDisplay[nextIdx].id],
                            viewed: true,
                          }
                        }
                      });
                    }}
                  >
                    <img src={commonImages.next} alt='Next' />
                    <img className='hover-img' src={commonImages.nextHover} alt='Next' />
                  </NoticeNavButton>
                </Container>
              )}
            </Popup>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userEmail: state.auth.currentUser.user_name
  };
}

export const NoticeDisplay: React.ComponentType<{}> = connect(mapStateToProps, {})(NoticeDisplayComponent);