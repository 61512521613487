import styled from 'styled-components';
import { HoverStyledButton } from '../../common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex: 1 1 0%;
  width: 100%;
`;

export const PanelLogo = styled.img`
  width: 158px;
  height: 158px;
  flex: 0 0 158px;
  object-fit: contain;
`;

export const SpinnerContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const ProfileInfoContainer = styled(Container)`
  position: initial;
  padding: 32px 86px;
  font-size: 18px;
  color: #576881;
  > form {
    width: 100%;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`;

export const ProfileInfoRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  position: relative;
  &:not(:first-child) {
    margin-top: 24px;
  }
  > img {
    width: 40px;
    flex: 0 0 40px;
    height: 40px;
    object-fit: contain;
    & ~ * {
      margin-left: 20px;
      flex: 1 1 0%;
    }
    & ~ input {
      height: 45px;
      border: solid 1px #d0dcea;
      padding-left: 10px;
      color: #576881;
      font-size: 18px;
      &:focus {
        outline: none;
        border: solid 1px #576881;
      }
    }
  }
`;

export const ErrorMsgSpan = styled.span`
  margin-top: 3px;
  font-size: 11px;
  color: #fc5882;
  &::before {
    content: '*';
  }
`;

export const FooterButton = styled(HoverStyledButton)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 0;
  width: 26px;
  height: 26px;
  > img {
    width: 26px;
    height: 26px;
    object-fit: contain;
  }
`;