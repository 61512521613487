// @flow
// module imports
import { combineReducers } from 'redux';
import loginFormReducer, { actionCreators as loginFormActions } from './loginform';
import currentUserReducer, { actionCreators as currentUserActions } from './currentuser';

const reducer = combineReducers({
  loginForm: loginFormReducer,
  currentUser: currentUserReducer
});

export default reducer;

export {
  loginFormActions,
  currentUserActions
};
