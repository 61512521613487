// @flow
// type imports
import type { AppState } from '../../../types';
import type { Customer } from '../../../types/customers';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Query } from '@apollo/client/react/components';
import { ComposedBarChart } from '../../common';
import {
  ChartSpinnerContainer,
  TooltipContent
} from './styled';
import { STAT_MONTHLY_ANLZ_BO_QUERY } from '../../../utils/graphql/queries';
import { Spinner } from '../../common';

type OwnProps = {};

type StateProps = {
  selectedCustomer: Customer | null
};

type Props = OwnProps & StateProps;

class MonthAnlzStatsComponent extends React.Component<Props, { activeTopDotY: number }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTopDotY: 0
    }
  }

  render() {
    const { selectedCustomer } = this.props;
    const monthLabels = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const datasetDefs = [
      { key: 'cpts', name: '분석 완료', color: '#00bbf9' },
      { key: 'srcs', name: '분석용 파일', color: '#576881' }
    ];
    return (
      <Query
        query={STAT_MONTHLY_ANLZ_BO_QUERY}
        pollInterval={5000}
        variables={{
          orgzId: selectedCustomer && selectedCustomer.orgz.id,
          year: moment().startOf('year').toISOString(true),
          months: 12
        }}
        context={{
          dataSourceType: 'api'
        }}
      >
        {({ loading, data, error }) => {
          if (data && data.boStatAnlzByZMonth) {
            return (
              <ComposedBarChart
                data={_.map(data.boStatAnlzByZMonth, (stat, idx) => {
                  if (moment(stat.statTime.from).month() <= moment().month()) {
                    return {
                      ...stat,
                      name: monthLabels[idx]
                    };
                  }
                  return {
                    ...stat,
                    name: monthLabels[idx],
                    srcs: 0,
                    cpts: 0
                  };
                })}
                chartMargin={{ top: 0, bottom: 25, left: 30, right: 50 }}
                datasetDefs={datasetDefs}
                tooltipContent={<TooltipContent />}
                height={280}
                tooltipOffsetX={-82}
                tooltipOffsetY={-120}
                dropRight={moment().month()}
              />
            );
          }
          return <ChartSpinnerContainer><Spinner /></ChartSpinnerContainer>;
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    selectedCustomer: state.customers.customerList.selectedCustomer
  };
}

export const MonthAnlzStats = connect(mapStateToProps, {})(MonthAnlzStatsComponent);
