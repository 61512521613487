// @flow
import gql from 'graphql-tag';

export const Disease = gql`
  fragment Disease on Indication {
    id
    code
    name
    category
    parentId
    sectionId
  }
`;

export const Patient = gql`
  fragment Patient on Subject {
    id
    code
    name
    birthday
    sex
    handedness
    tel
    email
    address
    manager
    indications {
      ...Disease
    }
    remarks
    comment
    created
  }
  ${Disease}
`;

export const DiseasePage = gql`
  fragment DiseasePage on IndicationPage {
    pageInfo {
      totalPages
      nowPage
      size
    }
    indications {
      ...Disease
    }
  }
  ${Disease}
`;

export const DiseasesWithBookmarks = gql`
  fragment DiseasesWithBookmarks on IndicationPageBookmark {
    bookmarks {
      ...Disease
    }
    indicationPage {
      ...DiseasePage
    }
  }
  ${Disease}
  ${DiseasePage}
`;

export const Notice = gql`
  fragment Notice on Notice {
    id
    title
    content
    expirationTime
    created
  }
`;