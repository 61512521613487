// @flow
export default {
  allPatients: require('./ico-all.svg'),
  calPrev: require('./ico-cal-previous.svg'),
  calGrayPrev: require('./ico-cal-previous-gray.svg'),
  calPrevHover: require('./ico-cal-previous-hover.svg'),
  calNext: require('./ico-cal-next.svg'),
  calGrayNext: require('./ico-cal-next-gray.svg'),
  calNextHover: require('./ico-cal-next-hover.svg'),
  file: require('./ico-file-img.svg'),
  patient: require('./ico-patient.svg'),
  pdf: require('./ico-pdf.svg'),
  analysisRequested: require('./ico-onrequest.svg'),
  analysisProgress: require('./ico-underanalysis.svg'),
  analysisComplete: require('./ico-completion.svg'),
  survey: require('./ico-survey.svg'),
  surveyNotComplete: require('./ico-survey-x.svg'),
  star: require('./ico-star.svg'),
  starFill: require('./ico-star-fill.svg'),
  searchSmall: require('./ico-search-small.svg'),
};