// @flow
// type imports
import type { AppState } from '../../../types';
import type { PatientFormState } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {
  AcceptButton,
  CancelButton,
  FormTitleContainer,
  FormTitle,
  FormFooterContainer,
  ContentContainer,
  Form,
  FormBody,
  FormRow,
  RadioGroup,
  FormErrorSpan,
  FormBodyContainer,
  CodeInputContainer
} from './styled';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withApollo, graphql } from '@apollo/client/react/hoc';
import BaseSelect from 'react-select';
import { patientFormActions, patientListActions } from '../../../ducks/analysis';
import { prefixes } from '../../../resources/phone';
import { Radio, styledSelect, Spinner } from '../../common';
import { NEW_SBJT_MUTATION, UPDATE_SBJT_MUTATION } from '../../../utils/graphql/mutations';
import { ICDSelectForm } from '../ICDSelectForm';
import { ICDCodeInput } from '../ICDCodeInput/ICDCodeInput';

type StateProps = PatientFormState & {
  patientListCurrentPage: number
};

type ActionProps = typeof patientFormActions & {
  selectPatient: typeof patientListActions.selectPatient
};

type MutationProps = {
  createSubject: (options: any) => Promise<any>,
  updateSubject: (options: any) => Promise<any>
}

type OwnProps = {|
  onMutationSuccess: (result: any, mode: 'create' | 'edit') => void
|};

type Props = StateProps & ActionProps & MutationProps & OwnProps;

type State = {
  loading: boolean,
  error: any
};

type SelectValue = {
  value: string,
  label: string
}

const Select = styledSelect(BaseSelect);

class PatientFormComponent extends React.Component<Props, State> {
  _firstInput: ?HTMLInputElement;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    }
  }
  
  onFormOpen() {
    if (this._firstInput) {
      this._firstInput.focus();
    }
  }

  onFormSubmit(e: SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    const {
      id,
      code,
      dob,
      email,
      handedness,
      name,
      tel,
      sex,
      address,
      manager,
      comment,
      icdCodes
    } = this.props.patientDetails;
    const subjectIn = {
      code,
      name,
      birthday: dob,
      sex: sex.toUpperCase(),
      handedness: (handedness !== '') ? handedness.toUpperCase() : 'NONE',
      tel: tel || "010-0000-0000",
      address,
      manager,
      email,
      comment,
      indicationIds: _.map(icdCodes, (code) => code.id)
    };
    if (this.props.mode === 'create') {
      this.setState({ loading: true, error: null });
      this.props.createSubject({
        variables: {
          subjectIn
        }
      }).then((result) => {
        this.setState({ loading: false });
        this.props.onMutationSuccess(result, this.props.mode);
        this.props.formCancel();
      }).catch((error) => {
        this.setState({ loading: false, error });
        console.log(error);
      });
    } else {
      this.setState({ loading: true, error: null });
      this.props.updateSubject({
        variables: {
          subjectIn,
          id
        }
      }).then((result) => {
        // this.props.selectPatient(result && result.data && result.data.updateSbjt);
        this.setState({ loading: false });
        this.props.formCancel();
      })
      .catch((error) => {
        this.setState({ loading: false, error });
        console.log(error);
      });
    }
  }

  zeroPaddedNumber(num: number): string {
    return (num < 10) ? `0${num}` : `${num}`
  }

  onInputChange(event: SyntheticEvent<HTMLInputElement>, key: string) {
    this.props.formUpdate(key, event.currentTarget.value);
  }

  onDobYearChange(event: SyntheticEvent<HTMLInputElement>) {
    const dobPieces = this.props.patientDetails.dob.split('-');
    dobPieces[0] = event.currentTarget.value;
    this.updateDob(dobPieces);
  }

  onDobDateChange(option: SelectValue, key: string) {
    const dobPieces = this.props.patientDetails.dob.split('-');
    switch (key) {
      case 'month':
        dobPieces[1] = option.value;
        break;
      case 'date':
        dobPieces[2] = option.value;
        break;
      default: return;
    }
    this.updateDob(dobPieces);
  }

  updateDob(dobPieces: string[]) {
    const dobMoment = moment(dobPieces.join('-'), 'YYYY-MM-DD');
    if (dobPieces.length < 3) {
      this.props.formUpdate('dob', dobPieces.join('-'));
    } else if (dobPieces.length === 3 && !dobMoment.isValid() && dobPieces[0] !== '') {
      dobPieces[2] = '01';
      dobPieces[2] = moment(dobPieces.join('-'), 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD').split('.')[2];
      this.props.formUpdate('dob', dobPieces.join('-'));
    } else {
      this.props.formUpdate('dob', dobPieces.join('-'));
    }
  }

  formatDobYear() {
    const dobMoment = moment(this.props.patientDetails.dob, 'YYYY');
    if (dobMoment.isValid()) {
      this.props.formUpdate('dob', dobMoment.format('YYYY'));
    } else {
      this.props.formUpdate('dob', '');
    }
  }

  onPhoneChange(pieceIdx: number, value: string) {
    const phonePieces = this.props.patientDetails.tel.split('-');
    phonePieces[pieceIdx] = value;
    this.props.formUpdate('tel', phonePieces.join('-'));
  }

  render() {
    const {
      code,
      dob,
      email,
      handedness,
      name,
      tel,
      address,
      manager,
      comment,
      sex
    } = this.props.patientDetails;
    const dobPieces = dob.split('-');
    const dobMoment = moment(`${dobPieces[0]}-${dobPieces[1]}-01`, 'YYYY-MM-DD').endOf('month');
    let dates = _.range(0, 31, 1);
    if (dobMoment.isValid()) {
      dates = _.range(0, dobMoment.date(), 1);
    }
    return (
      <Popup
        open={this.props.shown}
        closeOnDocumentClick
        onClose={() => this.props.formCancel()}
        onOpen={this.onFormOpen.bind(this)}
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          margin: 0,
          zIndex: 10000,
        }}
        contentStyle={{
          backgroundColor: '#fff',
          boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
          border: 'none',
          width: '800px',
          height: '658px',
          padding: 0
        }}
      >
        <ContentContainer>
          <FormTitleContainer>
            <FormTitle>대상자 등록</FormTitle>
          </FormTitleContainer>
          <Form onSubmit={this.onFormSubmit.bind(this)}>
            <FormBodyContainer>
              <FormBody>
                <FormRow>
                  <label className='required'>
                    이름
                  </label>
                  <input
                    type='text'
                    name='name'
                    maxLength={500}
                    ref={(ref) => this._firstInput = ref}
                    value={name}
                    onChange={(event) => this.onInputChange(event, 'name')}
                    autoComplete='section-patient pt-name'
                    required
                  />
                </FormRow>
                <FormRow>
                  <label className='required'>
                    코드
                  </label>
                  <input
                    type='text'
                    name='code'
                    maxLength={50}
                    value={code}
                    onChange={(event) => this.onInputChange(event, 'code')}
                    autoComplete='section-patient code'
                    required
                  />
                </FormRow>
                <FormRow className='dobRow'>
                  <label className='required'>
                    생년월일
                  </label>
                  <input
                    type='text'
                    name='dob'
                    value={dobPieces[0]}
                    onChange={(event) => this.onDobYearChange(event)}
                    onBlur={(event) => this.formatDobYear()}
                    autoComplete='section-patient-dob year'
                    required
                  />
                  <label>년</label>
                  <Select
                    options={_.map(_.range(1, 13, 1), (month: number) => {
                      const label = this.zeroPaddedNumber(month);
                      return { value: label, label };
                    })}
                    placeholder=''
                    isClearable={false}
                    value={(dobPieces[1]) ? { value: dobPieces[1], label: dobPieces[1] } : null}
                    styles={{
                      container: styles => ({
                        ...styles,
                        flex: '0 0 60px',
                        marginLeft: '15px'
                      })
                    }}
                    onChange={(option) => this.onDobDateChange(option, 'month')}
                  />
                  <label>월</label>
                  <Select
                    options={_.map(dates, (date: number) => {
                      const label = this.zeroPaddedNumber(date + 1);
                      return { value: label, label };
                    })}
                    placeholder=''
                    isClearable={false}
                    value={(dobPieces[2]) ? { value: dobPieces[2], label: dobPieces[2] } : null}
                    styles={{
                      container: styles => ({
                        ...styles,
                        flex: '0 0 60px',
                        marginLeft: '15px'
                      })
                    }}
                    onChange={(option) => this.onDobDateChange(option, 'date')}
                  />
                  <label>일</label>
                </FormRow>
                <FormRow>
                  <label className='required'>
                    성별
                  </label>
                  <RadioGroup>
                    <Radio
                      name='sex'
                      value='male'
                      checked={sex === 'male'}
                      onChange={(event) => this.onInputChange(event, 'sex')}
                      required
                    >
                      Male
                    </Radio>
                    <Radio
                      name='sex'
                      value='female'
                      checked={sex === 'female'}
                      onChange={(event) => this.onInputChange(event, 'sex')}
                      required
                    >
                      Female
                    </Radio>
                  </RadioGroup>
                </FormRow>
                <FormRow>
                  <label className='required'>
                    손잡이
                  </label>
                  <RadioGroup>
                    <Radio
                      name='handedness'
                      value='right'
                      checked={handedness === 'right'}
                      onChange={(event) => this.onInputChange(event, 'handedness')}
                      required
                    >
                      Right
                    </Radio>
                    <Radio
                      name='handedness'
                      value='left'
                      checked={handedness === 'left'}
                      onChange={(event) => this.onInputChange(event, 'handedness')}
                      required
                    >
                      Left
                    </Radio>
                    <Radio
                      name='handedness'
                      value='both'
                      checked={handedness === 'both'}
                      onChange={(event) => this.onInputChange(event, 'handedness')}
                      required
                    >
                      Both
                    </Radio>
                  </RadioGroup>
                </FormRow>
                <FormRow>
                  <label>
                    전화번호
                  </label>
                  <input
                    type='text'
                    name='tel'
                    maxLength={500}
                    placeholder='010-XXXX-XXXX'
                    value={tel}
                    onChange={(event) => this.onInputChange(event, 'tel')}
                    autoComplete='section-patient pt-tel'
                  />
                </FormRow>
                <span style={{ fontSize: '10px', paddingLeft: '77px' }}>전화번호가 없는경우 010-000-0000 으로 입력해주세요.</span>
                {/* <FormRow className='phoneRow'>
                  <label>
                    전화번호
                  </label>
                  <Select
                    options={_.map(prefixes, (prefix: string) => { return { value: prefix, label: prefix }; })}
                    placeholder=''
                    isClearable={false}
                    value={(phonePieces[0] !== '') ? { value: phonePieces[0], label: phonePieces[0] } : null}
                    onChange={(option) => this.onPhoneChange(0, option.value)}
                    styles={{
                      container: styles => ({
                        ...styles,
                        flex: '0 0 60px',
                        marginLeft: '15px'
                      })
                    }}
                  />
                  <span>-</span>
                  <input
                    type='text'
                    value={phonePieces[1] || ''}
                    onChange={(event) => this.onPhoneChange(1, event.target.value)}
                    autoComplete='section-patient-phone piece-2'
                  />
                  <span>-</span>
                  <input
                    type='text'
                    value={phonePieces[2] || ''}
                    onChange={(event) => this.onPhoneChange(2, event.target.value)}
                    autoComplete='section-patient-phone piece-3'
                  />
                </FormRow> */}
                <FormRow>
                  <label>
                    이메일
                  </label>
                  <input
                    type='email'
                    name='email'
                    value={email}
                    onChange={(event) => this.onInputChange(event, 'email')}
                    autoComplete='section-patient email-opt'
                  />
                </FormRow>
                <FormRow>
                  <label>
                    주소
                  </label>
                  <input
                    type='text'
                    name='address'
                    value={address}
                    onChange={(event) => this.onInputChange(event, 'address')}
                    autoComplete='section-patient address-opt'
                  />
                </FormRow>
                <FormRow>
                  <label>
                    작성자
                  </label>
                  <input
                    type='text'
                    name='manager'
                    value={manager}
                    onChange={(event) => this.onInputChange(event, 'manager')}
                    autoComplete='section-patient manager-opt'
                  />
                </FormRow>
                <FormRow>
                  <label>
                    비고
                  </label>
                  <input
                    type='text'
                    name='comment'
                    value={comment}
                    onChange={(event) => this.onInputChange(event, 'comment')}
                    autoComplete='section-patient comment-opt'
                  />
                </FormRow>
                {(this.state.error) ? <FormErrorSpan>이미 사용중인 코드입니다. 다른 코드를 입력해주세요</FormErrorSpan> : null}
              </FormBody>
              <FormBody>
                <FormRow>
                  <label className='required'>
                    질병코드
                  </label>
                  <CodeInputContainer>
                    <ICDCodeInput/>
                  </CodeInputContainer>
                </FormRow>
                <ICDSelectForm
                  onCodeSelect={(code) => console.log('code selected')}
                />
              </FormBody>
            </FormBodyContainer>
            <FormFooterContainer>
              {(this.state.loading) ? <Spinner /> : (
                <React.Fragment>
                  <CancelButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.formCancel();
                    }}
                    href='#'
                  >
                    <span>취소</span>
                  </CancelButton>
                  <AcceptButton
                    className='with-hover-overlay'
                    type='submit'
                  >
                    <span>확인</span>
                  </AcceptButton>
                </React.Fragment>
              )}
            </FormFooterContainer>
          </Form>
        </ContentContainer>
      </Popup>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.analysis.patientForm,
    patientListCurrentPage: state.analysis.patientList.currentPage
  };
};

//export const PatientForm: React.ComponentType<OwnProps>= connect(mapStateToProps, patientFormActions)(PatientFormComponent);

export const PatientForm = compose(
  connect(mapStateToProps, {
    ...patientFormActions,
    selectPatient: patientListActions.selectPatient
  }),
  withApollo,
  graphql(NEW_SBJT_MUTATION, {
    name: 'createSubject',
    options: {
      context: {
        dataSourceType: 'api'
      }
    }
  }),
  graphql(UPDATE_SBJT_MUTATION, { name: 'updateSubject', options: { context: { dataSourceType: 'api' } } })
)(PatientFormComponent);