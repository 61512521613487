// @flow
// type imports
import type { AppState } from '../../../types';
import type { CurrentUserState } from '../../../types/auth';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import axios from 'axios';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AnchorButton, Button } from '../../common';
import { Spinner } from '../Spinner';

const FormContainer = styled.form`
  display: inline-block;
  position: relative;
`;

const HiddenFileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  right: 0;
`;

const UploadButton = styled(Button)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  height: 45px;
  width: 100px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TitleContainer = styled.div`
  height: 82px;
  border-bottom: solid 2px #d0dcea;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0;
  span {
    color: #fc5882;
  }
`;

export const ContentContainer = styled.div`
  flex: 1 1 0%;
  min-height: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const FileOptionContainer = styled.div`
  flex: 1 1 0%;
  min-height: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 45px;
  margin-top: 10px;
`;

export const CancelButton = styled(AnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #94aabf;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const AcceptButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #576881;
  margin-left: 10px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

type State = {
  selectedFiles: FileList | null,
  fileOptions: FileOption[],
  fileLoading: { [filename: string]: boolean },
  loading: boolean
};

type FileOption = {
  [option: string]: string | Object
};

type OwnProps = {
  disabled: boolean,
  endpointUrl: string,
  renderFileOptions: (state: State, setState: Function) => React.Node,
  getDefaultFileOptions: (files: FileList) => FileOption[],
  getFileOptions: (state: State) => FileOption[],
  onSubmitDone: (error: any) => void,
  accept?: string,
  children?: React.Node
};

type Props = OwnProps & CurrentUserState;

class MultiStepFileFormComponent extends React.Component<Props, State> {
  _form: ?HTMLFormElement;
  _fileInput: ?HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFiles: null,
      fileOptions: [],
      fileLoading: {},
      loading: false
    };
  }

  async uploadFiles() {
    const { access_token } = this.props.tokens;
    const { selectedFiles } = this.state;
    if (selectedFiles) {
      this.setState({ loading: true });
      for (let i = 0; i < selectedFiles.length; i++) {
        const data = new FormData();
        data.append('srcFile', selectedFiles.item(i));
        const { anlz_algo, type, sessionInfo } = this.state.fileOptions[i];
        if (anlz_algo !== 'NONE') data.append('anlz_algo', '1');

        const sessionInfoObj = sessionInfo ? { ...sessionInfo, type } : { type };
        console.log(sessionInfoObj);
        const sessionInfoJson = JSON.stringify(sessionInfoObj);
        const sessionInfoBlob = new Blob([sessionInfoJson], {
          type: 'application/json'
        });

        data.append('sessionInfo', sessionInfoBlob);

        try {
          const response = await axios.post(
            this.props.endpointUrl,
            data,
            {
              timeout: 120000,
              headers: {
                'content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${access_token}`
              },
            }
          );
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedFiles !== this.state.selectedFiles) {
      const { fileLoading, fileOptions } = this.state;
      _.forEach(this.state.selectedFiles, (file, index) => {
        const filenamePieces = file.name.split('.');
        const extension = filenamePieces && filenamePieces[filenamePieces.length - 1];
        if (!fileLoading[file.name] && extension !== 'ytdf') {
          let reader = new FileReader();
          reader.onload = function(event) {
            let recIdRaw = new Uint8Array(event.target.result.slice(88, 168));
            let recIdStr = _.join(_.map(recIdRaw, (c) => String.fromCharCode(c)), '').trim();
            let sessionName = recIdStr.split(' ')[2]
            let sn = recIdStr.split(' ')[3].split('/')
            let stationSn = ''
            let moduleSn = ''
            try {
              stationSn = sn[0].slice(3)
              moduleSn = sn[1].slice(3)
            } catch (e) {
              // do nothing...
            }

            let recStartDateRaw = new Uint8Array(event.target.result.slice(168, 176));
            let recStartDateStr = _.join(_.map(recStartDateRaw, (c) => String.fromCharCode(c)), '');
            let recStartTimeRaw = new Uint8Array(event.target.result.slice(176, 184));
            let recStartTimeStr = _.join(_.map(recStartTimeRaw, (c) => String.fromCharCode(c)), '');
            const recStartDateTimeStr = `${recStartDateStr} ${recStartTimeStr}`;
            const recStartDateTime = moment(recStartDateTimeStr, 'DD.MM.YY HH.mm.ss');
            let numRecRaw = new Uint8Array(event.target.result.slice(236, 244));
            let numRecStr = _.join(_.map(numRecRaw, (c) => String.fromCharCode(c)), '').trim();
            let secPerRecRaw = new Uint8Array(event.target.result.slice(244, 252));
            let secPerRecStr = _.join(_.map(secPerRecRaw, (c) => String.fromCharCode(c)), '').trim();

            const recLengthSec = (+numRecStr) * (+secPerRecStr);
            const sessionInfo = {
              recordTime: recStartDateTime.toISOString(false),
              recLengthSec,
              name: sessionName,
              stationGs1Code: stationSn,
              moduleGs1Code: moduleSn,
            };


            if (_.includes(recIdStr, 'ECG')) {
              this.setState({
                fileOptions: _.map(fileOptions, (option, idx: number) => (
                  (idx === index) ? {
                    ...option,
                    anlz_algo: 'NONE',
                    type: 'ECG',
                    sessionInfo,
                  } : option
                ))
              });
            } else {
              this.setState({
                fileOptions: _.map(fileOptions, (option, idx: number) => (
                  (idx === index) ? {
                    ...option,
                    type: 'EEG',
                    sessionInfo,
                  } : option
                ))
              });
            }
            this.setState({ fileLoading: { ...fileLoading, [file.name]: false } })
          }.bind(this)
          reader.readAsArrayBuffer(file);
          this.setState({ fileLoading: { ...fileLoading, [file.name]: true } });
        }
      });
    }
    
  }

  render() {
    const {
      disabled,
      endpointUrl,
      accept,
      children
    } = this.props;
    const {
      loading
    } = this.state;
    return (
      <FormContainer
        ref={ref => this._form = ref}
        onSubmit={(event) => {
          console.log('file form submit')
          event.preventDefault();
          this.uploadFiles().then(() => {
            this.props.onSubmitDone();
            this.setState({
              loading: false,
              selectedFiles: null,
              fileOptions: []
            });
            if (this._form) {
              this._form.reset();
            }
          });
        }}
      >
        <HiddenFileInput
          ref={ref => this._fileInput = ref}
          onChange={(event) => {
            console.log(event.target.files);
            if (event.target.files) {
              this.setState({
                selectedFiles: event.target.files,
                fileOptions: this.props.getDefaultFileOptions(event.target.files)
              });
            }
          }}
          multiple
          type='file'
          tabIndex={-1}
          accept={accept}
        />
        <UploadButton
          className='with-hover-overlay'
          href='#'
          disabled={disabled}
          onClick={(event) => {
            if (this._fileInput) {
              this._fileInput.click();
            }
            event.preventDefault();
          }}
        >
          {children}
        </UploadButton>
        <Popup
          modal
          open={!!this.state.selectedFiles}
          closeOnDocumentClick={false}
          overlayStyle={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            margin: 0,
            zIndex: 10000,
            flexDirection: 'column'
          }}
          contentStyle={{
            backgroundColor: '#fff',
            boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
            border: 'none',
            width: '500px',
            height: '400px',
            padding: 0
          }}
        >
          {(close) => {
            return (
              <PopupContainer>
                <TitleContainer>
                  <Title>분석할 <span>파일</span>을 <span>업로드</span>해주세요</Title>
                </TitleContainer>
                <ContentContainer>
                  <FileOptionContainer>
                    {this.props.renderFileOptions(this.state, this.setState.bind(this))}
                  </FileOptionContainer>
                  <ButtonContainer>
                    <CancelButton
                      className='with-hover-overlay'
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        if (this._form) {
                          this._form.reset();
                        }
                        this.setState({
                          selectedFiles: null,
                          fileOptions: []
                        });
                        close();
                      }}
                    >
                      <span>취소</span>
                    </CancelButton>
                    <AcceptButton
                      className='with-hover-overlay'
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        if (typeof(Event) === 'function' && this._form) {
                          this._form.dispatchEvent(new Event('submit'));
                        } else {
                          const submitEvent = document.createEvent('Event');
                          submitEvent.initEvent('submit', true, true);
                          if(this._form) this._form.dispatchEvent(submitEvent);
                        }
                      }}
                    >
                      {loading ? <Spinner /> : (<span>확인</span>)}
                    </AcceptButton>
                  </ButtonContainer>
                </ContentContainer>
              </PopupContainer>
            )
          }}
        </Popup>
      </FormContainer>
    )
  }
}

const mapStateToProps = (state: AppState): CurrentUserState => state.auth.currentUser;

export const MultiStepFileForm = connect(mapStateToProps, {})(MultiStepFileFormComponent);

/*

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedFiles && nextProps.selectedFiles.length === 1 && this._form && !nextProps.loading) {
      if (typeof(Event) === 'function') {
        this._form.dispatchEvent(new Event('submit'));
      } else {
        const submitEvent = document.createEvent('Event');
        submitEvent.initEvent('submit', true, true);
        this._form.dispatchEvent(submitEvent);
      }
    } else if (this.props.loading && !nextProps.loading) {
      this.props.onSubmitDone(nextProps.error);
      if (this._form) {
        this._form.reset();
      }
    }
  }
*/
