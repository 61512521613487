// @flow
import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { HoverStyledAnchorButton } from '../Button';

const PanelContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 15px;
`;

const PanelHeader = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  padding: 0 40px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

const Title = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: #576881;
`;

const SegmentButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
  > * {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

const ButtonDivider = styled.div`
  width: 1px;
  height: 17px;
  background-color: #bcc9d8;
`;

const SegmentButton = styled(HoverStyledAnchorButton)`
  &.active-segment > span {
    color: #00bbf9;
  }
  > span {
    color: #d0dcea;
    font-size: 22px;
  }
  &:hover, &:focus {
    > span {
      color: #00bbf9;
    }
  }
`;

type State = {
  activeSegment: string
};

type Segment = {
  label: string,
  key: string
}

type Props = {
  children: (state: State) => React.Node,
  title: string,
  segments: Segment[]
}

class SegmentedPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeSegment: props.segments[0].key
    }
  }

  renderSegmentButtonGroup(segments: Segment[]) {
    return (
      <SegmentButtonGroup>
        {_.map(segments, (segment, idx) => {
          const {
            key,
            label
          } = segment;
          if (idx < segments.length - 1) {
            return (
              <React.Fragment key={key}>
                <SegmentButton
                  className={(key === this.state.activeSegment) ? 'active-segment' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ activeSegment: key });
                  }}
                >
                  <span>{label}</span>
                </SegmentButton>
                <ButtonDivider />
              </React.Fragment>
            )
          }
          return (
            <SegmentButton
              key={key}
              className={(key === this.state.activeSegment) ? 'active-segment' : ''}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ activeSegment: key });
              }}
            >
              <span>{label}</span>
            </SegmentButton>
          );
        })}
      </SegmentButtonGroup>
    );
  }

  render() {
    const {
      title,
      segments,
      children
    } = this.props;
    return (
      <PanelContainer>
        <PanelHeader>
          <Title>{title}</Title>
          {this.renderSegmentButtonGroup(segments)}
        </PanelHeader>
        {children(this.state)}
      </PanelContainer>
    )
  }
}

export { SegmentedPanel };