// @flow
// type imports
import type { AppState } from "../../../types";
import type { DateRangeInputState } from "../../../types/common";
// module imports
import * as React from "react";
import { connect } from "react-redux";
import {
  Input,
  CalendarInput,
  Container,
  CalendarContainer,
  CalendarButton,
  CalendarButton2,
  YearPickerContainer,
  CalendarPanelContainer,
  YearMonthPickerContainer,
} from "./styled";
import { BillingDateRangePicker } from "./BillingDateRangePicker";
import { actionCreators as dateRangeActions } from "../../../ducks/common/daterangeinput";
import commonImages from "../../../resources/images/common";
import monthlyImages from "../../../resources/images/monthly";
import moment from "moment";
import Popup from "reactjs-popup";
import styled from "styled-components";

type OwnProps = {
  name: string,
  parentStateSelector: (state: AppState) => any,
  onChange?: (orgzId: number, startDate: moment) => void,
  minDate: moment,
  maxDate: moment,
};

type StateProps = DateRangeInputState;

type ActionProps = typeof dateRangeActions;

type Props = OwnProps & StateProps & ActionProps;

class DateRangeInputComponent2 extends React.Component<Props> {
  _inputRef: ?HTMLInputElement;
  _containerRef: ?HTMLDivElement;

  onPickerSubmit(startDate: moment, endDate: moment) {
    this.props.setDateRange(this.props.name, startDate, endDate);
    // trigger daterangechanged handler
    if (this.props.onChange) {
      this.props.onChange(this.props.orgzId, startDate);
    }
  }

  onChangeMonth(action: string, value: number) {
    let startDate;
    if (action === "add")
      startDate = moment(this.props.startDate.add(value, "months"));
    else if (action === "subtract")
      startDate = moment(this.props.startDate.subtract(value, "months"));
    const endDate = moment(startDate).endOf("month");
    this.props.setDateRange(this.props.name, startDate, endDate);

    if (this.props.onChange) {
      this.props.onChange(this.props.orgzId, startDate);
    }
  }

  compare(a: moment, b: moment) {
    if (a.year() === b.year()) {
      return a.month() - b.month();
    } else {
      return a.year() - b.year();
    }
  }

  render() {
    const { startDate, endDate, minDate, maxDate } = this.props;
    return (
      <CalendarPanelContainer>
        {!minDate || this.compare(startDate, minDate) > 0 ? (
          <a
            onClick={() => this.onChangeMonth("subtract", 1)}
            style={{ width: 40 }}
          >
            <i className="arrow left" />
          </a>
        ) : (
          <a style={{ width: 40 }} />
        )}
        <Popup
          closeOnDocumentClick
          modal
          trigger={
            <YearMonthPickerContainer>
              <CalendarButton2
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <img src={monthlyImages.searchCal} alt="Calendar" />
                <img
                  src={monthlyImages.searchCalHover}
                  alt="Calendar"
                  className="hover-img"
                />
              </CalendarButton2>
              <CalendarInput
                ref={(ref) => (this._inputRef = ref)}
                type="text"
                value={startDate.format("YYYY년 MM월")}
                readOnly
              />
            </YearMonthPickerContainer>
          }
          overlayStyle={{
            zIndex: 10000,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          contentStyle={{
            zIndex: 10001,
            backgroundColor: "#fff",
            boxShadow: "4px 11px 11px 0 rgba(0, 0, 0, 0.1)",
            border: "none",
            width: "317px",
            height: "345px",
            padding: 0,
          }}
        >
          {(close) => (
            <BillingDateRangePicker
              currentYear={moment().year()}
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={maxDate}
              selectedMonth={
                startDate.format("YYYY.MM.DD") ===
                  moment(startDate).startOf("month").format("YYYY.MM.DD") &&
                endDate.format("YYYY.MM.DD") ===
                  moment(endDate).endOf("month").format("YYYY.MM.DD") &&
                startDate.month() === endDate.month()
                  ? startDate.month() + 1
                  : null
              }
              onSubmit={(startDate: moment, endDate: moment) => {
                this.onPickerSubmit(startDate, endDate);
                close();
              }}
              onCancel={() => close()}
            />
          )}
        </Popup>
        {!maxDate || this.compare(startDate, maxDate) < 0 ? (
          <a onClick={() => this.onChangeMonth("add", 1)} style={{ width: 40 }}>
            <i className="arrow right" />
          </a>
        ) : (
          <a style={{ width: 40 }} />
        )}
      </CalendarPanelContainer>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const parentState = ownProps.parentStateSelector(state);
  if (parentState.hasOwnProperty(`${ownProps.name}DateRange`)) {
    const dateRangeState: DateRangeInputState =
      parentState[`${ownProps.name}DateRange`];
    return {
      startDate: dateRangeState.startDate,
      endDate: dateRangeState.endDate,
      orgzId: state.auth.currentUser.orgz_id,
    };
  }
  return {
    startDate: moment().date(1),
    endDate: moment().add(1, "months").date(0),
    orgzId: state.auth.currentUser.orgz_id,
  };
};

export const BillingDateRangeInput: React.ComponentType<OwnProps> = connect(
  mapStateToProps,
  dateRangeActions
)(DateRangeInputComponent2);
