// @flow
// type imports
import type { AppState } from '../../../types';
import type { AnlzRequest, AnlzRequestListState, Patient } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import Popup from 'reactjs-popup';
import { Query } from '@apollo/client/react/components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import {
  PanelContentContainer,
  SessionListContainer,
  PDFDownloadButton,
  ErrorMsgTooltipSpan
} from './styled';
import { anlzRequestListActions, surveySessionListActions } from '../../../ducks/analysis';
import { ANLZ_REQUESTS_BY_SBJTID_QUERY } from '../../../utils/graphql/queries';
import analysisImages from '../../../resources/images/analysis';
import {
  selectableTable,
  checkableTable,
  styledTable,
  Spinner,
  EllipsisSpan
} from '../../common';

const SelectableTable = compose(styledTable, selectableTable, checkableTable)(ReactTable);

type StateProps = AnlzRequestListState & {
  selectedPatient: Patient | null
};

type ActionProps = typeof anlzRequestListActions;

type Props = StateProps & ActionProps;

class AnlzRequestListComponent extends React.Component<Props> {
  _refetch: () => Promise<any>;
  _listPageSize: number = 10;

  componentDidUpdate(prevProps) {
    if (this.props.refetch) {
      this._refetch().then((result) => {
        const {
          nowPage,
          totalPages
        } = result.data.anlzReqPgBySbjtId.pageInfo;
        this.props.refetchAnlzRequestsDone();
        this.props.setPagingInfo(nowPage, totalPages);
      });
    }
    if (prevProps.selectedPatient && this.props.selectedPatient && prevProps.selectedPatient.id !== this.props.selectedPatient.id) {
      this.props.clearAnlzRequestSelection();
    } else if (!this.props.selectedPatient) {
      this.props.clearAnlzRequestSelection();
    }
  }

  componentWillUnmount() {
    this.props.clearAnlzRequestSelection();
    this.props.resetListState();
  }

  render() {
    const {
      selectedPatient,
      selectedAnlzRequest,
      checkedAnlzRequests,
      currentPage,
      numPages
    } = this.props;
    return (
      <Query
        query={ANLZ_REQUESTS_BY_SBJTID_QUERY}
        context={{
          dataSourceType: 'api'
        }}
        skip={!selectedPatient}
        fetchPolicy='network-only'
        variables={{
          subjectId: selectedPatient && selectedPatient.id,
          anlzType: 'COMPARISON',
          page: currentPage,
          size: this._listPageSize
        }}
        onCompleted={(data) => {
          if (data && data.anlzReqPgBySbjtId) {
            const {
              nowPage,
              totalPages
            } = data.anlzReqPgBySbjtId.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          }
        }}
      >
        {({ loading, error, data, refetch }) => {
          console.log(error);
          this._refetch = refetch;
          console.log(data);
          return (
            <PanelContentContainer>
              <SessionListContainer>
                <SelectableTable
                  data={(!selectedPatient) ? [] : ((data && data.anlzReqPgBySbjtId) ? data.anlzReqPgBySbjtId.requests : [])} // data requests
                  isRowCheckable={(rowData) => rowData.anlzStatus !== 'PROCEEDING'}
                  loading={(!selectedPatient) ? false : loading}
                  page={(data && data.anlzReqPgBySbjtId) ? data.anlzReqPgBySbjtId.pageInfo.nowPage : currentPage}
                  pageSize={this._listPageSize}  // props pageSize
                  minRows={0}
                  manual
                  LoadingComponent={Spinner}
                  pages={(data && data.anlzReqPgBySbjtId) ? data.anlzReqPgBySbjtId.pageInfo.totalPages : numPages}
                  columns={[{
                      id: 'created',
                      Header: '분석 요청일',
                      minWidth: 220,
                      accessor: rowData => moment(rowData.created).format('YYYY-MM-DD HH:mm'),
                    }, {
                      id: 'anlzSrcFileName2',
                      minWidth: 245,
                      Header: '분석용 파일 Pre',
                      Cell: (rowInfo) => <EllipsisSpan>{rowInfo.value || '-'}</EllipsisSpan>,
                      accessor: rowData => rowData.anlzSources && rowData.anlzSources[1].visualFile,
                    }, {
                      id: 'anlzSrcFileName1',
                      minWidth: 245,
                      Header: '분석용 파일 Post',
                      Cell: (rowInfo) => <EllipsisSpan>{rowInfo.value || '-'}</EllipsisSpan>,
                      accessor: rowData => rowData.anlzSources && rowData.anlzSources[0].visualFile,
                    }, {
                      id: 'empName',
                      minWidth: 180,
                      Header: '분석 요청자',
                      Cell: (rowInfo) => <EllipsisSpan>{rowInfo.value}</EllipsisSpan>,
                      accessor: rowData => rowData.employeeName,
                    }, {
                      id: 'anlzStatus',
                      minWidth: 150,
                      Header: '분석 결과',
                      Cell: (rowInfo) => {
                        const {
                          anlzStatus,
                          remarks
                        } = rowInfo.value
                        switch(anlzStatus) {
                          case 'REQUEST': return '분석 요청중';
                          case 'PROCEEDING': return '분석중';
                          case 'COMPLETE':
                            return rowInfo.original.actualResultFile ? (
                              <PDFDownloadButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  if (selectedPatient) {
                                    this.props.downloadAnlzResource(selectedPatient.id, encodeURIComponent(rowInfo.original.actualResultFile), rowInfo.original.visualResultFile);
                                  }
                                }}
                                href='#'
                                download
                              >
                                <img src={analysisImages.pdf} alt='Download PDF' />
                              </PDFDownloadButton>
                            ) : '분석완료';
                          case 'CANCEL': return '분석취소';
                          case 'FAIL':
                            return (
                              <Popup
                                trigger={<span>분석실패</span>}
                                position='top center'
                                contentStyle={{
                                  width: 'auto',
                                  minWidth: '40px',
                                  maxWidth: '400px',
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center'
                                }}
                                arrowStyle={{
                                  boxShadow: '1.2px 1.2px 1.5px 0 rgba(0,0,0,0.2)',
                                  borderBottom: '1px solid rgb(187,187,187)',
                                  borderRight: '1px solid rgb(187,187,187)',
                                  borderTop: '1px solid transparent',
                                  borderLeft: '1px solid transparent',
                                }}
                                on='hover'
                              >
                                {() => <ErrorMsgTooltipSpan>{JSON.parse(remarks)['msg']}</ErrorMsgTooltipSpan>}
                              </Popup>
                            );
                          default: return anlzStatus;
                        }
                      },
                      accessor: rowData => ({
                        remarks: rowData.remarks,
                        anlzStatus: rowData.anlzStatus
                      }),
                    }
                  ]}
                  showPagination={false}
                  resizable={false}
                  multiSort={false}
                  sortable={false}
                  selectedRow={selectedAnlzRequest}
                  checkedRows={checkedAnlzRequests}
                  onRowsCheck={(rowIds: string[]) => {
                    this.props.checkAnlzRequests(rowIds);
                  }}
                  onRowsUncheck={(rowIds: string[]) => {
                    this.props.uncheckAnlzRequests(rowIds);
                  }}
                  onRowSelect={(rowInfo: any) => {
                    const request: AnlzRequest = rowInfo.original;
                    this.props.selectAnlzRequest(request);
                  }}
                  noDataText=''
                ></SelectableTable>
              </SessionListContainer>
            </PanelContentContainer>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.analysis.anlzRequestList,
    selectedPatient: state.analysis.patientList.selectedPatient
  };
}

export const CompAnlzRequestList: React.ComponentType<{}> = connect(mapStateToProps, anlzRequestListActions)(AnlzRequestListComponent);
