// @flow
import styled from 'styled-components';
import { AnchorButton } from '../Button';

export const Container = styled.div`
  width: 150px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1200;
  overflow: hidden;
  ${props => props.isBackoffice ? 'background-color: #576881;' : 'background-image: linear-gradient(348deg, #5b77d4, #3aaee8 57%, #1777db);'}
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
`;

export const ContentContainer = styled.div`
  position: relative;
  min-height: 900px;
  height: 100%;
`;

export const SidebarContentContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  background: transparent;
`;

export const SidebarHeader = styled.div`
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > *:not(:last-child) {
    margin-bottom: 15px;
  }
  > span {
    color: #fff;
    font-size: 15px;
  }
`;

export const LogoutButton = styled(AnchorButton)`
  span {
    color: #fff;
    font-size: 15px;
  }
  &:hover {
    span {
      color: #576881;
    }
  }
`;

export const SidebarNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SidebarNavItem = styled.div`
  width: 150px;
  height: 108px; 
  font-size: 18px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  *:first-child {
    margin-bottom: 8px;
  }
`;

export const SidebarFooter = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const NonNavLinkContainer = styled.div`
  padding: 0 13px;
  > a {
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    &:not(:first-child) {
      margin-top: 9px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LogoLink = styled.a`
  display: block;
  margin: 30px auto 0 13px;
  width: 64px;
  height: 15px;
  img {
    width: 64px;
    height: 15px;  
    object-fit: contain;
  }
`;

export const CopyRight = styled.p`
  margin: 13px 0;
  padding: 0 13px;
  font-size: 8px;
  color: #fff;
  opacity: 0.5;
`;