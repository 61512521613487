// @flow
// type imports
import type { AppState } from '../../types';
import type { Patient, PatientListState, AnlzSource, SurveySession, AnlzRequest } from '../../types/analysis';
// module imports
import * as React from 'react';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { patientListActions, anlzRequestListActions } from '../../ducks/analysis';
import moment from 'moment';
import scrollIntoView from 'scroll-into-view';
import {
  Page,
  PanelGrid
} from '../../components/common';

import {
  PatientListPanel,
  CalendarPanel,
  PatientDetailPanel,
  PatientForm,
  AnlzSourceDetailPanel,
  AnlzRequestDetailPanel,
  SurveySessionDetailPanel
} from '../../components/analysis';

import { ALL_SBJTS_QUERY } from '../../utils/graphql/queries';

type OwnProps = {};
type StateProps = PatientListState & {
  selectedAnlzSource: AnlzSource | null,
  selectedSurveySession: SurveySession | null,
  selectedAnlzRequest: AnlzRequest | null,
  activePatientDetailTab: string
};
type ActionProps = typeof patientListActions & {
  downloadAnlzResource: typeof anlzRequestListActions.downloadAnlzResource
};

type State = {
  noDataText: string
};

type Props = OwnProps & StateProps & ActionProps;

class AnalysisComponent extends React.Component<Props, State> {
  _listPageSize: number = 14;
  _refetch = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      noDataText: ''
    };
  }

  handlePatientSelect(patient: Patient) {
    //this.props.selectPatient(patient);
    this.props.history.push(`/analysis/subject/${patient.id}`);
  }

  handleRefetchSuccess = ({ data }: any) => {
    if (data && data.sbjtsInOrgz) {
      const { pageInfo } = data.sbjtsInOrgz;
      this.props.setPagingInfo(pageInfo.nowPage, pageInfo.totalPages)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentPage !== this.props.currentPage && this._refetch) {
      const { filter } = this.props;
      if (!filter.date && filter.keyword !== '') {
        this._refetch({ page: this.props.currentPage, size: this._listPageSize, codeOrName: filter.keyword, createdDate: undefined }).then(this.handleRefetchSuccess);
        this.setState({ noDataText: '검색결과가 없습니다' });
        return;
      } else if (filter.date) {
        this._refetch({ page: this.props.currentPage, size: this._listPageSize, codeOrName: undefined, createdDate: filter.date.toISOString(true)}).then(this.handleRefetchSuccess);
        this.setState({ noDataText: '검색결과가 없습니다' });
        return;
      }
      this.setState({ noDataText: '' });
      this._refetch({ page: this.props.currentPage, size: this._listPageSize, codeOrName: undefined, createdDate: undefined }).then(this.handleRefetchSuccess);
    }
  }

  renderDetailPanel() {
    const {
      selectedPatient,
      selectedAnlzSource,
      selectedSurveySession,
      selectedAnlzRequest,
      activePatientDetailTab
    } = this.props;
    if (selectedPatient && selectedAnlzSource && activePatientDetailTab === 'anlzSources') {
      return (
        <PanelGrid.Row style={{ height: '310px'}}>
          <PanelGrid.Col minWidth={1175}>
            <AnlzSourceDetailPanel selectedPatient={selectedPatient} selectedAnlzSource={selectedAnlzSource} />
          </PanelGrid.Col>
        </PanelGrid.Row>
      );
    } else if (selectedPatient && selectedSurveySession && activePatientDetailTab === 'surveySessions') {
      return (
        <PanelGrid.Row style={{ height: selectedSurveySession.submitted ? '673px' : '460px'}}>
          <PanelGrid.Col minWidth={1175}>
            <SurveySessionDetailPanel selectedPatient={selectedPatient} selectedSurveySession={selectedSurveySession} />
          </PanelGrid.Col>
        </PanelGrid.Row>
      );
    } else if (selectedPatient && selectedAnlzRequest && (activePatientDetailTab === 'anlzRequests' || activePatientDetailTab === 'compAnlzRequests')) {
      return (
        <PanelGrid.Row style={{ minHeight: '540px'}}>
          <PanelGrid.Col minWidth={1175}>
            <AnlzRequestDetailPanel selectedPatient={selectedPatient} selectedAnlzRequest={selectedAnlzRequest} downloadAnlzResource={this.props.downloadAnlzResource} />
          </PanelGrid.Col>
        </PanelGrid.Row>
      );
    }
    return null;
  }

  render() {
    return (
      <Page
        title='Analysis'
        subtitle='등록된 대상자의 측정기록 상태를 검색하고 분석을 요청하여 관리할 수 있습니다'
        contentMinWidth={1295}
      >
        <PanelGrid.Grid>
          <Query
            query={ALL_SBJTS_QUERY}
            delay
            fetchPolicy='network-only'
            context={{
              dataSourceType: 'api'
            }}
          >
            {({ loading, error, data, refetch }) => {
              this._refetch = refetch;
              return (
                <React.Fragment>
                  <PanelGrid.Row style={{ height: '918px' }}>
                    <PanelGrid.Col minWidth={1175}>
                      <PatientListPanel
                        onPatientSelect={(patient: Patient) => this.handlePatientSelect(patient)}
                        onPatientSearch={() => {
                          const { filter } = this.props;
                          this.props.clearPatientSelection();
                          if (!filter.date && filter.keyword !== '') {
                            refetch({ page: 0, size: this._listPageSize, codeOrName: filter.keyword, createdDate: undefined }).then(this.handleRefetchSuccess);
                            this.setState({ noDataText: '검색결과가 없습니다' });
                            return;
                          } else if (filter.date) {
                            refetch({ page: 0, size: this._listPageSize, codeOrName: undefined, createdDate: filter.date.toISOString(true)}).then(this.handleRefetchSuccess);
                            this.setState({ noDataText: '검색결과가 없습니다' });
                            return;
                          }
                          this.setState({ noDataText: '' });
                          refetch({ page: 0, size: this._listPageSize, codeOrName: undefined, createdDate: undefined }).then(this.handleRefetchSuccess);
                        }}
                        onShowAll={() => {
                          this.props.clearPatientSelection();
                          this.setState({ noDataText: '' });
                          refetch({ page: 0, size: this._listPageSize, codeOrName: undefined, createdDate: undefined }).then(this.handleRefetchSuccess);
                        }}
                        loading={loading}
                        subjects={(data && data.sbjtsInOrgz) ? data.sbjtsInOrgz.subjects : []}
                        currentPage={this.props.currentPage}
                        numPages={this.props.numPages}
                        pageSize={this._listPageSize}
                        noDataText={this.state.noDataText}
                        onPatientDelete={() => {
                          console.log('refetch on delete');
                          refetch({ page: this.props.currentPage, size: this._listPageSize }).then(this.handleRefetchSuccess);
                        }}
                        onFetchData={(state, instance) => {
                          const { filter } = this.props;
                          if (!filter.date && filter.keyword !== '') {
                            refetch({ page: this.props.currentPage, size: this._listPageSize, codeOrName: filter.keyword, createdDate: undefined }).then(this.handleRefetchSuccess);
                            this.setState({ noDataText: '검색결과가 없습니다' });
                            return;
                          } else if (filter.date) {
                            refetch({ page: this.props.currentPage, size: this._listPageSize, codeOrName: undefined, createdDate: filter.date.toISOString(true)}).then(this.handleRefetchSuccess);
                            this.setState({ noDataText: '검색결과가 없습니다' });
                            return;
                          }
                          this.setState({ noDataText: '' });
                          refetch({ page: this.props.currentPage, size: this._listPageSize, codeOrName: undefined, createdDate: undefined }).then(this.handleRefetchSuccess);
                        }}
                      />
                    </PanelGrid.Col>
                    {/* <PanelGrid.Col minWidth={252} widthFixed>
                      <CalendarPanel
                        onDayClick={(date: moment) => {
                          this.props.clearPatientSelection();
                          this.setState({ noDataText: '검색결과가 없습니다' });
                          refetch({ page: 0, size: this._listPageSize, codeOrName: undefined, createdDate: date.toISOString(true)}).then(this.handleRefetchSuccess);
                        }}
                      />
                    </PanelGrid.Col> */}
                  </PanelGrid.Row>
                  <PatientForm
                    onMutationSuccess={(result) => {
                      console.log(result);
                      // refetch({ page: 0, size: this._listPageSize }).then(this.handleRefetchSuccess);
                      if (result && result.data && result.data.newSbjt) {
                        this.props.history.push(`/analysis/subject/${result.data.newSbjt.id}`);
                      }
                    }}
                  />
                </React.Fragment>
              );
            }}
          </Query>
          {/* <PanelGrid.Row style={{ height: '753px'}} ref={this._ptDetailsPanel}>
            <PanelGrid.Col minWidth={1175}>
              <PatientDetailPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
          {this.renderDetailPanel()} */}
        </PanelGrid.Grid>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  return {
    ...state.analysis.patientList,
    selectedAnlzSource: state.analysis.anlzSourceList.selectedAnlzSource,
    selectedSurveySession: state.analysis.surveySessionList.selectedSurveySession,
    selectedAnlzRequest: state.analysis.anlzRequestList.selectedAnlzRequest,
    activePatientDetailTab: state.analysis.patientDetailTabs.activeTab
  };
}

export const Analysis = connect(mapStateToProps, {
  ...patientListActions,
  downloadAnlzResource: anlzRequestListActions.downloadAnlzResource
})(AnalysisComponent);
