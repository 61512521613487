// @flow
import * as React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  padding-top: 30px;
  > div:first-child {
    margin-top: 0;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  font-size: 0;
  > div {
    display: flex;
    flex-direction: column;
    min-height: inherit;
    &:first-child {
      margin-left: 0;
    }
  }
`;

const Col = styled.div`
  margin-left: 30px;
`;

type ColProps = {
  children?: React.ChildrenArray<*>,
  flex?: string,
  widthFixed?: boolean,
  minWidth: number
}

const GridCol = ({ children, minWidth, flex }: ColProps) => {
  return (
    <Col style={{ minWidth: `${minWidth}px`, flex }}>
      {children}
    </Col>
  );
};

type RowProps = {
  children: React.ChildrenArray<React.Element<typeof GridCol>>,
  style?: any
}

const GridRow = React.forwardRef(({ children, style }: RowProps, ref: React.Ref<HTMLDivElement>) => {
  let panelWidthTotal = 0;
  React.Children.forEach(children, (child) => {
    if (!child.props.widthFixed) {
      panelWidthTotal += child.props.minWidth;
    }
  });
  const flexStyles = React.Children.map(children, (child) => {
    const { minWidth } = child.props;
    if (!child.props.widthFixed) {
      const flex = child.props.minWidth / panelWidthTotal;
      return `${flex} ${flex} ${minWidth}px`;
    }
    return `0 0 ${minWidth}px`;
  });
  return (
    <Row style={style} ref={ref}>
      {React.Children.map(children, (child, idx) => React.cloneElement(child, {
        ...child.props,
        flex: flexStyles[idx]
      }))}
    </Row>
  )
});

export const PanelGrid = {
  Grid,
  Row: GridRow,
  Col: GridCol
};