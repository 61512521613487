// @flow
import type { PatientFormState, PatientDetails, FilterOption } from '../../types/analysis';
import _ from 'lodash';

// client actions
const FORM_UPDATE: 'sd/a/patientform/FORM_UPDATE' = 'sd/a/patientform/FORM_UPDATE';
const FORM_CANCEL: 'sd/a/patientform/FORM_CANCEL' = 'sd/a/patientform/FORM_CANCEL';
const CLEAR_FORM: 'sd/a/patientform/CLEAR_FORM' = 'sd/a/patientform/CLEAR_FORM';
const ADD_PATIENT: 'sd/a/patientform/ADD_PATIENT' = 'sd/a/patientform/ADD_PATIENT';
const EDIT_PATIENT: 'sd/a/patientform/EDIT_PATIENT' = 'sd/a/patientform/EDIT_PATIENT';
const ADD_ICD_CODE: 'sd/a/patientform/ADD_ICD_CODE' = 'sd/a/patientform/ADD_ICD_CODE';
const REMOVE_ICD_CODE: 'sd/a/patientform/REMOVE_ICD_CODE' = 'sd/a/patientform/REMOVE_ICD_CODE';
const SET_ICD_SEARCH_STR: 'sd/a/patientform/SET_ICD_SEARCH_STR' = 'sd/a/patientform/SET_ICD_SEARCH_STR';
const SET_ICD_GROUP_FILTER: 'sd/a/patientform/SET_ICD_GROUP_FILTER' = 'sd/a/patientform/SET_ICD_GROUP_FILTER';
const CLEAR_ICD_GROUP_FILTER: 'sd/a/patientform/CLEAR_ICD_GROUP_FILTER' = 'sd/a/patientform/CLEAR_ICD_GROUP_FILTER';
const RESET_ICD_FILTERS: 'sd/a/patientform/RESET_ICD_FILTERS' = 'sd/a/patientform/RESET_ICD_FILTERS';

type FormUpdateAction = {
  type: typeof FORM_UPDATE,
  payload: {
    key: string,
    value: string
  }
};

type FormCancelAction = {
  type: typeof FORM_CANCEL
};

type ClearFormAction = {
  type: typeof CLEAR_FORM
};

type AddPatientAction = {
  type: typeof ADD_PATIENT
};

type EditPatientAction = {
  type: typeof EDIT_PATIENT,
  payload: PatientDetails
};

type AddICDCodeAction = {
  type: typeof ADD_ICD_CODE,
  payload: any
};

type RemoveICDCodeAction = {
  type: typeof REMOVE_ICD_CODE,
  payload: any
};

type SetICDSearchStrAction = {
  type: typeof SET_ICD_SEARCH_STR,
  payload: string
};

type SetICDGroupFilterAction = {
  type: typeof SET_ICD_GROUP_FILTER,
  payload: {
    key: string,
    value: FilterOption | null
  }
};

type ClearICDGroupFilterAction = {
  type: typeof CLEAR_ICD_GROUP_FILTER,
}

type ResetICDFiltersAction = {
  type: typeof RESET_ICD_FILTERS
};

type PatientFormActions = (
  FormUpdateAction |
  FormCancelAction |
  ClearFormAction |
  AddPatientAction |
  EditPatientAction |
  AddICDCodeAction |
  RemoveICDCodeAction |
  SetICDSearchStrAction |
  SetICDGroupFilterAction |
  ClearICDGroupFilterAction |
  ResetICDFiltersAction
);

const INITIAL_STATE: PatientFormState = {
  patientDetails: {
    id: '',
    name: '',
    code: '',
    dob: '',
    sex: '',
    handedness: '',
    tel: '',
    email: '',
    comment: '',
    icdCodes: [],
  },
  icdFilter: {
    searchStr: '',
    superGroup: null,
    group: null
  },
  mode: 'create',
  loading: false,
  shown: false
};

export default function reducer(state: PatientFormState = INITIAL_STATE, action: PatientFormActions): PatientFormState {
  switch (action.type) {
    case FORM_UPDATE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          [action.payload.key]: action.payload.value
        }
      };
    case FORM_CANCEL:
      return {
        ...state,
        patientDetails: INITIAL_STATE.patientDetails,
        icdFilter: INITIAL_STATE.icdFilter,
        shown: false
      };
    case CLEAR_FORM:
      return {
        ...state,
        patientDetails: INITIAL_STATE.patientDetails,
        icdFilter: INITIAL_STATE.icdFilter
      };
    case ADD_PATIENT:
      return {
        ...state,
        patientDetails: INITIAL_STATE.patientDetails,
        mode: 'create',
        shown: true
      };
    case EDIT_PATIENT:
      return {
        ...state,
        patientDetails: action.payload,
        mode: 'edit',
        shown: true
      };
    case ADD_ICD_CODE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          icdCodes: _.concat([action.payload], state.patientDetails.icdCodes)
        }
      };
    case REMOVE_ICD_CODE:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          icdCodes: _.filter(state.patientDetails.icdCodes, (code) => code.id !== action.payload.id)
        }
      };
    case SET_ICD_SEARCH_STR:
      return {
        ...state,
        icdFilter: {
          ...state.icdFilter,
          searchStr: action.payload
        }
      };
    case SET_ICD_GROUP_FILTER:
      return {
        ...state,
        icdFilter: {
          ...state.icdFilter,
          [action.payload.key]: action.payload.value
        }
      };
    case CLEAR_ICD_GROUP_FILTER:
      return {
        ...state,
        icdFilter: {
          ...state.icdFilter,
          group: null,
          superGroup: null
        }
      }
    case RESET_ICD_FILTERS:
      return {
        ...state,
        icdFilter: INITIAL_STATE.icdFilter
      };
    default: return state;
  }
};

const formUpdate = (key: string, value: string): FormUpdateAction => {
  return {
    type: FORM_UPDATE,
    payload: {
      key,
      value
    }
  };
};

const formCancel = (): FormCancelAction => {
  return {
    type: FORM_CANCEL
  };
};

const clearForm = (): ClearFormAction => {
  return {
    type: CLEAR_FORM
  };
};

const addPatient = (): AddPatientAction => {
  return {
    type: ADD_PATIENT
  };
};

const editPatient = (patientDetails: PatientDetails): EditPatientAction => {
  return {
    type: EDIT_PATIENT,
    payload: patientDetails
  };
};

const addICDCode = (code: any): AddICDCodeAction => {
  return {
    type: ADD_ICD_CODE,
    payload: code
  };
};

const removeICDCode = (code: any): RemoveICDCodeAction => {
  return {
    type: REMOVE_ICD_CODE,
    payload: code
  };
};

const setICDSearchStr = (searchStr: string): SetICDSearchStrAction => {
  return {
    type: SET_ICD_SEARCH_STR,
    payload: searchStr
  };
};

const setICDGroupFilter = (key: string, filterOption: FilterOption | null): SetICDGroupFilterAction => {
  return {
    type: SET_ICD_GROUP_FILTER,
    payload: {
      key,
      value: filterOption
    }
  };
};

const clearICDGroupFilter = (): ClearICDGroupFilterAction => {
  return {
    type: CLEAR_ICD_GROUP_FILTER
  };
};

const resetICDFilters = (): ResetICDFiltersAction => {
  return {
    type: RESET_ICD_FILTERS
  };
};

export const actionCreators = {
  formUpdate,
  formCancel,
  clearForm,
  addPatient,
  editPatient,
  addICDCode,
  removeICDCode,
  setICDSearchStr,
  setICDGroupFilter,
  clearICDGroupFilter,
  resetICDFilters
};
