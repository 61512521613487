// @flow
// type imports
import type { Patient, AnlzSource } from "../../../types/analysis";
import type { CurrentUserState } from "../../../types/auth";
// module imports
import React, { useRef } from "react";
import { PlainPanel } from "../../common";
import { AnlzSourceInfo } from "./AnlzSourceInfo";
import { Container } from "./styled";
import { Box, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { readAnlzResourceRest } from "../../../utils/rest";

type Props = {
  selectedPatient: Patient,
  selectedAnlzSource: AnlzSource,
  currentUser: CurrentUserState,
};

export const AnlzSourceDetailPanelComponent = ({
  selectedPatient,
  selectedAnlzSource,
  currentUser,
}: Props) => {
  const [edf, setEdf] = React.useState();
  const [loadedIframe, setLoadedIframe] = React.useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    const load = async () => {
      const edf: Blob = await readAnlzResourceRest(
        selectedPatient.id,
        encodeURIComponent(selectedAnlzSource.actualFile),
        currentUser.tokens.access_token
      );
      setEdf(edf);
    };
    load();
  }, []);

  React.useEffect(() => {
    if (!!edf && loadedIframe) {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          edf,
          process.env.REACT_APP_EDF_WEB
        );
      }
    }
  }, [edf, loadedIframe]);

  const handleIframeLoad = () => {
    setLoadedIframe(true);
  };

  return (
    <Box bgcolor={"#FFF"} height={"100%"}>
      <AnlzSourceInfo
        selectedPatient={selectedPatient}
        selectedAnlzSource={selectedAnlzSource}
      />
      <Box style={{ height: "calc(100% - 130px)" }}>
        {selectedAnlzSource.type === "EEG" && (
          <iframe
            ref={iframeRef}
            src={process.env.REACT_APP_EDF_WEB}
            width={"100%"}
            height={"100%"}
            onLoad={handleIframeLoad}
          />
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export const AnlzSourceDetailPanel = connect(mapStateToProps)(
  AnlzSourceDetailPanelComponent
);
