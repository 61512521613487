// @flow
// type imports
import type { AppState } from '../../../types';
import type { Customer, CustomerListState } from '../../../types/customers';
// module imports
import _ from 'lodash';
import * as React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Query } from '@apollo/client/react/components';
import { customerListActions } from '../../../ducks/customers';
import { ALL_ORGZ_BO_QUERY } from '../../../utils/graphql/queries';
import analysisImages from '../../../resources/images/analysis';
import commonImages from '../../../resources/images/common';
import {
  TableControlButton,
  TablePagingInfo,
  RightButtonsContainer,
  RefreshButton,
  DefaultCellSpan
} from './styled';
import {
  styledTable,
  selectableTable,
  Spinner,
  ControlledPanel,
  SearchBar,
} from '../../common';

const SelectableTable = compose(styledTable, selectableTable)(ReactTable);

type StateProps = CustomerListState;

type ActionProps = typeof customerListActions;

type Props = StateProps & ActionProps;

type State = {
  lastSearchKeyword: string
}

class CustomerListPanelComponent extends React.Component<Props, State> {
  _listPageSize: number = 10;
  _refetch: () => Promise<any>;

  constructor(props) {
    super(props);
    this.state = {
      lastSearchKeyword: ''
    };
  }

  render() {
    const {
      currentPage,
      numPages,
      selectedCustomer
    } = this.props;
    return (
      <Query
        query={ALL_ORGZ_BO_QUERY}
        variables={{
          orgzName: this.state.lastSearchKeyword,
          page: currentPage,
          size: 10
        }}
        onCompleted={(data) => {
          if (data && data.boOrgzStatPage) {
            const {
              nowPage,
              totalPages
            } = data.boOrgzStatPage.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          }
        }}
        fetchPolicy='network-only'
        context={{
          dataSourceType: 'api'
        }}
      >
        {({loading, error, data, refetch}) => {
          this._refetch = refetch;
          let pageInfo = { nowPage: currentPage, totalPages: numPages};
          if (data && data.boOrgzStatPage) {
            pageInfo = data.boOrgzStatPage.pageInfo;
          }
          return (
            <ControlledPanel>
              <ControlledPanel.Header>
                <SearchBar
                  searchText={this.props.filter.keyword}
                  placeholder='고객명을 입력하세요'
                  showAllImgSrc={analysisImages.allPatients}
                  onTextChange={(event: SyntheticEvent<HTMLInputElement>) => this.props.setFilter('keyword', event.currentTarget.value)}
                  onSearchSubmit={(event: SyntheticEvent<HTMLFormElement>) => {
                    this.props.setPagingInfo(0, 0);
                    this.setState({ lastSearchKeyword: this.props.filter.keyword });
                  }}
                  onShowAll={(event) => {
                    event.preventDefault();
                    this.props.clearFilter('keyword');
                    this.props.setPagingInfo(0, 0);
                    this.setState({ lastSearchKeyword: '' });
                  }}
                />
              </ControlledPanel.Header>
              <ControlledPanel.Content>
                <SelectableTable
                  data={(data && data.boOrgzStatPage) ? data.boOrgzStatPage.stats : []} // data anlzSrcs
                  resolveData={data => _.map(data, row => ({
                      ...row,
                      id: row.orgz.id
                    })
                  )}
                  isRowCheckable={(rowData) => true}
                  loading={loading}
                  page={pageInfo.nowPage}
                  pageSize={this._listPageSize}  // props pageSize
                  minRows={0}
                  manual
                  LoadingComponent={Spinner}
                  pages={pageInfo.totalPages}
                  columns={[{
                      id: 'name',
                      minWidth: 280,
                      Header: '고객명',
                      accessor: rowData => rowData.orgz.name,
                      Cell: (rowInfo) => (
                        <DefaultCellSpan>
                          {rowInfo.value}
                        </DefaultCellSpan>
                      )
                    }, {
                      id: 'adminEmpId',
                      Header: '어드민 ID',
                      minWidth: 435,
                      Cell: (rowInfo) => {
                        switch (rowInfo.value) {
                          case 'U_ADMIN': return '관리자';
                          case 'U_NORMAL': return '일반';
                          case 'U_SUBJECT': return '대상자관리';
                          default: return rowInfo.value;
                        }
                      },
                      accessor: rowData => rowData.orgz.adminEmployee && rowData.orgz.adminEmployee.email,
                      Cell: (rowInfo) => (
                        <DefaultCellSpan>
                          {rowInfo.value}
                        </DefaultCellSpan>
                      )
                    }, {
                      id: 'statReqs',
                      Header: '분석 요청',
                      minWidth: 180,
                      accessor: rowData => rowData.anlzStat.reqs,
                    }, {
                      id: 'statCpts',
                      Header: '분석완료',
                      minWidth: 180,
                      accessor: rowData => rowData.anlzStat.cpts,
                    }
                  ]}
                  showPagination={false}
                  resizable={false}
                  multiSort={false}
                  sortable={false}
                  onRowSelect={(rowInfo: any) => {
                    const selectedCustomer: Customer = rowInfo.original;
                    this.props.selectCustomer(selectedCustomer);
                  }}
                  selectedRow={selectedCustomer}
                  noDataText={(this.state.lastSearchKeyword !== '') ? '검색결과가 없습니다' : ''}
                />
              </ControlledPanel.Content>
              <ControlledPanel.Footer>
                <TableControlButton onClick={() => this.props.prevPage()}>
                  <img src={commonImages.prev} alt='Previous' />
                  <img className='hover-img' src={commonImages.prevHover} alt='Previous' />
                </TableControlButton>
                <TablePagingInfo><span className='current-page'>{currentPage + 1}</span>/{numPages}</TablePagingInfo>
                <TableControlButton onClick={() => this.props.nextPage()}>
                  <img src={commonImages.next} alt='Next' />
                  <img className='hover-img' src={commonImages.nextHover} alt='Next' />
                </TableControlButton>
                <RightButtonsContainer>
                  <RefreshButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      refetch();
                    }}
                    href='#'
                  >
                    <img src={commonImages.refresh} alt='refresh'/>
                  </RefreshButton>
                </RightButtonsContainer>
              </ControlledPanel.Footer>
            </ControlledPanel>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return state.customers.customerList
}

export const CustomerListPanel = connect(mapStateToProps, customerListActions)(CustomerListPanelComponent);