// @flow
import * as React from 'react';
import styled from 'styled-components';

export const createStyledTableComponent = (WrappedComponent: React.ComponentType<any>) => {
  return (
    styled(WrappedComponent)`
      font-size: ${props => props.fontSize || '18px'};
      &.styled-table {
        border: none;
        height: 100%;
        .rt-noData {
          color: #BCC9D8;
        }
        .rt-table {
          color: #576881;
          .rt-td, .rt-th {
            display: flex;
            align-items: center;
            justify-content: center;
            &:focus {
              outline: none;
            }
          }
          .rt-tbody {
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 12px;
            }
            &::-webkit-scrollbar-corner {
              display: none;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(66,82,110,0.16);
              transition: all .1s ease-in-out;
              border: 3px solid transparent;
              border-radius: 8px;
              background-clip: content-box;
            }
            &:hover::-webkit-scrollbar-thumb {
              background-color: rgba(66,82,110,0.36);
            }
            &::-webkit-scrollbar-thumb:hover {
              background-color: rgba(66,82,110,0.56);
            }
          }
          .rt-thead {
            background-color: #f6f8f9;
            height: ${props => props.headerHeight || '60px'};
            .rt-tr::after {
              content: '';
              overflow-y: scroll;
              visibility: hidden;
              height: 0;
            }
            .rt-th {
              border-right: none;
              &:not(:last-child)::before {
                content: '';
                position: absolute;
                width: 1px;
                height: 16px;
                background-color: #bcc9d8;
                overflow: hidden;
                right: 0;
              }
            }
          }
          .rt-tbody {
            background-color: #fff;
            .rt-tr-group {
              border: none;
              flex: 0 0 52px;
            }
            .rt-td {
              border-right: none;
              &.left-align {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    `
  );
}

export const createSelectableStyledComponent = (WrappedComponent: React.ComponentType<any>) => {
  return (
    styled(WrappedComponent)`
      .rt-table .rt-tbody .rt-tr-group .rt-tr {
        &:not(.-padRow):hover {
          background: #ebf0f4;
          cursor: pointer;
        }
      }
    `
  );
}