// @flow
import styled from 'styled-components';
import { AnchorButton, EllipsisSpan } from '../../common';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TabContainer = styled.div`
  width: 100%;
  height: 23px;
  flex: 0 0 23px;
  padding: 0 18px;
  display: flex;
`;

export const Tab = styled.div`
  flex: 1 1 0%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.selected ? '#94aabf' : '#fff'};
  cursor: pointer;
  > span {
    font-size: 12px;
    color: ${props => props.selected ? '#fff' : '#d0dcea'};
  }
  > div {
    background-color: ${props => props.selected ? 'transparent' : '#ebf0f4'};
    height: 3px;
    width: 100%;
  }
  &:hover {
    > span {
      color: ${props => props.selected ? '#fff' : '#94aabf'};
    }
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  padding-left: 18px;
  min-height: 0;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ListContentContainer = styled.div`
  width: 100%;
  height: 0;
  min-height: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
`;

export const ICDCodeItem = styled.div`
  display: flex;
  height: 24px;
  flex: 0 0 24px;
  align-items: center;
  width: 320px;
  padding: 0 1px;
  color: #576881;
  font-size: 11px;
`;

export const ICDCode = styled.span`
  flex: 0 0 35px;
  text-align: left;
`;

export const DispName = styled(EllipsisSpan)`
  flex: 1 1 0%;
`;

export const ICDCodeButton = styled(AnchorButton)`
  display: flex;
  min-width: 0;
  flex: 1 1 0%;
  > span {
    color: #576881;
    font-size: 12px;
  }
  &:hover {
    > span {
      color: #000;
    }
  }
`;

export const FavoriteButton = styled(AnchorButton)`
  margin-left: 15px;
  flex-shrink: 0;
  > img {
    width: 10px;
    height: 10px;
    object-fit: contain;
  }
`;