// @flow
import _ from 'lodash';
import axios from 'axios';
import * as React from 'react';
import styled from 'styled-components';
import {
  Page,
  PanelGrid,
  PlainPanel,
  Spinner
} from '../../components/common';
import ReactMarkdown from 'react-markdown';
import { CompanyInfoPanel, FAQPanel } from '../../components/support';
import supportImages from '../../resources/images/support';
import { Query } from '@apollo/client/react/components';
import { COMPANY_PROPS_QUERY } from '../../utils/graphql/queries';

type State = {
  loading: boolean,
  eulaSrc: string
};

const ContentContainer = styled.div`
  flex: 1 1 0%;
  overflow-y: auto;
  padding: 25px 35px;
  width: 100%;
  p, li {
    color: #576881;
    font-size: 18px;
  }
  h3 {
    color: #576881;
    font-size: 22px;
  }
  h4 {
    color: #576881;
    font-size: 18px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.16);
    transition: all .1s ease-in-out;
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.36);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(66,82,110,0.56);
  }
`;

class EULA extends React.Component<{}, State> {
  eulaPath: string = require('../../resources/eula.md');
  
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      eulaSrc: ''
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    axios.get(this.eulaPath).then((response) => {
      console.log(response);
      this.setState({
        loading: false,
        eulaSrc: response.data
      });
    });
  }

  render() {
    console.log(this.eulaPath);
    return (
      <Page
        title='Terms of Use'
        subtitle='웹서비스 이용 시의 동의해야하는 조건 및 절차 등을 확인할 수 있습니다'
        contentMinWidth={1290}
      >
        <PanelGrid.Grid>
          <PanelGrid.Row style={{ height: '847px'}}>
            <PanelGrid.Col minWidth={870}>
              <PlainPanel
                title='컨텐츠 정보 이용약관'
                headerBorderColor='#00bbf9'
                headerBackgroundColor='#fff'
              >
                <ContentContainer>
                  <ReactMarkdown source={this.state.eulaSrc} />
                </ContentContainer>
              </PlainPanel>
            </PanelGrid.Col>
            <PanelGrid.Col minWidth={270}>
            </PanelGrid.Col>
          </PanelGrid.Row>
        </PanelGrid.Grid>
      </Page>
    )
  }
}

export { EULA };