// @flow
import { combineReducers } from 'redux';
import customerAccessListReducer, { actionCreators as customerAccessListActions } from './customeraccesslist';

const reducer = combineReducers({
  customerAccessList: customerAccessListReducer,
});

export default reducer;

export {
  customerAccessListActions,
};
