// @flow
import type { AppState } from '../../types';
import type { Dispatch } from 'redux';
import type { FileFormState } from '../../types/common';
// module imports
import axios from 'axios';
import { ThunkAction } from 'redux-thunk';

// client actions
const FILE_FORM_UPDATE: 'sd/c/fileform/FILE_FORM_UPDATE' = 'sd/c/fileform/FILE_FORM_UPDATE';
const FILE_FORM_RESET: 'sd/c/fileform/FILE_FORM_RESET' = 'sd/c/fileform/FILE_FORM_RESET';
// server actions
const FILE_UPLOAD: 'sd/c/fileform/FILE_UPLOAD' = 'sd/c/fileform/FILE_UPLOAD';
const FILE_UPLOAD_SUCCESS: 'sd/c/fileform/FILE_UPLOAD_SUCCESS' = 'sd/c/fileform/FILE_UPLOAD_SUCCESS';
const FILE_UPLOAD_FAILURE: 'sd/c/fileform/FILE_UPLOAD_FAILURE' = 'sd/c/fileform/FILE_UPLOAD_FAILURE';

type FileFormUpdateAction = {
  type: typeof FILE_FORM_UPDATE,
  name: string,
  payload: FileList
};

type FileFormResetAction = {
  type: typeof FILE_FORM_RESET,
  name: string
};

type FileUploadAction = {
  type: typeof FILE_UPLOAD,
  name: string
};

type FileUploadSuccessAction = {
  type: typeof FILE_UPLOAD_SUCCESS,
  name: string
};

type FileUploadFailureAction = {
  type: typeof FILE_UPLOAD_FAILURE,
  name: string,
  payload: any
};

type FileFormActions = (
  FileFormUpdateAction |
  FileFormResetAction |
  FileUploadAction |
  FileUploadSuccessAction |
  FileUploadFailureAction
);

const INITIAL_STATE: FileFormState = {
  selectedFiles: null,
  loading: false,
  error: null
};

export const createFileFormReducer = (formName: string) => {
  return (state: FileFormState = INITIAL_STATE, action: FileFormActions): FileFormState => {
    const { name } = action;
    if (name !== formName) return state;
    switch (action.type) {
      case FILE_FORM_UPDATE:
        return {
          ...state,
          selectedFiles: action.payload
        };
      case FILE_FORM_RESET:
        return {
          ...state,
          selectedFiles: null
        };
      case FILE_UPLOAD:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FILE_UPLOAD_SUCCESS:
        return {
          selectedFiles: null,
          loading: false,
          error: null
        };
      case FILE_UPLOAD_FAILURE:
        return {
          selectedFiles: null,
          loading: false,
          error: action.payload.error
        }
      default: return state;
    }
  }
}

const fileFormUpdate = (name: string, files: FileList): FileFormUpdateAction => {
  return {
    type: FILE_FORM_UPDATE,
    name,
    payload: files
  };
};

const fileFormReset = (name: string): FileFormResetAction => {
  return {
    type: FILE_FORM_RESET,
    name
  };
};

const fileUpload = (name: string): FileUploadAction => {
  return {
    type: FILE_UPLOAD,
    name
  };
};

const fileUploadSuccess = (name: string): FileUploadSuccessAction => {
  return {
    type: FILE_UPLOAD_SUCCESS,
    name
  };
};

const fileUploadFailure = (name: string, error: any): FileUploadFailureAction => {
  return {
    type: FILE_UPLOAD_FAILURE,
    name,
    payload: error
  };
};

const uploadFile = (name: string, url: string): ThunkAction => {
  return (dispatch: Dispatch<FileFormActions>, getState: () => AppState) => {
    const { selectedFiles } = getState().analysis[name];
    const { access_token } = getState().auth.currentUser.tokens;
    if (selectedFiles) {
      const data = new FormData();
      data.append('srcFile', selectedFiles.item(0));
      dispatch(fileUpload(name));
      axios.post(
        url,
        data,
        {
          timeout: 60000,
          headers: {
            'content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${access_token}`
          },
        }
      ).then((response) => {
        console.log(response);
        dispatch(fileUploadSuccess(name));
      }).catch((error) => {
        console.log(error.response);
        dispatch(fileUploadFailure(name, error));
      });
    }
  };
};

export const actionCreators = {
  fileFormUpdate,
  fileFormReset,
  uploadFile
};
