// @flow
// type imports
import type { AppState } from '../../../types';
import type { Patient, SurveySession } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { PlainPanel } from '../../common';
import {
  Container,
  SessionInfoContainer,
  SessionInfoSpan,
  InfoDivider,
  SessionAnlzResSpan,
  ContinueContainer,
  SurveyIncompleteImage,
  SurveyIncompleteMsgSpan,
  ContinueSurveyLink,
  PaginatorContainer,
  PagingInfo,
  PageNavButton,
} from './styled';
import { SurveyResults } from './SurveyResults';
import commonImages from '../../../resources/images/common';
import analysisImages from '../../../resources/images/analysis';
import { SurveySessionInfo } from './SurveySessionInfo';

type OwnProps = {
  selectedPatient: Patient,
  selectedSurveySession: SurveySession
}

type StateProps = {
  accessToken: string
};

type Props = OwnProps & StateProps;

type State = {
  surveyResult: any,
  subSurveyIds: string[],
  currentPage: number,
  mainSurveyResp: any,
  pubSurveyId: ?string,
  loading: boolean,
  error: any
};

class SurveySessionDetailPanelComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      surveyResult: null,
      currentPage: 0,
      subSurveyIds: [],
      mainSurveyResp: [],
      pubSurveyId: null,
      loading: false,
      error: null
    };
  }

  fetchSurveyResults(idHashCode: string, accessToken: string, pubSurveyId: ?string) {
    this.setState(() => ({
      surveyResult: null,
      loading: true,
      error: null
    }));
    axios.get(`${process.env.REACT_APP_SURVEY_HOST  || 'http://localhost:5000'}/api/v1/session/${idHashCode}/results`, {
      headers: {
        'authorization': `Bearer ${accessToken}`
      },
      params: {
        pubSurveyId
      }
    }).then((response) => {
      let subSurveyIds = this.state.subSurveyIds;
      let mainSurveyResp = this.state.mainSurveyResp;
      if (response.data.childSessions && response.data.childSessions.length > 0) {
        subSurveyIds = _.map(response.data.childSessions, (childSession) => childSession.pubSurveyId);
        mainSurveyResp = response.data.surveyResp
      }
      if (response.data.surveyResp.length > 0) {
        this.setState(() => ({
          surveyResult: response.data,
          subSurveyIds,
          mainSurveyResp,
          loading: false
        }));
      } else {
        this.setState(() => ({
          surveyResult: response.data,
          subSurveyIds,
          mainSurveyResp,
          pubSurveyId: subSurveyIds[0],
          loading: false
        }));
      }
      
    }).catch((error) => this.setState(() => ({
      loading: false,
      error
    })));
  }

  componentWillMount() {
    const {
      accessToken,
      selectedSurveySession
    } = this.props;
    const {
      submitted,
      idHashCode
    } = selectedSurveySession;
    if (!!submitted) {
      this.fetchSurveyResults(idHashCode, accessToken);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      accessToken,
      selectedSurveySession
    } = this.props;
    const {
      submitted,
      idHashCode
    } = selectedSurveySession;
    if (prevProps.selectedSurveySession.idHashCode !== idHashCode && !!submitted) {
      this.setState(() => ({ currentPage: 0, subSurveyIds: [], pubSurveyId: null }));
      this.fetchSurveyResults(idHashCode, accessToken, null);
    } else if (prevProps.selectedSurveySession.idHashCode !== idHashCode) {
      this.setState({
        currentPage: 0,
        pubSurveyId: null,
        subSurveyIds: [],
        surveyResult: null,
        loading: false,
        error: null
      });
    } else if (prevState.pubSurveyId !== this.state.pubSurveyId) {
      this.fetchSurveyResults(idHashCode, accessToken, this.state.pubSurveyId);
    }
  }

  renderSessionResult() {
    const {
      selectedSurveySession
    } = this.props;
    const {
      submitted,
      idHashCode
    } = selectedSurveySession;
    console.log(submitted);
    if (!!submitted) {
      const {
        surveyResult,
        currentPage,
        subSurveyIds,
        pubSurveyId,
        mainSurveyResp,
        loading,
        error
      } = this.state;
      console.log(pubSurveyId);
      console.log(subSurveyIds);
      const maxPages = mainSurveyResp.length > 0 ? (subSurveyIds.length + 1) : subSurveyIds.length;
      return (
        <React.Fragment>
          <SurveyResults idHashCode={idHashCode} surveyResult={surveyResult} loading={loading} error={error} />
          {(subSurveyIds.length > 0) ? (
            <PaginatorContainer>
              <PageNavButton onClick={() => {
                const nextCurrentPage = currentPage - 1;
                if (nextCurrentPage >= 0) {
                  const nextPubSurveyId = mainSurveyResp.length > 0 ? (nextCurrentPage > 0 ? subSurveyIds[nextCurrentPage - 1] : null) : subSurveyIds[nextCurrentPage];
                  this.setState(() => ({
                    currentPage: nextCurrentPage,
                    pubSurveyId: nextPubSurveyId
                  }));
                }
              }}>
                <img src={commonImages.prev} alt='Previous' />
                <img className='hover-img' src={commonImages.prevHover} alt='Previous' />
              </PageNavButton>
              <PagingInfo><span className="current-page">{currentPage+1}</span>/{maxPages}</PagingInfo>
              <PageNavButton onClick={() => {
                const nextCurrentPage = currentPage + 1;
                if (currentPage < maxPages - 1) {
                  const nextPubSurveyId = mainSurveyResp.length > 0 ? subSurveyIds[nextCurrentPage - 1] : subSurveyIds[nextCurrentPage];
                  console.log(nextPubSurveyId)
                  this.setState(() => ({
                    currentPage: nextCurrentPage,
                    pubSurveyId: nextPubSurveyId
                  }));
                }
              }}>
                <img src={commonImages.next} alt='Next' />
                <img className='hover-img' src={commonImages.nextHover} alt='Next' />
              </PageNavButton>
            </PaginatorContainer>
          ) : null}
        </React.Fragment>
      );
    }
    console.log(selectedSurveySession)
    const surveyExp = moment(selectedSurveySession.expiredTime);
    // render survey expired message
    return (
      <ContinueContainer>
        <SurveyIncompleteImage src={analysisImages.surveyNotComplete} alt='Survey incomplete' />
        <SurveyIncompleteMsgSpan>
          {(moment().isBefore(surveyExp)) ? '설문작성 미완료' : '설문작성 실패'}
        </SurveyIncompleteMsgSpan>
        {(moment().isBefore(surveyExp)) ? (
          <ContinueSurveyLink
            href={`${process.env.REACT_APP_SURVEY_HOST  || 'http://localhost:5000'}/take/${idHashCode}`}
            target='_blank'
          >
            <span>작성하기</span>
          </ContinueSurveyLink>
        ) : null}
      </ContinueContainer>
    );

  }

  render() {
    const {
      selectedPatient,
      selectedSurveySession
    } = this.props;
    const {
      surveyResult,
      mainSurveyResp
    } = this.state;
    return (
      <PlainPanel
        title={`${selectedPatient.name}님의 설문작성 상세내용`}
        headerBackgroundColor='#fff'
        headerBorderColor='transparent'
      >
        <Container>
          <SurveySessionInfo currentPage={this.state.currentPage} mainSurveyResp={mainSurveyResp} selectedSurveySession={selectedSurveySession} surveyResult={surveyResult} />
          {this.renderSessionResult()}
        </Container>
      </PlainPanel>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  return {
    accessToken: state.auth.currentUser.tokens.access_token
  };
};

export const SurveySessionDetailPanel = connect(mapStateToProps, {})(SurveySessionDetailPanelComponent);