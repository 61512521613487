import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import CSRequestForm from "../CSRequestForm/CSRequestForm";

const MoreMenu = ({ anlz, patient, empMe, orgId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCSPopup, setOpenCSPopup] = React.useState(false);

  const handleOpenMenuItems = (e: MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCSOpenPopup = (e: MouseEvent) => {
    e.stopPropagation();
    setOpenCSPopup(true);
    setAnchorEl(null);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleOpenMenuItems}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCSOpenPopup}>재분석 요청</MenuItem>
      </Menu>
      <CSRequestForm
        open={openCSPopup}
        onClose={() => setOpenCSPopup(false)}
        anlz={anlz}
        patient={patient}
        empMe={empMe}
        orgId={orgId}
      />
    </Box>
  );
};

export default MoreMenu;
