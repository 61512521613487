// @flow
import gql from "graphql-tag";
import {
  Disease,
  DiseasePage,
  DiseasesWithBookmarks,
  Patient,
  Notice,
} from "./fragments";

export const BILLING_USAGE_BY_ORGZ = gql`
  query ($orgzId: Int, $year: Int, $month: Int) {
    billingRecord(orgzId: $orgzId, year: $year, month: $month)
      @rest(
        type: "billingRecord"
        endpoint: "billingServer"
        path: "api/v2/bills/usage?orgzIds={args.orgzId}&year={args.year}&month={args.month}"
        method: "GET"
      ) {
      billingRecordId
      organizationUrn
      deviceURN
      creationTime
      subjectCode
      sessionName
      price
      claimStatus
      usageDetails
      paymentStatus
    }
  }
`;

export const BILLING_KEY_BY_CONTRACT_QUERY = gql`
  query ($orgzId: Int) {
    billingKeyInfo(orgzId: $orgzId)
      @rest(
        type: "billingKeyInfo"
        endpoint: "billingServer"
        path: "api/v1/payment/billing-key?orgzId={args.orgzId}"
        method: "GET"
      ) {
      billingKey
      cAt
      data {
        cardCode
        cardName
        cardNo
        cardCl
      }
      eAt
      method
      methodName
      orgzId
      pg
      pgName
    }
  }
`;

export const PAYMENT_HISTORY_BY_CONTRACT_QUERY = gql`
  query ($orgzId: Int) {
    paymentHistory(orgzId: $orgzId)
      @rest(
        type: "paymentHistory"
        endpoint: "billingServer"
        path: "api/v2/bills/receipts?orgzId={args.orgzId}"
        method: "GET"
      ) {
      itemName
      method
      methodName
      monthlyBillId
      receiptUrl
      params
      paymentData
      pg
      pgName
      price
      purchasedAt
      receiptId
      requestedAt
      revokedAt
      status
    }
  }
`;

export const BILLING_DATA_ALL_QUERY = gql`
  query ($orgzId: Int, $year: Int, $month: Int) {
    totalBillingInfo(orgzId: $orgzId, year: $year, month: $month)
      @rest(
        type: "totalBillingInfo"
        endpoint: "billingServer"
        path: "api/v2/bills?orgzId={args.orgzId}&year={args.year}&month={args.month}"
        method: "GET"
      ) {
      totalBillsCnt
      settlementDate
      settlementStatus
      settledAmountWithVat
      billedAmountWithVat
      settledUsageCount
      billedUsageCount
      monthlyBills
    }
  }
`;

export const STAT_ANLZ_QUERY = gql`
  query {
    statAnlz {
      statTime {
        index
        from
        to
      }
      srcs
      reqs
      procs
      cpts
    }
  }
`;

export const STAT_WEEKLY_ANLZ_QUERY = gql`
  query ($from: ZonedDateTime!) {
    statAnlzByZWeek(ymdFrom: $from, weeks: 5) {
      statTime {
        index
        from
        to
      }
      srcs
      cpts
    }
  }
`;

export const STAT_MONTHLY_ANLZ_QUERY = gql`
  query ($year: ZonedDateTime!, $months: Int!) {
    statAnlzByZMonth(ymFrom: $year, months: $months) {
      statTime {
        index
        from
        to
      }
      srcs
      cpts
    }
  }
`;

export const STAT_WEEKLY_USAGE_QUERY = gql`
  query ($from: ZonedDateTime!) {
    statUsagesByZWeek(ymdFrom: $from, weeks: 5) {
      statTime {
        index
        from
        to
      }
      eegUsage
      ecgUsage
    }
  }
`;

export const STAT_MONTHLY_USAGE_QUERY = gql`
  query ($year: ZonedDateTime!, $months: Int!) {
    statUsagesByZMonth(ymdFrom: $year, months: $months) {
      statTime {
        index
        from
        to
      }
      eegUsage
      ecgUsage
    }
  }
`;

export const STAT_SUBJT_QUERY = gql`
  query {
    statSbjt {
      statTime {
        index
        from
        to
      }
      totalSbjts
      newSbjts
    }
  }
`;

export const STAT_WEEKLY_SBJT_QUERY = gql`
  query ($from: ZonedDateTime!) {
    statSbjtByZWeek(ymdFrom: $from, weeks: 5) {
      statTime {
        index
        from
        to
      }
      totalSbjts
      newSbjts
    }
  }
`;

export const STAT_MONTHLY_SBJT_QUERY = gql`
  query ($year: ZonedDateTime!, $months: Int!) {
    statSbjtByZMonth(ymFrom: $year, months: $months) {
      statTime {
        index
        from
        to
      }
      totalSbjts
      newSbjts
    }
  }
`;

export const STAT_ANLZ_BO_QUERY = gql`
  query ($ymdhmsFrom: ZonedDateTime, $ymdhmsTo: ZonedDateTime) {
    boStatSummaryByZTime(ymdhmsFrom: $ymdhmsFrom, ymdhmsTo: $ymdhmsTo) {
      statTime {
        index
        from
        to
      }
      srcs
      reqs
      procs
      cpts
    }
  }
`;

export const STAT_BYTIME_ANLZ_BO_QUERY = gql`
  query ($orgzId: ID, $from: ZonedDateTime, $to: ZonedDateTime) {
    boStatAnlzByZTime(orgzId: $orgzId, ymdhmsFrom: $from, ymdhmsTo: $to) {
      statTime {
        index
        from
        to
      }
      srcs
      reqs
      procs
      cpts
    }
  }
`;

export const STAT_WEEKLY_ANLZ_BO_QUERY = gql`
  query ($orgzId: ID, $from: ZonedDateTime!) {
    boStatAnlzByZWeek(orgzId: $orgzId, ymdFrom: $from, weeks: 5) {
      statTime {
        index
        from
        to
      }
      srcs
      cpts
    }
  }
`;

export const STAT_MONTHLY_ANLZ_BO_QUERY = gql`
  query ($orgzId: ID, $year: ZonedDateTime!, $months: Int!) {
    boStatAnlzByZMonth(orgzId: $orgzId, ymFrom: $year, months: $months) {
      statTime {
        index
        from
        to
      }
      srcs
      cpts
    }
  }
`;

export const STAT_CUSTOMER_BO_QUERY = gql`
  query ($now: ZonedDateTime) {
    statOrgzSvcTodayStatistics(now: $now) {
      statTime {
        index
        from
        to
      }
      newOrgz
      totalOrgz
    }
  }
`;

export const STAT_WEEKLY_CUSTOMUER_QUERY = gql`
  query ($ymdFrom: ZonedDateTime!) {
    statOrgzSvcByZWeek(ymdFrom: $ymdFrom, weeks: 5) {
      statTime {
        index
        from
        to
      }
      newOrgz
      totalOrgz
    }
  }
`;

export const STAT_MONTHLY_CUSTOMUER_QUERY = gql`
  query ($ymFrom: ZonedDateTime!, $months: Int!) {
    statOrgzSvcByZMonth(ymFrom: $ymFrom, months: $months) {
      statTime {
        index
        from
        to
      }
      newOrgz
      totalOrgz
    }
  }
`;

export const SBJT_BYID_QUERY = gql`
  query ($id: ID!) {
    sbjtById(id: $id) {
      ...Patient
    }
  }
  ${Patient}
`;

export const ANLZ_SRCS_BY_SBJTID_QUERY = gql`
  query ($subjectId: ID!, $page: Int, $size: Int) {
    anlzSrcPgBySbjtId(subjectId: $subjectId, page: $page, size: $size) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      sources {
        id
        created
        visualFile
        actualFile
        type
        fileSize
        recorded
        detail {
          sessionName
          recTime
          recLengthSec
          eyeStatus
          stationSn
          moduleSn
        }
        anlzAlgorithm
        preProcStatus
        preProcVisualFile
        preProcActualFile
        preProcFileSize
        remarks
      }
    }
  }
`;

export const ANLZ_SRC_NAME_BYID_QUERY = gql`
  query ($srcId: ID!) {
    anlzSrcById(srcId: $srcId) {
      id
      detailSource {
        sessionName
        file {
          visualName
        }
      }
    }
  }
`;

export const ANLZ_SRC_BYID_QUERY = gql`
  query ($srcId: ID!) {
    anlzSrcById(srcId: $srcId) {
      id
      employeeId
      type
      created
      recorded
      detailSource {
        sessionName
        file {
          visualName
          actualName
          size
        }
        recTime
        recLengthSec
        eyeStatus
        stationSn
        moduleSn
      }
    }
  }
`;

export const ANLZ_REQUESTS_BY_SBJTID_QUERY = gql`
  query ($subjectId: ID!, $page: Int, $size: Int, $anlzType: AnlzType) {
    anlzReqPgBySbjtId(
      subjectId: $subjectId
      anlzType: $anlzType
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      requests {
        id
        subjectId
        employeeName
        anlzSources {
          visualFile
        }
        anlzStatus
        anlzType
        surveys {
          surveySessionId
          surveyName
          created
        }
        statusTime
        visualResultFile
        actualResultFile
        resultFileSize
        remarks
        created
      }
    }
  }
`;

export const ANLZ_REQUEST_BY_ID_QUERY = gql`
  query ($reqId: ID!) {
    anlzReqById(reqId: $reqId) {
      id
      anlzSources {
        id
        visualFile
        fileSize
        created
        recorded
        detail {
          sessionName
          recTime
          recLengthSec
          eyeStatus
          stationSn
          moduleSn
        }
      }
      surveys {
        surveySessionId
        surveyName
        created
      }
      anlzStatus
      visualResultFile
      actualResultFile
    }
  }
`;

export const SURVEY_SESSION_SUMMARY_QUERY = gql`
  query ($subjectId: ID!, $sessionId: ID!) {
    surveySessionSummary(subjectId: $subjectId, sessionId: $sessionId) {
      sessionId
      pubSurveyName
      pubSurveyDesc
      createdTime
      submittedTime
      submitted
    }
  }
`;

export const SURVEY_SESSIONS_QUERY = gql`
  query ($subjectId: ID!, $submitted: Boolean, $page: Int, $size: Int) {
    surveySessionSummaries(
      subjectId: $subjectId
      submitted: $submitted
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      surveySessions {
        sessionId
        pubSurveyOrgzId
        pubSurveyId
        pubSurveyName
        pubSurveyDesc
        idHashCode
        createdTime
        expiredTime
        submittedTime
        submitted
      }
    }
  }
`;

export const EMPS_LIST_QUERY = gql`
  query ($page: Int, $size: Int) {
    empsByOrgzWithoutMe(page: $page, size: $size, isAll: false) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      employees {
        id
        email
        name
        role
        created
      }
    }
  }
`;

export const EMPS_SEARCH_QUERY = gql`
  query ($value: String, $page: Int, $size: Int) {
    empsByEmailOrNameWithoutMe(value: $value, page: $page, size: $size) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      employees {
        id
        email
        name
        role
        created
      }
    }
  }
`;

export const PUB_SURVEY_QUERY = gql`
  query {
    pubSurveySummaries {
      id
      orgzId
      name
      description
      detail
      version
    }
  }
`;

export const EMP_ME_QUERY = gql`
  query {
    empMe {
      id
      name
      email
      orgz {
        name
        code
      }
      tel
    }
  }
`;

export const ANLZ_REQ_SRC_SELECT_QUERY = gql`
  query ($subjectId: ID!, $page: Int, $size: Int) {
    anlzSrcPgBySbjtId(subjectId: $subjectId, page: $page, size: $size) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      sources {
        id
        created
        recorded
        visualFile
      }
    }
  }
`;
export const ANLZ_REQ_SURVEY_SESSION_SELECT_QUERY = gql`
  query ($subjectId: ID!, $page: Int, $size: Int) {
    surveySessionSummaries(
      subjectId: $subjectId
      submitted: true
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      surveySessions {
        sessionId
        pubSurveyName
        pubSurveyDesc
        createdTime
        submittedTime
        submitted
      }
    }
  }
`;

export const ALL_ORGZ_BO_QUERY = gql`
  query ($orgzName: String, $page: Int, $size: Int) {
    boOrgzStatPage(orgzName: $orgzName, page: $page, size: $size) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      stats {
        orgz {
          id
          code
          name
          adminEmployee {
            id
            name
            email
          }
        }
        anlzStat {
          reqs
          cpts
        }
      }
    }
  }
`;

export const ANLZ_REQUESTS_BY_STATUS_BO_DASH_QUERY = gql`
  query ($anlzStatus: AnlzStatus!, $page: Int, $size: Int) {
    boAnlzRequestPgByAnlzStatus(
      orgzId: null
      anlzStatus: $anlzStatus
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      requests {
        id
        created
        anlzSources {
          visualFile
        }
        orgzName
      }
    }
  }
`;

export const ANLZ_REQUESTS_BY_STATUS_BO_ORGZ_QUERY = gql`
  query ($orgzId: ID, $anlzStatus: AnlzStatus!, $page: Int, $size: Int) {
    boAnlzRequestPgByAnlzStatus(
      orgzId: $orgzId
      anlzStatus: $anlzStatus
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      requests {
        id
        created
        anlzSources {
          visualFile
        }
      }
    }
  }
`;

export const ANLZ_REQUESTS_BY_ORGZ_BO_QUERY = gql`
  query ($orgzId: ID!, $page: Int, $size: Int) {
    boAnlzRequestPgByZTime(
      orgzId: $orgzId
      ymdhmsFrom: null
      ymdhmsTo: null
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      requests {
        id
        subjectId
        employeeName
        anlzSources {
          visualFile
          actualFile
        }
        anlzStatus
        surveys {
          surveySessionId
          surveyName
          created
        }
        statusTime
        visualResultFile
        actualResultFile
        resultFileSize
        remarks
        created
      }
    }
  }
`;

export const COMPANY_PROPS_QUERY = gql`
  query ($language: String) {
    ybrainInfo(language: $language) {
      id
      value
    }
  }
`;

export const ALL_FAQS_QUERY = gql`
  query ($language: String) {
    allFaqsByLang(language: $language) {
      id
      question
      answer
    }
  }
`;

export const BILL_AMT_BY_TIME_QUERY = gql`
  query ($orgzId: ID, $ymdhmsFrom: ZonedDateTime, $ymdhmsTo: ZonedDateTime) {
    boBillAmountByZTime(
      orgzId: $orgzId
      ymdhmsFrom: $ymdhmsFrom
      ymdhmsTo: $ymdhmsTo
    ) {
      statTime {
        index
        from
        to
      }
      count
      amount
    }
  }
`;

export const BILL_DETAIL_BY_WEEK_QUERY = gql`
  query ($orgzId: ID, $ymdFrom: ZonedDateTime!, $weeks: Int!) {
    boBillAmountByZWeek(orgzId: $orgzId, ymdFrom: $ymdFrom, weeks: $weeks) {
      statTime {
        index
        from
        to
      }
      count
      amount
    }
  }
`;

export const BILL_DETAIL_BY_DAY_QUERY = gql`
  query ($orgzId: ID, $ymdFrom: ZonedDateTime!, $days: Int!) {
    boBillAmountByZDay(orgzId: $orgzId, ymdFrom: $ymdFrom, days: $days) {
      statTime {
        index
        from
        to
      }
      count
      amount
    }
  }
`;

export const ORGZ_LIST_QUERY = gql`
  query ($page: Int, $size: Int) {
    orgzs(page: $page, size: $size) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      orgzs {
        id
        code
        name
        services
        creationTime
        adminEmployee {
          id
          email
        }
      }
    }
  }
`;

export const ORGZ_LIST_SEARCH_QUERY = gql`
  query ($codeOrName: String!, $page: Int, $size: Int) {
    orgzsByCodeOrName(codeOrName: $codeOrName, page: $page, size: $size) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      orgzs {
        id
        code
        name
        services
        creationTime
        adminEmployee {
          id
          email
        }
      }
    }
  }
`;

export const ALL_SBJTS_QUERY = gql`
  query (
    $codeOrName: String
    $birthday: LocalDate
    $createdDate: ZonedDateTime
    $page: Int
    $size: Int
  ) {
    sbjtsInOrgz(
      codeOrName: $codeOrName
      birthday: $birthday
      createdDate: $createdDate
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      subjects {
        ...Patient
      }
    }
  }
  ${Patient}
`;

export const ALL_SBJT_IDS_QUERY = gql`
  query ($page: Int, $size: Int) {
    sbjtsInOrgz(
      codeOrName: null
      birthday: null
      createdDate: null
      page: $page
      size: $size
    ) {
      pageInfo {
        totalPages
        nowPage
        size
      }
      subjects {
        id
      }
    }
  }
`;

export const ICD_FILTER_OPTION_QUERY = gql`
  query ($category: IndicationCategory!, $page: Int, $size: Int) {
    indicationsByCategory(category: $category, page: $page, size: $size) {
      ...DiseasePage
    }
  }
  ${DiseasePage}
`;

export const ICD_FILTER_OPTION_GROUP_QUERY = gql`
  query ($parentId: ID!, $page: Int, $size: Int) {
    indicationsByParentId(parentId: $parentId, page: $page, size: $size) {
      ...DiseasePage
    }
  }
  ${DiseasePage}
`;

export const ICD_FILTER_QUERY = gql`
  query ($parentId: ID!, $page: Int, $size: Int, $includeBookmark: Boolean!) {
    indicationAndBookmarkByParentId(
      parentId: $parentId
      page: $page
      size: $size
      includeBookmark: $includeBookmark
    ) {
      ...DiseasesWithBookmarks
    }
  }
  ${DiseasesWithBookmarks}
`;

export const ICD_SEARCH_QUERY = gql`
  query (
    $codeOrName: String!
    $page: Int
    $size: Int
    $includeBookmark: Boolean!
  ) {
    indicationAndBookmarkByCodeOrName(
      codeOrName: $codeOrName
      page: $page
      size: $size
      includeBookmark: $includeBookmark
    ) {
      ...DiseasesWithBookmarks
    }
  }
  ${DiseasesWithBookmarks}
`;

export const ICD_BOOKMARK_QUERY = gql`
  query {
    myIndicationBookmarks {
      ...Disease
    }
  }
  ${Disease}
`;

export const NOTICES_QUERY = gql`
  query {
    noticesByCurTime {
      ...Notice
    }
  }
  ${Notice}
`;

export const AUTH_DATA_YBRAIN_EMPLOYEES = gql`
  query {
    ybrainEmployees
      @rest(
        type: "employee"
        endpoint: "auth"
        path: "1.4/organizations/1/employees"
        method: "GET"
      ) {
      id
      orgzId
      email
      name
      tel
      department
    }
  }
`;

export const AUTH_DATA_YBRAIN_ORGANIZATION = gql`
  query ($orgzId: Int) {
    organization(orgzId: $orgzId)
      @rest(
        type: "organization"
        endpoint: "auth"
        path: "1.4/organizations/{args.orgzId}"
        method: "GET"
      ) {
      id
      name
      mgmtOfficerDetails
    }
  }
`;

export const CONTRACT_DATA = gql`
  query ($contractId: Int) {
    contract(contractId: $contractId)
      @rest(
        type: "contract"
        endpoint: "contract"
        path: "api/v2/contracts/{args.contractId}"
        method: "GET"
      ) {
      startDate
      endDate
      code
      id
      managerUrn
      organizationUrn
      deviceUrn
      serviceType
    }
  }
`;
