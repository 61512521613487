// @flow
// module imports
import * as React from 'react';
import { Query } from '@apollo/client/react/components';
import { PlainPanel, Spinner } from '../../common';
import { Container, FlexRow, StatPiece, Divider, SpinnerContainer } from './styled';
import { STAT_ANLZ_BO_QUERY } from '../../../utils/graphql/queries';

class AnalysisPanel extends React.Component<{}> {
  render() {
    return (
      <Query
        query={STAT_ANLZ_BO_QUERY}
        pollInterval={5000}
        context={{
          dataSourceType: 'api'
        }}
      >
        {({ loading, error, data, refetch }) => {
          return (
            <PlainPanel
              title='분석 통계'
              headerBorderColor='#00bbf9'
            >
              <Container>
                <FlexRow>
                  {(loading) ? <SpinnerContainer><Spinner /></SpinnerContainer> : (
                    <React.Fragment>
                      <StatPiece>
                        <span className="stat-heading">
                          분석용 파일
                        </span>
                        <span className="stat-data">
                          {(data && data.boStatSummaryByZTime) ? data.boStatSummaryByZTime.srcs : '-'}
                        </span>
                      </StatPiece>
                      <Divider />
                      <StatPiece>
                        <span className="stat-heading">
                          분석 요청중
                        </span>
                        <span className="stat-data">
                          {(data && data.boStatSummaryByZTime) ? data.boStatSummaryByZTime.reqs : '-'}
                        </span>
                      </StatPiece>
                      <StatPiece>
                        <span className="stat-heading">
                          분석중
                        </span>
                        <span className="stat-data">
                          {(data && data.boStatSummaryByZTime) ? data.boStatSummaryByZTime.procs : '-'}
                        </span>
                      </StatPiece>
                      <StatPiece color='#00bbf9'>
                        <span className="stat-heading">
                          분석완료
                        </span>
                        <span className="stat-data">
                          {(data && data.boStatSummaryByZTime) ? data.boStatSummaryByZTime.cpts : '-'}
                        </span>
                      </StatPiece>
                    </React.Fragment>
                  )}
                </FlexRow>
              </Container>
            </PlainPanel>
          );
        }}
      </Query>
    )
  }
}

export { AnalysisPanel };
