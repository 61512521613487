// @flow
import type { ClearCurrentUserAction } from '../auth/currentuser';
import type { ClearPatientSelectionAction } from './patientlist';
import type { SurveySessionListState, SurveySession } from '../../types/analysis';
// module imports
import _ from 'lodash';
import { CLEAR_PATIENT_SELECTION } from './patientlist';
import { CLEAR_CURRENT_USER } from '../auth/currentuser';

// client actions
const SET_PAGING_INFO: 'sd/a/surveysessionlist/SET_PAGING_INFO' = 'sd/a/surveysessionlist/SET_PAGING_INFO';
const NEXT_PAGE: 'sd/a/surveysessionlist/NEXT_PAGE' = 'sd/a/surveysessionlist/NEXT_PAGE';
const PREV_PAGE: 'sd/a/surveysessionlist/PREV_PAGE' = 'sd/a/surveysessionlist/PREV_PAGE';
const SELECT_SURVEY_SESSION: 'sd/a/surveysessionlist/SELECT_SURVEY_SESSION' = 'sd/a/surveysessionlist/SELECT_SURVEY_SESSION';
const CLEAR_SURVEY_SESSION_SELECTION: 'sd/a/surveysessionlist/CLEAR_SURVEY_SESSION_SELECTION' = 'sd/a/surveysessionlist/CLEAR_SURVEY_SESSION_SELECTION';
const CHECK_SURVEY_SESSIONS: 'sd/a/surveysessionlist/CHECK_SURVEY_SESSIONS' = 'sd/a/surveysessionlist/CHECK_SURVEY_SESSIONS';
const UNCHECK_SURVEY_SESSIONS: 'sd/a/surveysessionlist/UNCHECK_SURVEY_SESSIONS' = 'sd/a/surveysessionlist/UNCHECK_SURVEY_SESSIONS';
// server actions
const DOWNLOAD_ANLZ_RESOURCE: 'sd/a/surveysessionlist/DOWNLOAD_ANLZ_RESOURCE' = 'sd/a/surveysessionlist/DOWNLOAD_ANLZ_RESOURCE';
const REFETCH_SURVEY_SESSIONS: 'sd/a/surveysessionlist/REFETCH_SURVEY_SESSION' = 'sd/a/surveysessionlist/REFETCH_SURVEY_SESSION';
const REFETCH_SURVEY_SESSIONS_DONE: 'sd/a/surveysessionlist/REFETCH_SURVEY_SESSION_DONE' = 'sd/a/surveysessionlist/REFETCH_SURVEY_SESSION_DONE';

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type DownloadAnlzResourceAction = {
  type: typeof DOWNLOAD_ANLZ_RESOURCE,
}

type SelectSurveySessionAction = {
  type: typeof SELECT_SURVEY_SESSION,
  payload: SurveySession
};

type ClearSurveySessionSelectionAction = {
  type: typeof CLEAR_SURVEY_SESSION_SELECTION
};

type CheckSurveySessionsAction = {
  type: typeof CHECK_SURVEY_SESSIONS,
  payload: string[]
};

type UncheckSurveySessionsAction = {
  type: typeof UNCHECK_SURVEY_SESSIONS,
  payload: string[]
};

type RefetchSurveySessionsAction = {
  type: typeof REFETCH_SURVEY_SESSIONS
};

type RefetchSurveySessionsDoneAction = {
  type: typeof REFETCH_SURVEY_SESSIONS_DONE
};

type SurveySessionListActions = (
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  SelectSurveySessionAction |
  ClearSurveySessionSelectionAction |
  CheckSurveySessionsAction |
  UncheckSurveySessionsAction |
  RefetchSurveySessionsAction |
  RefetchSurveySessionsDoneAction |
  DownloadAnlzResourceAction |
  ClearPatientSelectionAction |
  ClearCurrentUserAction
);

const INITIAL_STATE: SurveySessionListState = {
  currentPage: 0,
  numPages: 0,
  refetch: false,
  selectedSurveySession: null,
  checkedSurveySessions: []
};

export default function reducer(state: SurveySessionListState = INITIAL_STATE, action: SurveySessionListActions): SurveySessionListState {
  switch (action.type) {
    case SET_PAGING_INFO:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        numPages: action.payload.numPages
      };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      };
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      };
    case SELECT_SURVEY_SESSION:
      return {
        ...state,
        selectedSurveySession: action.payload
      };
    case CLEAR_PATIENT_SELECTION:
    case CLEAR_SURVEY_SESSION_SELECTION:
      return {
        ...state,
        selectedSurveySession: null
      };
    case CHECK_SURVEY_SESSIONS:
      return {
        ...state,
        checkedSurveySessions: _.union(state.checkedSurveySessions, action.payload)
      };
    case UNCHECK_SURVEY_SESSIONS:
      return {
        ...state,
        checkedSurveySessions: _.difference(state.checkedSurveySessions, action.payload)
      };
    case REFETCH_SURVEY_SESSIONS:
      return {
        ...state,
        refetch: true
      };
    case REFETCH_SURVEY_SESSIONS_DONE:
      return {
        ...state,
        refetch: false
      };
    case CLEAR_CURRENT_USER: return INITIAL_STATE;
    case DOWNLOAD_ANLZ_RESOURCE: return state;
    default: return state;
  }
};

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const selectSurveySession = (session: SurveySession): SelectSurveySessionAction => {
  return {
    type: SELECT_SURVEY_SESSION,
    payload: session
  };
};

const clearSurveySessionSelection = (): ClearSurveySessionSelectionAction => {
  return {
    type: CLEAR_SURVEY_SESSION_SELECTION
  };
};

const checkSurveySessions = (sessionIds: string[]): CheckSurveySessionsAction => {
  return {
    type: CHECK_SURVEY_SESSIONS,
    payload: sessionIds
  };
};

const uncheckSurveySessions = (sessionIds: string[]): UncheckSurveySessionsAction => {
  return {
    type: UNCHECK_SURVEY_SESSIONS,
    payload: sessionIds
  };
};

const refetchSurveySessions = (): RefetchSurveySessionsAction => {
  return {
    type: REFETCH_SURVEY_SESSIONS
  };
};

const refetchSurveySessionsDone = (): RefetchSurveySessionsDoneAction => {
  return {
    type: REFETCH_SURVEY_SESSIONS_DONE
  };
};

export const actionCreators = {
  setPagingInfo,
  nextPage,
  prevPage,
  selectSurveySession,
  clearSurveySessionSelection,
  checkSurveySessions,
  uncheckSurveySessions,
  refetchSurveySessions,
  refetchSurveySessionsDone
};