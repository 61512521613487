// @flow
import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

const PanelContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
  box-shadow: ${props => props.tabsMerged && props.addBoxShadow ? '4px 11px 11px 0 rgba(0, 0, 0, 0.07)' : null};
`;

const TabStripContainer = styled.div`
  display: flex;
  width: 100%;
  height: 66px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: ${props => !props.tabsMerged ? '4px 11px 11px 0 rgba(0, 0, 0, 0.07)' : null};
  margin-bottom: ${props => !props.tabsMerged ? '8px' : null};
`;

const Tab = styled.div`
  cursor: pointer;
  height: 66px;
  border-bottom: ${props => props.active ? 'solid 6px #576881' : 'solid 6px transparent'};
  flex: ${props => !props.tabsMerged ? null : '1 1 0%'};
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    display: block;
    min-width: 200px;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    color: ${props => props.active ? '#576881' : '#bcc9d8'};
  }
`;

const TabDivider = styled.div`
  width: 1px;
  height: 33px;
  background-color: #d0dcea;
`;

const TabContentContainer = styled.div`
  width: 100%;
  font-size: 16px;
  box-shadow: ${props => !props.tabsMerged ? '4px 11px 11px 0 rgba(0, 0, 0, 0.07)' : null};
  background-color: #fff;
  flex: 1 1 0%;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

type TabProps = {
  children?: React.ChildrenArray<*>,
  tabsMerged?: boolean,
  tabKey: string,
  title: string
}

const TabContent = ({ children, tabKey, tabsMerged }: TabProps) => {
  return <TabContentContainer key={tabKey} tabsMerged={tabsMerged}>{children}</TabContentContainer>;
}

type Props = {
  children: React.ChildrenArray<React.Element<typeof TabContent>>,
  tabsMerged?: boolean,
  addBoxShadow?: boolean,
  onTabChange?: (tabKey: string) => void,
  selectedTab: string
}

type State = {
  selectedTab: string
}

class TabbedPanel extends React.Component<Props, State> {
  static Tab = TabContent;
  _tabs: Array<TabProps>;
  _tabKeysToIdx: { [tabKey: string]: number };

  constructor(props: Props) {
    super(props);
    this._tabs = this.extractTabProps(props);
    this._tabKeysToIdx = {};
    _.forEach(this._tabs, (tab, idx: number) => {
      this._tabKeysToIdx[tab.tabKey] = idx;
    });
    this.state = {
      selectedTab: props.selectedTab
    }
  }

  extractTabProps({ children }: Props): Array<TabProps> {
    return React.Children.map(children, (child) => child.props);
  }

  componentDidUpdate(prevProps: Props) {
    this._tabs = this.extractTabProps(this.props);
    if (this.props.selectedTab !== prevProps.selectedTab) {
      this.setState({ selectedTab: this.props.selectedTab });
    }
  }

  renderTabStrip() {
    const tabs = _.map(this._tabs, (tab, idx) => {
      const {
        title,
        tabKey
      } = tab;
      const ret = [(
        <Tab
          onClick={() => {
            if (this.state.selectedTab !== tabKey) {
              this.setState({ selectedTab: tabKey });
              if (this.props.onTabChange) {
                this.props.onTabChange(tabKey);
              }
            }
          }}
          active={this.state.selectedTab === tabKey}
          tabsMerged={this.props.tabsMerged}
          key={tabKey}
        >
          <span>{title}</span>
        </Tab>
      )];
      if (idx === this._tabs.length - 1) {
        return ret;
      }
      return _.concat(ret, <TabDivider key={`${tabKey}_divider`} />);
    });
    return (
      <TabStripContainer tabsMerged={this.props.tabsMerged}>
        {_.flatten(tabs)}
      </TabStripContainer>
    )
  }

  renderTabContent() {
    return React.cloneElement(React.Children.toArray(this.props.children)[this._tabKeysToIdx[this.state.selectedTab]], {
      tabsMerged: this.props.tabsMerged
    });
  }
  
  render() {
    return (
      <PanelContainer tabsMerged={this.props.tabsMerged} addBoxShadow={this.props.addBoxShadow}>
        {this.renderTabStrip()}
        {this.renderTabContent()}
      </PanelContainer>
    );
  }
}

export { TabbedPanel };