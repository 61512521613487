// @flow
import * as React from 'react';
import { PlainPanel, EllipsisSpan } from '../../common';
import mypageImages from '../../../resources/images/mypage';
import {
  Container,
  PanelLogo,
  FooterButton,
  ProfileInfoContainer,
  ProfileInfoRow,
  ErrorMsgSpan,
  SpinnerContainer,
} from './styled';
import { Spinner } from '../../common';
import { UPDATE_PWD_MUTATION } from '../../../utils/graphql/mutations';
import { Mutation } from '@apollo/client/react/components';

type Props = {};

type State = {
  editing: boolean
}

class PasswordPanel extends React.Component<Props, State> {
  _firstInput: ?HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.editing !== prevState.editing && this._firstInput) {
      this._firstInput.focus();
    }
  }

  renderPasswordChangeForm(editing: boolean) {
    if (editing) {
      return (
        <Mutation
          mutation={UPDATE_PWD_MUTATION}
          context={{
            dataSourceType: 'auth'
          }}
        >
          {(updatePwd, { loading, error }) => {
            let errorMsg = '';
            if (error && error.message.includes('Password is not corrected.')) {
              errorMsg = '기존 비밀번호가 일치하지 않습니다.';
            }
            return (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  console.log('update password');
                  const oldPwd = event.currentTarget.elements['oldPwd'].value;
                  const newPwd = event.currentTarget.elements['newPwd'].value;
                  const confirmPwd = event.currentTarget.elements['confirmPwd'].value;
                  if (newPwd !== confirmPwd) {
                    alert('새 비밀번호가 일치하지 않습니다.');
                    event.currentTarget.elements['confirmPwd'].value = '';
                    return;
                  }
                  updatePwd({variables: {
                    oldPwd,
                    newPwd
                  }}).then((result) => {
                    this.setState({ editing: false });
                  });
                }}
              >
                <ProfileInfoRow>
                  <img src={mypageImages.password} alt='New password'/>
                  <input type='password' name='oldPwd' required ref={ref => this._firstInput = ref} placeholder='현재 비밀번호' autoComplete='current-password' />
                </ProfileInfoRow>
                <ProfileInfoRow>
                  <img src={mypageImages.newPassword} alt='New password'/>
                  <input type='password' name='newPwd' required placeholder='새 비밀번호' autoComplete='new-password' />
                </ProfileInfoRow>
                <ProfileInfoRow>
                  <img src={mypageImages.confirmPassword} alt='Confirm password'/>
                  <input type='password' name='confirmPwd' required placeholder='새 비밀번호 확인' autoComplete='new-password' />
                </ProfileInfoRow>
                {(error) ? <ErrorMsgSpan>{errorMsg}</ErrorMsgSpan> : null}
                <FooterButton
                  type='submit'
                  disabled={loading}
                >
                  <img src={mypageImages.save} alt='Save'/>
                  <img src={mypageImages.saveHover} alt='Save hover' className='hover-img'/>
                </FooterButton>
                {(loading) ? <SpinnerContainer><Spinner /></SpinnerContainer> : null}
              </form>
            )
          }}
        </Mutation>
      );
    }
    return (
      <ProfileInfoRow>
        <img src={mypageImages.password} alt='Password'/>
        <EllipsisSpan>*************</EllipsisSpan>
      </ProfileInfoRow>
    );
  }

  renderFooterButton(editing: boolean) {
    if (!editing) {
      return (
        <FooterButton
          onClick={() => this.setState({ editing: true })}
        >
          <img src={mypageImages.edit} alt='Edit setting'/>
          <img src={mypageImages.editHover} alt='Edit setting hover' className='hover-img'/>
        </FooterButton>
      );
    }
  }

  render() {
    return (
      <PlainPanel
        title='비밀번호'
        headerBorderColor='#4a90e2'
        headerBackgroundColor='#fff'
      >
        <Container>
          <PanelLogo src={mypageImages.passwordPanel} alt='Profile' />
          <ProfileInfoContainer>
            {this.renderPasswordChangeForm(this.state.editing)}
          </ProfileInfoContainer>
          {this.renderFooterButton(this.state.editing)}
        </Container>
      </PlainPanel>
    );
  }
}

export { PasswordPanel };
