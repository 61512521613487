// @flow
import styled from 'styled-components';
import { AnchorButton } from '../../common';

export const Container = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0%;
  width: 100%;
`;

export const Row = styled.div`
  margin-top: 34px;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
`;

export const FlexRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 120px;
  position: relative;
  > * {
    display: inline-block;
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.3);
`;

export const Divider = styled.div`
  width: 1px;
  height: 98px;
  background-color: #d0dcea;
`;

export const StatPiece = styled.div`
  flex: ${props => props.flex || '1 1 0%'};
  text-align: center;
  > span {
    display: block;
    color: ${props => props.color || '#576881'};
  }
  > .stat-heading {
    font-size: 20px;
    font-weight: 500;
  }
  > .stat-data {
    margin-top: 12px;
    font-size: 55px;
    font-weight: 500;
  }
`;

export const StatHeadingButton = styled(AnchorButton)`
  align-self: center;
  > span {
    color: #00bbf9
    display: inline-block;
  }
  &:hover {
    > span {
      text-decoration: underline;
    }
  }
`;