// @flow
import styled from 'styled-components';
import { HoverStyledAnchorButton } from '../../common';

export const Container = styled.div`
  display: flex;
  padding: 0 30px 34px;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;
  overflow-y: auto;
`;

export const RowContainer = styled.div`
  width: 100%;
  margin-top: 34px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
`;

export const QuestionContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  align-items: center;
  display: flex;
`;

export const QuestionMark = styled.span`
  color: #576881;
  font-size: 20px;
  font-weight: 600;
`;

export const Question = styled.p`
  flex: 1 1 0%;
  font-size: 18px;
  font-weight: 400;
  color: #576881;
  margin: 0;
`;

export const AnswerContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  display: flex;
  margin-top: 19px;
`;

export const AnswerMark = styled.span`
  color: #fc5882;
  font-size: 14px;
  margin-left: 27px;
`;

export const Answer = styled(Question)`
  color: #fc5882;
  margin-right: 70px;
`;

export const ExpandButton = styled(HoverStyledAnchorButton)``;