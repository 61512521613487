// @flow
import type { AppState } from '../../types';
import type { Dispatch } from 'redux';
import type { ClearCurrentUserAction } from '../auth/currentuser';
import type { ClearPatientSelectionAction } from './patientlist';
import type { AnlzSourceListState, AnlzSource } from '../../types/analysis';
// module imports
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import { downloadAnlzResourceRest } from '../../utils/rest';
import { CLEAR_PATIENT_SELECTION } from './patientlist';
import { CLEAR_CURRENT_USER } from '../auth/currentuser';

// client actions
const SET_PAGING_INFO: 'sd/a/anlzsourcelist/SET_PAGING_INFO' = 'sd/a/anlzsourcelist/SET_PAGING_INFO';
const NEXT_PAGE: 'sd/a/anlzsourcelist/NEXT_PAGE' = 'sd/a/anlzsourcelist/NEXT_PAGE';
const PREV_PAGE: 'sd/a/anlzsourcelist/PREV_PAGE' = 'sd/a/anlzsourcelist/PREV_PAGE';
const SELECT_ANLZ_SOURCE: 'sd/a/anlzsourcelist/SELECT_ANLZ_SOURCE' = 'sd/a/anlzsourcelist/SELECT_ANLZ_SOURCE';
const DESELECT_ANLZ_SOURCE: 'sd/a/anlzsourcelist/DESELECT_ANLZ_SOURCE' = 'sd/a/anlzsourcelist/DESELECT_ANLZ_SOURCE';
const CLEAR_ANLZ_SOURCE_SELECTION: 'sd/a/anlzsourcelist/CLEAR_ANLZ_SOURCE_SELECTION' = 'sd/a/anlzsourcelist/CLEAR_ANLZ_SOURCE_SELECTION';
const CHECK_ANLZ_SOURCES: 'sd/a/anlzsourcelist/CHECK_ANLZ_SOURCES' = 'sd/a/anlzsourcelist/CHECK_ANLZ_SOURCES';
const UNCHECK_ANLZ_SOURCES: 'sd/a/anlzsourcelist/UNCHECK_ANLZ_SOURCES' = 'sd/a/anlzsourcelist/UNCHECK_ANLZ_SOURCES';
// server actions
const DOWNLOAD_ANLZ_RESOURCE: 'sd/a/anlzsourcelist/DOWNLOAD_ANLZ_RESOURCE' = 'sd/a/anlzsourcelist/DOWNLOAD_ANLZ_RESOURCE';
const REFETCH_ANLZ_SOURCES: 'sd/a/anlzsourcelist/REFETCH_ANLZ_SOURCE' = 'sd/a/anlzsourcelist/REFETCH_ANLZ_SOURCE';
const REFETCH_ANLZ_SOURCES_DONE: 'sd/a/anlzsourcelist/REFETCH_ANLZ_SOURCE_DONE' = 'sd/a/anlzsourcelist/REFETCH_ANLZ_SOURCE_DONE';

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type DownloadAnlzResourceAction = {
  type: typeof DOWNLOAD_ANLZ_RESOURCE,
}

type SelectAnlzSourceAction = {
  type: typeof SELECT_ANLZ_SOURCE,
  payload: AnlzSource
};

type DeselectAnlzSourceAction = {
  type: typeof DESELECT_ANLZ_SOURCE
};

type ClearAnlzSourceSelectionAction = {
  type: typeof CLEAR_ANLZ_SOURCE_SELECTION
};

type CheckAnlzSourcesAction = {
  type: typeof CHECK_ANLZ_SOURCES,
  payload: string[]
};

type UncheckAnlzSourcesAction = {
  type: typeof UNCHECK_ANLZ_SOURCES,
  payload: string[]
};

type RefetchAnlzSourcesAction = {
  type: typeof REFETCH_ANLZ_SOURCES
};

type RefetchAnlzSourcesDoneAction = {
  type: typeof REFETCH_ANLZ_SOURCES_DONE
};

type AnlzSourceListActions = (
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  SelectAnlzSourceAction |
  DeselectAnlzSourceAction |
  ClearAnlzSourceSelectionAction |
  CheckAnlzSourcesAction |
  UncheckAnlzSourcesAction |
  RefetchAnlzSourcesAction |
  RefetchAnlzSourcesDoneAction |
  DownloadAnlzResourceAction |
  ClearPatientSelectionAction |
  ClearCurrentUserAction
);

const INITIAL_STATE: AnlzSourceListState = {
  currentPage: 0,
  numPages: 0,
  refetch: false,
  selectedAnlzSource: null,
  checkedAnlzSources: [],
};

export default function reducer(state: AnlzSourceListState = INITIAL_STATE, action: AnlzSourceListActions): AnlzSourceListState {
  switch (action.type) {
    case SET_PAGING_INFO:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        numPages: action.payload.numPages
      };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      };
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      };
    case SELECT_ANLZ_SOURCE:
      return {
        ...state,
        selectedAnlzSource: action.payload
      };
    case DESELECT_ANLZ_SOURCE:
      return {
        ...state,
        selectedAnlzSource: null,
      };
    case CLEAR_PATIENT_SELECTION:
    case CLEAR_ANLZ_SOURCE_SELECTION:
      return {
        ...state,
        selectedAnlzSource: null
      };
    case CHECK_ANLZ_SOURCES:
      return {
        ...state,
        checkedAnlzSources: _.union(state.checkedAnlzSources, action.payload)
      };
    case UNCHECK_ANLZ_SOURCES:
      return {
        ...state,
        checkedAnlzSources: _.difference(state.checkedAnlzSources, action.payload)
      };
    case REFETCH_ANLZ_SOURCES:
      return {
        ...state,
        refetch: true
      };
    case REFETCH_ANLZ_SOURCES_DONE:
      return {
        ...state,
        refetch: false
      };
    case CLEAR_CURRENT_USER: return INITIAL_STATE;
    case DOWNLOAD_ANLZ_RESOURCE: return state;
    default: return state;
  }
};

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const selectAnlzSource = (source: AnlzSource): SelectAnlzSourceAction => {
  return {
    type: SELECT_ANLZ_SOURCE,
    payload: source
  };
};

const deselectAnlzSource = (): DeselectAnlzSourceAction => {
  return {
    type: DESELECT_ANLZ_SOURCE
  };
};

const clearAnlzSourceSelection = (): ClearAnlzSourceSelectionAction => {
  return {
    type: CLEAR_ANLZ_SOURCE_SELECTION
  };
};

const checkAnlzSources = (sourceIds: string[]): CheckAnlzSourcesAction => {
  return {
    type: CHECK_ANLZ_SOURCES,
    payload: sourceIds
  };
};

const uncheckAnlzSources = (sourceIds: string[]): UncheckAnlzSourcesAction => {
  return {
    type: UNCHECK_ANLZ_SOURCES,
    payload: sourceIds
  };
};

const refetchAnlzSources = (): RefetchAnlzSourcesAction => {
  return {
    type: REFETCH_ANLZ_SOURCES
  };
};

const refetchAnlzSourcesDone = (): RefetchAnlzSourcesDoneAction => {
  return {
    type: REFETCH_ANLZ_SOURCES_DONE
  };
};

const downloadAnlzResource = (sbjtId: string, actualName: string, visualName: string): ThunkAction => {
  return (dispatch: Dispatch<AnlzSourceListActions>, getState: () => AppState) => {
    const { access_token } = getState().auth.currentUser.tokens;
    downloadAnlzResourceRest(sbjtId, actualName, visualName, access_token);
  }
}

export const actionCreators = {
  setPagingInfo,
  nextPage,
  prevPage,
  downloadAnlzResource,
  selectAnlzSource,
  deselectAnlzSource,
  clearAnlzSourceSelection,
  checkAnlzSources,
  uncheckAnlzSources,
  refetchAnlzSources,
  refetchAnlzSourcesDone
};