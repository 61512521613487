// @flow
import * as React from 'react';
import {
  QuestionContainer,
  QuestionMark,
  Question,
  ExpandButton,
  RowContainer,
  AnswerContainer,
  AnswerMark,
  Answer
} from './styled';
import supportImages from '../../../resources/images/support';

type Props = {
  question: string,
  answer: string
};

type State = {
  collapsed: boolean
};

class FAQRow extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }

  render() {
    const {
      question,
      answer
    } = this.props;
    const {
      collapsed
    } = this.state;
    return (
      <RowContainer>
        <QuestionContainer>
          <QuestionMark>Q.&nbsp;</QuestionMark>
          <Question>{question}</Question>
          <ExpandButton
            href='#'
            onClick={(e) => {
              e.preventDefault();
              this.setState(() => ({ collapsed: !collapsed }));
            }}
          >
            <img src={(collapsed) ? supportImages.faqOpen : supportImages.faqClose} alt='Expand' />
            <img className='hover-img' src={(collapsed) ? supportImages.faqOpenHover : supportImages.faqCloseHover} alt='Expand' />
          </ExpandButton>
        </QuestionContainer>
        {(collapsed) ? null : (
          <AnswerContainer>
            <AnswerMark>{'\u21b3'}&nbsp;</AnswerMark>
            <Answer>{answer}</Answer>
          </AnswerContainer>
        )}
      </RowContainer>
    )
  }
}

export { FAQRow };