// @flow
// module imports
import { combineReducers } from 'redux';
import customerListReducer, { actionCreators as customerListActions } from './customerlist';
import customerAnlzRequestListReducer, { actionCreators as customerAnlzRequestListActions } from './customeranlzrequestlist';
import * as DateRangeInput from '../common/daterangeinput';

const reducer = combineReducers({
  customerList: customerListReducer,
  customerAnalysisDateRange: DateRangeInput.createDateRangeInputReducer('customerAnalysis'),
  customerAnlzRequestList: customerAnlzRequestListReducer
});

export default reducer;

export {
  customerListActions,
  customerAnlzRequestListActions
};
