// @flow
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { Text } from 'recharts';
import { Query } from '@apollo/client/react/components';
import { ComposedAreaChart, ComposedBarChart } from '../../common';
import {
  TooltipContentContainer,
  TooltipLeftContainer,
  TooltipRightContainer,
  ChartSpinnerContainer
} from './styled';
import { STAT_WEEKLY_CUSTOMUER_QUERY, STAT_MONTHLY_CUSTOMUER_QUERY } from '../../../utils/graphql/queries';
import { Spinner } from '../../common';

class TooltipContent extends React.Component<{ type?: string, payload?: Array<any>, label?: string, active?: boolean, coordinate?: any }> {
  render() {
    const {
      active,
      payload
    } = this.props;
    if (active && payload && payload.length > 0 && moment(payload[0].payload.statTime.from).isBefore(moment())) {
      return (
        <TooltipContentContainer>
          <TooltipLeftContainer backgroundColor='#4a90e2'>
            <span className='dataset-name'>{payload[0].name}</span>
            <span className='dataset-value'>{payload[0].value}</span>
          </TooltipLeftContainer>
          <TooltipRightContainer>
            <span className='dataset-name'>{payload[1].name}</span>
            <span className='dataset-value'>{payload[1].value}</span>
          </TooltipRightContainer>
        </TooltipContentContainer>
      );
    }
    return null;
  }
}

const Tick = (props: any) => {
  const { value } = props.payload;
  const wkNum = value.substring(0, 2);
  const dates = value.substring(2);
  return (
    <React.Fragment>
      <Text
        {...props}
        fill='#94aabf'
        textAnchor='end'
        dx={-30}
      >
        {wkNum}
      </Text>
      <Text
        {...props}
        fill='#94aabf'
        textAnchor='start'
        dx={-30}
        fontWeight={300}
      >
        {dates}
      </Text>
    </React.Fragment>
  );
}

class CustomerStats extends React.Component<{}, { activeTopDotY: number }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      activeTopDotY: 0
    }
  }

  render() {
    const monthLabels = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const datasetDefs = [
      { key: 'newOrgz', name: '신규', color: '#4a90e2' },
      { key: 'totalOrgz', name: '전체', color: '#576881' },
    ];
    return (
      <React.Fragment>
        <Query
          query={STAT_WEEKLY_CUSTOMUER_QUERY}
          pollInterval={5000}
          variables={{
            ymdFrom: moment().startOf('isoWeek').subtract(4, 'w').toISOString(true)
          }}
          context={{
            dataSourceType: 'auth'
          }}
        >
          {({ loading, data, error }) => {
            console.log(data);
            if (data && data.statOrgzSvcByZWeek) {
              return (
                <ComposedAreaChart
                  data={_.map(data.statOrgzSvcByZWeek, (stat, idx) => {
                    const fromDate = moment(stat.statTime.from).format('MM/DD');
                    const toDate = moment(stat.statTime.to).format('MM/DD');
                    return {
                      ...stat,
                      name: `${idx+1}W(${fromDate}-${toDate})`
                    };
                  })}
                  datasetDefs={datasetDefs}
                  title='Week'
                  tooltipContent={<TooltipContent />}
                  height={419}
                  tooltipOffsetX={-82}
                  tooltipOffsetY={-120}
                  showLegend={false}
                  xTick={<Tick />}
                />
              );
            }
            return <ChartSpinnerContainer><Spinner /></ChartSpinnerContainer>;
          }}
        </Query>
        <Query
          query={STAT_MONTHLY_CUSTOMUER_QUERY}
          pollInterval={5000}
          variables={{
            ymFrom: moment().startOf('year').toISOString(true),
            months: 12
          }}
          context={{
            dataSourceType: 'auth'
          }}
        >
          {({ loading, data, error }) => {
            console.log(data);
            if (data && data.statOrgzSvcByZMonth) {
              return (
                <ComposedBarChart
                  data={_.map(data.statOrgzSvcByZMonth, (stat, idx) => {
                    if (moment(stat.statTime.from).month() <= moment().month()) {
                      return {
                        ...stat,
                        totalOrgz: stat.totalOrgz,
                        newOrgz: stat.newOrgz,
                        name: monthLabels[idx]
                      };
                    }
                    return {
                      ...stat,
                      totalOrgz: 0,
                      newOrgz: 0,
                      name: monthLabels[idx]
                    };
                  })}
                  datasetDefs={datasetDefs}
                  title='Month'
                  tooltipContent={<TooltipContent />}
                  height={419}
                  tooltipOffsetX={-82}
                  tooltipOffsetY={-120}
                  dropRight={moment().month()}
                />
              );
            }
            return <ChartSpinnerContainer><Spinner /></ChartSpinnerContainer>;
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export { CustomerStats };
