// @flow
import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import {
  Page,
  PanelGrid,
  PlainPanel,
  HoverStyledAnchorButton,
  Spinner
} from '../../components/common';
import { CompanyInfoPanel, FAQPanel } from '../../components/support';
import supportImages from '../../resources/images/support';
import { Query } from '@apollo/client/react/components';
import { COMPANY_PROPS_QUERY } from '../../utils/graphql/queries';

const SpinnerContainer = styled.div`
  width: 100%;
  height: ${props => props.height || 27}px;
  margin-top: ${props => props.marginTop || 26}px;
`;
const InfoMessage = styled.p`
  margin: 0;
  margin-top: ${props => props.marginTop || 26}px;
  padding: 0 10px;
  color: #576881;
  font-size: 18px;
  text-align: center;
  white-space: pre;
`;

const LinkButton = styled(HoverStyledAnchorButton)`
  margin-top: ${props => props.marginTop || 26}px;
  > span {
    color: #576881;
    font-size: 18px;
    padding: 0 10px;
  }
  &:hover, &:focus {
    > span {
      text-decoration: underline;
      color: #00bbf9;
    }
  }
`;

class Support extends React.Component<{}> {
  render() {
    return (
      <Page
        title='Customer Center'
        subtitle='서비스를 조금 더 편리하게 이용할 수 있습니다'
        contentMinWidth={1290}
      >
        <PanelGrid.Grid>
          <Query
            query={COMPANY_PROPS_QUERY}
            context={{
              dataSourceType: 'api'
            }}
            fetchPolicy='network-only'
          >
            {({ loading, error, data }) => {
              console.log(data);
              return (
                <PanelGrid.Row style={{ height: '270px' }}>
                  <PanelGrid.Col minWidth={270}>
                    <CompanyInfoPanel
                      imgProps={{
                        alt: 'Homepage',
                        src: supportImages.homepage,
                        width: 112,
                        height: 112
                      }}
                      renderInfo={() => {
                        if (loading) {
                          return (
                            <SpinnerContainer>
                              <Spinner />
                            </SpinnerContainer>
                          );
                        }
                        let homepageHref = '-';
                        if (data && data.ybrainInfo) {
                          homepageHref = _.find(data.ybrainInfo, (info) => _.endsWith(info.id, 'home_page'))
                          if (homepageHref) homepageHref = homepageHref['value'];
                        }
                        return (
                          <LinkButton
                            href='#'
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(`http://${homepageHref}`, '_blank');
                            }}
                          >
                            <span>Main Homepage</span>
                          </LinkButton>
                        );
                      }}
                    />
                  </PanelGrid.Col>
                  <PanelGrid.Col minWidth={270}>
                    <CompanyInfoPanel
                      imgProps={{
                        alt: 'Call Center',
                        src: supportImages.callCenter,
                        width: 113,
                        height: 113
                      }}
                      renderInfo={() => {
                        if (loading) {
                          return (
                            <SpinnerContainer>
                              <Spinner />
                            </SpinnerContainer>
                          );
                        }
                        let callCenter = '-';
                        if (data && data.ybrainInfo) {
                          callCenter = _.find(data.ybrainInfo, (info) => _.endsWith(info.id, 'tel'))
                          if (callCenter) callCenter = callCenter['value'];
                        }
                        return (
                          <InfoMessage>{callCenter}</InfoMessage>
                        );
                      }}
                    />
                  </PanelGrid.Col>
                  <PanelGrid.Col minWidth={270}>
                    <CompanyInfoPanel
                      imgProps={{
                        alt: 'CS Email',
                        src: supportImages.email,
                        width: 113,
                        height: 113
                      }}
                      renderInfo={() => {
                        if (loading) {
                          return (
                            <SpinnerContainer>
                              <Spinner />
                            </SpinnerContainer>
                          );
                        }
                        let email = '-';
                        if (data && data.ybrainInfo) {
                          email = _.find(data.ybrainInfo, (info) => _.endsWith(info.id, 'email'))
                          if (email) email = email['value'];
                        }
                        return (
                          <LinkButton
                            href='#'
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(`${email}`, '_blank');
                            }}
                          >
                            <span>CS Homepage</span>
                          </LinkButton>
                        );
                      }}
                    />
                  </PanelGrid.Col>
                  {/* <PanelGrid.Col minWidth={270}>
                    <CompanyInfoPanel
                      imgProps={{
                        alt: 'Map',
                        src: supportImages.map,
                        width: 80,
                        height: 80
                      }}
                      renderInfo={() => {
                        if (loading) {
                          return (
                            <SpinnerContainer
                              marginTop={17}
                              height={81}
                            >
                              <Spinner />
                            </SpinnerContainer>
                          );
                        }
                        let address = '-';
                        if (data && data.ybrainInfo) {
                          address = _.find(data.ybrainInfo, (info) => _.endsWith(info.id, 'address'))
                          if (address) {
                            address = address['value'];
                          }
                        }
                        return (
                          <InfoMessage
                            marginTop={17}
                          >{address}</InfoMessage>
                        );
                      }}
                    />
                  </PanelGrid.Col> */}
                </PanelGrid.Row>
              )
            }}
          </Query>
          <PanelGrid.Row style={{ height: '570px'}}>
            <PanelGrid.Col minWidth={870}>
              <FAQPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
        </PanelGrid.Grid>
      </Page>
    )
  }
}

export { Support };