// @flow
// module imports
import * as React from 'react';
import moment from 'moment';
import { Query } from '@apollo/client/react/components';
import { PlainPanel, Spinner } from '../../common';
import { Container, FlexRow, StatPiece, SpinnerContainer, Divider } from './styled';
import { STAT_CUSTOMER_BO_QUERY } from '../../../utils/graphql/queries';

class CustomerPanel extends React.Component<{}> {
  refetchData: ?(variables ? : any) => Promise<mixed>;
  componentDidMount() {
    if (this.refetchData) {
      this.refetchData();
    }
  }

  render() {
    return (
      <Query
        query={STAT_CUSTOMER_BO_QUERY}
        pollInterval={5000}
        context={{
          dataSourceType: 'auth'
        }}
        variables={{
          now: moment().toISOString(true)
        }}
      >
        {({ loading, error, data, refetch }) => {
          console.log(data);
          if (!this.refetchData) {
            this.refetchData = refetch;
          }
          return (
            <PlainPanel
              title='고객 통계'
              headerBorderColor='#4a90e2'
            >
              <Container>
                <FlexRow>
                  {(loading) ? <SpinnerContainer><Spinner /></SpinnerContainer> : (
                    <React.Fragment>
                      <StatPiece color='#4a90e2'>
                        <span className="stat-heading">
                          오늘 신규
                        </span>
                        <span className="stat-data">
                          {(data && data.statOrgzSvcTodayStatistics) ? data.statOrgzSvcTodayStatistics.newOrgz : '-'}
                        </span>
                      </StatPiece>
                      <Divider />
                      <StatPiece>
                        <span className="stat-heading">
                          전체
                        </span>
                        <span className="stat-data">
                          {(data && data.statOrgzSvcTodayStatistics) ? data.statOrgzSvcTodayStatistics.totalOrgz : '-'}
                        </span>
                      </StatPiece>
                    </React.Fragment>
                  )}
                </FlexRow>
              </Container>
            </PlainPanel>
          )
        }}
      </Query>
    );
  }
}

export { CustomerPanel };