// @flow
import styled from 'styled-components';
import { Button, AnchorButton } from '../../common';

export const TopContainer = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
`;

export const Container = styled.div`
  display: block;
  width: 553px;
  margin: auto;
  z-index: 2;
`;

export const Title = styled.span`
  font-size: 65px;
  color: #fff;
  display: block;
  text-align: center;
`;

export const FormContainer = styled.form`
  display: block;
  margin: auto;
  margin-top: 46px;
  width: 370px;
  position: relative;
`;

export const FormRow = styled.div`
  width: 100%;
  position: relative;
  height: 60px;
  &:not(:first-child) {
    margin-top: 8px;
  }
  > img {
    width: 27px;
    height: 26px;
    object-fit: contain;
    position: absolute;
    top: 17px;
    left: 18px;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  padding-left: 60px;
  border: none;
  font-size: 18px;
  color: #576881;
  &::placeholder {
    font-size: 18px;
    color: #bcc9d8;
  }
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: #576881;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const LoginButton = styled(Button)`
  width: 100%;
  height: 66px;
  border-radius: 1.5px;
  background-color: #435268;
  box-shadow: 11px 11px 15px 0 rgba(57, 73, 98, 0.2);
  margin-top: 15px;
  font-size: 22px;
  color: #fff;
`;

export const LoginErrorSpan = styled.span`
  display: block;
  width: 100%;
  margin-top: 8px;
  color: #B20016;
  font-size: 11px;
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 66px;
  margin-top: 15px;
`;

export const LoginFormBottomContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const IdPwdButton = styled(AnchorButton)`
  > span {
    color: #fff;
    font-size: 18px;
    margin-right: 8px;
  }
  &:hover, &:focus {
    > span {
      color: #435268;
    }
  }
`;

export const LoginFormFooter = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 45px;
`;

export const LogoLink = styled.a`
  display: block;
  margin: auto;
  margin-bottom: 9px;
  width: 92px;
  height: 21px;
  img {
    width: 92px;
    height: 21px;  
    object-fit: contain;
  }
`;

export const ContactContainer = styled.div`
  text-align: center;
`;

export const Contact = styled.span`
  display: inline-block;
  position: relative;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 0 10px;
  &:not(:last-child)::after {
    content: '';
    width: 1px;
    height: 10px;
    position: absolute;
    top: 5px;
    right: 0;
    background-color: #fff;
  }
  a {
    color: #fff;
    &:hover {
      color #435268;
    }
  }
`;

export const CopyRightContainer = styled.div`
  margin-top: 11px;
  text-align: center;
`;

export const CopyRight = styled.span`
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  opacity: 0.5;
`;

export const SegmentedControl = styled.div`
  margin: auto;
  width: 370px;
  height: 30px;
  display: flex;
`;

export const SegmentButton = styled(Button)`
  flex: 1 1 0%;
  height: 30px;
  background-color: transparent;
  font-size: 14px;
  color: #fff;
  border: 1px solid #fff;
  opacity: 0.3;
  &.active-segment {
    opacity: 1;
  }
  &:hover, &:active {
    opacity: 1;
  }
  &:not(:first-child) {
    margin-left: -1px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  margin-top: 15px;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const ConfirmButton = styled(Button)`
  flex: 1 1 0%;
  height: 66px;
  border-radius: 1.5px;
  background-color: #435268;
  box-shadow: 11px 11px 15px 0 rgba(57, 73, 98, 0.2);
  font-size: 22px;
  color: #fff;
`;

export const CancelButton = styled(AnchorButton)`
  flex: 1 1 0%;
  height: 66px;
  border-radius: 1.5px;
  background-color: #ebf0f4;
  box-shadow: 11px 11px 15px 0 rgba(57, 73, 98, 0.2);
  text-align: center;
  font-size: 22px;
  color: #576881;
`;

export const ResponseMessageContainer = styled.div`
  display: block;
  margin: auto;
  margin-top: 20px;
  width: 370px;
`;

export const ResponseMessage = styled.div`
  width: 100%;
  height: 196px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

export const RecoverIdHeading = styled.span`
  font-size: 18px;
  color: #576881;
  max-width: 370px;
  padding: 0 13px;
  word-wrap: normal;
  text-align: center;
`;

export const ErrorMessage = styled(RecoverIdHeading)`
  color: #fc5882;
`;

export const RecoverIdMessage = styled.span`
  font-size: 18px;
  color: #00bbf9;
  max-width: 370px;
  padding: 4px 13px;
  word-wrap: normal;
  text-align: center;
  span {
    color: #576881;
  }
`;

export const AgreementsContainer = styled.div`
  display: block;
  width: 440px;
  margin: auto;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const AgreementsTitle = styled.span`
  display: block;
  font-size: 19.5px;
  color: #000;
  line-height: 62px;
  border-bottom: solid 2px #d0dcea;
  text-align: center;
`;

export const AgreementsBodyContainer = styled.div`
  padding: 15px;
`;

export const AgreementSectionContainer = styled.div`
  margin-top: 15px;
  &:not(:first-child) {
    margin-top: 20px;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
`;

export const AgreementContentContainer = styled.div`
  margin-top: 8px;
  border: solid 1px #d0dcea;
  padding: 12px;
  overflow: auto;
  height: 100px;
  p, li {
    color: #576881;
    font-size: 15px;
  }
  h3 {
    color: #576881;
    font-size: 16px;
  }
  h4 {
    color: #576881;
    font-size: 15px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.16);
    transition: all .1s ease-in-out;
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.36);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(66,82,110,0.56);
  }
`;

export const AgreementNotice = styled.div`
  display: flex;
  &:not(:first-child) {
    margin-top: 8px;
  }
  .star {
    font-size: 16px;
    color: ${props => props.color || '#94aabf'};
    flex: 0 0 auto;
  }
  p {
    font-size: 13px;
    color: ${props => props.color || '#94aabf'};
    flex: 1 1 0%;
    margin: 0;
    padding: 0;
  }
`;

export const AgreementsButtonContainer = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  margin-top: 40px;
  position: relative;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const AgreeButton = styled(Button)`
  flex: 1 1 0%;
  height: 45px;
  border-radius: 1.5px;
  background-color: #435268;
  font-size: 18px;
  color: #fff;
`;

export const NotAgreeButton = styled(AnchorButton)`
  flex: 1 1 0%;
  height: 45px;
  border-radius: 1.5px;
  background-color: #94aabf;
  text-align: center;
  font-size: 18px;
  color: #fff;
`;