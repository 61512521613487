// @flow
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 0;
  height: 0;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1 1 0%;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.16);
    transition: all .1s ease-in-out;
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.36);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(66,82,110,0.56);
  }
`;

export const BottomIndicator = styled.div`
  width: 100%;
  height: 21%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 3%, #ffffff);
  display: ${props => props.scrolledToBottom ? 'none' : 'block'};
`;