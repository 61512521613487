// @flow
import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { SegmentDivider } from "../customers/DetailBill/styled";
import { Checkbox } from "../common/Checkbox";
import commonImages from "../../resources/images/common";
import { RefreshButton, ExcelDownloadButton, UtilBox } from "../billing/styled";
import { BillingExcelDownload } from "./BillingExcelDownload";
import type { MonthlyTotalBillingInfo } from "../../types/billing";
import { downloadBillingDetailExcelRest, insertCommas } from "../../utils/rest";
import { AUTH_DATA_YBRAIN_ORGANIZATION } from "../../utils/graphql/queries";
import { Query } from "@apollo/client/react/components";
import { Container } from "../support/FAQPanel/styled";
import { FlexRow, StatPiece } from "../dashboard/CurrentStatePanels/styled";

const PanelContainer = styled.div`
  width: 100%;
  flex: 1 1 0%;
  min-height: 0;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PanelHeader = styled.div`
  position: relative;
  background-color: ${(props) => props.headerBackgroundColor || "#f6f9fc"};
  padding-left: 28px;
  height: 70px;
  border-left: solid 6px ${(props) => props.headerBorderColor || "#00bbf9"};
  flex: 0 0 70px;
  * {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    content: "";
    display: inline-block;
    overflow: hidden;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
`;

const Title = styled.span`
  font-family: AppleSDGothicNeo;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.68px;
  color: #576881;
`;
const BillingCountText = styled.span`
  font-family: AppleSDGothicNeo;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.68px;
  color: #94aabf;
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 16px;
  flex: 1 1 0%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Divider = styled.div`
  width: 1px;
  height: 11px;
  opacity: 0.15;
  border: solid 1px #1c3241;
  margin-left: 10px;
  margin-right: 10px;
`;

type Props = {
  headerBorderColor?: string,
  headerBackgroundColor?: string,
  headless?: boolean,
  title?: string,
  children?: React.ChildrenArray<React.Element<any>>,
  monthlyTotalBillingInfo?: MonthlyTotalBillingInfo,
  currentUser: CurrentUserState,
  year: number,
  month: number,
  onRefresh: () => void,
};

class BillingItemsPanelComponent extends React.Component<Props> {
  render() {
    const {
      title,
      headless,
      headerBackgroundColor,
      headerBorderColor,
      count,
      monthlyTotalBillingInfo,
      currentUser,
      year,
      month,
      onRefresh,
    } = this.props;

    return (
      <Query
        query={AUTH_DATA_YBRAIN_ORGANIZATION}
        variables={{
          orgzId: this.props.currentUser.orgz_id,
        }}
        fetchPolicy="network-only"
      >
        {({ data }) => {
          return (
            <PanelContainer>
              {headless ? null : (
                <PanelHeader
                  headerBackgroundColor={headerBackgroundColor}
                  headerBorderColor={headerBorderColor}
                >
                  <Title>{title}</Title>
                  <BillingCountText> ({count}개)</BillingCountText>
                  <Divider />
                  <Checkbox
                    checked={
                      monthlyTotalBillingInfo.settlementStatus === "SETTLED"
                    }
                    onChange={(event) => {}}
                    stopClickPropagation
                    checkboxAlign="left"
                    checkedImgSrc={commonImages.checkedPrimary}
                    uncheckedImgSrc={commonImages.check}
                  >
                    {monthlyTotalBillingInfo.settlementStatus === "SETTLED"
                      ? " 확정"
                      : " 미확정"}
                  </Checkbox>

                  <UtilBox>
                    <RefreshButton
                      className="with-hover-overlay"
                      onClick={onRefresh}
                    >
                      <img src={commonImages.refreshGray} alt="refresh" />
                      <span>
                        {moment().format("YYYY[-]MM[-]DD HH:mm:ss")} 동기화
                      </span>
                    </RefreshButton>
                    {monthlyTotalBillingInfo.monthlyBills.length > 0 && (
                      <BillingExcelDownload
                        monthlyTotalBillingInfo={monthlyTotalBillingInfo}
                        orgzId={this.props.currentUser.orgz_id}
                        year={year}
                        month={month}
                        organization={data?.organization}
                      />
                    )}
                    {data &&
                      monthlyTotalBillingInfo.monthlyBills.length > 0 &&
                      monthlyTotalBillingInfo.settlementStatus ===
                        "SETTLED" && (
                        <ExcelDownloadButton
                          className="with-hover-overlay"
                          onClick={(event) => {
                            event.preventDefault();
                            downloadBillingDetailExcelRest(
                              monthlyTotalBillingInfo.monthlyBills[0].code,
                              data.organization.name,
                              month,
                              year,
                              currentUser.tokens.access_token
                            );
                          }}
                          href="#"
                        >
                          <span>청구서 다운로드</span>
                        </ExcelDownloadButton>
                      )}
                  </UtilBox>
                </PanelHeader>
              )}
              <ContentContainer>
                <Container
                  style={{
                    paddingTop: "48px",
                    paddingBottom: "48px",
                  }}
                >
                  <FlexRow>
                    <React.Fragment>
                      <StatPiece>
                        <span
                          className="stat-heading"
                          style={{
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          총 사용 건수
                        </span>
                        <span
                          className="stat-data"
                          style={{
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {monthlyTotalBillingInfo.settledUsageCount}건
                        </span>
                      </StatPiece>
                      <StatPiece>
                        <span
                          className="stat-heading"
                          style={{ textAlign: "left" }}
                        >
                          총 사용 금액
                        </span>
                        <span
                          className="stat-data"
                          style={{ textAlign: "left" }}
                        >
                          {insertCommas(
                            String(monthlyTotalBillingInfo.settledAmountWithVat)
                          )}
                          원
                        </span>
                      </StatPiece>
                      <Divider />
                      <StatPiece color="#00bbf9">
                        <span
                          className="stat-heading"
                          style={{
                            textAlign: "left",
                            paddingLeft: "64px",
                          }}
                        >
                          총 청구 건수
                        </span>
                        <span
                          className="stat-data"
                          style={{
                            textAlign: "left",
                            paddingLeft: "64px",
                          }}
                        >
                          {monthlyTotalBillingInfo.billedUsageCount}건
                        </span>
                      </StatPiece>
                      <StatPiece color="#00bbf9">
                        <span
                          className="stat-heading"
                          style={{ textAlign: "left" }}
                        >
                          총 청구 금액
                        </span>
                        <span
                          className="stat-data"
                          style={{ textAlign: "left" }}
                        >
                          {insertCommas(
                            String(monthlyTotalBillingInfo.billedAmountWithVat)
                          )}
                          원
                        </span>
                      </StatPiece>
                    </React.Fragment>
                  </FlexRow>
                  <span className="stat-heading">
                    * 위의 모든 금액은 모두 VAT를 포함한 가격임
                  </span>
                </Container>
              </ContentContainer>
            </PanelContainer>
          );
        }}
      </Query>
    );
  }
}

export const BillingPlainPanel = BillingItemsPanelComponent;
