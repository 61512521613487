// @flow
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { AnchorButton, HoverStyledButton, EllipsisSpan, Button } from '../../common';

export const TableControlButton = styled(HoverStyledButton)`
  height: 45px;
  width: 45px;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
`;

export const TablePagingInfo = styled.span`
  font-size: 18px;
  line-height: 1.17;
  color: #586881;
  min-width: 40px;
  text-align: center;
  .current-page {
    color: #00bbf9;
  }
`;

export const CheckAllButton = styled(AnchorButton)`
  background-color: #94aabf;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 100px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const RegisterButtonContainer = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const DeleteEditButton = styled(AnchorButton)`
  background-color: #94aabf;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  height: 45px;
  min-width: 100px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    margin: 0 15px;
  }
`;

export const SurveyResultButton = styled(Button)`
  background-color: #94aabf;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  height: 45px;
  min-width: 100px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    margin: 0 15px;
  }
`;

export const DeleteConfirmMessage = styled.p`
  font-size: 20px;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #000;
  > span {
    color: #fc5882;
  }
`;

export const RegisterButton = styled(AnchorButton)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  height: 60px;
  width: 100px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const RefreshButton = styled(AnchorButton)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 45px;
  > img {
    width: 22px;
    height: 20px;
    line-height: 45px;
    object-fit: contain;
  }
`;

export const DefaultCellSpan = styled(EllipsisSpan)`
  text-align: center;
  padding: 0 10px;
  width: 0;
  flex: 1 1 0%;
`;

export const StyledCalendar = styled(Calendar)`
  border: none !important;
  > .react-calendar__navigation {
    background-color: #00bbf9;
    height: 78px;
    margin-bottom: 22px;
    > button,
    > button[disabled] {
      background-color: transparent;
      font-size: 40px;
      color: #fff;
      &.react-calendar__navigation__next2-button,
      &.react-calendar__navigation__prev2-button {
        display: none;
      }
      &.react-calendar__navigation__next-button,
      &.react-calendar__navigation__prev-button {
        img {
          display: inline-block;
          &.hover-img {
            display: none;
          }
        }
        &:hover, &:focus {
          img {
            display: none;
            &.hover-img {
              display: inline-block;
            }
          }
        }
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }
  > .react-calendar__month-view {
    padding-left: 7px;
    padding-right: 7px;
    .react-calendar__month-view__weekdays {
      font-size: 14px;
      font-weight: 500;
      line-height: 2.14;
      letter-spacing: -0.6px;
      text-transform: none;
      text-align: center;
      color: #94aabf;
      padding:0;
      .react-calendar__month-view__weekdays__weekday {
        padding: 0;
      }
    }
    button.react-calendar__month-view__days__day {
      margin: 0;
      border: 0;
      outline: none;
      font-size: 14px;
      padding: 0;
      height: 30px;
      border-radius: 15px;
      time {
        display: none;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        border-radius: 13px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        color: #94aabf;
      }
      &.react-calendar__month-view__days__day--weekend {
        span {
          color: #fc5882;
        }
      }
      &.react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.3;
      }
      &:hover,
      &.react-calendar__tile--active {
        background-color: transparent;
        > span {
          background-color: #00bbf9;
          color: #fff;
        }
      }
      &:not(.react-calendar__tile--active):focus:not(:hover) {
        background-color: transparent;
        > span {
          background-color: rgba(0,0,0,0.2);
          color: #fff;
        }
      }
    }
  }
`;

export const CalendarPanelFooter = styled.div`
  position: absolute;
  bottom: 0;
  height: 64px;
  padding: 8px 15px;
  &::before {
    content: '';
    background-color: #d0dcea;
    display: inline-block;
    position: absolute;
    top: 0;
    width: 222px;
    height: 1px;
  }
  * {
    display: inline-block;
    color: #94aabf;
    vertical-align: top;
  }
  > span {
    width: 15px;
    height: 15px;
    font-size: 16px;
  }
  > p {
    margin: 0;
    padding: 0;
    max-width: 207px;
    font-size: 12px;
    word-break: keep-all;
  }
`;