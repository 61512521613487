// @flow
import styled from 'styled-components';
import { AnchorButton, HoverStyledAnchorButton } from '../../common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 64px;
  border-bottom: solid 1px #d0dcea;
`;

export const PopupCloseButton = styled(HoverStyledAnchorButton)`
  position: absolute;
  right: 20px;
  height: 16px;
  width: 16px;
  > img {
    height: 16px;
    width: 16px;
    object-fit: contain;
  }
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: #000;
`;

export const ContentContainer = styled.div`
  padding: 14px 20px 20px;
  min-height: 0;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

export const SummaryContainer = styled.div`
  margin-top: 45px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const AnlzReqSummary = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    color: #576881;
    font-size: 55px;
    font-weight: 400;
    &.unit {
      font-size: 45px;
      margin-left: 10px;
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 44px;
  background-color: #d0dcea;
`;

export const BillAmountSummary = styled.div`
  flex: 1 1 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    color: #00bbf9;
    font-size: 55px;
    font-weight: 400;
    &.unit {
      color: #576881;
      font-size: 45px;
      margin-left: 10px;
    }
  }
`;

export const BillDetailContainer = styled.div`
  margin-top: 47px;
  min-height: 0;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

export const DetailHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const DetailHeading = styled.span`
  color: #576881;
  font-size: 18px;
  font-weight: 400;
`;

export const SegmentedControlGroup = styled.div`
  display: flex;
  align-items: center;
  *:not(:first-child) {
    margin-left: 14px;
  }
`;

export const SegmentButton = styled(AnchorButton)`
  > span {
    font-size: 18px;
    font-weight: 400;
    color: #d0dcea;
  }
  &:hover, &.active-segment {
    > span {
      color: #576881;
    }
  }
`;

export const SegmentDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: #bcc9d8;
`;

export const WeeklyDetail = styled.div`
  margin-top: 7px;
  min-height: 0;
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  border: solid 1px #d0dcea;
  > div:first-child {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    justify-content: flex-start;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-corner {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(66,82,110,0.16);
      transition: all .1s ease-in-out;
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: content-box;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(66,82,110,0.36);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(66,82,110,0.56);
    }
  }
`;

export const WeekContainer = styled.div`
  &:not(:last-child) {
    border-bottom: solid 2px #fff;
  }
  &:nth-child(odd) {
    background-color: #f6f8f9;
  }
  height: 60px;
  width: 100%;
  min-width: 581px;
  flex-shrink: 0;
  display: flex;
`;

export const WeekHeading = styled.div`
  width: 190px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px #d0dcea;
  background-color: #ebf0f4;
  > span {
    font-size: 18px;
    font-weight: 400;
    color: #576881;
  }
`;

export const WeekDetailContainer = styled.div`
  flex: 1 1 0%;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 0;
`;

const BaseDetail = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    color: #00bbf9;
    font-size: 21px;
    font-weight: 400;
    &.unit {
      color: #576881;
      margin-left: 10px;
    }
  }
`;

export const AnlzReqDetail = BaseDetail

export const WeekDetailDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: #d0dcea;
`;

export const BillAmountDetail = styled(BaseDetail)`
  flex: 1 1 78px;
`;

export const DailyDetail = styled.div`
  margin-top: 7px;
  min-height: 0;
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  border: solid 1px #d0dcea;
  > div:first-child {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-corner {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(66,82,110,0.16);
      transition: all .1s ease-in-out;
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: content-box;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(66,82,110,0.36);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(66,82,110,0.56);
    }
  }
`;

export const DayContainer = styled.div`
  height: 60px;
  min-width: 82px;
  flex: 1 0 82px;
  display: flex;
  flex-direction: column;
  border-right: solid 1px #d0dcea;
  border-bottom: solid 1px #d0dcea;
  &:nth-child(7n) {
    border-right: none;
  }
  &:nth-last-child(-n+7) {
    border-bottom: none;
  }
`;

export const DayHeading = styled.div`
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf0f4;
  > span {
    font-size: 14px;
    font-weight: 400;
    color: #576881;
  }
`;

export const DayDetailContainer = styled.div`
  flex: 1 1 0%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    color: #00bbf9;
    font-size: 21px;
    font-weight: 400;
    &.unit {
      color: #576881;
      font-size: 18px;
      margin-left: 10px;
    }
  }
`;