// @flow
import * as React from 'react';
import moment from 'moment';
import { Text } from 'recharts';
import styled from 'styled-components';

export const TooltipContentContainer = styled.div`
  width: 164px;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: 7px 7px 15px 0 rgba(0, 0, 0, 0.2);
`;

const TooltipDataContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    color: #fff;
    display: block;
    text-align: center;
    &.dataset-name {
      font-size: 15px;
      font-weight: 400;
    }
    &.dataset-value {
      font-size: 30px;
      font-weight: 400;
    }
  }
  &::after {
    content:"";
    position: absolute;
    width:0px;
    height:0px;
  }
`;

export const TooltipLeftContainer = styled(TooltipDataContainer)`
  background-color: ${props => props.backgroundColor || '#00bbf9'};
  &::after {
    right: 82px;
    bottom: -9px;
    border-top: 5px solid ${props => props.backgroundColor || '#00bbf9'};
    border-right: 3px solid ${props => props.backgroundColor || '#00bbf9'};
    border-bottom: 5px solid transparent;
    border-left: 3px solid transparent;
  }
`;

export const TooltipRightContainer = styled(TooltipDataContainer)`
  background-color: #576881;
  &::after {
    left: 82px;
    bottom: -9px;
    border-top: 5px solid #576881;
    border-right: 3px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 3px solid #576881;
  }
`;

export const TooltipSingleContainer = styled(TooltipDataContainer)`
  background-color: #4a90e2;
  &::after {
    left: 77px;
    bottom: -19px;
    border-top: 10px solid #4a90e2;
    border-right: 5px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 5px solid transparent;
  }
`;

export const ChartSpinnerContainer = styled.div`
  height: 419px;
  position: relative;
`;

class TooltipContent extends React.Component<{ type?: string, payload?: Array<any>, label?: string, active?: boolean, coordinate?: any }> {
  render() {
    const {
      active,
      payload
    } = this.props;
    if (active && payload && payload.length > 0 && moment(payload[0].payload.statTime.from).isBefore(moment())) {
      return (
        <TooltipContentContainer>
          <TooltipLeftContainer>
            <span className='dataset-name'>{payload[0].name}</span>
            <span className='dataset-value'>{payload[0].value}</span>
          </TooltipLeftContainer>
          <TooltipRightContainer>
            <span className='dataset-name'>{payload[1].name}</span>
            <span className='dataset-value'>{payload[1].value}</span>
          </TooltipRightContainer>
        </TooltipContentContainer>
      );
    }
    return null;
  }
}

const Tick = (props: any) => {
  const { value } = props.payload;
  const wkNum = value.substring(0, 2);
  const dates = value.substring(2);
  return (
    <React.Fragment>
      <Text
        {...props}
        fill='#94aabf'
        textAnchor='end'
        dx={-30}
      >
        {wkNum}
      </Text>
      <Text
        {...props}
        fill='#94aabf'
        textAnchor='start'
        dx={-30}
        fontWeight={300}
      >
        {dates}
      </Text>
    </React.Fragment>
  );
}

export { TooltipContent, Tick };