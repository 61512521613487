// @flow
import * as React from 'react';
import styled from 'styled-components';

const PanelContainer = styled.div`
  width: 100%;
  flex: 1 1 0%;
  min-height: 0;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PanelHeader = styled.div`
  background-color: ${props => props.headerBackgroundColor || '#f6f9fc'};
  padding-left: 28px;
  height: 70px;
  border-left: solid 6px ${props => props.headerBorderColor || '#00bbf9'};
  flex: 0 0 70px;
  * {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    content: '';
    display: inline-block;
    overflow: hidden;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
`;

const Title = styled.span`
  font-size: 26px;
  font-weight: 600;
  color: #576881;
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 16px;
  flex: 1 1 0%;
  max-height: calc(100% - 70px);
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

type Props = {
  headerBorderColor?: string,
  headerBackgroundColor?: string,
  headless?: boolean,
  title?: string,
  children?: React.ChildrenArray<React.Element<any>>
}

export const PlainPanel = ({ headerBorderColor, title, children, headerBackgroundColor, headless }: Props) => {
  return (
    <PanelContainer>
      {(headless) ? null : (
        <PanelHeader headerBackgroundColor={headerBackgroundColor} headerBorderColor={headerBorderColor}>
          <Title>
            {title}
          </Title>
        </PanelHeader>
      )}
      <ContentContainer>
        {children}
      </ContentContainer>
    </PanelContainer>
  )
}