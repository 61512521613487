// @flow
// type imports
import type { AppState } from '../../../types';
import type { Customer, CustomerAnlzRequestListState } from '../../../types/customers';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import Popup from 'reactjs-popup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Query } from '@apollo/client/react/components';
import { customerAnlzRequestListActions } from '../../../ducks/customers';
import { ANLZ_REQUESTS_BY_ORGZ_BO_QUERY } from '../../../utils/graphql/queries';
import commonImages from '../../../resources/images/common';
import {
  Container,
  TitleContainer,
  Title,
  TableControlButton,
  TablePagingInfo,
  FileDownloadLinkButton,
  DefaultCellSpan,
  NoEllipsisCellSpan,
  TableContainer,
  RightButtonsContainer,
  RefreshButton
} from './styled';
import {
  styledTable,
  Spinner,
  ControlledPanel,
  CellFileForm
} from '../../common';
import { REST_API_ROOT } from '../../../utils/rest';

// TODO: when deleting AnlzRequest from backoffice is added in backend, use the checkableTable hoc
const CheckableTable = compose(styledTable)(ReactTable);

type StateProps = CustomerAnlzRequestListState & {
  selectedCustomer: Customer | null
};

type ActionProps = typeof customerAnlzRequestListActions;

type Props = StateProps & ActionProps;

class CustomerAnlzRequestListPanelComponent extends React.Component<Props> {
  _listPageSize: number = 10;
  _refetch: () => Promise<any>;

  render() {
    const {
      currentPage,
      numPages,
      selectedCustomer
    } = this.props;
    return (
      <Query
        query={ANLZ_REQUESTS_BY_ORGZ_BO_QUERY}
        variables={{
          orgzId: selectedCustomer && selectedCustomer.orgz.id,
          page: currentPage,
          size: 10
        }}
        onCompleted={(data) => {
          if (data && data.boAnlzRequestPgByZTime) {
            const {
              nowPage,
              totalPages
            } = data.boAnlzRequestPgByZTime.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          }
        }}
        fetchPolicy='network-only'
        context={{
          dataSourceType: 'api'
        }}
      >
        {({loading, error, data, refetch}) => {
          this._refetch = refetch;
          console.log(error);
          let pageInfo = { nowPage: currentPage, totalPages: numPages};
          if (data && data.boAnlzRequestPgByZTime) {
            console.log({ loading, data });
            pageInfo = data.boAnlzRequestPgByZTime.pageInfo;
          }
          return (
            <ControlledPanel>
              <ControlledPanel.Content>
                <Container>
                  <TitleContainer>
                    <Title>분석 요청 통계</Title>
                  </TitleContainer>
                  <TableContainer>
                    <CheckableTable
                      data={(data && data.boAnlzRequestPgByZTime) ? data.boAnlzRequestPgByZTime.requests : []} // data anlzSrcs
                      loading={loading}
                      page={pageInfo.nowPage}
                      pageSize={this._listPageSize}  // props pageSize
                      minRows={0}
                      manual
                      LoadingComponent={Spinner}
                      pages={pageInfo.totalPages}
                      columns={[
                        {
                          id: 'anlzReq',
                          Header: '분석 요청',
                          minWidth: 550,
                          Cell: rowInfo => {
                            return (
                              <React.Fragment>
                                <span>{moment(rowInfo.value.created).format('YYYY-MM-DD HH:mm')}</span>
                                {(rowInfo.value.anlzSources && rowInfo.value.anlzSources.length > 0 && rowInfo.value.anlzSources[0]) ? (
                                  <React.Fragment>
                                    <FileDownloadLinkButton
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.props.downloadAnlzResource(rowInfo.value.subjectId, encodeURIComponent(rowInfo.value.anlzSources[0].actualFile), rowInfo.value.anlzSources[0].visualFile);
                                      }}
                                      href='#'
                                      download
                                    >
                                      <img src={commonImages.download} alt='Download' />
                                      <img src={commonImages.downloadHover} alt='Download' className="hover-img"/>
                                    </FileDownloadLinkButton>
                                    <DefaultCellSpan flex='1 1 0%'>{rowInfo.value.anlzSources[0].visualFile}</DefaultCellSpan>
                                  </React.Fragment>
                                ) : <DefaultCellSpan flex='1 1 0%'>존재하지 않는 측정기록</DefaultCellSpan>}
                                {(rowInfo.value.surveys && rowInfo.value.surveys.length > 1) ? (
                                  <Popup
                                    trigger={<NoEllipsisCellSpan flex='0.5 1 0%'>{`${rowInfo.value.surveys[0].surveyName}외 ${rowInfo.value.surveys.length - 1}개`}</NoEllipsisCellSpan>}
                                    position='top center'
                                    contentStyle={{
                                      width: 'auto',
                                      minWidth: '40px',
                                      maxWidth: '200px',
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      justifyContent: 'center'
                                    }}
                                    arrowStyle={{
                                      boxShadow: '1.2px 1.2px 1.5px 0 rgba(0,0,0,0.2)',
                                      borderBottom: '1px solid rgb(187,187,187)',
                                      borderRight: '1px solid rgb(187,187,187)',
                                      borderTop: '1px solid transparent',
                                      borderLeft: '1px solid transparent',
                                    }}
                                    on='hover'
                                  >
                                    {() => {
                                      return (
                                        <React.Fragment>
                                          {_.map(rowInfo.value.surveys, (session, idx: number) => (
                                            <span style={{ whiteSpace: 'pre', fontSize: '14px' }} key={session.surveySessionId}>{(idx < rowInfo.value.surveys.length - 1) ? `${session.surveyName}, ` : `${session.surveyName}`}</span>
                                          ))}
                                        </React.Fragment>
                                      );
                                    }}
                                  </Popup>
                                ) : <NoEllipsisCellSpan flex='0.5 1 0%'>{(rowInfo.value.surveys && rowInfo.value.surveys.length === 1) ? `${rowInfo.value.surveys[0].surveyName}` : '-'}</NoEllipsisCellSpan>}
                              </React.Fragment>
                            );
                          },
                          accessor: rowData => ({
                            subjectId: rowData.subjectId,
                            anlzSources: rowData.anlzSources,
                            surveys: rowData.surveys,
                            created: rowData.created
                          }),
                        }, {
                          id: 'anlzResult',
                          Header: '분석완료',
                          minWidth: 350,
                          Cell: rowInfo => {
                            if (!_.isNil(rowInfo.value.actualResultFile)) {
                              return (
                                <React.Fragment>
                                  <span>{moment(rowInfo.value.statusTime).format('YYYY-MM-DD')}</span>
                                  <CellFileForm
                                    disabled={false}
                                    endpointUrl={`${REST_API_ROOT}/anlz/report/${rowInfo.value.reqId}`}
                                    accept='.pdf'
                                    onSubmitDone={(error) => {
                                      if (!error) {
                                        refetch();
                                      }
                                    }}
                                  />
                                  <DefaultCellSpan>{rowInfo.value.visualResultFile}</DefaultCellSpan>
                                </React.Fragment>
                              );
                            } else if (rowInfo.value.anlzStatus === 'CANCEL') {
                              return '분석취소';
                            } else if (rowInfo.value.anlzStatus === 'PROCEEDING') {
                              return '분석중';
                            }
                            return (
                              <CellFileForm
                                disabled={false}
                                endpointUrl={`${REST_API_ROOT}/anlz/report/${rowInfo.value.reqId}`}
                                accept='.pdf'
                                onSubmitDone={(error) => {
                                  if (!error) {
                                    refetch();
                                  }
                                }}
                              />
                            );
                          },
                          accessor: rowData => ({
                            reqId: rowData.id,
                            visualResultFile: rowData.visualResultFile,
                            actualResultFile: rowData.actualResultFile,
                            anlzStatus: rowData.anlzStatus,
                            statusTime: rowData.statusTime
                          }),
                        }, {
                          id: 'extraFile',
                          Header: '추가파일',
                          minWidth: 200,
                          Cell: rowInfo => {
                            const filenamePieces = rowInfo.value.anlzSources && rowInfo.value.anlzSources[0] && rowInfo.value.anlzSources[0].actualFile.split('.');
                            const extension = filenamePieces && filenamePieces[filenamePieces.length - 1];
                            if (extension === 'ytdf' && rowInfo.value.anlzStatus === 'REQUEST' && _.isNil(rowInfo.value.extraFile)) {
                              return (
                                <CellFileForm
                                  disabled={false}
                                  endpointUrl={`${REST_API_ROOT}/anlz/request/${rowInfo.value.reqId}/source/extra`}
                                  accept='.tdt'
                                  onSubmitDone={(error) => {
                                    if (!error) {
                                      refetch();
                                    }
                                  }}
                                />
                              );
                            } else if (!_.isNil(rowInfo.value.extraFile)) {
                              return <DefaultCellSpan>{rowInfo.value.extraFile}</DefaultCellSpan>;
                            }
                            return '-';
                          },
                          accessor: rowData => ({
                            reqId: rowData.id,
                            extraFile: rowData.extraFile,
                            anlzStatus: rowData.anlzStatus,
                            anlzSources: rowData.anlzSources
                          })
                        }
                      ]}
                      showPagination={false}
                      resizable={false}
                      multiSort={false}
                      sortable={false}
                      noDataText=''
                    />
                  </TableContainer>
                </Container>
              </ControlledPanel.Content>
              <ControlledPanel.Footer>
                <TableControlButton onClick={() => this.props.prevPage()}>
                  <img src={commonImages.prev} alt='Previous' />
                  <img className='hover-img' src={commonImages.prevHover} alt='Previous' />
                </TableControlButton>
                <TablePagingInfo><span className='current-page'>{currentPage + 1}</span>/{numPages}</TablePagingInfo>
                <TableControlButton onClick={() => this.props.nextPage()}>
                  <img src={commonImages.next} alt='Next' />
                  <img className='hover-img' src={commonImages.nextHover} alt='Next' />
                </TableControlButton>
                <RightButtonsContainer>
                  <RefreshButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      refetch();
                    }}
                    href='#'
                  >
                    <img src={commonImages.refresh} alt='refresh'/>
                  </RefreshButton>
                </RightButtonsContainer>
              </ControlledPanel.Footer>
            </ControlledPanel>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.customers.customerAnlzRequestList,
    selectedCustomer: state.customers.customerList.selectedCustomer
  };
}

export const CustomerAnlzRquestList = connect(mapStateToProps, customerAnlzRequestListActions)(CustomerAnlzRequestListPanelComponent);