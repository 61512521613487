// @flow
// type imports
import type { AppState } from '../../../types';
import type { SurveySession, SurveySessionListState, Patient } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { Query } from '@apollo/client/react/components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Base64 } from 'js-base64';
import {
  PanelContentContainer,
  SessionListContainer,
  ColoredCell,
} from './styled';
import { surveySessionListActions } from '../../../ducks/analysis';
import { SURVEY_SESSIONS_QUERY } from '../../../utils/graphql/queries';
import {
  selectableTable,
  checkableTable,
  styledTable,
  Spinner
} from '../../common';

const SelectableTable = compose(styledTable, selectableTable, checkableTable)(ReactTable);

type StateProps = SurveySessionListState & {
  selectedPatient: Patient | null
};

type ActionProps = typeof surveySessionListActions;

type Props = StateProps & ActionProps;

class SurveySessionListComponent extends React.Component<Props> {
  _refetch: () => Promise<any>;
  _listPageSize: number = 10;

  componentDidUpdate(prevProps) {
    if (this.props.refetch) {
      this._refetch().then((result) => {
        const {
          nowPage,
          totalPages
        } = result.data.surveySessionSummaries.pageInfo;
        this.props.refetchSurveySessionsDone();
        this.props.setPagingInfo(nowPage, totalPages);
      });
    }
    if (prevProps.selectedPatient && this.props.selectedPatient && prevProps.selectedPatient.id !== this.props.selectedPatient.id) {
      this.props.clearSurveySessionSelection();
    } else if (!this.props.selectedPatient) {
      this.props.clearSurveySessionSelection();
    }
  }

  componentWillUnmount() {
    this.props.clearSurveySessionSelection();
  }

  render() {
    const {
      selectedPatient,
      selectedSurveySession,
      checkedSurveySessions,
      currentPage,
      numPages
    } = this.props;
    return (
      <Query
        query={SURVEY_SESSIONS_QUERY}
        context={{
          dataSourceType: 'api'
        }}
        skip={!selectedPatient}
        fetchPolicy='network-only'
        variables={{
          subjectId: selectedPatient && selectedPatient.id,
          page: currentPage,
          size: this._listPageSize
        }}
        onCompleted={(data) => {
          if(this.props.selectedSurveySession) this.props.clearSurveySessionSelection();
          if (data && data.surveySessionSummaries) {
            const {
              nowPage,
              totalPages
            } = data.surveySessionSummaries.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          }
        }}
      >
        {({ loading, error, data, refetch }) => {
          console.log(error);
          this._refetch = refetch;
          return (
            <PanelContentContainer>
              <SessionListContainer>
                <SelectableTable
                  data={(!selectedPatient) ? [] : ((data && data.surveySessionSummaries) ? data.surveySessionSummaries.surveySessions : [])} // data surveySessions
                  resolveData={data => _.map(data, row => {
                    // const claims = JSON.parse(Base64.decode(row.surveyToken.split('.')[1]));
                    // const surveyExp = moment(row.expiredTime);
                    return {
                      ...row,
                      id: row.sessionId,
                      sessionState: (row.submitted) ? 'SUBMITTED' : 'INCOMPLETE'
                    };
                  })}
                  idKey='sessionId'
                  isRowCheckable={(rowData) => true}
                  loading={(!selectedPatient) ? false : loading}
                  page={(data && data.surveySessionSummaries) ? data.surveySessionSummaries.pageInfo.nowPage : currentPage}
                  pageSize={this._listPageSize}  // props pageSize
                  minRows={0}
                  manual
                  LoadingComponent={Spinner}
                  pages={(data && data.surveySessionSummaries) ? data.surveySessionSummaries.pageInfo.totalPages : numPages}
                  columns={[{
                      id: 'created',
                      Header: '설문작성일',
                      minWidth: 200,
                      accessor: rowData => moment(rowData.createdTime).format('YYYY-MM-DD HH:mm'),
                    }, {
                      id: 'surveyNameDesc',
                      Header: '설문지명',
                      minWidth: 620,
                      accessor: rowData => `${rowData.pubSurveyName}${(rowData.pubSurveyDesc) ? `(${rowData.pubSurveyDesc.split('(')[0]})` : ''}`, // TODO: append survey name and description when query is updated
                    }, {
                      id: 'sessionState',
                      Header: '작성상태',
                      minWidth: 200,
                      accessor: rowData => rowData.sessionState,
                      Cell: (rowInfo) => {
                        if (rowInfo.value === 'SUBMITTED') {
                          return <ColoredCell color='#576881'>완료</ColoredCell>;
                        } else if (rowInfo.value === 'INCOMPLETE') {
                          return <ColoredCell color='#fc5882'>미완료</ColoredCell>
                        }
                        return <ColoredCell color='#9faabf'>만료</ColoredCell>;
                      },
                    }
                  ]}
                  showPagination={false}
                  resizable={false}
                  multiSort={false}
                  sortable={false}
                  selectedRow={selectedSurveySession}
                  checkedRows={checkedSurveySessions}
                  onRowsCheck={(rowIds: string[]) => {
                    this.props.checkSurveySessions(rowIds);
                  }}
                  onRowsUncheck={(rowIds: string[]) => {
                    this.props.uncheckSurveySessions(rowIds);
                  }}
                  onRowSelect={(rowInfo: any) => {
                    const session: SurveySession = rowInfo.original;
                    this.props.selectSurveySession(session);
                  }}
                  noDataText=''
                ></SelectableTable>
              </SessionListContainer>
            </PanelContentContainer>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.analysis.surveySessionList,
    selectedPatient: state.analysis.patientList.selectedPatient
  };
}

export const SurveySessionList: React.ComponentType<{}> = connect(mapStateToProps, surveySessionListActions)(SurveySessionListComponent);
