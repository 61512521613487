// @flow
import type { Patient } from "../../../types/analysis";
// module imports
import * as React from "react";
import { TabbedPanel } from "../../common";
import { AnlzSourceList, CombinedAnlzSourceList } from "./AnlzSourceList";
import { SurveySessionList } from "./SurveySessionList";
import { AnlzRequestList } from "./AnlzRequestList";
import { CompAnlzRequestList } from "./CompAnlzRequestList";
import { TempAnlzRequestList } from "./TempAnlzRequestList";
import { fetchTemperamentAnalysisAuthority } from "../../../utils/rest";
import { connect } from "react-redux";

type Props = {
  selectedPatient: ?Patient,
  selectedTab: string,
  onTabChange: (tabKey: string) => void,
};

const PatientDetailTabsComponent = ({
  enabledTmpAnlz,
  selectedPatient,
  selectedTab,
  onTabChange,
}: Props) => {
  if (enabledTmpAnlz) {
    return (
      <TabbedPanel
        selectedTab={selectedTab}
        tabsMerged={enabledTmpAnlz}
        onTabChange={onTabChange}
      >
        <TabbedPanel.Tab title="측정 기록" tabKey="anlzSources">
          <CombinedAnlzSourceList
            selectedPatient={selectedPatient}
            enabledTmpAnlz={enabledTmpAnlz}
          />
        </TabbedPanel.Tab>
        <TabbedPanel.Tab title="분석 기록" tabKey="anlzRequests">
          <AnlzRequestList selectedPatient={selectedPatient} />
        </TabbedPanel.Tab>
        <TabbedPanel.Tab title="기질 분석 기록" tabKey="tempAnlzRequests">
          <TempAnlzRequestList selectedPatient={selectedPatient} />
        </TabbedPanel.Tab>
      </TabbedPanel>
    );
  } else {
    return (
      <TabbedPanel
        selectedTab={selectedTab}
        tabsMerged={enabledTmpAnlz}
        onTabChange={onTabChange}
      >
        <TabbedPanel.Tab title="측정 기록" tabKey="anlzSources">
          <CombinedAnlzSourceList
            selectedPatient={selectedPatient}
            enabledTmpAnlz={enabledTmpAnlz}
          />
        </TabbedPanel.Tab>
        <TabbedPanel.Tab title="분석 기록" tabKey="anlzRequests">
          <AnlzRequestList selectedPatient={selectedPatient} />
        </TabbedPanel.Tab>
      </TabbedPanel>
    );
  }
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export const PatientDetailTabs: React.ComponentType<{}> = connect(
  mapStateToProps
)(PatientDetailTabsComponent);
