// @flow
import _ from 'lodash';
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { RetryLink } from '@apollo/client/link/retry';
import { loadCurrentUser } from '../localStorage';

const apiGQLLink = new HttpLink({
  uri: `${process.env.REACT_APP_SCAN_API_HOST || 'http://192.168.3.66:4020'}/graphql`
});

const authGQLLink = new HttpLink({
  uri: `${process.env.REACT_APP_AUTH_HOST || 'http://192.168.3.66:5190'}/graphql`
});

const gqlContextLink = setContext((request, prevContext) => {
  const currentUser = loadCurrentUser();
  return {
    headers: {
      ...prevContext.headers,
      authorization: (currentUser && currentUser.tokens.access_token !== '') ? `Bearer ${currentUser.tokens.access_token}` : '',
    }
  };
});

const gqlLink = new RetryLink().split(
  (op) => (op.getContext().dataSourceType === 'api' || op.getContext().dataSourceType === undefined), // default to api endpoint for gql ops
  apiGQLLink,
  authGQLLink,
);

const apiRestLink = new RestLink({
  endpoints: {
    billingServer: {
      uri: `${process.env.REACT_APP_BILLING_API_HOST || 'http://192.168.3.66:6002'}/`,
    },
    auth: {
      uri: `${process.env.REACT_APP_AUTH_HOST || 'http://192.168.3.66:5190'}/`,
    },
    contract: {
      uri: `${process.env.REACT_APP_CONTRACT_API_HOST || 'http://192.168.3.66:6001'}/`,
    }
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export default new ApolloClient({
  addTypeName: true,
  link: ApolloLink.from([gqlContextLink, apiRestLink, gqlLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true
});
