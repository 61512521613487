// @flow
// type imports
import type { AppState } from '../../../types';
import type { DataRow, TableState, ColumnMetadata } from '../../../types/common';
// module imports
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Table as StyledTable,
  Th, Td, Tr,
  DefaultCellSpan,
  Thead, TheadScrollable,
  ZeroStatePromptContainer
} from './styled';
import { actionCreators as tableActions } from '../../../ducks/common/table';
import commonImages from '../../../resources/images/common';
import { Checkbox } from '../Checkbox';

type ColumnProps = {
  colKey: string,
  flex?: string,
  children: string,
  renderCellContent?: (dataRow: DataRow, colKey: string) => React.Node
}

const Column = ({ colKey, flex, children }: ColumnProps) => {
  return <Th key={colKey} flex={flex}>{children}</Th>;
};

export { Column as TableColumn };

type OwnProps = {|
  children: React.ChildrenArray<React.Element<typeof Column>>,
  name: string,
  rows: DataRow[],
  selectable?: boolean,
  checkable?: boolean,
  emptyPrompt?: string,
  parentStateSelector: (state: AppState) => any,
  onRowSelect?: (row: DataRow) => void
|};

type StateProps = TableState;

type ActionProps = typeof tableActions;

type Props = OwnProps & StateProps & ActionProps;

type State = {
  bodyScrollable: boolean,
}

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_ENTER = 13;

class TableComponent extends React.Component<Props, State> {
  static defaultProps = {
    selectable: false
  };
  _table: ?HTMLTableElement;
  _tbody: ?HTMLTableSectionElement;
  _focusedTr: ?HTMLTableRowElement;

  constructor(props: Props) {
    super(props);
    const columns: ColumnMetadata[] = React.Children.map(props.children, (child) => {
      return {
        key: child.props.colKey,
        displayName: child.props.children,
        flex: child.props.flex,
        renderCellContent: child.props.renderCellContent
      };
    });
    props.setColumns(props.name, columns);
    this.state = {
      bodyScrollable: false
    };
  }

  
  componentWillMount() {
    this.setRows(this.props.rows);
  }

  componentWillReceiveProps(nextProps) {
    const { dataSource } = this.props;
    if ((dataSource && this.didRowsUpdate(dataSource.rows, nextProps.rows)) || _.isNull(dataSource)) {
      this.setRows(nextProps.rows);
    }
    if (this._tbody && this._tbody.offsetHeight < this._tbody.scrollHeight) {
      this.setState(() => {
        return {
          bodyScrollable: true
        }
      });
    }
    if (this._tbody && this.state.bodyScrollable && this.props.currentPage !== nextProps.currentPage) {
      this._tbody.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._focusedTr && this.props.focusedRowIdx !== -1 && prevProps.focusedRowIdx !== this.props.focusedRowIdx) {
      this._focusedTr.focus();
    }
  }
  

  didRowsUpdate(oldRows: DataRow[], newRows: DataRow[]) {
    if (oldRows.length !== newRows.length) {
      return true;
    }
    return _.reduce(
      _.map(newRows, (newRow, idx) => newRow.rowId() !== oldRows[idx].rowId()),
      (rowChange, ret) => ret || rowChange,
      false
    );
  }
  
  onRowClick(rowId: string, rowIdx: number) {
    const {
      name,
      selectedRowId,
      selectable,
      dataSource,
      onRowSelect
    } = this.props;
    if (selectable && selectedRowId !== rowId) {
      this.props.selectRow(name, rowId);
    }
    if (onRowSelect && dataSource) {
      onRowSelect(dataSource.getRow(rowId));
    }
  }

  handleKeyDownFocused(event: SyntheticKeyboardEvent<HTMLTableRowElement>) {
    const { pages, currentPage, focusedRowIdx } = this.props;
    const { rows } = pages[currentPage];
    const numRows = rows.length;
    const focusedRowId = rows[focusedRowIdx];
    let rowIdxToFocus = -1;
    switch(event.keyCode) {
      case KEY_UP:
        event.preventDefault();
        rowIdxToFocus = (focusedRowIdx - 1 + numRows) % numRows;
        break;
      case KEY_DOWN:
        event.preventDefault();
        rowIdxToFocus = (focusedRowIdx + 1) % numRows;
        break;
      case KEY_ENTER:
        event.preventDefault();
        this.onRowClick(focusedRowId, focusedRowIdx);
        break;
      default: break;
    }
    if (rowIdxToFocus !== -1) {
      this.props.focusRow(this.props.name, rowIdxToFocus);
    }
  }

  setRows(rows: DataRow[]) {
    this.props.setRows(this.props.name, rows);
  }

  renderHead(bodyScrollable: boolean) {
    let TheadToRender = Thead;
    if (bodyScrollable) {
      TheadToRender = TheadScrollable;
    }
    if (!this.props.checkable) {
      return <TheadToRender><tr>{this.props.children}</tr></TheadToRender>;
    }
    const {
      pages,
      checkedRowIds,
      currentPage
    } = this.props;
    return (
      <TheadToRender>
        <tr>
          {[
            <Th
              key='_check'
              flex='0 0 58px'
            >
              <Checkbox
                disabled={pages.length < 1 || pages[currentPage].checkableRows.length < 1}
                checked={pages.length > 0 && _.difference(pages[currentPage].checkableRows, checkedRowIds).length === 0}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    this.props.checkRows(this.props.name, pages[currentPage].checkableRows);
                  } else {
                    this.props.uncheckRows(this.props.name, pages[currentPage].checkableRows);
                  }
                }}
                checkedImgSrc={commonImages.checked}
                uncheckedImgSrc={commonImages.check}
              />
            </Th>,
            ...React.Children.toArray(this.props.children)
          ]}
        </tr>
      </TheadToRender>
    );
  }

  renderCells(row: DataRow, columns: ColumnMetadata[]) {
    return _.map(columns, (col) => {
      return (
        <Td key={`cell_${row.rowId()}_${col.key}`} flex={col.flex}>
          {(col.renderCellContent) ?
            col.renderCellContent(row, col.key) :
            <DefaultCellSpan>{row.data[col.key]}</DefaultCellSpan>
          }
        </Td>);
    });
  }

  renderBody() {
    const {
      dataSource,
      columns,
      pages,
      currentPage,
      focusedRowIdx,
      emptyPrompt,
      checkedRowIds,
      name
    } = this.props;
    if (dataSource && pages[currentPage]) {
      const { rows, checkableRows } = pages[currentPage];
      return _.map(rows, (rowId: string, idx: number) => {
        return (
          <Tr
            key={`row_${rowId}`}
            className={(this.props.selectedRowId === rowId) ? 'selected' : ''}
            onClick={() => this.onRowClick(rowId, idx)}
            tabIndex={(focusedRowIdx === idx || (focusedRowIdx === -1 && idx === 0)) ? 0 : -1}
            onFocus={() => this.props.focusRow(this.props.name, idx)}
            ref={(ref) => {
              if (focusedRowIdx === idx) {
                this._focusedTr = ref;
              }
            }}
            onKeyDown={(focusedRowIdx === idx) ? this.handleKeyDownFocused.bind(this) : null}
          >
            {
              (this.props.checkable) ?
              (
                <Td
                  key={`_check_${rowId}`}
                  flex='0 0 58px'
                >
                  {
                    (_.includes(checkableRows, rowId)) ?
                    <Checkbox
                      checked={_.includes(checkedRowIds, rowId)}
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          this.props.checkRows(name, [rowId]);
                        } else {
                          this.props.uncheckRows(name, [rowId])
                        }
                      }}
                      checkedImgSrc={commonImages.checked}
                      uncheckedImgSrc={commonImages.check}
                      stopClickPropagation
                    /> :
                    null
                  }
                </Td>
              ) :
              null
            }
            {this.renderCells(dataSource.getRow(rowId), columns)}
          </Tr>
        );
      });
    } else if (emptyPrompt) {
      return (
        <ZeroStatePromptContainer><td>{emptyPrompt}</td></ZeroStatePromptContainer>
      )
    }
  }

  render() {
    return (
      <StyledTable ref={ ref => this._table = ref}>
        {this.renderHead(this.state.bodyScrollable)}
        <tbody ref={ ref => this._tbody = ref }>
          {this.renderBody()}
        </tbody>
      </StyledTable>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const parentState = ownProps.parentStateSelector(state);
  if (parentState.hasOwnProperty(`${ownProps.name}Table`)) {
    const tableState: TableState = parentState[`${ownProps.name}Table`];
    return tableState;
  }
  return {
    dataSource: null,
    columns: [],
    selectedRowId: '',
    focusedRowIdx: -1,
    checkedRowIds: [],
    currentPage: -1,
    pages: []
  };
};

export const Table: React.ComponentType<OwnProps> = connect(mapStateToProps, tableActions)(TableComponent);
