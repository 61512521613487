// @flow
import styled from 'styled-components';
import { EllipsisSpan } from '../../common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex: 1 1 0%;
  width: 100%;
  padding: 0 30px 30px;
`;

export const SourceInfoContainer = styled.div`
  display: table;
  height: 130px;
  width: 100%;
  border-collapse: collapse;
  border: solid 1px #bcc9d8;
  > div {
    display: table-row;
    > div {
      border: solid 1px #bcc9d8;
      display: table-cell;
      vertical-align: top;
    }
  }
`;

export const SourceFileInfoCell = styled.div`
  width: 263px;
`;

export const SourceFileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const SourceFileName = styled(EllipsisSpan)`
  margin-top: 20px;
  max-width: 100%;
  text-align: center;
  color: #576881;
  font-size: 16px;
  padding: 0 10px;
`;

export const SourceFileSize = styled.span`
  color: #94aabf;
  margin-top: 9px;
`;

export const SourcePropertiesCell = styled.div``;

export const SourcePropertiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const SourcePropertiesList = styled.ul`
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  font-size: 15px;
  li {
    padding-left: 70px;
    display: flex;
    &::before {
      content: "•";
      color: #00bbf9;
    }
  }
`;

export const SourcePropertyName = styled.span`
  color: #576881;
  font-weight: bold;
  padding-left: 15px;
  flex: ${props => props.longName ? '2 1 0%' : '1 1.2 0%'};
`;

export const SourcePropertyData = styled.span`
  color: #7e8ca2;
  flex: ${props => props.longName ? '1 2 0%' : '1.2 1 0%'};
`;

export const SourcePropertyDataLong = styled(EllipsisSpan)`
  color: #7e8ca2;
  min-width: 0;
  flex: ${props => props.longName ? '1 2 0%' : '1.2 1 0%'};
`