// @flow
import styled from 'styled-components';
import { EllipsisSpan } from '../EllipsisSpan';

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #576881;
  font-size: 18px;
  > thead {
    display: flex;
    flex: 0 0 60px;
    background-color: #f6f8f9;
    > tr {
      display: flex;
      flex: 1;
      border: solid 1px transparent;
      > th {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &:not(:last-child)::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 16px;
          background-color: #bcc9d8;
          overflow: hidden;
          right: 0;
        }
      }
    }
  }
  > tbody {
    display: flex;
    height: 0;
    flex: 1 1 0%;
    flex-direction: column;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-corner {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(66,82,110,0.16);
      transition: all .1s ease-in-out;
      border: 3px solid transparent;
      border-radius: 8px;
      background-clip: content-box;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(66,82,110,0.36);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(66,82,110,0.56);
    }
    > tr {
      display: flex;
      border: solid 1px transparent;
      > td {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const Thead = styled.thead``;

export const TheadScrollable = styled.thead`
  > tr:after {
    content: '';
    overflow-y: scroll;
    visibility: hidden;
    height: 0;
  }
`

export const Th = styled.th`
  flex: ${props => props.flex || '1 1 0%'};
`;

export const Tr = styled.tr`
  cursor: pointer;
  flex: 0 0 52px;
  &:focus {
    outline: none;
    border-color: #4a90e2;
  }
  &.selected, &.selected:hover, &:hover {
    background-color: #ebf0f4;
  }
`;

export const Td = styled.td`
  flex: ${props => props.flex || '1 1 0%'};
`

export const DefaultCellSpan = styled(EllipsisSpan)`
  text-align: center;
  padding: 0 10px;
  width: 0;
  flex: 1 1 0%;
`;

export const ZeroStatePromptContainer = styled.tr`
  flex: 1 1 0%;
  display: flex;
  > td {
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #bcc9d8;
  } 
`;