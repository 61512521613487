// @flow
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
`;

const HeaderControlContainer = styled(ControlContainer)`
  height: 60px;
  margin-bottom: 8px;
  flex: 0 0 60px;
`;

type SharedProps = {
  children?: React.Node,
  style?: any
};

const HeaderControl = ({ children, style }: SharedProps) => {
  return <HeaderControlContainer style={style}>{children}</HeaderControlContainer>;
};

const MainPanelContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1 1 0%;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 0;
`;

const PanelContent = ({ children }: SharedProps) => {
  return <MainPanelContainer>{children}</MainPanelContainer>
}

const FooterControlContainer = styled(ControlContainer)`
  height: 45px;
  margin-top: 15px;
  flex: 0 0 45px;
  align-items: center;
  >*:not(:first-child) {
    margin-left: 10px;
  }
`;

const FooterControl = ({ children }: SharedProps) => {
  return <FooterControlContainer>{children}</FooterControlContainer>
};

type Props = {
  children?: React.ChildrenArray<React.Element<typeof HeaderControl | typeof PanelContent | typeof FooterControl>>,
};

class ControlledPanel extends React.Component<Props> {
  static Header = HeaderControl;
  static Content = PanelContent;
  static Footer = FooterControl;

  render() {
    return (
      <Container>
        {this.props.children}
      </Container>
    )
  }
}

export { ControlledPanel };