// @flow
import _ from 'lodash';
import styled from 'styled-components';
import * as React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Legend,
  Tooltip
} from 'recharts';

type Props = {
  data: any,
  datasetDefs: { key: string, name: string, color: string }[],
  title?: string,
  tooltipContent: React.Element<*>,
  tooltipOffsetX: number,
  tooltipOffsetY: number,
  height: number,
  chartMargin?: any,
  showLegend?: boolean,
  xTick?: React.Element<*>
};

type State = {
  activeTopDotY: number,
  activeTooltipIndex: number
};

class ComposedAreaChart extends React.Component<Props, State> {
  _areas: any;

  static defaultProps = {
    showLegend: true
  };

  constructor(props: Props) {
    super(props);
    this._areas = {};
    this.state = {
      activeTooltipIndex: -1,
      activeTopDotY: 0
    }
  }

  onChartMouseMove(chart: any) {
    if (chart.isTooltipActive && this.state.activeTooltipIndex !== chart.activeTooltipIndex) {
      let minY = this.props.height;
      _.forIn(this._areas, (area) => {
        const point = area.props.points[chart.activeTooltipIndex];
        if (point && minY > point.y) {
          minY = point.y;
        }
      });
      this.setState({ activeTopDotY: minY, activeTooltipIndex: chart.activeTooltipIndex });
    }
  }

  renderLegend(legendProps: any) {
    const { payload } = legendProps;
    const LegendList = styled.ul`
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        margin-top: 5px;
        &:first-child {
          margin-top: 0;
        }
        i {
          display: inline-block;
          width: 19px;
          height: 4px;
          border-radius: 2px;
        }
        span {
          color: #94aabf;
          font-size: 15px;
          margin-left: 8px;
        }
        * {
          vertical-align: middle;
        }
      }
    `;
    const LegendIcon = styled.i`
      background-color: ${props => props.color};
    `;
    return (
      <LegendList>
        {_.map(payload, (entry, index: number) => {
          return (
            <li key={`line_legend_${entry.dataKey}`}>
              <LegendIcon color={entry.color} />
              <span>{entry.value}</span>
            </li>
          );
        })}
      </LegendList>
    )
  }
  
  render() {
    const {
      data,
      datasetDefs,
      title,
      tooltipContent,
      tooltipOffsetX,
      tooltipOffsetY,
      showLegend,
      chartMargin,
      xTick,
      height
    } = this.props;
    return (
      <ResponsiveContainer width='100%' height={height}>
        <ComposedChart
          data={data}
          margin={chartMargin || { top: 50, bottom: 25, left: 30, right: 50 }}
          onMouseMove={this.onChartMouseMove.bind(this)}
        >
          <defs>
            <filter id='dropshadow' x='-5%' y='-5%' width='200%' height='200%'>
              <feGaussianBlur in='SourceAlpha' stdDeviation='1'/>
              <feOffset dx='1' dy='1' result='offsetblur'/>
              <feComponentTransfer>
                <feFuncA type='linear' slope='0.3'/>
              </feComponentTransfer>
              <feMerge>
                <feMergeNode/>
                <feMergeNode in='SourceGraphic'/>
              </feMerge>
            </filter>
          </defs>
          {title ? (
            <text x={65} y={50} textAnchor='start' alignmentBaseline='hanging' fontSize='20px' fill='#576881'>
              {title}
            </text>
          ) : null}
          <XAxis dataKey='name' stroke='#d0dcea' tick={xTick || { fill: '#94aabf' }} tickLine={false} />
          <YAxis domain={[dataMin => dataMin * 0.7, _.max([5, dataMax => dataMax * 1.3])]} scale='linear' interval={0} stroke='#d0dcea' tickLine={false} />
          <Legend
            layout='vertical'
            verticalAlign='top'
            align='center'
            wrapperStyle={{ right: '50px', opacity: (showLegend) ? '1' : '0', minHeight: '43px' }}
            content={this.renderLegend.bind(this)}
          />
          <Tooltip
            isAnimationActive={false}
            position={{ y: this.state.activeTopDotY + tooltipOffsetY }}
            offset={tooltipOffsetX}
            cursor={false}
            content={tooltipContent}
          />
          {_.map(datasetDefs, (def, idx: number) => {
            return (
              <Area
                type='linear'
                dataKey={def.key}
                key={`${def.key}_area`}
                name={def.name}
                stroke={def.color}
                strokeWidth={3}
                fill={def.color}
                fillOpacity='0.15'
                animationDuration={500}
                dot={{ stroke: def.color, strokeWidth: 2, fill: '#fff', fillOpacity: 1 }}
                activeDot={{ fill: def.color, strokeWidth: 0, r: 5, style: { filter: 'url(#dropshadow)' } }}
                ref={ref => this._areas[idx] = ref}
              />
            );
          })}
          
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export { ComposedAreaChart };