// @flow
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { Text } from 'recharts';
import { Query } from '@apollo/client/react/components';
import { ComposedAreaChart, ComposedBarChart } from '../../common';
import {
  TooltipContentContainer,
  TooltipLeftContainer,
  TooltipRightContainer,
  ChartSpinnerContainer,
  ChartContainer,
  ChartDateNextButton,
  ChartDatePrevButton
} from './styled';
import { STAT_WEEKLY_SBJT_QUERY, STAT_MONTHLY_SBJT_QUERY } from '../../../utils/graphql/queries';
import { Spinner } from '../../common';
import commonImages from '../../../resources/images/common';

class TooltipContent extends React.Component<{ type?: string, payload?: Array<any>, label?: string, active?: boolean, coordinate?: any }> {
  render() {
    const {
      active,
      payload
    } = this.props;
    if (active && payload && payload.length > 0 && moment(payload[0].payload.statTime.from).isBefore(moment())) {
      return (
        <TooltipContentContainer>
          <TooltipLeftContainer backgroundColor='#4a90e2'>
            <span className='dataset-name'>{payload[0].name}</span>
            <span className='dataset-value'>{payload[0].value}</span>
          </TooltipLeftContainer>
          <TooltipRightContainer>
            <span className='dataset-name'>{payload[1].name}</span>
            <span className='dataset-value'>{payload[1].value}</span>
          </TooltipRightContainer>
        </TooltipContentContainer>
      );
    }
    return null;
  }
}

const Tick = (props: any) => {
  const { value } = props.payload;
  const wkNum = value.substring(0, 2);
  const dates = value.substring(2);
  return (
    <React.Fragment>
      <Text
        {...props}
        fill='#94aabf'
        textAnchor='end'
        dx={-30}
      >
        {wkNum}
      </Text>
      <Text
        {...props}
        fill='#94aabf'
        textAnchor='start'
        dx={-30}
        fontWeight={300}
      >
        {dates}
      </Text>
    </React.Fragment>
  );
}

class PatientStats extends React.Component<{}, { activeTopDotY: number, weeklyFrom: moment, monthlyFrom: moment }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      activeTopDotY: 0,
      weeklyFrom: moment().startOf('isoWeek').subtract(4, 'w'),
      monthlyFrom: moment().startOf('year')
    }
  }

  render() {
    const monthLabels = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const datasetDefs = [
      { key: 'newSbjts', name: '신규', color: '#4a90e2' },
      { key: 'totalSbjts', name: '전체', color: '#576881' },
    ];
    return (
      <React.Fragment>
        <Query
          query={STAT_WEEKLY_SBJT_QUERY}
          pollInterval={5000}
          variables={{
            from: this.state.weeklyFrom.toISOString(true)
          }}
          context={{
            dataSourceType: 'api'
          }}
        >
          {({ loading, data, error }) => {
            if (data && data.statSbjtByZWeek) {
              return (
                <ChartContainer>
                  <ComposedAreaChart
                    data={_.map(data.statSbjtByZWeek, (stat, idx) => {
                      const fromDate = moment(stat.statTime.from).format('MM/DD');
                      const toDate = moment(stat.statTime.to).format('MM/DD');
                      return {
                        ...stat,
                        name: `${fromDate}-${toDate}`
                      };
                    })}
                    datasetDefs={datasetDefs}
                    title='Weekly'
                    tooltipContent={<TooltipContent />}
                    height={419}
                    tooltipOffsetX={-82}
                    tooltipOffsetY={-120}
                    showLegend
                    xTick={<Tick />}
                  />
                  <ChartDatePrevButton
                    onClick={() => {
                      this.setState({
                        weeklyFrom: this.state.weeklyFrom.clone().subtract(5, 'weeks')
                      })
                    }}
                  >
                    <img src={commonImages.prev} alt='PrevWeeks' />
                    <img className='hover-img' src={commonImages.prevHover} alt='PrevWeeks' />
                  </ChartDatePrevButton>
                  <ChartDateNextButton
                    onClick={() => {
                      this.setState({
                        weeklyFrom: this.state.weeklyFrom.clone().add(5, 'weeks')
                      })
                    }}
                    disabled={this.state.weeklyFrom.clone().add(5, 'weeks').isAfter(moment())}
                  >
                    <img src={commonImages.next} alt='NextWeeks' />
                    <img className='hover-img' src={commonImages.nextHover} alt='NextWeeks' />
                  </ChartDateNextButton>
                </ChartContainer>
              );
            }
            return <ChartSpinnerContainer><Spinner /></ChartSpinnerContainer>;
          }}
        </Query>
        <Query
          query={STAT_MONTHLY_SBJT_QUERY}
          pollInterval={5000}
          variables={{
            year: this.state.monthlyFrom.toISOString(true),
            months: 12
          }}
          context={{
            dataSourceType: 'api'
          }}
        >
          {({ loading, data, error }) => {
            if (data && data.statSbjtByZMonth) {
              return (
                <ChartContainer>
                  <ComposedBarChart
                    data={_.map(data.statSbjtByZMonth, (stat, idx) => {
                      if (moment(stat.statTime.from).isBefore(moment())) {
                        return {
                          ...stat,
                          totalSbjts: stat.totalSbjts,
                          newSbjts: stat.newSbjts,
                          name: monthLabels[idx]
                        };
                      }
                      return {
                        ...stat,
                        totalSbjts: 0,
                        newSbjts: 0,
                        name: monthLabels[idx]
                      };
                    })}
                    datasetDefs={datasetDefs}
                    title={`Monthly (${this.state.monthlyFrom.format('YYYY')})`}
                    tooltipContent={<TooltipContent />}
                    height={419}
                    tooltipOffsetX={-82}
                    tooltipOffsetY={-120}
                    dropRight={(this.state.monthlyFrom.year() >= moment().year()) ? moment().month() : 11}
                  />
                  <ChartDatePrevButton
                    onClick={() => {
                      this.setState({
                        monthlyFrom: this.state.monthlyFrom.clone().subtract(1, 'years')
                      })
                    }}
                  >
                    <img src={commonImages.prev} alt='PrevYear' />
                    <img className='hover-img' src={commonImages.prevHover} alt='PrevYear' />
                  </ChartDatePrevButton>
                  <ChartDateNextButton
                    onClick={() => {
                      this.setState({
                        monthlyFrom: this.state.monthlyFrom.clone().add(1, 'years')
                      })
                    }}
                    disabled={this.state.monthlyFrom.clone().add(1, 'years').isAfter(moment())}
                  >
                    <img src={commonImages.next} alt='NextYear' />
                    <img className='hover-img' src={commonImages.nextHover} alt='NextYear' />
                  </ChartDateNextButton>
                </ChartContainer>
              );
            }
            return <ChartSpinnerContainer><Spinner /></ChartSpinnerContainer>;
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export { PatientStats };
