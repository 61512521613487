// @flow
import * as React from 'react';
import styled from 'styled-components';
import { ConfirmPopup } from '../ConfirmPopup';

const SwitchContainer = styled.label`
  width: 40px;
  height: 22px;
  position: relative;
  display: inline-block;
  input {
    display: none;
  }
  input:checked + span {
    background-color: #00bbf9;
  }
  input:checked + span::before {
    transform: translateX(18px);
  }
`;

const Slider = styled.span`
  border-radius: 22px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d0dcea;
  transition: .2s;
  &::before {
    border-radius: 50%;
    box-shadow: 2px 5px 5px 0 rgba(0, 0, 0, 0.07);
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
  }
`;

type Props = {
  checked: boolean,
  withConfirm?: boolean,
  onConfirm?: () => any,
  confirmMessage? : React.Node,
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => any
}

export const ToggleSwitch = React.forwardRef((props: Props, ref) => {
  if (props.withConfirm) {
    const {
      onConfirm,
      confirmMessage,
      checked,
      onChange
    } = props;
    return (
      <ConfirmPopup
        trigger={
          <SwitchContainer>
            <input onClick={(e) => e.stopPropagation()} type='checkbox' ref={ref} checked={checked} onChange={onChange} />
            <Slider/>
          </SwitchContainer>
        }
        onConfirm={onConfirm}
      >
        {confirmMessage}
      </ConfirmPopup>
    );
  }
  return (
    <SwitchContainer onClick={(e) => e.stopPropagation()}>
      <input type='checkbox' ref={ref} checked={props.checked} onChange={props.onChange} />
      <Slider/>
    </SwitchContainer>
  );
});
