// @flow
import * as React from 'react';
import { AnalysisStats } from './AnalysisStats';
import { TabbedPanel } from '../../common';
import { PatientStats } from './PatientStats';

class StatsChartPanel extends React.Component<{}> {
  render() {
    return (
       <TabbedPanel selectedTab='analysis'>
        <TabbedPanel.Tab
          title='사용 통계'
          tabKey='analysis'
        >
          <AnalysisStats />
        </TabbedPanel.Tab>
        <TabbedPanel.Tab
          title='대상자 통계'
          tabKey='patient'
        >
          <PatientStats />
        </TabbedPanel.Tab>
      </TabbedPanel>
    )
  }
}

export { StatsChartPanel };
