// @flow
// module imports
import { Query } from "@apollo/client/react/components";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { BillingItemsPanel } from "../../components/billing";
import { BillingPlainPanel } from "../../components/billing/BillingPlainPanel";
import BillingYbrainInfo from "../../components/billing/BillingYbrainInfo";
import { AutoPaymentInfoPopup } from "../../components/billing/detail/AutoPaymentInfoPopup";
import { PaymentHistoryPopup } from "../../components/billing/detail/PaymentHistoryPopup";

import {
  BillingDateRangeInput,
  Page,
  PanelGrid,
} from "../../components/common";
import { billingItemDetailActions } from "../../ducks/billing";
import type { AppState } from "../../types";
import BootPayUtils from "../../utils/BootPayUtils";
import {
  BILLING_DATA_ALL_QUERY,
  BILLING_KEY_BY_CONTRACT_QUERY,
  PAYMENT_HISTORY_BY_CONTRACT_QUERY,
} from "../../utils/graphql/queries";
import { toCamelCase } from "../../utils/rest";

type OwnProps = {
  startDate: moment,
  currentUser: CurrentUserState,
  orgzId: number,
  billingKeyLoading: boolean,
  receiptLoading: boolean,
};
type ActionProps = typeof billingItemDetailActions;

type Props = OwnProps & ActionProps;

type State = {
  paymentHistoryOpen: boolean,
  autoPaymentInfoOpen: boolean,
};

class BillingComponent extends React.Component<Props, State> {
  _refetchBillingKey = null;
  _refetchPaymentHistory = null;
  refetchBills = null;

  constructor(props) {
    super(props);
    this.state = {
      paymentHistoryOpen: false,
      autoPaymentInfoOpen: false,
    };
  }

  handleRefresh() {
    if (this.refetchBills) {
      this.refetchBills();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.billingKeyLoading &&
      !this.props.billingKeyLoading &&
      this._refetchBillingKey
    ) {
      // refetch billing key
      this._refetchBillingKey();
    }
    if (
      prevProps.receiptLoading &&
      !this.props.receiptLoading &&
      this._refetchPaymentHistory
    ) {
      // refetch billing key
      this._refetchPaymentHistory();
    }
  }

  transformBillingKeyInfo(data: any, orgzId: number): any {
    let camelCased = toCamelCase(data);
    console.log(camelCased);
    return {
      ...camelCased,
      orgzId,
    };
  }

  makeButtonInfo(billingKeyData) {
    if (billingKeyData) {
      if (billingKeyData.billingKeyInfo && !!billingKeyData.billingKeyInfo[0]) {
        return [
          {
            title: "정기 결제 정보",
            onClick: () => {
              // open automatic payment info popup
              this.setState({ autoPaymentInfoOpen: true });
            },
          },
          {
            title: "결제 내역",
            onClick: () => {
              // open automatic payment info popup
              this.setState({ paymentHistoryOpen: true });
            },
          },
        ];
      } else {
        return [
          {
            title: "정기 결제 신청",
            onClick: () => {
              try {
                BootPayUtils.requestBillingKey(
                  this.props.currentUser,
                  (data) => {
                    this.props.postBillingKey(
                      this.transformBillingKeyInfo(data, this.props.orgzId)
                    );
                  }
                );
              } catch (error) {
                console.log(error);
              }
            },
          },
          {
            title: "정기 결제 정보",
            onClick: () => {
              // open automatic payment info popup
              this.setState({ autoPaymentInfoOpen: true });
            },
          },
          {
            title: "결제 내역",
            onClick: () => {
              // open automatic payment info popup
              this.setState({ paymentHistoryOpen: true });
            },
          },
        ];
      }
    }
    return [];
  }

  render() {
    return (
      <Query
        query={BILLING_KEY_BY_CONTRACT_QUERY}
        variables={{
          orgzId: this.props.orgzId,
        }}
        fetchPolicy="network-only"
      >
        {({ data: billingKeyData, refetch: refetchBillingKey }) => {
          this._refetchBillingKey = refetchBillingKey;
          let rightButtons = this.makeButtonInfo(billingKeyData);

          return (
            <Page
              title="Billing"
              subtitle="전체 정산 내역을 확인해 보세요"
              contentMinWidth={1175}
              rightButtons={rightButtons}
            >
              <PanelGrid.Grid>
                <PaymentHistoryPopup
                  open={this.state.paymentHistoryOpen}
                  onClose={() => this.setState({ paymentHistoryOpen: false })}
                  orgzId={this.props.orgzId}
                />
                <AutoPaymentInfoPopup
                  open={this.state.autoPaymentInfoOpen}
                  billingKeyInfo={
                    billingKeyData &&
                    billingKeyData.billingKeyInfo &&
                    billingKeyData.billingKeyInfo[0]
                  }
                  onClose={() => this.setState({ autoPaymentInfoOpen: false })}
                  onUnsubscribe={(orgzId: number) => {
                    console.log("unsubscribe", orgzId);
                    this.props.deleteBillingKey(orgzId);
                  }}
                />
                <BillingDateRangeInput
                  parentStateSelector={(state) => state.billing}
                  maxDate={moment().add(1, "M")}
                  name="billing"
                />
                <Query
                  query={BILLING_DATA_ALL_QUERY}
                  variables={{
                    orgzId: this.props.orgzId,
                    year: this.props.startDate.format("YYYY"),
                    month: Number(this.props.startDate.format("MM")),
                  }}
                  fetchPolicy="network-only"
                >
                  {({ loading, error, data, refetch }) => {
                    this.refetchBills = refetch;
                    if (error) return `Error! ${error.message}`;

                    if (!data || data.totalBillingInfo === undefined)
                      return null;

                    let monthlyTotalBillingInfo = data.totalBillingInfo;

                    return (
                      <React.Fragment>
                        <PanelGrid.Row style={{ height: "286px" }}>
                          <BillingPlainPanel
                            title={"전체 정산내역"}
                            year={this.props.startDate.format("YYYY")}
                            month={Number(this.props.startDate.format("MM"))}
                            currentUser={this.props.currentUser}
                            count={monthlyTotalBillingInfo.totalBillsCnt}
                            headerBorderColor="#00bbf9"
                            headerBackgroundColor="#f6f9fc"
                            monthlyTotalBillingInfo={monthlyTotalBillingInfo}
                            history={this.props.history}
                            onRefresh={() => this.handleRefresh()}
                          />
                        </PanelGrid.Row>
                        <PanelGrid.Row style={{ height: "480px" }}>
                          <BillingItemsPanel
                            loading={loading}
                            billingItems={
                              monthlyTotalBillingInfo &&
                              monthlyTotalBillingInfo.monthlyBills
                                ? monthlyTotalBillingInfo.monthlyBills
                                : []
                            }
                            currentPage={this.props.currentPage}
                            numPages={this.props.numPages}
                            noDataText={""}
                            history={this.props.history}
                          />
                        </PanelGrid.Row>
                      </React.Fragment>
                    );
                  }}
                </Query>
                <PanelGrid.Row>
                  <PanelGrid.Col>
                    <BillingYbrainInfo />
                  </PanelGrid.Col>
                </PanelGrid.Row>
              </PanelGrid.Grid>
            </Page>
          );
        }}
      </Query>
    );
  }
}
const mapStateToProps = (state: AppState): StateProps => {
  return {
    startDate: state.billing.billingDateRange.startDate,
    orgzId: state.auth.currentUser.orgz_id,
    currentUser: state.auth.currentUser,
    billingKeyLoading: state.billing.billingItemDetail.billingKeyLoading,
    receiptLoading: state.billing.billingItemDetail.receiptLoading,
  };
};

export const Billings = connect(mapStateToProps, {
  ...billingItemDetailActions,
})(BillingComponent);
