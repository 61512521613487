// @flow
import axios from 'axios';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

type Props = {
  src: string,
  containerComponent?: React.ComponentType<*>
};

type State = {
  loading: boolean,
  mdSrc: string
};

class MarkDownRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      mdSrc: ''
    };
  }

  loadMDSource() {
    this.setState({ loading: true });
    axios.get(this.props.src).then((response) => {
      console.log(response);
      this.setState({
        loading: false,
        mdSrc: response.data
      });
    });
  }

  componentDidMount() {
    this.loadMDSource();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.src !== this.props.src) {
      this.loadMDSource();
    }
  }

  render() {
    const { containerComponent } = this.props;
    if (!!containerComponent) {
      return (
        <containerComponent>
          <ReactMarkdown source={this.state.mdSrc} />
        </containerComponent>
      );
    }
    return <ReactMarkdown source={this.state.mdSrc} />;
  }
}

export { MarkDownRenderer };