// @flow
// type imports
import type { AppState } from "../../../types";
import type {
  LoginFormState,
  UserCredentials,
  CurrentUserState,
} from "../../../types/auth";
// module imports
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Mutation } from "@apollo/client/react/components";
import {
  TopContainer,
  Container,
  FormContainer,
  FormRow,
  Title,
  Input,
  LoginButton,
  SpinnerContainer,
  IdPwdButton,
  LoginFormFooter,
  LogoLink,
  ContactContainer,
  Contact,
  CopyRightContainer,
  CopyRight,
  SegmentedControl,
  SegmentButton,
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  ResponseMessageContainer,
  ResponseMessage,
  ErrorMessage,
  RecoverIdHeading,
  RecoverIdMessage,
  LoginErrorSpan,
  AgreementsContainer,
  AgreementsTitle,
  AgreementsBodyContainer,
  AgreementSectionContainer,
  AgreementContentContainer,
  AgreementNotice,
  AgreementsButtonContainer,
  AgreeButton,
  NotAgreeButton,
  LoginFormBottomContainer,
} from "./styled";
import { Spinner, Checkbox } from "../../common";
import { loginFormActions, currentUserActions } from "../../../ducks/auth";
import loginImages from "../../../resources/images/login";
import commonImages from "../../../resources/images/common";
import { ApolloConsumer } from "@apollo/client";
import { AGREE_MUTATION } from "../../../utils/graphql/mutations";
import { MarkDownRenderer } from "./MarkDownRenderer";

type ActionProps = typeof loginFormActions & {
  clearCurrentUser: typeof currentUserActions.clearCurrentUser,
};
type StateProps = LoginFormState & CurrentUserState;
type OwnProps = {
  loading: boolean,
  error: any,
  onAgree: (refreshToken: string) => Promise<any>,
  onLogin: (userCredentials: UserCredentials) => void,
};
type Props = ActionProps & StateProps & OwnProps;

type State = {
  loading: boolean,
  response: any,
  error: any,
  mode: "login" | "recoverId" | "resetPwd" | "agreements",
  step: "form" | "confirm", // ignored when mode === 'login'
  name: string,
  orgzCode: string,
  recoverId: string,
  eulaAgreed: boolean,
  privacyCollectionAgreed: boolean,
  privacyPolicyAgreed: boolean,
};

class LoginFormComponent extends React.Component<Props, State> {
  privacyPolicyPath: string = require("../../../resources/privacyPolicy.md");
  privacyCollectionPath: string = require("../../../resources/privacyCollection.md");
  eulaPath: string = require("../../../resources/eula.md");

  constructor(props) {
    super(props);
    this.state = {
      mode:
        props.tokens.access_token !== "" && !props.agreed
          ? "agreements"
          : "login",
      step: "form",
      loading: false,
      response: null,
      error: null,
      name: "",
      orgzCode: "",
      recoverId: "",
      eulaAgreed: true,
      privacyPolicyAgreed: true,
      privacyCollectionAgreed: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tokens.access_token !== this.props.tokens.access_token &&
      this.props.tokens.access_token !== "" &&
      !this.props.agreed
    ) {
      this.setState({
        mode: "agreements",
        eulaAgreed: true,
        privacyPolicyAgreed: true,
        privacyCollectionAgreed: true,
      });
    }
  }

  recoverId(reqBody: { orgzCode: string, userName: string }) {
    this.setState({
      loading: true,
      response: null,
      error: null,
    });
    axios
      .post(
        `${
          process.env.REACT_APP_AUTH_HOST || "http://192.168.3.66:5190"
        }/auth/id/simple`,
        reqBody,
        {
          timeout: 60000,
          headers: {
            "content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.setState({
          loading: false,
          response,
          step: "confirm",
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({
          loading: false,
          error: error.response,
          step: "confirm",
        });
      });
  }

  resetPwd(reqBody: { email: string }) {
    this.setState({
      loading: true,
      response: null,
      error: null,
    });
    axios
      .post(
        `${
          process.env.REACT_APP_AUTH_HOST || "http://192.168.3.66:5190"
        }/auth/password/temp/email`,
        reqBody,
        {
          timeout: 60000,
          headers: {
            "content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        this.setState({
          loading: false,
          response,
          step: "confirm",
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({
          loading: false,
          error: error.response,
          step: "confirm",
        });
      });
  }

  partialMaskEmail(email: string) {
    const pieces = email.split("@");
    const emailId = pieces[0];
    if (pieces[0].length <= 3) {
      return email;
    }
    pieces[0] = `${emailId.slice(0, 3)}${"*".repeat(emailId.length - 3)}`;
    return pieces.join("@");
  }

  renderRecoverIdForm() {
    const { step, name, orgzCode, loading, response } = this.state;
    if (step === "form") {
      console.log(name);
      return (
        <FormContainer
          id="recoverId"
          style={{
            marginTop: "20px",
          }}
          onSubmit={(event) => {
            event.preventDefault();
            this.recoverId({
              orgzCode,
              userName: name,
            });
          }}
        >
          <FormRow>
            <img src={loginImages.name} alt="name" />
            <Input
              type="text"
              name="name"
              placeholder="이름"
              required
              value={name}
              autoComplete="section-recover-id name"
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </FormRow>
          <FormRow>
            <img src={loginImages.orgzCode} alt="code" />
            <Input
              type="text"
              name="orgzCode"
              placeholder="병원코드"
              required
              value={orgzCode}
              onChange={(e) => this.setState({ orgzCode: e.target.value })}
            />
          </FormRow>
          {loading ? (
            <SpinnerContainer>
              <Spinner spinnerColor="#fff" />
            </SpinnerContainer>
          ) : (
            <ButtonContainer>
              <CancelButton
                className="with-hover-overlay"
                href="#"
                onClick={(e) => {
                  console.log("cancel");
                  e.preventDefault();
                  this.setState({
                    mode: "login",
                    step: "form",
                    loading: false,
                    error: null,
                    name: "",
                    orgzCode: "",
                    response: null,
                    recoverId: "",
                  });
                }}
              >
                <span>취소</span>
              </CancelButton>
              <ConfirmButton type="submit" className="with-hover-overlay">
                <span>확인</span>
              </ConfirmButton>
            </ButtonContainer>
          )}
        </FormContainer>
      );
    }
    return (
      <ResponseMessageContainer>
        {response && response.data.length > 0 ? (
          <ResponseMessage style={{ height: "128px" }}>
            <RecoverIdHeading>{`${name}님의 이름으로 등록된 아이디입니다.`}</RecoverIdHeading>
            {_.map(response.data, (email) => (
              <RecoverIdMessage key={email}>{`${this.partialMaskEmail(
                email
              )}`}</RecoverIdMessage>
            ))}
          </ResponseMessage>
        ) : (
          <ResponseMessage style={{ height: "128px" }}>
            <ErrorMessage>{`${name}님의 아이디(이메일)을 찾을수 없습니다.`}</ErrorMessage>
          </ResponseMessage>
        )}
        <ButtonContainer>
          <ConfirmButton
            className="with-hover-overlay"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ mode: "login" });
            }}
          >
            <span>메인으로</span>
          </ConfirmButton>
        </ButtonContainer>
      </ResponseMessageContainer>
    );
  }

  renderResetPwdForm() {
    const { step, recoverId, error, loading } = this.state;
    if (step === "form") {
      return (
        <FormContainer
          id="resetPwd"
          style={{
            marginTop: "20px",
          }}
          onSubmit={(event) => {
            event.preventDefault();
            this.resetPwd({
              email: recoverId,
            });
          }}
        >
          <FormRow>
            <img src={loginImages.id} alt="user id" />
            <Input
              type="email"
              name="id"
              placeholder="아이디(이메일)"
              required
              value={recoverId}
              autoComplete="section-reset-pwd username"
              onChange={(e) => this.setState({ recoverId: e.target.value })}
            />
          </FormRow>
          {loading ? (
            <SpinnerContainer>
              <Spinner spinnerColor="#fff" />
            </SpinnerContainer>
          ) : (
            <ButtonContainer>
              <CancelButton
                className="with-hover-overlay"
                href="#"
                onClick={(e) => {
                  console.log("cancel");
                  e.preventDefault();
                  this.setState({
                    mode: "login",
                    step: "form",
                    loading: false,
                    response: null,
                    error: null,
                    name: "",
                    orgzCode: "",
                    recoverId: "",
                  });
                }}
              >
                <span>취소</span>
              </CancelButton>
              <ConfirmButton type="submit" className="with-hover-overlay">
                <span>확인</span>
              </ConfirmButton>
            </ButtonContainer>
          )}
        </FormContainer>
      );
    } else if (error) {
      return (
        <ResponseMessageContainer>
          <ResponseMessage style={{ height: "128px" }}>
            <RecoverIdMessage>
              잘못된 정보를 입력<span>하여</span>
            </RecoverIdMessage>
            <RecoverIdHeading>
              임시비밀번호를 발송할 수 없습니다.
            </RecoverIdHeading>
            <RecoverIdMessage>
              다시 입력<span>해주시기 바랍니다.</span>
            </RecoverIdMessage>
          </ResponseMessage>
          <ButtonContainer>
            <ConfirmButton
              className="with-hover-overlay"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ mode: "login" });
              }}
            >
              <span>메인으로</span>
            </ConfirmButton>
          </ButtonContainer>
        </ResponseMessageContainer>
      );
    }
    return (
      <ResponseMessageContainer>
        <ResponseMessage style={{ height: "128px" }}>
          <RecoverIdHeading>{`임시 비밀번호가`}</RecoverIdHeading>
          <RecoverIdMessage>
            {`${this.partialMaskEmail(recoverId)}`}
            <span>로</span>
          </RecoverIdMessage>
          <RecoverIdHeading>전송되었습니다</RecoverIdHeading>
        </ResponseMessage>
        <ButtonContainer>
          <ConfirmButton
            className="with-hover-overlay"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ mode: "login" });
            }}
          >
            <span>메인으로</span>
          </ConfirmButton>
        </ButtonContainer>
      </ResponseMessageContainer>
    );
  }

  renderLoginForm() {
    const {
      loading,
      onLogin,
      formUpdate,
      username,
      saveIdPwd,
      error,
      password,
    } = this.props;
    const { mode } = this.state;
    if (mode === "login") {
      return (
        <React.Fragment>
          <Title>MINDD SCAN</Title>
          <FormContainer
            id="loginForm"
            onSubmit={(event) => {
              event.preventDefault();
              // clear password on submit
              onLogin({ username, password });
            }}
          >
            <FormRow>
              <img src={loginImages.id} alt="user id" />
              <Input
                type="email"
                name="id"
                placeholder="아이디(이메일)"
                required
                value={username}
                autoComplete="section-login username"
                onChange={(e) =>
                  this.props.formUpdate("username", e.target.value)
                }
              />
            </FormRow>
            <FormRow>
              <img src={loginImages.password} alt="password" />
              <Input
                type="password"
                name="password"
                placeholder="비밀번호"
                required
                value={password}
                autoComplete="section-login current-password"
                onChange={(e) =>
                  this.props.formUpdate("password", e.target.value)
                }
              />
            </FormRow>
            {error ? (
              <LoginErrorSpan>
                아이디 또는 비밀번호를 잘못 입력하셨습니다
              </LoginErrorSpan>
            ) : null}
            {loading ? (
              <SpinnerContainer>
                <Spinner spinnerColor="#fff" />
              </SpinnerContainer>
            ) : (
              <LoginButton type="submit" className="with-hover-overlay">
                로그인
              </LoginButton>
            )}
            <LoginFormBottomContainer>
              <IdPwdButton
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    mode: "recoverId",
                    step: "form",
                    loading: false,
                    response: null,
                    error: null,
                    name: "",
                    orgzCode: "",
                    recoverId: "",
                  });
                }}
              >
                <span>아이디/비밀번호찾기</span>
              </IdPwdButton>
              <Checkbox
                checked={saveIdPwd}
                onChange={(event) => this.props.setSaveIdPwd(!saveIdPwd)}
                stopClickPropagation
                checkedImgSrc={commonImages.checked}
                uncheckedImgSrc={commonImages.check}
                fontStyle={{
                  size: 15,
                  color: "#fff",
                }}
              >
                ID/PWD 저장
              </Checkbox>
            </LoginFormBottomContainer>
          </FormContainer>
        </React.Fragment>
      );
    } else if (mode === "agreements") {
      const { eulaAgreed, privacyPolicyAgreed, privacyCollectionAgreed } =
        this.state;
      return (
        <AgreementsContainer>
          <AgreementsTitle>서비스 이용약관 동의</AgreementsTitle>
          <AgreementsBodyContainer>
            <AgreementSectionContainer>
              <span>
                <Checkbox
                  checked={eulaAgreed}
                  onChange={() => this.setState({ eulaAgreed: !eulaAgreed })}
                  stopClickPropagation
                  checkedImgSrc={commonImages.checked}
                  uncheckedImgSrc={commonImages.check}
                  fontStyle={{
                    size: 15,
                    color: "#435268",
                  }}
                >
                  이용약관(필수)
                </Checkbox>
              </span>
              <AgreementContentContainer>
                <MarkDownRenderer src={this.eulaPath} />
              </AgreementContentContainer>
            </AgreementSectionContainer>
            <AgreementSectionContainer>
              <span>
                <Checkbox
                  checked={privacyPolicyAgreed}
                  onChange={() =>
                    this.setState({ privacyPolicyAgreed: !privacyPolicyAgreed })
                  }
                  stopClickPropagation
                  checkedImgSrc={commonImages.checked}
                  uncheckedImgSrc={commonImages.check}
                  fontStyle={{
                    size: 15,
                    color: "#435268",
                  }}
                >
                  개인정보처리방침(필수)
                </Checkbox>
              </span>
              <AgreementContentContainer>
                <MarkDownRenderer src={this.privacyPolicyPath} />
              </AgreementContentContainer>
            </AgreementSectionContainer>
            <AgreementSectionContainer>
              <span>
                <Checkbox
                  checked={privacyCollectionAgreed}
                  onChange={() =>
                    this.setState({
                      privacyCollectionAgreed: !privacyCollectionAgreed,
                    })
                  }
                  stopClickPropagation
                  checkedImgSrc={commonImages.checked}
                  uncheckedImgSrc={commonImages.check}
                  fontStyle={{
                    size: 15,
                    color: "#435268",
                  }}
                >
                  개인정보수집 및 이용(필수)
                </Checkbox>
              </span>
              <AgreementContentContainer>
                <MarkDownRenderer src={this.privacyCollectionPath} />
              </AgreementContentContainer>
            </AgreementSectionContainer>
            <AgreementSectionContainer>
              <AgreementNotice color="#576881">
                <span className="star">*</span>
                <p>
                  위 개인정보 수집∙이용에 대한 동의를 거부할 권리가 있습니다.
                  <br />
                  다만, 동의를 거부하는 경우 MINDD Web Dashboard 서비스를 이용할
                  수 없습니다.
                </p>
              </AgreementNotice>
              <AgreementNotice>
                <span className="star">*</span>
                <p>
                  이용약관 및 개인정보처리방침은 로그인 후 좌측 메뉴 하단에서
                  다시 확인하실 수 있습니다.
                </p>
              </AgreementNotice>
            </AgreementSectionContainer>
            <Mutation
              mutation={AGREE_MUTATION}
              context={{
                dataSourceType: "auth",
              }}
            >
              {(agree, { loading, error }) => (
                <AgreementsButtonContainer>
                  {loading || this.props.loading ? (
                    <Spinner />
                  ) : (
                    <React.Fragment>
                      <ApolloConsumer>
                        {(client) => (
                          <NotAgreeButton
                            className="with-hover-overlay"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              client.clearStore();
                              this.props.clearCurrentUser();
                              this.setState({
                                mode: "login",
                                eulaAgreed: true,
                                privacyPolicyAgreed: true,
                                privacyCollectionAgreed: true,
                              });
                            }}
                          >
                            취소
                          </NotAgreeButton>
                        )}
                      </ApolloConsumer>
                      <AgreeButton
                        className="with-hover-overlay"
                        disabled={
                          !(
                            eulaAgreed &&
                            privacyPolicyAgreed &&
                            privacyCollectionAgreed
                          )
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          agree({
                            variables: {
                              agreed:
                                eulaAgreed &&
                                privacyPolicyAgreed &&
                                privacyCollectionAgreed,
                            },
                          })
                            .then((result) => {
                              this.props.onAgree(
                                this.props.tokens.refresh_token
                              );
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        확인
                      </AgreeButton>
                    </React.Fragment>
                  )}
                </AgreementsButtonContainer>
              )}
            </Mutation>
          </AgreementsBodyContainer>
        </AgreementsContainer>
      );
    }
    return (
      <React.Fragment>
        <FormContainer
          id="loginForm"
          onSubmit={(event) => {
            event.preventDefault();
            // clear password on submit
            formUpdate("password", "");
            onLogin({ username, password });
          }}
          style={{ display: "none" }}
        >
          <FormRow>
            <img src={loginImages.id} alt="user id" />
            <Input
              type="email"
              name="id"
              placeholder="아이디(이메일)"
              required
              value={username}
              autoComplete="section-login username"
              onChange={(e) =>
                this.props.formUpdate("username", e.target.value)
              }
            />
          </FormRow>
          <FormRow>
            <img src={loginImages.password} alt="password" />
            <Input
              type="password"
              name="password"
              placeholder="비밀번호"
              required
              value={password}
              autoComplete="section-login current-password"
              onChange={(e) =>
                this.props.formUpdate("password", e.target.value)
              }
            />
          </FormRow>
          {loading ? (
            <SpinnerContainer>
              <Spinner spinnerColor="#fff" />
            </SpinnerContainer>
          ) : (
            <LoginButton type="submit" className="with-hover-overlay">
              로그인
            </LoginButton>
          )}
          <IdPwdButton
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                mode: "recoverId",
                step: "form",
                loading: false,
                response: null,
                error: null,
                name: "",
                orgzCode: "",
                recoverId: "",
              });
            }}
          >
            <span>아이디/비밀번호찾기</span>
          </IdPwdButton>
        </FormContainer>
        <SegmentedControl>
          <SegmentButton
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                mode: "recoverId",
                step: "form",
                loading: false,
                response: null,
                error: null,
                name: "",
                orgzCode: "",
                recoverId: "",
              });
            }}
            className={mode === "recoverId" ? "active-segment" : ""}
          >
            <span>아이디찾기</span>
          </SegmentButton>
          <SegmentButton
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                mode: "resetPwd",
                step: "form",
                loading: false,
                response: null,
                error: null,
                name: "",
                orgzCode: "",
                recoverId: "",
              });
            }}
            className={mode === "resetPwd" ? "active-segment" : ""}
          >
            <span>비밀번호찾기</span>
          </SegmentButton>
        </SegmentedControl>
        {mode === "recoverId"
          ? this.renderRecoverIdForm()
          : this.renderResetPwdForm()}
      </React.Fragment>
    );
  }

  render() {
    return (
      <TopContainer>
        <Container>{this.renderLoginForm()}</Container>
        <LoginFormFooter>
          <LogoLink href="http://www.ybrain.com" target="_blank">
            <img src={commonImages.logo} alt="Ybrain" />
          </LogoLink>
          <ContactContainer>
            <Contact>(주) 와이브레인</Contact>
            <Contact>사업자등록번호: 314-86-43580</Contact>
            <Contact>통신판매업 신고번호: 2020-대전유성-0983</Contact>
            <Contact>대표: 이기원</Contact>
          </ContactContainer>
          <ContactContainer>
            <Contact>
              영업지점: 대전광역시 유성구 죽동로 280번길 41, 101호
            </Contact>
            <Contact>
              본사: 경기도 성남시 수정구 창업로 54 판교 제2테크노밸리
            </Contact>
          </ContactContainer>
          <ContactContainer>
            <Contact>전화번호: 031-758-2873</Contact>
            <Contact>
              E-mail: <a href="mailto:cs@ybrain.com">cs@ybrain.com</a>
            </Contact>
          </ContactContainer>
          <CopyRightContainer>
            <CopyRight>copyright © YBRAIN Corp. ALL Rights reserved.</CopyRight>
          </CopyRightContainer>
        </LoginFormFooter>
      </TopContainer>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.auth.loginForm,
    ...state.auth.currentUser,
  };
};

export const LoginForm = connect(mapStateToProps, {
  ...loginFormActions,
  clearCurrentUser: currentUserActions.clearCurrentUser,
})(LoginFormComponent);
