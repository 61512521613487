// @flow
import * as React from 'react';
import _ from 'lodash';
import commonImages from '../../../resources/images/common';
import { createSelectableStyledComponent, createStyledTableComponent } from './styled';
import { Checkbox } from '../Checkbox';

export const styledTable = (WrappedComponent: React.ComponentType<any>) => {
  const StyledWrappedComponent = createStyledTableComponent(WrappedComponent);
  class EnhancedTable extends React.Component<any> {
    render() {
      return (
        <StyledWrappedComponent
          className='styled-table'
          {...this.props}
        />
      );
    }
  }
  return EnhancedTable;
}

export const selectableTable = (WrappedComponent: React.ComponentType<any>) => {
  const StyledWrappedComponent = createSelectableStyledComponent(WrappedComponent);
  class EnhancedTable extends React.Component<any> {
    rowSelected(original: any, selectedRow: any) {
      const { idKey } = this.props;
      return (idKey) ? (
        original[idKey] === selectedRow[idKey]
      ) : (original.id === selectedRow.id);
    }

    render() {
      const { selectedRow } = this.props;
      return (
        <StyledWrappedComponent
          className={`${this.props.className} -highlight`}
          getTrProps={(state, rowInfo, column) => {
            return {
              style: {
                backgroundColor: (selectedRow && this.rowSelected(rowInfo.original, selectedRow)) ? '#ebf0f4' : ''
              },
              onClick: (e, handleOriginal) => {
                console.log(this.props.onRowSelect);
                if (this.props.onRowSelect) {
                  this.props.onRowSelect(rowInfo);
                }
                if (handleOriginal) {
                  handleOriginal();
                }
              }
            };
          }}
          {...this.props}
        />
      );
    }
  }
  return EnhancedTable;
};

export const checkableTable = (WrappedComponent: React.ComponentType<any>) => {
  class EnhancedTable extends React.Component<any>  {
    render() {
      const checkableRowIds = _.map(_.filter(this.props.data, (rowData) => this.props.isRowCheckable(rowData)), (row) => (this.props.idKey) ? row[this.props.idKey] : row.id);
      const { checkedRows } = this.props;
      const columnsWithCheckCol = [
        {
          id: '_check',
          Header: (input) => {
            return (
              <Checkbox
                disabled={!(this.props.data.length > 0 && checkableRowIds.length > 0)}
                checked={this.props.data.length > 0 && checkableRowIds.length > 0 && _.difference(checkableRowIds, checkedRows).length === 0}
                onChange={(event) => {
                  if (event.currentTarget.checked && this.props.onRowsCheck) {
                    this.props.onRowsCheck(checkableRowIds);
                  } else if (!event.currentTarget.checked && this.props.onRowsUncheck) {
                    this.props.onRowsUncheck(checkableRowIds);
                  }
                }}
                stopClickPropagation
                checkedImgSrc={commonImages.checked}
                uncheckedImgSrc={commonImages.check}
              />
            );
          },
          Cell: (rowInfo) => {
            const rowId = (this.props.idKey) ? rowInfo.original[this.props.idKey] : rowInfo.original.id;
            if (_.includes(checkableRowIds, rowId)) {
              return (
                <Checkbox
                  disabled={false}
                  checked={_.includes(checkedRows, rowId)}
                  onChange={(event) => {
                    if (event.currentTarget.checked && this.props.onRowsCheck) {
                      this.props.onRowsCheck([rowId]);
                    } else if (!event.currentTarget.checked && this.props.onRowsUncheck) {
                      this.props.onRowsUncheck([rowId]);
                    }
                  }}
                  stopClickPropagation
                  checkedImgSrc={commonImages.checked}
                  uncheckedImgSrc={commonImages.check}
                />
              );
            } else {
              return null;
            }
          }, // TODO: render a checkbox
          width: 58
        },
        ...this.props.columns
      ];
      return (
        <WrappedComponent
          {...this.props}
          columns={columnsWithCheckCol}
        />
      )
    }
  }
  return EnhancedTable;
}