// @flow
import { combineReducers } from 'redux';
import accountListReducer, { actionCreators as accountListActions } from './accountlist';
import accountFormReducer, { actionCreators as accountFormActions } from './accountform';

const reducer = combineReducers({
  accountList: accountListReducer,
  accountForm: accountFormReducer
});

export default reducer;

export {
  accountListActions,
  accountFormActions
};
