// @flow
import * as React from 'react';
import {
  Page,
  PanelGrid
} from '../../components/common';
import { AccountListPanel } from '../../components/accounts';

class Accounts extends React.Component<{}> {
  render() {
    return (
      <Page
        title='Access Control'
        subtitle='유저의 계정을 관리하고 확인할 수 있습니다'
        contentMinWidth={1295}
      >
        <PanelGrid.Grid>
          <PanelGrid.Row style={{ height: '608px'}}>
            <PanelGrid.Col minWidth={1175}>
              <AccountListPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
        </PanelGrid.Grid>
      </Page>
    )
  }
}

export { Accounts };