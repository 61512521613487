// @flow
// type imports
import type { AppState } from '../../../types';
import type { CurrentUserState } from '../../../types/auth';
import type { AccountFormState } from '../../../types/accounts';
// module imports
import * as React from 'react';
import Popup from 'reactjs-popup';
import { Mutation } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import BaseSelect from 'react-select';
import { accountFormActions, accountListActions } from '../../../ducks/accounts';
import { Spinner, styledSelect } from '../../common';
import {
  AcceptButton,
  CancelButton,
  FormTitleContainer,
  FormTitle,
  FormFooterContainer,
  ContentContainer,
  Form,
  FormBody,
  FormRow
} from './styled';
import { CREATE_EMP_MUTATION, ADD_DEFAULT_BOOKMARKS_MUTATION, UPDATE_EMP_ROLE_MUTATION } from '../../../utils/graphql/mutations';


type StateProps = AccountFormState & {
  selectedUser: User,
  currentUser: CurrentUserState
};

type ActionProps = typeof accountFormActions & {
  selectUser: typeof accountListActions.selectUser,
  refetchUsers: typeof accountListActions.refetchUsers
};

type Props = StateProps & ActionProps;

const Select = styledSelect(BaseSelect)

class AccountFormComponent extends React.Component<Props> {
  _roles = {
    'U_NORMAL': { value: 'U_NORMAL', label: '일반' },
    'U_SUBJECT': { value: 'U_SUBJECT', label: '대상자관리' },
//    'U_ADMIN': { value: 'U_ADMIN', label: '관리자' }   // disabling admin account creation for now
  };
  _firstInput: ?HTMLInputElement;
  
  onFormOpen() {
    if (this._firstInput) {
      this._firstInput.focus();
    }
  }

  onInputChange(event: SyntheticEvent<HTMLInputElement>, key: string) {
    this.props.formUpdate(key, event.currentTarget.value);
  }

  render() {
    const {
      name,
      email,
      role
    } = this.props.user;
    const { currentUser, mode } = this.props;
    if (mode === 'update') {
      console.log(role);
      return (
        <Mutation
          mutation={UPDATE_EMP_ROLE_MUTATION}
          context={{
            dataSourceType: 'auth'
          }}
        >
          {(updateUser, { loading, error }) => {
            return (
              <Popup
                open={this.props.shown}
                closeOnDocumentClick
                onClose={() => this.props.formCancel()}
                overlayStyle={{
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  margin: 0,
                  zIndex: 10000,
                  flexDirection: 'column'
                }}
                contentStyle={{
                  backgroundColor: '#fff',
                  boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
                  border: 'none',
                  width: '440px',
                  minHeight: '300px',
                  padding: 0
                }}
              >
                <ContentContainer>
                  <FormTitleContainer>
                    <FormTitle>계정권한 수정</FormTitle>
                  </FormTitleContainer>
                  <Form
                    onSubmit={async (event) => {
                      event.preventDefault();
                      const { user, selectedUser } = this.props;
                      try {
                        const userResult = await updateUser({ variables: {
                          email: user.email,
                          role: user.role
                        } });
                        console.log(userResult);
                        if (userResult && userResult.data) {
                          this.props.selectUser({
                            ...selectedUser,
                            role: userResult.data.updateEmpRole
                          });
                        }
                        this.props.formCancel();
                        this.props.refetchUsers();
                      } catch (err) {
                        console.log(err.message);
                      }
                    }}
                  >
                    <FormBody>
                      <FormRow>
                        <label className='required'>
                          이름
                        </label>
                        <input
                          type='text'
                          name='name'
                          readOnly
                          ref={(ref) => this._firstInput = ref}
                          value={name}
                          autoComplete='off'
                        />
                      </FormRow>
                      <FormRow>
                        <label className='required'>
                          이메일
                        </label>
                        <input
                          type='email'
                          name='email'
                          readOnly
                          value={email}
                        />
                      </FormRow>
                      <FormRow>
                        <label>
                          권한
                        </label>
                        <Select
                          options={[{ value: 'U_NORMAL', label: '일반' }, { value: 'U_SUBJECT', label: '대상자관리' }]}
                          placeholder=''
                          isClearable={false}
                          backspaceRemovesValue={false}
                          styles={{
                            container: styles => ({
                              ...styles,
                              flex: '0 0 188px',
                              marginLeft: '15px'
                            })
                          }}
                          value={this._roles[role]}
                          onChange={(option) => this.props.formUpdate('role', option.value)}
                        />
                      </FormRow>
                    </FormBody>
                    <FormFooterContainer>
                      {(loading) ? <Spinner /> :
                      <React.Fragment>
                        <CancelButton
                          className='with-hover-overlay'
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.formCancel();
                          }}
                          href='#'
                        >
                          <span>취소</span>
                        </CancelButton>
                        <AcceptButton
                          className='with-hover-overlay'
                          type='submit'
                          disabled={loading}
                        >
                          <span>확인</span>
                        </AcceptButton>
                      </React.Fragment>}
                    </FormFooterContainer>
                  </Form>
                </ContentContainer>
              </Popup>
            );
          }}
        </Mutation>
      );
    }
    return (
      <Mutation
        mutation={ADD_DEFAULT_BOOKMARKS_MUTATION}
        context={{
          dataSourceType: 'api'
        }}
      >
        {(addDefaultBookmarks, { loading: bkmkLoading, error: bkmkError }) => (
          <Mutation
            mutation={CREATE_EMP_MUTATION}
            context={{
              dataSourceType: 'auth'
            }}
          >
            {(createUser, { loading, error }) => {
              return (
                <Popup
                  open={this.props.shown}
                  closeOnDocumentClick
                  onClose={() => this.props.formCancel()}
                  onOpen={this.onFormOpen.bind(this)}
                  overlayStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    margin: 0,
                    zIndex: 10000,
                    flexDirection: 'column'
                  }}
                  contentStyle={{
                    backgroundColor: '#fff',
                    boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
                    border: 'none',
                    width: '440px',
                    minHeight: '300px',
                    padding: 0
                  }}
                >
                  <ContentContainer>
                    <FormTitleContainer>
                      <FormTitle>계정등록</FormTitle>
                    </FormTitleContainer>
                    <Form
                      onSubmit={async (event) => {
                        event.preventDefault();
                        const empIn = this.props.user;
                        try {
                          const userResult = await createUser({ variables: {
                            empIn,
                            signinUrl: process.env.PUBLIC_URL
                          } });
                          console.log(userResult);
                          const bkmkResult = await addDefaultBookmarks({ variables: {
                            orgzId: currentUser.orgz_id,
                            empEmail: empIn.email
                          } });
                          this.props.formCancel();
                          this.props.refetchUsers();
                        } catch (err) {
                          console.log(err.message);
                        }
                      }}
                    >
                      <FormBody>
                        <FormRow>
                          <label className='required'>
                            이름
                          </label>
                          <input
                            type='text'
                            name='name'
                            ref={(ref) => this._firstInput = ref}
                            value={name}
                            onChange={(event) => this.onInputChange(event, 'name')}
                            autoComplete='off'
                            required
                          />
                        </FormRow>
                        <FormRow>
                          <label className='required'>
                            이메일
                          </label>
                          <input
                            type='email'
                            name='email'
                            value={email}
                            onChange={(event) => this.onInputChange(event, 'email')}
                            required
                          />
                        </FormRow>
                        <FormRow>
                          <label>
                            권한
                          </label>
                          <Select
                            options={[{ value: 'U_NORMAL', label: '일반' }, { value: 'U_SUBJECT', label: '대상자관리' }]}
                            placeholder=''
                            isClearable={false}
                            backspaceRemovesValue={false}
                            styles={{
                              container: styles => ({
                                ...styles,
                                flex: '0 0 188px',
                                marginLeft: '15px'
                              })
                            }}
                            value={this._roles[role]}
                            onChange={(option) => this.props.formUpdate('role', option.value)}
                          />
                        </FormRow>
                      </FormBody>
                      <FormFooterContainer>
                        {(loading || bkmkLoading) ? <Spinner /> :
                        <React.Fragment>
                          <CancelButton
                            className='with-hover-overlay'
                            onClick={(event) => {
                              event.preventDefault();
                              this.props.formCancel();
                            }}
                            href='#'
                          >
                            <span>취소</span>
                          </CancelButton>
                          <AcceptButton
                            className='with-hover-overlay'
                            type='submit'
                            disabled={loading || bkmkLoading}
                          >
                            <span>확인</span>
                          </AcceptButton>
                        </React.Fragment>}
                      </FormFooterContainer>
                    </Form>
                  </ContentContainer>
                </Popup>
              );
            }}
          </Mutation>
        )}
      </Mutation>
    )
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.accounts.accountForm,
    selectedUser: state.accounts.accountList.selectedUser,
    currentUser: state.auth.currentUser
  };
};

export const AccountForm: React.ComponentType<{}> = connect(mapStateToProps, {
  ...accountFormActions,
  selectUser: accountListActions.selectUser,
  refetchUsers: accountListActions.refetchUsers
})(AccountFormComponent);