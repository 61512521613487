// @flow
// type imports
import { Query } from "@apollo/client/react/components";
// module imports
import * as React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { compose } from "redux";
import type { AppState } from "../../types";
import type { BillingItem } from "../../types/billing";
import {
  AUTH_DATA_YBRAIN_EMPLOYEES,
  AUTH_DATA_YBRAIN_ORGANIZATION,
} from "../../utils/graphql/queries";
import { insertCommas } from "../../utils/rest";
import {
  ControlledPanel,
  selectableTable,
  Spinner,
  styledTable,
} from "../common";
import { DefaultCellSpan } from "./styled";

type OwnProps = {
  onItemSelect: (billingItem: BillingItem) => void,
  onItemSearch: () => void,
  onShowAll: () => void,
  loading: boolean,
  currentPage: number,
  numPages: number,
  noDataText: string,
  // TODO: create a type for subjects
  billingItems: any[],
  pageSize: number, // ReactTable pageSize
  history: any,
};

type StateProps = {};

type ActionProps = typeof billingItemListActions;

// TODO: move BillingForm out to Analysis screen component
type Props = OwnProps & StateProps & ActionProps & {};

const SelectableTable = compose(styledTable, selectableTable)(ReactTable);

const typeToString = (type) => {
  switch (type) {
    case "SCAN":
      return "EEG";
    case "HRV":
      return "HRV";
    case "NEURO_GUIDE":
      return "NeuroGuide";
    default:
      return type;
  }
};

class BillingItemsPanelComponent extends React.Component<Props> {
  render() {
    const { billingItems, noDataText } = this.props;

    return (
      <Query
        query={AUTH_DATA_YBRAIN_ORGANIZATION}
        variables={{
          orgzId: this.props.orgzId,
        }}
        fetchPolicy="network-only"
      >
        {({ data }) => {
          const orgManagerName = data?.organization?.mgmtOfficerDetails?.name;
          return (
            <Query
              query={AUTH_DATA_YBRAIN_EMPLOYEES}
              fetchPolicy="network-only"
            >
              {({ loading, error, data }) => {
                let employeeMap: { [id: number]: string } = {};
                let contractItems = [];
                if (data) {
                  data.ybrainEmployees.forEach((employee) => {
                    employeeMap[employee.id] = employee.name;
                  });
                  billingItems.reduce((prev, curr) => {
                    prev.push(
                      ...curr.monthlyBillDetails.map((detail) => {
                        return {
                          contractId: detail.contractId,
                          contractCode: detail.contractCode,
                          serviceType: detail.contractRelationInfo.serviceType,
                          managerName:
                            employeeMap[
                              detail.contractRelationInfo.managerId
                            ] || orgManagerName,
                          billRound: detail.billRound,
                          settledUsageCount: detail.settledUsageCount,
                          billedUsageCount: detail.billedUsageCount,
                          billedAmountWithVat: detail.billedAmountWithVat,
                        };
                      })
                    );
                    return prev;
                  }, contractItems);
                }

                return (
                  <ControlledPanel>
                    <ControlledPanel.Content>
                      <SelectableTable
                        data={contractItems}
                        loading={loading}
                        minRows={0}
                        onRowSelect={(rowInfo: any) => {
                          this.props.history.push(
                            `/billing/detail/${rowInfo.original.contractId}`
                          );
                        }}
                        LoadingComponent={Spinner}
                        columns={[
                          {
                            id: "contractCode",
                            Header: "계약 번호",
                            accessor: (rowData) => rowData.contractCode,
                            Cell: (rowInfo) => {
                              return (
                                <DefaultCellSpan>
                                  {rowInfo.value}
                                </DefaultCellSpan>
                              );
                            },
                            minWidth: 140,
                          },
                          {
                            id: "serviceType",
                            Header: "서비스 타입",
                            accessor: (rowData) => rowData.serviceType,
                            Cell: (rowInfo) => {
                              return (
                                <DefaultCellSpan>
                                  {typeToString(rowInfo.value)}
                                </DefaultCellSpan>
                              );
                            },
                            minWidth: 140,
                          },
                          {
                            id: "managerName",
                            Header: "담당자",
                            accessor: (rowData) => rowData.managerName,
                            Cell: (rowInfo) => {
                              return (
                                <DefaultCellSpan>
                                  {rowInfo.value}
                                </DefaultCellSpan>
                              );
                            },
                            minWidth: 140,
                          },
                          {
                            id: "billRound",
                            Header: "납부 횟수",
                            accessor: (rowData) => rowData.billRound,
                            Cell: (rowInfo) => {
                              return (
                                <DefaultCellSpan>
                                  {rowInfo.value}
                                </DefaultCellSpan>
                              );
                            },
                            minWidth: 140,
                          },
                          {
                            id: "settledUsageCount",
                            Header: "사용건수",
                            accessor: (rowData) => rowData.settledUsageCount,
                            Cell: (rowInfo) => {
                              return (
                                <DefaultCellSpan>
                                  {rowInfo.value}
                                </DefaultCellSpan>
                              );
                            },
                            minWidth: 140,
                          },
                          {
                            id: "billedUsageCount",
                            accessor: (rowData) => rowData.billedUsageCount,
                            Header: "청구건수",
                            Cell: (rowInfo) => {
                              return (
                                <DefaultCellSpan>
                                  {rowInfo.value}
                                </DefaultCellSpan>
                              );
                            },
                            minWidth: 140,
                          },
                          {
                            id: "billedAmountWithVat",
                            accessor: (rowData) =>
                              rowData.billedAmountWithVat || 0,
                            Header: "총 금액(VAT 포함)",
                            Cell: (rowInfo) => {
                              return (
                                <DefaultCellSpan>
                                  {insertCommas(String(rowInfo.value))}
                                </DefaultCellSpan>
                              );
                            },
                            minWidth: 140,
                          },
                        ]}
                        showPagination={false}
                        resizable={true}
                        multiSort={false}
                        noDataText={noDataText}
                        getTrProps={(state, rowInfo, column) => {
                          return {
                            onClick: (e, handleOriginal) => {
                              if (state.onRowSelect) {
                                state.onRowSelect(rowInfo);
                                // this.props.history.pushState("","", "/billing/detail");
                              }
                              if (handleOriginal) {
                                handleOriginal();
                              }
                            },
                          };
                        }}
                      />
                    </ControlledPanel.Content>
                  </ControlledPanel>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  return {
    orgzId: state.auth.currentUser.orgz_id,
  };
};

export const BillingItemsPanel: React.ComponentType<OwnProps> = connect(
  mapStateToProps,
  {}
)(BillingItemsPanelComponent);
