// @flow
// type imports
import type { TempAnlzRequest, Patient } from "../../../types/analysis";
// module imports
import React from "react";
import { PlainPanel } from "../../common";
import { Box, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

type Props = {
  selectedPatient: Patient,
  selectedAnlzRequest: TempAnlzRequest,
};

export const TempAnlzRequestDetailPanel = ({
  selectedPatient,
  selectedAnlzRequest,
}: Props) => {
  const value = React.useMemo(() => {
    return Math.floor(selectedAnlzRequest.result.prediction * 100) / 100;
  }, [selectedAnlzRequest]);

  return (
    <PlainPanel
      title={`${selectedPatient.name}님의 기질 분석 내용`}
      headerBackgroundColor="#fff"
      headerBorderColor="transparent"
    >
      <Box position={"relative"} color={"#929292"} width={670}>
        <Typography>
          {selectedPatient.name}님의 간질 분석 결과 <b>{value}</b>의 값으로
          예측되었습니다.
        </Typography>
        <br />
        <Typography>
          본 리포트는 뇌파 데이터를 기반으로 간질 환자 여부를 판별하는 결과를
          제공합니다. Temple University EEG Corpus 뇌파 데이터중 간질환자
          100명과 비간질환자 100명의 데이터를 사용하였습니다. 사용되는 모델은
          90.1%의 정확도로 학습되었으며, 뇌파 패턴의 차이를 분석하여 간질 환자와
          비간질 환자를 구분 합니다.
        </Typography>
        <br />

        <Typography>
          간질 환자의 뇌파는 측정 주파수 대역에서의 에너지 변화가 일반인과 다를
          수 있습니다. 이러한 차이를 Power Spectral Density(PSD)를 통해
          정량적으로 측정하고 분석함으로써, 간질 환자와 비간질 환자를 더
          정확하게 구분할 수 있습니다.
        </Typography>
        <br />

        <Typography>
          모델의 출력 값은 0에서 1사이의 값으로 제공됩니다. 값이{" "}
          <b>0에 가까울수록 비간질 환자, 1에 가까울수록 간질 환자</b>로
          판별됩니다. 예)0.2의 값은 비간질 환자일 확률이 높다는 것을 의미
          합니다.
        </Typography>
        <br />

        <Typography variant="caption" component={"p"}>
          *이 서비스는 진단 목적이 아닌 참고용으로 제공됩니다. 최종 진단은
          전문의와 상담을 통해 이루어져야 합니다. 개인의 실제 상태와 다른 결과가
          나올 수 있으므로, 결과에 너무 의존하지 않도록 주의해야 합니다.
        </Typography>
      </Box>
    </PlainPanel>
  );
};
