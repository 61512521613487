// @flow
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import {
  SessionInfoContainer,
  SessionInfoSpan,
  InfoDivider,
  SessionAnlzResSpan
} from './styled';
import analysisImages from '../../../resources/images/analysis';

type Props = {
  surveyResult?: any,
  mainSurveyResp: any[],
  selectedSurveySession: any,
  currentPage: number
};

export const SurveySessionInfo = ({ currentPage, surveyResult, mainSurveyResp, selectedSurveySession }: Props) => {
  return (
    <SessionInfoContainer>
      <img src={analysisImages.survey} alt="Survey"/>
      <SessionInfoSpan>{moment(selectedSurveySession.createdTime || selectedSurveySession.created).format('YYYY-MM-DD')}</SessionInfoSpan>
      <SessionInfoSpan>
        {(currentPage === 0 && mainSurveyResp.length > 0) ? `${selectedSurveySession.pubSurveyName || selectedSurveySession.surveyName}${(selectedSurveySession.pubSurveyDesc && selectedSurveySession.pubSurveyDesc.length < 20) ? `(${selectedSurveySession.pubSurveyDesc.split('(')[0]})` : ''}` :
        `${selectedSurveySession.pubSurveyName || selectedSurveySession.surveyName}${(surveyResult) ? `(${surveyResult.surveyName})` : ''}`}
      </SessionInfoSpan>
      {(surveyResult) ?
      (
        <React.Fragment>
          {(surveyResult.anlzResult) ? <InfoDivider /> : null}
          <SessionAnlzResSpan
            alwaysShowTooltip={surveyResult && surveyResult.anlzResult && surveyResult.anlzResult.additions}
            tooltipTextAlign='left'
            customTitle={(surveyResult && surveyResult.anlzResult && surveyResult.anlzResult.additions) ? (
              _.join(_.map(surveyResult.anlzResult.additions, ({ key, value }) => `${key}: ${value}`), '\n')
            ) : null}
          >
            {(surveyResult.anlzResult && surveyResult.anlzSpec) ? (
              (surveyResult.anlzSpec.maxLevel) ? `Level ${surveyResult.anlzResult.level}/${surveyResult.anlzSpec.maxLevel} (${surveyResult.anlzResult.score}점)`
              : `${surveyResult.anlzResult.score}점`
            ) : (
              surveyResult.anlzResult ? `${surveyResult.anlzResult.score}점`
              : ''
            )}
          </SessionAnlzResSpan>
        </React.Fragment>
      )
      : null}
    </SessionInfoContainer>
  )
};