// @flow
// module imports
import * as React from 'react';
import {
  Page,
  PanelGrid
} from '../../components/common';
import { ProfilePanel, PasswordPanel } from '../../components/mypage';

class Mypage extends React.Component<{}> {
  render() {
    return (
      <Page
        title='My Page'
        subtitle='나의 개인정보를 확인하고 수정할 수 있습니다'
        contentMinWidth={1295}
      >
        <PanelGrid.Grid>
          <PanelGrid.Row style={{ height: '664px' }}>
            <PanelGrid.Col minWidth={490} widthFixed>
              <ProfilePanel />
            </PanelGrid.Col>
            <PanelGrid.Col minWidth={490} widthFixed>
              <PasswordPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
        </PanelGrid.Grid>
      </Page>
    )
  }
}

export { Mypage };