import styled from 'styled-components';
import { AnchorButton, Button, EllipsisSpan, HoverStyledAnchorButton } from '../../common';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import Tooltip from '@material-ui/core/Tooltip';


export const PanelContentContainer = styled.div`
  padding: ${props => props.padding || '13px 30px 30px'};
  min-height: 0;
  flex: 1 1 0%;
  > *:not(:first-child) {
    margin-top: 30px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SessionListContainer = styled.div`
  flex: 1 1 0%;
  min-height: 0;
  position: relative;
`;

export const EdfConversionPrefContainer = styled.div`
  width: 100%;
  height: 40px;
  position: absolute;
  border-top: solid 1px #bcc9d8;
  bottom: -45px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 5px;
`;

export const FilenameSpan = styled(EllipsisSpan)`
  text-align: left;
  padding: 0 10px;
  min-width: 0;
  > * {
    vertical-align: middle;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px 0 20px;
`;

export const DefaultCellSpan = styled(EllipsisSpan)`
  text-align: center;
  padding: 0 10px;
  width: 0;
  flex: 1 1 0%;
`;

export const ErrorMsgTooltipSpan = styled.span`
  white-space: pre-wrap;
  text-align: center;
  font-size: 14px;
`;

export const AnalysisStateSpan = styled.span`

`;

export const FileDownloadLinkButton = styled(HoverStyledAnchorButton)`
  height: 23px;
  width: 23px;
  margin-right: 10px;
  > img {
    height: 23px;
    width: 23px;
    object-fit: contain;
  }
`;

export const PDFDownloadButton = styled(AnchorButton)`
  width: 22px;
  height: 28px;
  > img {
    width: 22px;
    height: 28px;
    object-fit: contain;
  }
`;

export const ColoredCell = styled.span`
  color: ${props => props.color || '#000'};
  font-size: 18px;
  text-align: center;
`;

export const DetailContainer = styled(Drawer)`
  &.MuiDrawer-root {
    z-index: 10000;
  }
  .MuiDrawer-paper {
    background-color: #e7ecf1;
    width: ${props => (props.anchor === 'right' || props.anchor === 'left') ? `${props.width}px` : '100%'};
    height: ${props => (props.anchor === 'top' || props.anchor === 'bottom') ? `${props.height}px` : '100%'};
    .MuiToolbar-root {
      background-color: #e7ecf1;
      color: ${props => props.theme.black};
      background-color: ${props => props.theme.sandyGreen};
    }
    > div:last-child {
      margin: auto;
      margin-top: 0;
    }
  }
`;

export const ToolbarPaper = styled(Paper)`
  && {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const ToolbarButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: ${props => props.theme.deepBlue};
  }
  &:first-child {
    margin-right: 8px;
  }
  &:not(:first-child) {
    margin-left: 8px
  }
`;

export const ResultViewButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 40px;
  width: 100px;
  background-color: #94aabf;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;