// @flow
// type imports
import type { AppState } from "../../../types";
// module imports
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { compose } from "redux";
import ReactTable from "react-table";
import { Query } from "@apollo/client/react/components";
import Popup from "reactjs-popup";
import {
  ContentContainer,
  PopupTitleContainer,
  PopupTitle,
  PopupBodyContainer,
  ReceiptLink,
  CloseButton,
  PopupFooterContainer,
} from "./styled";
import { Spinner, styledTable } from "../../common";
import { PAYMENT_HISTORY_BY_CONTRACT_QUERY } from "../../../utils/graphql/queries";
import { DefaultCellSpan } from "../styled";
import { insertCommas } from "../../../utils/rest";

const SortableTable = compose(styledTable)(ReactTable);

type OwnProps = {
  open: boolean,
  orgzId: number,
  onClose: () => any,
};

const paymentStatusToString = (paymentStatus: number) => {
  switch (paymentStatus) {
    case 0:
      return "결제 대기";
    case 1:
      return "결제 완료";
    case 2:
      return "결제 승인 대기";
    case 3:
      return "결제 승인 중";
    case 20:
      return "결제 취소";
    case -20:
      return "결제 취소 실패";
    case -30:
      return "결제 취소 중";
    case -1:
      return "결제 실패";
    case -2:
      return "결제 승인 실패";
    default:
      return "UNKNOWN";
  }
};

export const PaymentHistoryPopup = ({ open, orgzId, onClose }: OwnProps) => {
  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={() => {
        console.log("payment history popup close");
        onClose();
      }}
      onOpen={() => {
        console.log("payment history popup open");
      }}
      overlayStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        margin: 0,
        zIndex: 10000,
      }}
      contentStyle={{
        backgroundColor: "#fff",
        boxShadow: "4px 11px 11px 0 rgba(0, 0, 0, 0.1)",
        border: "none",
        width: "1200px",
        height: "758px",
        padding: 0,
      }}
    >
      {(close) => (
        <ContentContainer>
          <PopupTitleContainer>
            <PopupTitle>결제 내역</PopupTitle>
          </PopupTitleContainer>
          <PopupBodyContainer style={{ maxHeight: "640px" }}>
            <Query
              query={PAYMENT_HISTORY_BY_CONTRACT_QUERY}
              variables={{
                orgzId: orgzId,
              }}
              fetchPolicy="network-only"
            >
              {({ loading, data }) => {
                let paymentHistory = _.map(
                  (data && data.paymentHistory) || [],
                  (receipt) => ({
                    ...receipt,
                    paymentData: JSON.parse(receipt.paymentData),
                  })
                );
                console.log(paymentHistory);
                return (
                  <SortableTable
                    data={_.orderBy(paymentHistory, ["purchasedAt"], ["desc"])}
                    loading={loading}
                    minRows={0}
                    LoadingComponent={Spinner}
                    columns={[
                      {
                        id: "purchasedAt",
                        Header: "결제 일시",
                        accessor: (rowData) => rowData.purchasedAt,
                        Cell: (rowInfo) => {
                          return (
                            <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>
                          );
                        },
                        minWidth: 140,
                      },
                      {
                        id: "billedDate",
                        Header: "청구서",
                        accessor: (rowData) =>
                          rowData.itemName || rowData.paymentData.n,
                        Cell: (rowInfo) => {
                          return (
                            <DefaultCellSpan>
                              {rowInfo.value.slice(-9).trim().slice(1, -1)}
                            </DefaultCellSpan>
                          );
                        },
                        minWidth: 140,
                      },
                      {
                        id: "price",
                        Header: "총 결제 금액(VAT 포함)",
                        accessor: (rowData) => rowData.price,
                        Cell: (rowInfo) => {
                          return (
                            <DefaultCellSpan>
                              {insertCommas(String(rowInfo.value))}원
                            </DefaultCellSpan>
                          );
                        },
                        minWidth: 200,
                      },
                      {
                        id: "methodName",
                        Header: "결제 방법",
                        accessor: (rowData) => rowData.methodName,
                        Cell: (rowInfo) => {
                          return (
                            <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>
                          );
                        },
                        minWidth: 140,
                      },
                      {
                        id: "status",
                        accessor: (rowData) => rowData.status,
                        Header: "결제 상태",
                        Cell: (rowInfo) => {
                          return (
                            <DefaultCellSpan>
                              {paymentStatusToString(rowInfo.value)}
                            </DefaultCellSpan>
                          );
                        },
                        minWidth: 140,
                      },
                      {
                        id: "receiptUrl",
                        accessor: (rowData) => rowData.receiptUrl,
                        Header: "영수증",
                        Cell: (rowInfo) => {
                          return !!rowInfo.value ? (
                            <ReceiptLink
                              href={`${rowInfo.value}`}
                              target="_blank"
                            >
                              <span>영수증</span>
                            </ReceiptLink>
                          ) : null;
                        },
                        minWidth: 70,
                      },
                    ]}
                    showPagination={true}
                    resizable={true}
                    multiSort={true}
                    sortable={true}
                    noDataText={"결제 내역이 없습니다."}
                  />
                );
              }}
            </Query>
          </PopupBodyContainer>
          <PopupFooterContainer>
            <CloseButton
              className="with-hover-overlay"
              onClick={(event) => {
                event.preventDefault();
                close();
              }}
              href="#"
            >
              <span>닫기</span>
            </CloseButton>
          </PopupFooterContainer>
        </ContentContainer>
      )}
    </Popup>
  );
};
