// @flow
import gql from 'graphql-tag';
import { Disease } from './fragments';

export const LOGIN_MUTATION = gql`
  mutation($userCredentials: any) {
    authResponse(userCredentials: $userCredentials)
    @rest(
      type: "AuthResponse"
      endpoint: "auth"
      path: "auth/token"
      method: "POST"
      bodyKey: "userCredentials"
    ) {
      access_token
      refresh_token
    }
  }
`;

export const AGREE_MUTATION = gql`
  mutation($agreed: Boolean) {
    agree(agreed: $agreed)
  }
`;

export const NEW_SBJT_MUTATION = gql`
  mutation($subjectIn: SubjectInput!) {
    newSbjt(subjectIn: $subjectIn) {
      id
      code
      name
      sex
      birthday
      handedness
      comment
      tel
      email
      address
      manager
      indications {
        ...Disease
      }
    }
  }
  ${Disease}
`;

export const UPDATE_SBJT_MUTATION = gql`
  mutation($id: ID!, $subjectIn: SubjectInput!) {
    updateSbjt(id: $id, subjectIn: $subjectIn) {
      id
      code
      name
      sex
      birthday
      handedness
      tel
      email
      address
      comment
      manager
      indications {
        ...Disease
      }
    }
  }
  ${Disease}
`;

export const DELETE_SBJT_MUTATION = gql`
  mutation($id: ID!) {
    deleteSbjtById(id: $id)
  }
`;

export const DELETE_SBJTS_MUTATION = gql`
  mutation($ids: [ID]!) {
    deleteSbjtsById(ids: $ids)
  }
`;

export const DELETE_ANLZ_SRCS_MUTATION = gql`
  mutation($srcIds: [ID]!) {
    deleteAnlzSrcs(anlzSrcIds: $srcIds) {
      id
    }
  }
`;

export const CREATE_EMP_MUTATION = gql`
  mutation($empIn: EmployeeInput!, $lang: String, $signinUrl: String) {
    newEmp(empIn: $empIn, lang: $lang, signinUrl: $signinUrl) {
      id
    }
  }
`;

export const UPDATE_EMP_ROLE_MUTATION = gql`
  mutation($email: String!, $role: EmployeeRole!) {
    updateEmpRole(email: $email, role: $role)
  }
`;

export const ADD_DEFAULT_BOOKMARKS_MUTATION = gql`
  mutation($orgzId: ID!, $empEmail: String!) {
    addDefaultBookmarks(orgzId: $orgzId, empEmail: $empEmail)
  }
`;

export const DELETE_EMP_MUTATION = gql`
  mutation($email: String!) {
    deleteEmpOrRoleByEmail(email: $email)
  }
`;

export const CREATE_SURVEY_SESSION_MUTATION = gql`
  mutation($subjectId: ID!, $surveyId: ID!, $expiredMin: Int) {
    createSurveySession(subjectId: $subjectId, surveyId: $surveyId, expiredMin: $expiredMin) {
      url
      token
    }
  }
`;

export const DELETE_SURVEY_SESSIONS_MUTATION = gql`
  mutation($sessionIds: [ID]!) {
    deleteSurveySessions(sessionIds: $sessionIds)
  }
`;

export const UPDATE_ME_MUTATION = gql`
  mutation($empIn: EmployeeInput!) {
    updateMe(empIn: $empIn) {
      id
      name
      email
      orgz {
        name
      }
      tel
    }
  }
`;

export const UPDATE_PWD_MUTATION = gql`
  mutation($oldPwd: String, $newPwd: String) {
    updatePassword(oldPwd: $oldPwd, newPwd: $newPwd) {
      id
    }
  }
`;

export const CREATE_ANLZ_REQ_MUTATION = gql`
  mutation($anlzReqInput: AnlzRequestInput!, $timeZone: String) {
    createAnlzReq(anlzReqInput: $anlzReqInput, timeZone: $timeZone) {
      id
    }
  }
`;

export const DELETE_ANLZ_REQS_MUTATION = gql`
  mutation($anlzReqIds: [ID]!) {
    deleteAnlzReqs(anlzReqIds: $anlzReqIds) {
      id
    }
  }
`;

export const CANCEL_ANLZ_REQ_MUTATION = gql`
  mutation($anlzReqId: ID!) {
    cancelAnlzReqById(anlzReqId: $anlzReqId) {
      id
    }
  }
`;

export const ORGZ_TOGGLE_SVC_MUTATION = gql`
  mutation($orgzId: ID!, $enable: Boolean!) {
    enableOrgzSvc(orgzId: $orgzId, enable: $enable) {
      id
      services
    }
  }
`;

export const REGISTER_ORGZ_ADMIN_MUTATION = gql`
  mutation($orgzId: ID!, $empIn: EmployeeInput!, $lang: String, $signinUrl: String) {
    addEmpAdmin(orgzId: $orgzId, empIn: $empIn, lang: $lang, signinUrl: $signinUrl) {
      id
      email
      name
    }
  }
`;

export const NEW_ORGZ_MUTATION = gql`
  mutation($orgzIn: OrganizationInput) {
    newOrgz(orgzIn: $orgzIn) {
      id
    }
  }
`;

export const ICD_BOOKMARK_MUTATION = gql`
  mutation($indicationId: ID!) {
    addOrDeleteBookmark(indicationId: $indicationId)
  }
`;

export const SEND_KAKAO_MESSAGE_MUTATION = gql`
  mutation($kakaoFriendTalkInput: KakaoFriendTalkInput) {
    sendKakaoFriendTalk(kakaoFriendTalkInput: $kakaoFriendTalkInput) {
      uid
      status
      date
    }
  }
`;