// @flow
// type imports
import type { AppState } from '../../../types';
import type { ICDFilter } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Query, Mutation } from '@apollo/client/react/components';
import BaseSelect from 'react-select';
import { patientFormActions } from '../../../ducks/analysis';
import {
  Container,
  FilterRow,
  CodeSearch,
  CodeListContainer
} from './styled';
import analysisImages from '../../../resources/images/analysis';
import { styledSelect } from '../../common';
import { ICDCodeList } from '../ICDCodeList/ICDCodeList';
import { ICD_FILTER_OPTION_QUERY, ICD_FILTER_OPTION_GROUP_QUERY } from '../../../utils/graphql/queries';

type OwnProps = {
  onCodeSelect: (code: any) => any,
}

type StateProps = {
  icdFilter: ICDFilter,
  selectedCodes: any[]
}

type ActionProps = {
  addICDCode: typeof patientFormActions.addICDCode,
  removeICDCode: typeof patientFormActions.removeICDCode,
  setICDSearchStr: typeof patientFormActions.setICDSearchStr,
  setICDGroupFilter: typeof patientFormActions.setICDGroupFilter,
  clearICDGroupFilter: typeof patientFormActions.clearICDGroupFilter,
  resetICDFilters: typeof patientFormActions.resetICDFilters
};

type Props = OwnProps & StateProps & ActionProps;

const Select = styledSelect(BaseSelect);

class ICDSelectFormComponent extends React.Component<Props> {
  render() {
    const {
      icdFilter
    } = this.props;
    return (
      <Container>
        <FilterRow>
          <span>질병검색</span>
          <CodeSearch>
            <input
              type='text'
              placeholder='질병코드나 질병이름을 입력하세요'
              value={icdFilter.searchStr}
              onChange={(e) => {
                if (icdFilter.group !== null || icdFilter.superGroup !== null) this.props.clearICDGroupFilter();
                this.props.setICDSearchStr(e.target.value);
              }}
            />
            <img
              src={analysisImages.searchSmall}
              alt='Search small'
            />
          </CodeSearch>
        </FilterRow>
        <FilterRow>
          <span>대분류</span>
          <Query
            query={ICD_FILTER_OPTION_QUERY}
            context={{
              dataSourceType: 'api'
            }}
            fetchPolicy='network-only'
            notifyOnNetworkStatusChange
            variables={{
              category: 'SECTION',
              page: 0,
              size: 20
            }}
          >
            {({ loading, data, error, fetchMore }) => {
              return (
                <Select
                  options={data && data.indicationsByCategory && data.indicationsByCategory.indications}
                  getOptionLabel={(code) => `${code.code} ${code.name}`}
                  getOptionValue={(code) => code.id}
                  isLoading={loading}
                  placeholder=''
                  isSearchable={false}
                  isClearable
                  backspaceRemovesValue={false}
                  value={icdFilter.superGroup}
                  onChange={(option) => {
                    if (option !== null) {
                      if (icdFilter.searchStr !== '') this.props.setICDSearchStr('');
                      if (icdFilter.group !== null) this.props.setICDGroupFilter('group', null);
                    }
                    this.props.setICDGroupFilter('superGroup', option);
                  }}
                  styles={{
                    clearIndicator: (styles, state) => ({
                      ...styles,
                      padding: 0
                    })
                  }}
                  minHeight='22px'
                  fontSize='11px'
                  lineHeight='13px'
                  borderColor='#ebf0f4'
                  onMenuScrollToBottom={_.debounce((event: SyntheticEvent<HTMLElement>) => {
                    if (!loading && data.indicationsByCategory.pageInfo.nowPage + 1 < data.indicationsByCategory.pageInfo.totalPages) {
                      fetchMore({
                        variables: {
                          page: data.indicationsByCategory.pageInfo.nowPage + 1
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          console.log(prev);
                          console.log(fetchMoreResult);
                          if (!fetchMoreResult) return prev;
                          const {
                            pageInfo,
                            indications
                          } = fetchMoreResult.indicationsByCategory
                          return {
                            ...prev,
                            indicationsByCategory: {
                              ...prev.indicationsByCategory,
                              pageInfo,
                              indications: [...prev.indicationsByCategory.indications, ...indications]
                            }
                          }
                        }
                      })
                    }
                  })}
                  showLoadMore
                />
              );
            }}
          </Query>
        </FilterRow>
        <FilterRow>
          <span>중분류</span>
          <Query
            query={(icdFilter.superGroup !== null) ? ICD_FILTER_OPTION_GROUP_QUERY : ICD_FILTER_OPTION_QUERY}
            context={{
              dataSourceType: 'api'
            }}
            fetchPolicy='network-only'
            notifyOnNetworkStatusChange
            variables={(icdFilter.superGroup !== null) ? {
              parentId: icdFilter.superGroup.id,
              page: 0,
              size: 20
            } : {
              category: 'DIVISION',
              page: 0,
              size: 20
            }}
          >
            {({ data, error, loading, fetchMore }) => {
              const dataKey = (icdFilter.superGroup !== null) ? 'indicationsByParentId' : 'indicationsByCategory';
              return (
                <Select
                  options={data && data[dataKey] && data[dataKey].indications}
                  getOptionLabel={(code) => `${code.code} ${code.name}`}
                  getOptionValue={(code) => code.id}
                  isLoading={loading}
                  placeholder=''
                  isSearchable={false}
                  isClearable
                  backspaceRemovesValue={false}
                  value={icdFilter.group}
                  onChange={(option) => {
                    if (option !== null && icdFilter.searchStr !== '') this.props.setICDSearchStr('');
                    this.props.setICDGroupFilter('group', option);
                  }}
                  styles={{
                    clearIndicator: (styles, state) => ({
                      ...styles,
                      padding: 0
                    })
                  }}
                  minHeight='22px'
                  fontSize='11px'
                  lineHeight='13px'
                  borderColor='#ebf0f4'
                  onMenuScrollToBottom={_.debounce((event: SyntheticEvent<HTMLElement>) => {
                    if (!loading && data[dataKey].pageInfo.nowPage + 1 < data[dataKey].pageInfo.totalPages) {
                      fetchMore({
                        variables: {
                          page: data[dataKey].pageInfo.nowPage + 1
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          console.log(prev);
                          console.log(fetchMoreResult);
                          if (!fetchMoreResult) return prev;
                          const {
                            pageInfo,
                            indications
                          } = fetchMoreResult[dataKey]
                          return {
                            ...prev,
                            [dataKey]: {
                              ...prev[dataKey],
                              pageInfo,
                              indications: [...prev[dataKey].indications, ...indications]
                            }
                          }
                        }
                      })
                    }
                  })}
                  showLoadMore
                />
              );
            }}
          </Query>
        </FilterRow>
        <CodeListContainer>
          <ICDCodeList mode='search'/>
        </CodeListContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    icdFilter: state.analysis.patientForm.icdFilter,
    selectedCodes: state.analysis.patientForm.patientDetails.icdCodes
  };
};

export const ICDSelectForm: React.ComponentType<OwnProps> = connect(mapStateToProps, {
  addICDCode: patientFormActions.addICDCode,
  removeICDCode: patientFormActions.removeICDCode,
  setICDSearchStr: patientFormActions.setICDSearchStr,
  setICDGroupFilter: patientFormActions.setICDGroupFilter,
  clearICDGroupFilter: patientFormActions.clearICDGroupFilter,
  resetICDFilters: patientFormActions.resetICDFilters
})(ICDSelectFormComponent);