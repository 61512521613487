// @flow
import * as React from 'react';
import styled from 'styled-components';
import 'spinkit/css/spinkit.css';

type Props = {
  loading?: boolean,
  spinnerColor?: string,
  containerStyle?: any
}

const SpinnerContainer = styled.div`
  &.sk-wave {
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin: auto;
    transition: all .1s ease-in;
    z-index: ${props => props.isLoading ? 1 : -1};
    opacity: ${props => props.isLoading ? 1 : 0};
  }
  > div.sk-rect {
    width: 3px;
    margin-right: 2px;
    background-color: ${props => props.spinnerColor || '#00bbf9'};
  }
`;

export const Spinner = ({ loading, spinnerColor, containerStyle }: Props) => {
  if (loading === undefined) {
    loading = true;
  }
  return (
    <SpinnerContainer isLoading={loading} spinnerColor={spinnerColor} style={containerStyle} className="sk-wave">
      <div className="sk-rect sk-rect1"></div>
      <div className="sk-rect sk-rect2"></div>
      <div className="sk-rect sk-rect3"></div>
      <div className="sk-rect sk-rect4"></div>
      <div className="sk-rect sk-rect5"></div>
    </SpinnerContainer>
  );
};
