// @flow
import * as React from 'react';
import styled from 'styled-components';
import { PlainPanel } from '../../common';

type ImgProps = {
  alt: string,
  src: any,
  width: number,
  height: number
};

type Props = {
  imgProps: ImgProps,
  renderInfo: () => React.Node
};

export const Container = styled.div`
  display: flex;
  flex: 1 1 0%;
  margin-top: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CompanyInfoPanel = ({ imgProps, renderInfo }: Props) => {
  const {
    alt,
    src,
    width,
    height
  } = imgProps;
  return (
    <PlainPanel
      headless
    >
      <Container>
        <img src={src} alt={alt} width={width} height={height} style={{ objectFit: 'contain' }} />
        {renderInfo()}
      </Container>
    </PlainPanel>
  );
};
