// @flow
// type imports
import moment from "moment";
import * as React from "react";
import ReactTable from "react-table";
import { compose } from "redux";
import { ControlledPanel, Spinner, styledTable } from "../../common";
import { DefaultCellSpan } from "../styled";

type OwnProps = {
  loading: boolean,
  noDataText: string,
  billingItemDetails: any[],
};

// TODO: move BillingForm out to Analysis screen component
type Props = OwnProps;

const SortableTable = compose(styledTable)(ReactTable);

class BillingItemDetailPanelComponent extends React.Component<Props> {
  render() {
    const { billingItemDetails, loading, noDataText } = this.props;
    return (
      <ControlledPanel>
        <ControlledPanel.Content>
          <SortableTable
            data={billingItemDetails}
            loading={loading}
            minRows={0}
            LoadingComponent={Spinner}
            columns={[
              {
                id: "id",
                Header: "ID",
                accessor: (rowData) => rowData.billingRecordId,
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                minWidth: 40,
              },
              {
                id: "creationTime",
                Header: "분석파일 생성일시",
                accessor: (rowData) => rowData.creationTime,
                Cell: (rowInfo) => {
                  return (
                    <DefaultCellSpan>
                      {moment(rowInfo.value).format("YYYY-MM-DDTkk:mm:ss")}
                    </DefaultCellSpan>
                  );
                },
                minWidth: 140,
              },
              {
                id: "subjectCode",
                Header: "대상자 코드",
                accessor: (rowData) => rowData.subjectCode,
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                minWidth: 140,
              },
              {
                id: "eegRecordFileName",
                Header: "EEG 레코드 파일",
                accessor: (rowData) => rowData.sessionName,
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                minWidth: 140,
              },
              {
                id: "analysisFileName",
                Header: "분석파일",
                accessor: (rowData) => rowData.sessionName,
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                minWidth: 140,
              },
              {
                id: "price",
                accessor: (rowData) => rowData.unitPriceWithVat,
                Header: "금액(VAT 포함)",
                Cell: (rowInfo) => {
                  return <DefaultCellSpan>{rowInfo.value}</DefaultCellSpan>;
                },
                minWidth: 140,
              },
            ]}
            showPagination={true}
            resizable={true}
            multiSort={true}
            sortable={true}
            noDataText={noDataText}
          />
        </ControlledPanel.Content>
      </ControlledPanel>
    );
  }
}

export const BillingItemDetailPanel = BillingItemDetailPanelComponent;
