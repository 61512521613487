// @flow
// type imports
import type { PatientDetailTabsState } from '../../types/analysis';

const SET_ACTIVE_TAB: 'sd/a/patientdetailtabs/SET_ACTIVE_TAB' = 'sd/a/patientdetailtabs/SET_ACTIVE_TAB';

type SetActiveTabAction = {
  type: typeof SET_ACTIVE_TAB,
  payload: string
};

type PatientDetailTabsActions = SetActiveTabAction;

const INITIAL_STATE: PatientDetailTabsState = {
  activeTab: 'anlzSources'
}

export default function reducer(state: PatientDetailTabsState = INITIAL_STATE, action: PatientDetailTabsActions): PatientDetailTabsState {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        activeTab: action.payload
      };
    default: return state;
  }
};

const setActiveTab = (tabKey: string): SetActiveTabAction => {
  return {
    type: SET_ACTIVE_TAB,
    payload: tabKey
  };
};

export const actionCreators = {
  setActiveTab
};
