// @flow
// type imports
import type { Patient } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { Query, Mutation } from '@apollo/client/react/components';
import BaseSelect from 'react-select';
import { MultiStepForm, styledSelect, Spinner, Checkbox } from '../../common';
import { CREATE_ANLZ_REQ_MUTATION } from '../../../utils/graphql/mutations';
import {
  ANLZ_REQ_SRC_SELECT_QUERY,
  ANLZ_REQ_SURVEY_SESSION_SELECT_QUERY,
  ANLZ_REQUESTS_BY_SBJTID_QUERY
} from '../../../utils/graphql/queries';
import {
  Container,
  TitleContainer,
  Title,
  FormContainer,
  SelectHeading,
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  ConfirmMessage,
  SelectedFiles,
  FinePrintMessage
} from './styled';
import commonImages from '../../../resources/images/common';

const Select = styledSelect(BaseSelect);

type Props = {
  selectedPatient: Patient | null,
  mode: 'anlzRequests' | 'compAnlzRequests',
  trigger: React.Node
};

type AnlzSourceOption = {
  created: string,
  id: string,
  visualFile: string
};

type SurveySessionOption = {
  created: string,
  pubSurveyDesc: string,
  pubSurveyName: string,
  sessionId: string,
  createdTime: string,
  submittedTime: string,
  submitted: boolean
}

type State = {
  selectedAnlzSource: AnlzSourceOption | null,
  addlAnlzSource: AnlzSourceOption | null,
  selectedSurveySessions: SurveySessionOption[],
  lastLoadedSrcPg: number,
  lastLoadedSurveyPg: number,
  enableICA: boolean,
  addAppendix: boolean
};

class AnlzRequestForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedAnlzSource: null,
      addlAnlzSource: null,
      selectedSurveySessions: [],
      lastLoadedSrcPg: 0,
      lastLoadedSurveyPg: 0,
      enableICA: true,
      addAppendix: false
    };
  }
  render() {
    const {
      trigger,
      selectedPatient,
      mode
    } = this.props;
    const {
      selectedAnlzSource,
      addlAnlzSource,
      selectedSurveySessions,
      enableICA,
      addAppendix
    } = this.state;
    return (
      <MultiStepForm
        modal
        trigger={trigger}
        numStep={2}
        confirmAfterSubmit
      >
        {({ step, confirmStep, nextStep, submit, confirm, cancel }) => {
          switch (step) {
            case 0:
              return (
                <Container>
                  <TitleContainer>
                    {mode === 'anlzRequests' ? (
                      <Title style={{ padding: '30px 0' }}>분석할 <span>분석용 파일</span>과 <span>설문기록</span>을 <span>선택</span>해주세요</Title>
                    ) : (
                      <Title style={{ padding: '30px 0' }}>비교 분석할 <span>분석용 파일</span> 두개를 <span>선택</span>해주세요</Title>
                    )}
                  </TitleContainer>
                  <FormContainer>
                    {mode === 'anlzRequests' ? (
                      <React.Fragment>
                        <Query
                          query={ANLZ_REQ_SRC_SELECT_QUERY}
                          context={{
                            dataSourceType: 'api'
                          }}
                          onCompleted={(data) => {
                            if (data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.sources.length > 0) {
                              this.setState({
                                selectedAnlzSource: null
                              });
                            }
                          }}
                          fetchPolicy='network-only'
                          variables={{
                            subjectId: selectedPatient && selectedPatient.id,
                            page: 0,
                            size: 10
                          }}
                        >
                          {({ loading, data, error, fetchMore }) => {
                            return (
                              <React.Fragment>
                                {mode === 'anlzRequests' ? (
                                  <SelectHeading>분석용 파일</SelectHeading>
                                ) : (
                                  <SelectHeading>분석용 파일 Post</SelectHeading>
                                )}
                                <Select
                                  options={data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.sources}
                                  isLoading={loading}
                                  placeholder=''
                                  isSearchable={false}
                                  isClearable={false}
                                  backspaceRemovesValue={false}
                                  value={selectedAnlzSource}
                                  onMenuScrollToBottom={_.debounce((event: SyntheticEvent<HTMLElement>) => {
                                    if (!loading && data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1 < data.anlzSrcPgBySbjtId.pageInfo.totalPages) {
                                      fetchMore({
                                        variables: {
                                          page: data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1
                                        },
                                        updateQuery: (prev, { fetchMoreResult }) => {
                                          console.log(prev);
                                          console.log(fetchMoreResult);
                                          if (!fetchMoreResult) return prev;
                                          const {
                                            pageInfo,
                                            sources
                                          } = fetchMoreResult.anlzSrcPgBySbjtId
                                          return {
                                            ...prev,
                                            anlzSrcPgBySbjtId: {
                                              ...prev.anlzSrcPgBySbjtId,
                                              pageInfo,
                                              sources: [...prev.anlzSrcPgBySbjtId.sources, ...sources]
                                            }
                                          }
                                        }
                                      })
                                    }
                                  })}
                                  showLoadMore
                                  moreOptionsAvailable={data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1 < data.anlzSrcPgBySbjtId.pageInfo.totalPages}
                                  getOptionValue={(option: AnlzSourceOption) => option.id}
                                  getOptionLabel={(option: AnlzSourceOption) => `${moment(option.recorded).format('YYYY-MM-DD')}   ${option.visualFile}`}
                                  styles={{
                                    container: styles => ({
                                      ...styles,
                                      margin: '11px 15px 0 15px'
                                    })
                                  }}
                                  components={{
                                    LoadingIndicator: () => <Spinner containerStyle={{ height: '80%', marginRight: '20px' }} />
                                  }}
                                  onChange={(option) => this.setState({ selectedAnlzSource: option })}
                                />
                              </React.Fragment>
                            );
                          }}
                        </Query>
                        <Query
                          query={ANLZ_REQ_SURVEY_SESSION_SELECT_QUERY}
                          context={{
                            dataSourceType: 'api'
                          }}
                          fetchPolicy='network-only'
                          variables={{
                            subjectId: selectedPatient && selectedPatient.id,
                            page: 0,
                            size: 10
                          }}
                        >
                          {({ loading, data, error, fetchMore }) => {
                            return (
                              <React.Fragment>
                                <SelectHeading>설문기록</SelectHeading>
                                <Select
                                  options={data && data.surveySessionSummaries && data.surveySessionSummaries.surveySessions}
                                  isLoading={loading}
                                  placeholder=''
                                  isSearchable={false}
                                  isClearable
                                  isMulti
                                  value={selectedSurveySessions}
                                  filterOption={(option, strIn) => {
                                    const selectedSurveyNames = _.map(this.state.selectedSurveySessions, (session) => session.pubSurveyName);
                                    return !_.includes(selectedSurveyNames, option.data.pubSurveyName);
                                  }}
                                  onMenuScrollToBottom={_.debounce((event: SyntheticEvent<HTMLElement>) => {
                                    if (!loading && data.surveySessionSummaries.pageInfo.nowPage + 1 < data.surveySessionSummaries.pageInfo.totalPages) {
                                      fetchMore({
                                        variables: {
                                          page: data.surveySessionSummaries.pageInfo.nowPage + 1
                                        },
                                        updateQuery: (prev, { fetchMoreResult }) => {
                                          console.log(prev);
                                          console.log(fetchMoreResult);
                                          if (!fetchMoreResult) return prev;
                                          const {
                                            pageInfo,
                                            surveySessions
                                          } = fetchMoreResult.surveySessionSummaries
                                          return {
                                            ...prev,
                                            surveySessionSummaries: {
                                              ...prev.surveySessionSummaries,
                                              pageInfo,
                                              surveySessions: [...prev.surveySessionSummaries.surveySessions, ...surveySessions]
                                            }
                                          }
                                        }
                                      })
                                    }
                                  })}
                                  showLoadMore
                                  moreOptionsAvailable={data && data.surveySessionSummaries && data.surveySessionSummaries.pageInfo.nowPage + 1 < data.surveySessionSummaries.pageInfo.totalPages}
                                  getOptionValue={(option: SurveySessionOption) => option.sessionId}
                                  getOptionLabel={(option: SurveySessionOption) => `${moment(option.createdTime).format('YYYY-MM-DD')}   ${option.pubSurveyName}${(option.pubSurveyDesc) ? `(${option.pubSurveyDesc.split('(')[0]})` : ''}`}
                                  getMultiValueLabel={(option: SurveySessionOption) => `${moment(option.createdTime).format('MM-DD')} ${option.pubSurveyName}`}
                                  styles={{
                                    container: styles => ({
                                      ...styles,
                                      margin: '11px 15px 0 15px'
                                    })
                                  }}
                                  components={{
                                    LoadingIndicator: () => <Spinner containerStyle={{ height: '80%', marginRight: '20px' }} />
                                  }}
                                  onChange={(option) => {
                                    console.log(option);
                                    this.setState({ selectedSurveySessions: option });
                                  }}
                                />
                              </React.Fragment>
                            );
                          }}
                        </Query>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Query
                          query={ANLZ_REQ_SRC_SELECT_QUERY}
                          context={{
                            dataSourceType: 'api'
                          }}
                          onCompleted={(data) => {
                            if (data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.sources.length > 0) {
                              this.setState({
                                addlAnlzSource: null
                              });
                            }
                          }}
                          fetchPolicy='network-only'
                          variables={{
                            subjectId: selectedPatient && selectedPatient.id,
                            page: 0,
                            size: 10
                          }}
                        >
                          {({ loading, data, error, fetchMore }) => {
                            return (
                              <React.Fragment>
                                <SelectHeading>분석용 파일 Pre</SelectHeading>
                                <Select
                                  options={data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.sources}
                                  isLoading={loading}
                                  placeholder=''
                                  isSearchable={false}
                                  isClearable={false}
                                  backspaceRemovesValue={false}
                                  value={addlAnlzSource}
                                  onMenuScrollToBottom={_.debounce((event: SyntheticEvent<HTMLElement>) => {
                                    if (!loading && data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1 < data.anlzSrcPgBySbjtId.pageInfo.totalPages) {
                                      fetchMore({
                                        variables: {
                                          page: data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1
                                        },
                                        updateQuery: (prev, { fetchMoreResult }) => {
                                          console.log(prev);
                                          console.log(fetchMoreResult);
                                          if (!fetchMoreResult) return prev;
                                          const {
                                            pageInfo,
                                            sources
                                          } = fetchMoreResult.anlzSrcPgBySbjtId
                                          return {
                                            ...prev,
                                            anlzSrcPgBySbjtId: {
                                              ...prev.anlzSrcPgBySbjtId,
                                              pageInfo,
                                              sources: [...prev.anlzSrcPgBySbjtId.sources, ...sources]
                                            }
                                          }
                                        }
                                      })
                                    }
                                  })}
                                  showLoadMore
                                  moreOptionsAvailable={data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1 < data.anlzSrcPgBySbjtId.pageInfo.totalPages}
                                  getOptionValue={(option: AnlzSourceOption) => option.id}
                                  getOptionLabel={(option: AnlzSourceOption) => `${moment(option.recorded).format('YYYY-MM-DD')}   ${option.visualFile}`}
                                  styles={{
                                    container: styles => ({
                                      ...styles,
                                      margin: '11px 15px 0 15px'
                                    })
                                  }}
                                  components={{
                                    LoadingIndicator: () => <Spinner containerStyle={{ height: '80%', marginRight: '20px' }} />
                                  }}
                                  onChange={(option) => {
                                    if (selectedAnlzSource && selectedAnlzSource.id === option.id) return
                                    this.setState({ addlAnlzSource: option });
                                  }}
                                />
                              </React.Fragment>
                            );
                          }}
                        </Query>
                        <Query
                          query={ANLZ_REQ_SRC_SELECT_QUERY}
                          context={{
                            dataSourceType: 'api'
                          }}
                          onCompleted={(data) => {
                            if (data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.sources.length > 0) {
                              this.setState({
                                selectedAnlzSource: null
                              });
                            }
                          }}
                          fetchPolicy='network-only'
                          variables={{
                            subjectId: selectedPatient && selectedPatient.id,
                            page: 0,
                            size: 10
                          }}
                        >
                          {({ loading, data, error, fetchMore }) => {
                            return (
                              <React.Fragment>
                                {mode === 'anlzRequests' ? (
                                  <SelectHeading>분석용 파일</SelectHeading>
                                ) : (
                                  <SelectHeading>분석용 파일 Post</SelectHeading>
                                )}
                                <Select
                                  options={data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.sources}
                                  isLoading={loading}
                                  placeholder=''
                                  isSearchable={false}
                                  isClearable={false}
                                  backspaceRemovesValue={false}
                                  value={selectedAnlzSource}
                                  onMenuScrollToBottom={_.debounce((event: SyntheticEvent<HTMLElement>) => {
                                    if (!loading && data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1 < data.anlzSrcPgBySbjtId.pageInfo.totalPages) {
                                      fetchMore({
                                        variables: {
                                          page: data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1
                                        },
                                        updateQuery: (prev, { fetchMoreResult }) => {
                                          console.log(prev);
                                          console.log(fetchMoreResult);
                                          if (!fetchMoreResult) return prev;
                                          const {
                                            pageInfo,
                                            sources
                                          } = fetchMoreResult.anlzSrcPgBySbjtId
                                          return {
                                            ...prev,
                                            anlzSrcPgBySbjtId: {
                                              ...prev.anlzSrcPgBySbjtId,
                                              pageInfo,
                                              sources: [...prev.anlzSrcPgBySbjtId.sources, ...sources]
                                            }
                                          }
                                        }
                                      })
                                    }
                                  })}
                                  showLoadMore
                                  moreOptionsAvailable={data && data.anlzSrcPgBySbjtId && data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1 < data.anlzSrcPgBySbjtId.pageInfo.totalPages}
                                  getOptionValue={(option: AnlzSourceOption) => option.id}
                                  getOptionLabel={(option: AnlzSourceOption) => `${moment(option.recorded).format('YYYY-MM-DD')}   ${option.visualFile}`}
                                  styles={{
                                    container: styles => ({
                                      ...styles,
                                      margin: '11px 15px 0 15px'
                                    })
                                  }}
                                  components={{
                                    LoadingIndicator: () => <Spinner containerStyle={{ height: '80%', marginRight: '20px' }} />
                                  }}
                                  onChange={(option) => {
                                    if (addlAnlzSource && addlAnlzSource.id === option.id) return
                                    this.setState({ selectedAnlzSource: option });
                                  }}
                                />
                              </React.Fragment>
                            );
                          }}
                        </Query>
                      </React.Fragment>
                    )}
                    <SelectHeading>
                      <Checkbox
                        checked={addAppendix}
                        onChange={(event) => this.setState({ addAppendix: event.currentTarget.checked })}
                        stopClickPropagation
                        checkedImgSrc={commonImages.checked}
                        uncheckedImgSrc={commonImages.check}
                      >
                        Appendix 추가하기
                      </Checkbox>
                    </SelectHeading>
                    <ButtonContainer>
                      <CancelButton
                        className='with-hover-overlay'
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            selectedAnlzSource: null,
                            selectedSurveySessions: [],
                            lastLoadedSrcPg: 0,
                            lastLoadedSurveyPg: 0
                          });
                          cancel();
                        }}
                        href='#'
                      >
                        <span>취소</span>
                      </CancelButton>
                      <ConfirmButton
                        className='with-hover-overlay'
                        disabled={(mode === 'anlzRequests') ? ((this.state.selectedAnlzSource === null) && (this.state.selectedSurveySessions.length === 0)) : ((this.state.selectedAnlzSource === null) || (this.state.addlAnlzSource === null))}
                        onClick={(e) => {
                          e.preventDefault();
                          nextStep();
                        }}
                      >
                        <span>확인</span>
                      </ConfirmButton>
                    </ButtonContainer>
                  </FormContainer>
                </Container>
              );
            case 1:
              if (!confirmStep) {
                const refetchQueries = [{
                  query: ANLZ_REQUESTS_BY_SBJTID_QUERY,
                  variables: {
                    subjectId: selectedPatient && selectedPatient.id,
                    anlzType: mode === 'anlzRequests' ? 'INDIVIDUAL' : 'COMPARISON',
                    page: 0,
                    size: 10
                  }
                }];
                return (
                  <Mutation
                    mutation={CREATE_ANLZ_REQ_MUTATION}
                    context={{
                      dataSourceType: 'api'
                    }}
                    refetchQueries={refetchQueries}
                  >
                    {(createAnlzRequest, { loading, error }) => {
                      const submitAction = () => createAnlzRequest({ variables: {
                        anlzReqInput: {
                          subjectId: selectedPatient && selectedPatient.id,
                          srcIds: (mode === 'anlzRequests') ? (selectedAnlzSource && [selectedAnlzSource.id]) : [selectedAnlzSource.id, addlAnlzSource.id],
                          surveySessionIds: (selectedSurveySessions.length > 0) ? _.map(selectedSurveySessions, (session) => session.sessionId) : null,
                          anlzType: (mode === 'anlzRequests') ? 'INDIVIDUAL' : 'COMPARISON',
                          anlzAlgorithm: (enableICA) ? 'ICA' : 'NONE',
                          appendix: addAppendix
                        },
                        timeZone: moment().format('Z')
                      }});
                      return (
                        <Container>
                          <TitleContainer>
                            {mode === 'anlzRequests' ? (
                              <Title style={{ padding: '20px 70px' }}>선택한 <span>분석용 파일</span>과 <span>설문기록</span>을 분석 요청 하시겠습니까?</Title>
                            ) : (
                              <Title style={{ padding: '20px 70px' }}>선택한 <span>분석용 파일</span> 두개를 비교 분석 요청 하시겠습니까?</Title>
                            )}
                          </TitleContainer>
                          <FormContainer>
                            {mode === 'anlzRequests' ? (
                              <React.Fragment>
                                <SelectHeading>분석용 파일</SelectHeading>
                                <SelectedFiles>
                                  {selectedAnlzSource && `${moment(selectedAnlzSource.recorded).format('YYYY-MM-DD')}   ${selectedAnlzSource.visualFile}`}
                                </SelectedFiles>
                                <SelectHeading>설문기록</SelectHeading>
                                {(selectedSurveySessions.length > 0) ? _.map(selectedSurveySessions, (session) => (
                                  <SelectedFiles key={session.sessionId}>
                                    {`${moment(session.createdTime).format('YYYY-MM-DD')}   ${session.pubSurveyName}${(session.pubSurveyDesc && session.pubSurveyDesc.length < 20) ? `(${session.pubSurveyDesc.split('(')[0]})` : ''}`}
                                  </SelectedFiles>
                                )) : <SelectedFiles>선택 안함</SelectedFiles>}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <SelectHeading>분석용 파일 Pre</SelectHeading>
                                <SelectedFiles>
                                  {`${moment(addlAnlzSource.recorded).format('YYYY-MM-DD')}   ${addlAnlzSource.visualFile}`}
                                </SelectedFiles>
                                <SelectHeading>분석용 파일 Post</SelectHeading>
                                <SelectedFiles>
                                  {selectedAnlzSource && `${moment(selectedAnlzSource.recorded).format('YYYY-MM-DD')}   ${selectedAnlzSource.visualFile}`}
                                </SelectedFiles>
                              </React.Fragment>
                            )}
                            <SelectHeading>Appendix 추가하기</SelectHeading>
                            <SelectedFiles>{(addAppendix) ? '사용 함' : '사용 안함'}</SelectedFiles>
                            <ButtonContainer>
                              {(loading) ? <Spinner /> : (
                                <React.Fragment>
                                  <CancelButton
                                    className='with-hover-overlay'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        selectedAnlzSource: null,
                                        selectedSurveySessions: [],
                                        lastLoadedSrcPg: 0,
                                        lastLoadedSurveyPg: 0
                                      });
                                      cancel();
                                    }}
                                    href='#'
                                  >
                                    <span>취소</span>
                                  </CancelButton>
                                  <ConfirmButton
                                    className='with-hover-overlay'
                                    disabled={(mode === 'anlzRequests') ? ((this.state.selectedAnlzSource === null) && (this.state.selectedSurveySessions.length === 0)) : ((this.state.selectedAnlzSource === null) || (this.state.addlAnlzSource === null))}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      submit(submitAction).catch((error) => console.log(error));
                                    }}
                                  >
                                    <span>확인</span>
                                  </ConfirmButton>
                                </React.Fragment>
                              )}
                            </ButtonContainer>
                          </FormContainer>
                        </Container>
                      );
                    }}
                  </Mutation>
                )
              }
              return (
                <Container>
                  <ConfirmMessage>
                    분석 요청이 완료되었습니다.<br/>정확한 분석을 위해 다소 시간이 걸릴 수 있으니 5분 후에 확인해주세요.
                  </ConfirmMessage>
                  <FinePrintMessage>
                    * 분석 요청중 상태에서는 분석취소가 가능합니다.
                  </FinePrintMessage>
                  <ButtonContainer style={{ padding: '0 15px', marginTop: '92px', marginBottom: '15px' }}>
                    <ConfirmButton
                      className='with-hover-overlay'
                      disabled={(mode === 'anlzRequests') ? ((this.state.selectedAnlzSource === null) && (this.state.selectedSurveySessions.length === 0)) : ((this.state.selectedAnlzSource === null) || (this.state.addlAnlzSource === null))}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          selectedAnlzSource: null,
                          selectedSurveySessions: [],
                          lastLoadedSrcPg: 0,
                          lastLoadedSurveyPg: 0
                        });
                        confirm();
                      }}
                    >
                      <span>확인</span>
                    </ConfirmButton>
                  </ButtonContainer>
                </Container>
              );
            default: {
              this.setState({
                selectedAnlzSource: null,
                selectedSurveySessions: [],
                lastLoadedSrcPg: 0,
                lastLoadedSurveyPg: 0
              });
              cancel();
              return null;
            }
          }
        }}
      </MultiStepForm>
    );
  }
}

export { AnlzRequestForm };