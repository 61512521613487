// @flow
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@material-ui/core";
import * as React from "react";
import NotiContents from "../../resources/images/noti";

const UpdateNotiPopup = ({ currentUser }) => {
  const [check, setCheck] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const storageData = localStorage.getItem("update_popup3");
      if (!storageData) {
        setOpen(true);
      }
    };

    getData();
  }, []);

  const handleClose = () => {
    if (check) {
      localStorage.setItem("update_popup3", true);
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth={"md"}>
      <DialogContent>
        <Box position={"relative"}>
          <img src={NotiContents.update_noti_20241112} />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "space-between", paddingLeft: "16px" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={check}
              onChange={(_, checked) => setCheck(checked)}
              color="primary"
              size="small"
            />
          }
          label="다시 보지 않기"
        />
        <Button onClick={handleClose} color="primary">
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateNotiPopup;
