// @flow
import styled from 'styled-components';
import { EllipsisSpan } from '../../common';
import { HoverStyledAnchorButton } from '../../common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex: 1 1 0%;
  width: 100%;
  padding: 0 30px 30px;
`;

export const SessionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  flex: 0 0 100px;
  border: solid 1px #bcc9d8;
`;

export const SessionInfoSpan = styled.span`
  font-size: 18px;
  color: #576881;
  margin-left: 15px;
`;

export const InfoDivider = styled.div`
  margin: 0 20px;
  background-color: #bcc9d8;
  width: 1px;
  height: 17px;
`;

export const SessionAnlzResSpan = styled(EllipsisSpan)`
  font-size: 18px;
  color: #00bbf9;
  margin: 0;
`;

const SessionDetailContainer = styled.div`
  background-color: #f6f8f9;
  width: 100%;
  padding: 40px 50px;
  margin-top: 10px;
  min-height: 250px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.16);
    transition: all .1s ease-in-out;
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.36);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(66,82,110,0.56);
  }
`;

export const ResultContainer = styled(SessionDetailContainer)`
  height: 410px;
`;

export const ResponseRow = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: space-between;
  color: #576881;
  font-size: 18px;
  &:not(:first-child) {
    margin-top: 15px;
  }
`;

const PRDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  span {
    font-weight: bold;
  }
  p {
    display: inline-block;
    margin: 0 0 0 8px;
    padding: 0;
  }
`;

export const ChoicePrompt = styled(PRDiv)`
  flex-direction: row;
  flex: 1 1 761px;
`;
export const ChoiceResponse = styled(PRDiv)`
  flex-direction: row;
  flex: 1 1 153px;
  margin-left: 40px;
`;

// TODO: figure out how to render other answer types
export const EssayPrompt = styled(PRDiv)`

`;
export const EssayResponse = styled(PRDiv)`
  span {
    color: transparent;
  }
`;

export const ContinueContainer = styled(SessionDetailContainer)`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SurveyIncompleteImage = styled.img`
  width: 53px;
  height: 51px;
  object-fit: contain;
`;

export const SurveyIncompleteMsgSpan = styled.span`
  font-size: 22px;
  color: #576881;
  margin-top: 16px;
`;

export const ContinueSurveyLink = styled(HoverStyledAnchorButton)`
  > span {
    color: #576881;
    font-size: 22px;
    font-weight: 400;
    text-decoration: underline;
  }
  &:hover, &:focus {
    > span {
      color: #00bbf9;
    }
  }
`;

export const PaginatorContainer = styled.div`
  display: flex;
  width: 100%;
  height: 45px;
  margin-top: 8px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const PagingInfo = styled.span`
  font-size: 18px;
  line-height: 1.17;
  color: #586881;
  min-width: 40px;
  text-align: center;
  .current-page {
    color: #00bbf9;
  }
`;

export const PageNavButton = styled(HoverStyledAnchorButton)`
  height: 45px;
  width: 45px;
  text-align: center;
`;