// @flow
// type imports
import type { Patient } from "../../../types/analysis";
// module imports
import _ from "lodash";
import * as React from "react";
import moment from "moment";
import { Query } from "@apollo/client/react/components";
import BaseSelect from "react-select";
import { MultiStepForm, styledSelect, Spinner } from "../../common";
import { ANLZ_REQ_SRC_SELECT_QUERY } from "../../../utils/graphql/queries";
import {
  Container,
  TitleContainer,
  Title,
  FormContainer,
  SelectHeading,
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  ConfirmMessage,
  SelectedFiles,
  FinePrintMessage,
} from "./styled";
import { requestTemperamentAnalysis } from "../../../utils/rest";

const Select = styledSelect(BaseSelect);

type Props = {
  selectedPatient: Patient | null,
  mode: "anlzRequests" | "compAnlzRequests",
  trigger: React.Node,
};

type AnlzSourceOption = {
  created: string,
  id: string,
  visualFile: string,
};

type State = {
  selectedAnlzSource: AnlzSourceOption | null,
  loadingRequest: boolean,
};

class TempAnlzRequestForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedAnlzSource: null,
      loadingRequest: false,
    };
  }

  async createAnlzRequest() {
    this.setState({ loadingRequest: true });
    console.log(this.props.currentUser);
    await requestTemperamentAnalysis(
      this.state.selectedAnlzSource.id,
      this.props.currentUser.tokens.access_token
    ).finally(() => {
      this.setState({ loadingRequest: false });
    });
  }

  render() {
    const { trigger, selectedPatient } = this.props;
    const { selectedAnlzSource } = this.state;
    return (
      <MultiStepForm modal trigger={trigger} numStep={2} confirmAfterSubmit>
        {({ step, confirmStep, nextStep, submit, confirm, cancel }) => {
          switch (step) {
            case 0:
              return (
                <Container>
                  <TitleContainer>
                    <Title style={{ padding: "30px 0" }}>
                      분석할 <span>분석용 파일</span>을 <span>선택</span>
                      해주세요
                    </Title>
                  </TitleContainer>
                  <FormContainer>
                    <React.Fragment>
                      <Query
                        query={ANLZ_REQ_SRC_SELECT_QUERY}
                        context={{
                          dataSourceType: "api",
                        }}
                        onCompleted={(data) => {
                          if (
                            data &&
                            data.anlzSrcPgBySbjtId &&
                            data.anlzSrcPgBySbjtId.sources.length > 0
                          ) {
                            this.setState({
                              selectedAnlzSource: null,
                            });
                          }
                        }}
                        fetchPolicy="network-only"
                        variables={{
                          subjectId: selectedPatient && selectedPatient.id,
                          page: 0,
                          size: 10,
                        }}
                      >
                        {({ loading, data, error, fetchMore }) => {
                          return (
                            <React.Fragment>
                              <SelectHeading>분석용 파일</SelectHeading>
                              <Select
                                options={
                                  data &&
                                  data.anlzSrcPgBySbjtId &&
                                  data.anlzSrcPgBySbjtId.sources
                                }
                                isLoading={loading}
                                placeholder=""
                                isSearchable={false}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                value={selectedAnlzSource}
                                onMenuScrollToBottom={_.debounce(
                                  (event: SyntheticEvent<HTMLElement>) => {
                                    if (
                                      !loading &&
                                      data.anlzSrcPgBySbjtId.pageInfo.nowPage +
                                        1 <
                                        data.anlzSrcPgBySbjtId.pageInfo
                                          .totalPages
                                    ) {
                                      fetchMore({
                                        variables: {
                                          page:
                                            data.anlzSrcPgBySbjtId.pageInfo
                                              .nowPage + 1,
                                        },
                                        updateQuery: (
                                          prev,
                                          { fetchMoreResult }
                                        ) => {
                                          console.log(prev);
                                          console.log(fetchMoreResult);
                                          if (!fetchMoreResult) return prev;
                                          const { pageInfo, sources } =
                                            fetchMoreResult.anlzSrcPgBySbjtId;
                                          return {
                                            ...prev,
                                            anlzSrcPgBySbjtId: {
                                              ...prev.anlzSrcPgBySbjtId,
                                              pageInfo,
                                              sources: [
                                                ...prev.anlzSrcPgBySbjtId
                                                  .sources,
                                                ...sources,
                                              ],
                                            },
                                          };
                                        },
                                      });
                                    }
                                  }
                                )}
                                showLoadMore
                                moreOptionsAvailable={
                                  data &&
                                  data.anlzSrcPgBySbjtId &&
                                  data.anlzSrcPgBySbjtId.pageInfo.nowPage + 1 <
                                    data.anlzSrcPgBySbjtId.pageInfo.totalPages
                                }
                                getOptionValue={(option: AnlzSourceOption) =>
                                  option.id
                                }
                                getOptionLabel={(option: AnlzSourceOption) =>
                                  `${moment(option.recorded).format(
                                    "YYYY-MM-DD"
                                  )}   ${option.visualFile}`
                                }
                                styles={{
                                  container: (styles) => ({
                                    ...styles,
                                    margin: "11px 15px 0 15px",
                                  }),
                                }}
                                components={{
                                  LoadingIndicator: () => (
                                    <Spinner
                                      containerStyle={{
                                        height: "80%",
                                        marginRight: "20px",
                                      }}
                                    />
                                  ),
                                }}
                                onChange={(option) =>
                                  this.setState({
                                    selectedAnlzSource: option,
                                  })
                                }
                              />
                            </React.Fragment>
                          );
                        }}
                      </Query>
                    </React.Fragment>
                    <ButtonContainer style={{ flexGrow: 1, alignItems: "end" }}>
                      <CancelButton
                        className="with-hover-overlay"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            selectedAnlzSource: null,
                          });
                          cancel();
                        }}
                        href="#"
                      >
                        <span>취소</span>
                      </CancelButton>
                      <ConfirmButton
                        className="with-hover-overlay"
                        disabled={this.state.selectedAnlzSource === null}
                        onClick={(e) => {
                          e.preventDefault();
                          nextStep();
                        }}
                      >
                        <span>확인</span>
                      </ConfirmButton>
                    </ButtonContainer>
                  </FormContainer>
                </Container>
              );
            case 1:
              if (!confirmStep) {
                return (
                  <Container>
                    <TitleContainer>
                      <Title style={{ padding: "20px 70px" }}>
                        선택한 <span>분석용 파일</span>을 분석 요청
                        하시겠습니까?
                      </Title>
                    </TitleContainer>
                    <FormContainer>
                      <React.Fragment>
                        <SelectHeading>분석용 파일</SelectHeading>
                        <SelectedFiles>
                          {selectedAnlzSource &&
                            `${moment(selectedAnlzSource.recorded).format(
                              "YYYY-MM-DD"
                            )}   ${selectedAnlzSource.visualFile}`}
                        </SelectedFiles>
                      </React.Fragment>
                      <ButtonContainer
                        style={{ flexGrow: 1, alignItems: "end" }}
                      >
                        {this.state.loadingRequest ? (
                          <Spinner />
                        ) : (
                          <React.Fragment>
                            <CancelButton
                              className="with-hover-overlay"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  selectedAnlzSource: null,
                                });
                                cancel();
                              }}
                              href="#"
                            >
                              <span>취소</span>
                            </CancelButton>
                            <ConfirmButton
                              className="with-hover-overlay"
                              disabled={this.state.selectedAnlzSource === null}
                              onClick={(e) => {
                                e.preventDefault();
                                submit(() => this.createAnlzRequest()).catch(
                                  (error) => console.log(error)
                                );
                              }}
                            >
                              <span>확인</span>
                            </ConfirmButton>
                          </React.Fragment>
                        )}
                      </ButtonContainer>
                    </FormContainer>
                  </Container>
                );
              }
              return (
                <Container>
                  <ConfirmMessage>
                    분석 요청이 완료되었습니다.
                    <br />
                    정확한 분석을 위해 다소 시간이 걸릴 수 있으니 5분 후에
                    확인해주세요.
                  </ConfirmMessage>
                  <ButtonContainer
                    style={{
                      padding: "0 15px",
                      marginTop: "92px",
                      marginBottom: "15px",
                    }}
                  >
                    <ConfirmButton
                      className="with-hover-overlay"
                      disabled={this.state.selectedAnlzSource === null}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ selectedAnlzSource: null });
                        confirm();
                      }}
                    >
                      <span>확인</span>
                    </ConfirmButton>
                  </ButtonContainer>
                </Container>
              );
            default: {
              this.setState({
                selectedAnlzSource: null,
              });
              cancel();
              return null;
            }
          }
        }}
      </MultiStepForm>
    );
  }
}

export { TempAnlzRequestForm };
