// @flow
// type imports
import type { AppState } from '../../types';
import type { Dispatch } from 'redux';
import type { ClearCurrentUserAction } from '../auth/currentuser';
import type { PatientListState, Patient, Survey } from '../../types/analysis';
// module imports
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import moment from 'moment';
import { CLEAR_CURRENT_USER } from '../auth/currentuser';
import { downloadSurveyResultRest } from '../../utils/rest';

// client actions
const SET_FILTER: 'sd/a/patientlist/SET_FILTER' = 'sd/a/patientlist/SET_FILTER';
const CLEAR_FILTER: 'sd/a/patientlist/CLEAR_FILTER' = 'sd/a/patientlist/CLEAR_FILTER';
const CLEAR_ALL_FILTERS: 'sd/a/patientlist/CLEAR_ALL_FILTERS' = 'sd/a/patientlist/CLEAR_ALL_FILTERS';
const SET_PAGING_INFO: 'sd/a/patientlist/SET_PAGING_INFO' = 'sd/a/patientlist/SET_PAGING_INFO';
const NEXT_PAGE: 'sd/a/patientlist/NEXT_PAGE' = 'sd/a/patientlist/NEXT_PAGE';
const PREV_PAGE: 'sd/a/patientlist/PREV_PAGE' = 'sd/a/patientlist/PREV_PAGE';
const SELECT_PATIENT: 'sd/a/patientlist/SELECT_PATIENT' = 'sd/a/patientlist/SELECT_PATIENT';
export const CLEAR_PATIENT_SELECTION: 'sd/a/patientlist/CLEAR_SELECTION' = 'sd/a/patientlist/CLEAR_SELECTION';
const CHECK_PATIENTS: 'sd/a/patientlist/CHECK_PATIENTS' = 'sd/a/patientlist/CHECK_PATIENTS';
const UNCHECK_PATIENTS: 'sd/a/patientlist/UNCHECK_PATIENTS' = 'sd/a/patientlist/UNCHECK_PATIENTS';
const DOWNLOAD_START: 'sd/a/patientlist/DOWNLOAD_START' = 'sd/a/patientlist/DOWNLOAD_START';
const DOWNLOAD_FINISH: 'sd/a/patientlist/DOWNLOAD_FINISH' = 'sd/a/patientlist/DOWNLOAD_FINISH';


type SetFilterAction = {
  type: typeof SET_FILTER,
  payload: {
    key: 'date' | 'keyword',
    value: moment | string
  }
};

type ClearFilterAction = {
  type: typeof CLEAR_FILTER,
  payload: 'date' | 'keyword'
};

type ClearAllFiltersAction = {
  type: typeof CLEAR_ALL_FILTERS
};

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type SelectPatientAction = {
  type: typeof SELECT_PATIENT,
  payload: Patient
};

export type ClearPatientSelectionAction = {
  type: typeof CLEAR_PATIENT_SELECTION
}

type CheckPatientsAction = {
  type: typeof CHECK_PATIENTS,
  payload: string[]
};

type UncheckPatientsAction = {
  type: typeof UNCHECK_PATIENTS,
  payload: string[]
};

type DownloadStartAction = {
  type: typeof DOWNLOAD_START
}

type DownloadFinishAction = {
  type: typeof DOWNLOAD_FINISH
}

type PatientListActions = (
  SetFilterAction |
  ClearFilterAction |
  ClearAllFiltersAction |
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  SelectPatientAction |
  ClearPatientSelectionAction |
  ClearCurrentUserAction |
  CheckPatientsAction |
  UncheckPatientsAction |
  DownloadStartAction |
  DownloadFinishAction
);

const INITIAL_STATE: PatientListState = {
  currentPage: 0,
  numPages: 0,
  filter: {
    date: null,
    keyword: ''
  },
  selectedPatient: null,
  checkedPatients: [],
  downloading: false
};

export default function reducer(state: PatientListState = INITIAL_STATE, action: PatientListActions) {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: action.payload.value
        }
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload]: INITIAL_STATE.filter[action.payload]
        }
      };
    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filter: INITIAL_STATE.filter
      };
    case SET_PAGING_INFO:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        numPages: action.payload.numPages
      }
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      }
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      }
    case SELECT_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload
      };
    case CLEAR_PATIENT_SELECTION:
      return {
        ...state,
        selectedPatient: null
      };
    case CHECK_PATIENTS:
      return {
        ...state,
        checkedPatients: _.union(state.checkedPatients, action.payload)
      };
    case UNCHECK_PATIENTS:
      return {
        ...state,
        checkedPatients: _.difference(state.checkedPatients, action.payload)
      };
    case DOWNLOAD_START:
      return {
        ...state,
        downloading: true
      };
    case DOWNLOAD_FINISH:
      return {
        ...state,
        downloading: false
      };
    case CLEAR_CURRENT_USER: return INITIAL_STATE;
    default: return state;
  }
};

const setFilter = (key: 'date' | 'keyword', value: moment | string): SetFilterAction => {
  return {
    type: SET_FILTER,
    payload: { key, value }
  };
};

const clearFilter = (key: 'date' | 'keyword'): ClearFilterAction => {
  return {
    type: CLEAR_FILTER,
    payload: key
  };
};

const clearAllFilters = (): ClearAllFiltersAction => {
  return {
    type: CLEAR_ALL_FILTERS
  };
};

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const selectPatient = (patient: Patient): SelectPatientAction => {
  return {
    type: SELECT_PATIENT,
    payload: patient
  };
};

const clearPatientSelection = (): ClearPatientSelectionAction => {
  return {
    type: CLEAR_PATIENT_SELECTION
  };
};

const checkPatients = (patientIds: string[]): CheckPatientsAction => {
  return {
    type: CHECK_PATIENTS,
    payload: patientIds
  };
};

const uncheckPatients = (patientIds: string[]): UncheckPatientsAction => {
  return {
    type: UNCHECK_PATIENTS,
    payload: patientIds
  };
};

const downloadStart = (): DownloadStartAction => {
  return {
    type: DOWNLOAD_START
  };
};

const downloadFinish = (): DownloadFinishAction => {
  return {
    type: DOWNLOAD_FINISH
  };
};

const downloadSurveyResult = (survey: Survey, sbjtIds: string[]): ThunkAction => {
  return (dispatch: Dispatch<PatientListActions>, getState: () => AppState) => {
    const { access_token } = getState().auth.currentUser.tokens;
    dispatch(downloadStart());
    downloadSurveyResultRest(survey, sbjtIds, access_token)
      .catch((error) => {
        console.log(error);
        alert(`다운로드가 실패했습니다. 다시 시도해주세요.\n${error.message}`)
      })
      .finally(() => {
        dispatch(downloadFinish());
      })
  }
}

export const actionCreators = {
  setFilter,
  clearFilter,
  clearAllFilters,
  setPagingInfo,
  nextPage,
  prevPage,
  selectPatient,
  clearPatientSelection,
  checkPatients,
  uncheckPatients,
  downloadSurveyResult,
};
