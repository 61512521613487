export default {
  profilePanel: require('./ico-mypage-profile-panel.svg'),
  passwordPanel: require('./ico-mypage-password-panel.svg'),
  username: require('./ico-mypage-username.svg'),
  email: require('./ico-mypage-email.svg'),
  hospitalname: require('./ico-mypage-hospitalname.svg'),
  phone: require('./ico-mypage-phone.svg'),
  password: require('./ico-mypage-password.svg'),
  newPassword: require('./ico-mypage-newpassword.svg'),
  confirmPassword: require('./ico-mypage-confirmpassword.svg'),
  code: require('./ico-mypage-code.svg'),
  edit: require('./ico-mypage-edit.svg'),
  editHover: require('./ico-mypage-edit-hover.svg'),
  save: require('./ico-mypage-save.svg'),
  saveHover: require('./ico-mypage-save-hover.svg')
}