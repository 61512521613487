// @flow
import _ from 'lodash';
import * as React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const Span = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TooltipSpan = styled.span`
  white-space: pre-wrap;
  text-align: ${props => props.textAlign || 'center'};
  font-size: ${props => props.fontSize || 14}px;
`;

type Props = {
  children?: string,
  customTitle?: string,
  alwaysShowTooltip?: boolean,
  tooltipTextAlign?: string,
  tooltipFontSize?: number,
  offsetY?: number,
  style?: any,
  className?: string
}

type State = {
  textOverflown: boolean
}

class EllipsisSpan extends React.Component<Props, State> {
  _span: ?HTMLSpanElement;
  constructor(props: Props) {
    super(props);
    this.state = {
      textOverflown: false
    };
  }
  
  componentDidMount() {
    this.updateTextOverflown();
    window.addEventListener('resize', this.updateTextOverflown);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this.updateTextOverflown();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTextOverflown);
  }
  
  updateTextOverflown = _.debounce(() => {
    if (this._span && this._span.offsetWidth < this._span.scrollWidth && !this.state.textOverflown) {
      this.setState({ textOverflown: true });
    } else if (this._span && this._span.offsetWidth >= this._span.scrollWidth && this.state.textOverflown) {
      this.setState({ textOverflown: false });
    }
  }, 500);
  
  render() {
    if (this.state.textOverflown || this.props.alwaysShowTooltip) {
      return (
        <Popup
          trigger={(
            <Span
              innerRef={ref => this._span = ref}
              className={this.props.className}
              style={this.props.style}
            >
              {this.props.children}
            </Span>
          )}
          position='top center'
          contentStyle={{
            width: 'auto',
            minWidth: '40px',
            maxWidth: '500px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            zIndex: 10000,
            backgroundColor: '#fff',
            boxShadow: '1.2px 1.2px 1.5px 0 rgba(0,0,0,0.2)',
            borderBottom: '1px solid rgb(187,187,187)',
            borderRight: '1px solid rgb(187,187,187)',
            borderTop: '1px solid rgb(187,187,187)',
            borderLeft: '1px solid rgb(187,187,187)',
            padding: '14px',
          }}
          on='hover'
          offsetY={this.props.offsetY}
        >
          {() => <TooltipSpan fontSize={this.props.tooltipFontSize} textAlign={this.props.tooltipTextAlign}>{this.props.customTitle || this.props.children}</TooltipSpan>}
        </Popup>
      )
    }
    return (
      <Span
        ref={ref => this._span = ref}
        className={this.props.className}
        style={this.props.style}
        data-tip={(this.state.textOverflown) ? this.props.customTitle || this.props.children : null}
      >
        {this.props.children}
      </Span>
    );
  }
}

export { EllipsisSpan };