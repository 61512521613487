// @flow
// type imports
import type { AppState } from '../../../types'
import type { ICDFilter } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { patientFormActions } from '../../../ducks/analysis';
import {
  Container,
  TabContainer,
  Tab,
  ListContainer,
  ListContentContainer,
  ICDCodeItem,
  ICDCode,
  ICDCodeButton,
  FavoriteButton,
  DispName
} from './styled';
import analysisImages from '../../../resources/images/analysis';
import { ScrollView, Spinner } from '../../common';
import { Query, Mutation } from '@apollo/client/react/components';
import { ICD_FILTER_QUERY, ICD_SEARCH_QUERY, ICD_BOOKMARK_QUERY } from '../../../utils/graphql/queries';
import { ICD_BOOKMARK_MUTATION } from '../../../utils/graphql/mutations';

type StateProps = {
  selectedCodes: any[],
  icdFilter: ICDFilter
};

type ActionProps = {
  addICDCode: typeof patientFormActions.addICDCode
};

type OwnProps = {
}

type Props = StateProps & ActionProps & OwnProps;

type State = {
  activeTab: 'list' | 'favorites',
  codeOrName: string
};

class ICDCodeListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 'list',
      codeOrName: ''
    };
  }

  handleSearchStringUpdate = _.debounce((searchStr: string) => {
    this.setState({ codeOrName: searchStr })
  }, 700);

  componentDidUpdate(prevProps: Props) {
    if (prevProps.icdFilter.searchStr !== this.props.icdFilter.searchStr) {
      this.handleSearchStringUpdate(this.props.icdFilter.searchStr);
    }
  } 

  renderCodeItem(code, selectedCodes, refetch, isFavorite) {
    return (
      <ICDCodeItem key={code.id}>
        <ICDCode>{code.code}</ICDCode>
        <ICDCodeButton
          onClick={() => {
            if (_.filter(selectedCodes, (selectedCode) => selectedCode.id === code.id).length < 1) {
              this.props.addICDCode(code);
            }
          }}
        >
          <DispName
            customTitle={_.join(_.concat([code.name], code.otherNames), '\n')}
            alwaysShowTooltip={!!code.otherNames}
            offsetY={5}
            tooltipFontSize={11}
          >
            {code.name}
          </DispName>
        </ICDCodeButton>
        <Mutation
          mutation={ICD_BOOKMARK_MUTATION}
          variables={{
            indicationId: code.id
          }}
        >
          {addOrDeleteBookmark => (
            <FavoriteButton
              onClick={async () => {
                try {
                  const result = await addOrDeleteBookmark();
                  await refetch();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {isFavorite ? <img src={analysisImages.starFill} alt='star filled' /> : <img src={analysisImages.star} alt='star' />}
            </FavoriteButton>
          )}
        </Mutation>
      </ICDCodeItem>
    );
  }

  renderList() {
    const {
      selectedCodes,
      icdFilter
    } = this.props;
    const {
      codeOrName
    } = this.state;
    const mode = (icdFilter.superGroup === null && icdFilter.group === null) ? 'search' : 'filter';
    const includeBookmark = (mode === 'search') ? !codeOrName : false;
    return (
      <Query
        query={(mode === 'search') ? ICD_SEARCH_QUERY : ICD_FILTER_QUERY}
        fetchPolicy='network-only'
        notifyOnNetworkStatusChange
        variables={(mode === 'search') ? {
          codeOrName,
          page: 0,
          size: 20,
          includeBookmark
        } : {
          parentId: (icdFilter.group && icdFilter.group.id) || (icdFilter.superGroup && icdFilter.superGroup.id),
          page: 0,
          size: 20,
          includeBookmark
        }}
      >
        {({ data, error, loading, refetch, fetchMore }) => {
          const dataKey = (mode === 'search') ? 'indicationAndBookmarkByCodeOrName' : 'indicationAndBookmarkByParentId';
          const codesAndBookmarks = data && data[dataKey];
          const bookmarks = codesAndBookmarks && codesAndBookmarks.bookmarks;
          const codes = codesAndBookmarks && codesAndBookmarks.indicationPage && codesAndBookmarks.indicationPage.indications;
          return (
            <React.Fragment>
              <Spinner loading={loading} />
              <ScrollView
                onScrollBottom={(event) => {
                  if (!loading && data[dataKey] && data[dataKey].indicationPage
                    && data[dataKey].indicationPage.pageInfo.nowPage + 1 < data[dataKey].indicationPage.pageInfo.totalPages
                  ) {
                    fetchMore({
                      variables: {
                        page: data[dataKey].indicationPage.pageInfo.nowPage + 1
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        const {
                          indicationPage: {
                            pageInfo,
                            indications
                          }
                        } = fetchMoreResult[dataKey];
                        return {
                          ...prev,
                          [dataKey]: {
                            ...prev[dataKey],
                            indicationPage: {
                              ...prev[dataKey].indicationPage,
                              pageInfo,
                              indications: [...prev[dataKey].indicationPage.indications, ...indications]
                            }
                          }
                        };
                      }
                    })
                  }
                }}
              >
                <ListContentContainer>
                  {includeBookmark ? _.map(bookmarks, (code) => this.renderCodeItem(code, selectedCodes, refetch, true)) : null}
                  {_.map(codes, (code) => this.renderCodeItem(code, selectedCodes, refetch, _.includes(_.map(bookmarks, bookmark => bookmark.id), code.id)))}
                </ListContentContainer>
              </ScrollView>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }

  renderFavoriteList() {
    const {
      selectedCodes
    } = this.props;
    return (
      <Query
        query={ICD_BOOKMARK_QUERY}
        fetchPolicy='network-only'
        notifyOnNetworkStatusChange
      >
        {({ data, error, loading, refetch }) => {
          return (
            <React.Fragment>
              <Spinner loading={loading} />
              <ScrollView>
                <ListContentContainer>
                  {_.map(data && data.myIndicationBookmarks, (code) => this.renderCodeItem(code, selectedCodes, refetch, true))}
                </ListContentContainer>
              </ScrollView>
            </React.Fragment>
          )
        }}
      </Query>
    );
  }

  render() {
    const { activeTab } = this.state;
    const {
      selectedCodes
    } = this.props;
    return (
      <Container>
        <TabContainer>
          <Tab
            selected={activeTab === 'list'}
            onClick={(e) => activeTab !== 'list' && this.setState(() => ({ activeTab: 'list'}))}
          >
            <span>목록</span>
            <div/>
          </Tab>
          <Tab
            selected={activeTab === 'favorites'}
            onClick={(e) => activeTab !== 'favorites' && this.setState(() => ({ activeTab: 'favorites'}))}
          >
            <span>즐겨찾기</span>
            <div/>
          </Tab>
        </TabContainer>
        <ListContainer>
          {activeTab === 'list' ? this.renderList() : this.renderFavoriteList()}
        </ListContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    icdFilter: state.analysis.patientForm.icdFilter,
    selectedCodes: state.analysis.patientForm.patientDetails.icdCodes
  };
}

export const ICDCodeList: React.ComponentType<OwnProps> = connect(mapStateToProps, {
  addICDCode: patientFormActions.addICDCode
})(ICDCodeListComponent);