import { Box, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React from "react";
import Popup from "reactjs-popup";
import {
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  Container,
  FormContainer,
  Title,
  TitleContainer,
} from "./styled";
import { Spinner } from "../../common";

const CSRequestForm = ({ open, onClose, anlz, patient, empMe, orgId }) => {
  const [result, setResult] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setResult();
  }, [open]);

  const metadata = React.useMemo(() => {
    if (anlz.result) {
      // 기질 분석
      return `- 분석 타입: 기질분석\n- 분석ID: ${
        anlz.result.id
      }\n- 분석 요청시간: ${moment(anlz.createdTime).format(
        "YYYY-MM-DD HH:mm:ss"
      )}\n- 파일: ${anlz.source?.visualFile}`;
    } else {
      if (anlz.anlzSources.length === 2) {
        // 비교 분석
        return `- 분석 타입: 비교분석\n- 분석ID: ${
          anlz.id
        }\n- 분석 요청시간: ${moment(anlz.created).format(
          "YYYY-MM-DD HH:mm:ss"
        )}\n- 파일(PRE): ${anlz.anlzSources[0].visualFile}\n- 파일(POST): ${
          anlz.anlzSources[1].visualFile
        }`;
      } else if (anlz.anlzSources.length === 1) {
        // 개별 분석
        return `- 분석 타입: 개별분석\n- 분석ID: ${
          anlz.id
        }\n- 분석 요청시간: ${moment(anlz.created).format(
          "YYYY-MM-DD HH:mm:ss"
        )}\n- 파일: ${anlz.anlzSources[0].visualFile}`;
      } else {
        return `- 분석 타입: 개별분석\n- 분석ID: ${
          anlz.id
        }\n- 분석 요청시간: ${moment(anlz.created).format(
          "YYYY-MM-DD HH:mm:ss"
        )}\n- 파일: 분석 파일 없음`;
      }
    }
  }, [anlz, patient, empMe]);

  const handleRequest = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_NOTIFY_HOST}/mindd/cs`,
        {
          employee_email: empMe.email,
          employee_name: empMe.name,
          org_name: empMe.orgz.name,
          org_id: orgId,
          subject_id: patient.id,
          subject_name: patient.name,
          subject_code: patient.code,
          sex: patient.sex,
          birthdate: patient.birthday,
          question: "",
          service_type: "SCAN",
          meta: metadata,
        },
        {
          headers: {
            "x-api-key": "Km2GgL2C477xuKNo0W6Zm7hujqDLa2V53iKOTdwQ",
          },
        }
      );
      setLoading(false);
      setResult(true);
    } catch {
      setLoading(false);
      setResult(false);
    }
  };

  return (
    <Popup
      open={open}
      modal
      closeOnDocumentClick
      onClose={() => {
        if (onClose) onClose();
      }}
      overlayStyle={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        margin: 0,
        zIndex: 10000,
        flexDirection: "column",
      }}
      contentStyle={{
        backgroundColor: "#fff",
        boxShadow: "4px 11px 11px 0 rgba(0, 0, 0, 0.1)",
        border: "none",
        width: 500,
        padding: 0,
      }}
    >
      {(close) => {
        return (
          <>
            {result === undefined && (
              <Container>
                <TitleContainer>
                  <Title>재분석 요청</Title>
                </TitleContainer>
                <FormContainer>
                  <Typography style={{ textAlign: "center" }}>
                    분석 기술 담당자에게 재분석 요청 메세지를 발송합니다. <br />
                    확인 및 처리에 시간이 소요될 수 있습니다. <br /> (
                    <b>분석 실패</b>나 <b>리포트 오류</b> 발생시에만
                    사용해주세요)
                  </Typography>
                  <ButtonContainer style={{ position: "relative" }}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <>
                        <CancelButton
                          className="with-hover-overlay"
                          onClick={(event) => onClose()}
                          href="#"
                        >
                          <span>취소</span>
                        </CancelButton>
                        <ConfirmButton
                          className="with-hover-overlay"
                          onClick={handleRequest}
                          href="#"
                        >
                          <span>발송</span>
                        </ConfirmButton>
                      </>
                    )}
                  </ButtonContainer>
                </FormContainer>
              </Container>
            )}
            {result !== undefined && (
              <Container>
                <FormContainer>
                  <Box
                    flexGrow={1}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6">
                      {!!result && "재분석 요청이 완료되었습니다."}
                      {!result &&
                        "재분석 요청에 실패했습니다. 잠시 후 다시 시도해주세요."}
                    </Typography>
                  </Box>
                  <ButtonContainer>
                    <ConfirmButton
                      className="with-hover-overlay"
                      onClick={() => onClose()}
                      href="#"
                    >
                      <span>확인</span>
                    </ConfirmButton>
                  </ButtonContainer>
                </FormContainer>
              </Container>
            )}
          </>
        );
      }}
    </Popup>
  );
};

export default CSRequestForm;
