// @flow
// type imports
import type { Patient, AnlzRequest } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Query, Mutation } from '@apollo/client/react/components';
import { PlainPanel, Spinner } from '../../common';
import { AnlzSourceInfo } from '../AnlzSourceDetailPanel';
import {
  ANLZ_REQUEST_BY_ID_QUERY,
  SURVEY_SESSION_SUMMARY_QUERY,
  ANLZ_REQUESTS_BY_SBJTID_QUERY
} from '../../../utils/graphql/queries';
import { CANCEL_ANLZ_REQ_MUTATION } from '../../../utils/graphql/mutations';
import {
  Container,
  SessionInfoContainer,
  SessionAnlzResSpan,
  SessionInfoSpan,
  InfoDivider,
  AnlzRequestStatusContainer,
  StatusMessage,
  ButtonContainer,
  CancelButton,
  DownloadButton
} from './styled';
import analysisImages from '../../../resources/images/analysis';
import { anlzRequestListActions } from '../../../ducks/analysis';
import { SurveySessionInfo } from '../SurveySessionDetailPanel/SurveySessionInfo';

type Props = {
  selectedPatient: Patient,
  selectedAnlzRequest: AnlzRequest,
  downloadAnlzResource: typeof anlzRequestListActions.downloadAnlzResource
};

const renderAnlzRequestStatus = ({ selectedPatient, downloadAnlzResource }: Props, anlzReqData: any) => {
  if (anlzReqData.anlzStatus === 'REQUEST') {
    const refetchQueries = [{
      query: ANLZ_REQUESTS_BY_SBJTID_QUERY,
      variables: {
        subjectId: selectedPatient && selectedPatient.id,
      }
    },
    {
      query: ANLZ_REQUEST_BY_ID_QUERY,
      variables: {
        reqId: anlzReqData.id
      }
    }];
    return (
      <React.Fragment>
        <AnlzRequestStatusContainer>
          <img src={analysisImages.analysisRequested} alt='Requesting analysis' />
          <StatusMessage>{`${selectedPatient.name}님의 종합분석 리포트요청중`}</StatusMessage>
        </AnlzRequestStatusContainer>
        <Mutation
          mutation={CANCEL_ANLZ_REQ_MUTATION}
          refetchQueries={refetchQueries}
        >
          {(cancelAnlzReq, { loading, error }) => {
            return (
              <ButtonContainer>
                <CancelButton
                  className='with-hover-overlay'
                  onClick={(e) => {
                    e.preventDefault();
                    cancelAnlzReq({
                      variables: {
                        anlzReqId: anlzReqData.id
                      }
                    });
                  }}
                  href='#'
                >
                  {loading ? <Spinner /> : <span>분석 요청 취소</span>}
                </CancelButton>
              </ButtonContainer>
            )
          }}
        </Mutation>
      </React.Fragment>
    );
  } else if (anlzReqData.anlzStatus === 'PROCEEDING') {
    return (
      <AnlzRequestStatusContainer>
        <img src={analysisImages.analysisProgress} alt='Analyzing...' />
        <StatusMessage>{`${selectedPatient.name}님의 종합분석 리포트 생성중`}</StatusMessage>
      </AnlzRequestStatusContainer>
    );
  } else if (anlzReqData.anlzStatus === 'CANCEL') {
    return (
      <AnlzRequestStatusContainer>
        <img src={analysisImages.surveyNotComplete} alt='Request cancelled' />
        <StatusMessage>{`${selectedPatient.name}님의 종합분석 리포트 생성요청 취소`}</StatusMessage>
      </AnlzRequestStatusContainer>
    );
  } else if (anlzReqData.anlzStatus === 'FAIL') {
    return (
      <AnlzRequestStatusContainer>
        <img src={analysisImages.surveyNotComplete} alt='Request cancelled' />
        <StatusMessage>{`${selectedPatient.name}님의 종합분석 리포트 생성실패`}</StatusMessage>
      </AnlzRequestStatusContainer>
    );
  }
  return (
    <AnlzRequestStatusContainer>
      <img src={analysisImages.analysisComplete} alt='Requesting analysis' />
      <StatusMessage>{`${selectedPatient.name}님의 종합분석 리포트 완료`}</StatusMessage>
      <DownloadButton
        onClick={(e) => {
          e.preventDefault();
          downloadAnlzResource(selectedPatient.id, encodeURIComponent(anlzReqData.actualResultFile), anlzReqData.visualResultFile);
        }}
        href='#'
      >
        <span>PDF다운로드</span>
      </DownloadButton>
    </AnlzRequestStatusContainer>
  )
}

export const AnlzRequestDetailPanel = ({ selectedPatient, selectedAnlzRequest, downloadAnlzResource }: Props) => {
  return (
    <PlainPanel
      title={`${selectedPatient.name}님의 종합분석 내용`}
      headerBackgroundColor='#fff'
      headerBorderColor='transparent'
    >
      <Query
        query={ANLZ_REQUEST_BY_ID_QUERY}
        variables={{
          reqId: selectedAnlzRequest.id
        }}
      >
        {({ loading, data, error }) => {
          console.log(error);
          return (
            <Container>
              {
                (loading) ? <Spinner /> :
                ((data && data.anlzReqById && data.anlzReqById.anlzSources) ? (
                  _.map(data.anlzReqById.anlzSources, (anlzSource) => <AnlzSourceInfo key={anlzSource.id} selectedPatient={selectedPatient} selectedAnlzSource={anlzSource} />)
                ) :
                <AnlzSourceInfo selectedPatient={selectedPatient} selectedAnlzSource={data.anlzReqById.anlzSources} />)
              }
              {_.map(selectedAnlzRequest.surveys, (survey) => {
                return (<SurveySessionInfo key={survey.surveySessionId} selectedSurveySession={survey} />);
              })}
              {(data && data.anlzReqById) ? renderAnlzRequestStatus({ selectedPatient, selectedAnlzRequest, downloadAnlzResource }, data.anlzReqById) : null}
            </Container>
          )
        }}
      </Query>
    </PlainPanel>
  );
};