// @flow
// type imports
import type { AppState } from '../../types';
import type { CurrentUserState, AuthResponse } from '../../types/auth';
import type { Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';
// module imports
import _ from 'lodash';
import moment from 'moment';
import * as LocalStorageUtils from '../../utils/localStorage';
import { Base64 } from 'js-base64';

const INITIAL_STATE: CurrentUserState = {
  tokens: {
    access_token: '',
    refresh_token: ''
  },
  expiry: null,
  authorities: [],
  orgz_id: -1,
  user_id: '',
  user_name: '',
  user_given_name: '',
  agreed: false,
};

const SET_CURRENT_USER: 'sd/auth/currentuser/SET_CURRENT_USER' = 'sd/auth/currentuser/SET_CURRENT_USER';
const SYNC_CURRENT_USER: 'sd/auth/currentuser/SYNC_CURRENT_USER' = 'sd/auth/currentuser/SYNC_CURRENT_USER';
export const CLEAR_CURRENT_USER: 'sd/auth/currentuser/CLEAR_CURRENT_USER' = 'sd/auth/currentuser/CLEAR_CURRENT_USER';
const CHECK_CURRENT_JWT_EXPIRY: 'sd/auth/currentuser/CHECK_CURRENT_JWT_EXPIRY' = 'sd/auth/currentuser/CHECK_CURRENT_JWT_EXPIRY';
const SET_USER_PREFERENCE: 'sd/auth/currentuser/SET_USER_PREFERENCE' = 'sd/auth/currentuser/SET_USER_PREFERENCE';

type SetCurrentUserAction = {
  type: typeof SET_CURRENT_USER,
  payload: CurrentUserState
};

type SyncCurrentUserAction = {
  type: typeof SYNC_CURRENT_USER,
  payload: CurrentUserState
};

export type ClearCurrentUserAction = {
  type: typeof CLEAR_CURRENT_USER
};

type CheckCurrentJwtExpiry = {
  type: typeof CHECK_CURRENT_JWT_EXPIRY,
  payload: moment
};

type CurrentUserActions = (
  SetCurrentUserAction |
  SyncCurrentUserAction |
  ClearCurrentUserAction |
  CheckCurrentJwtExpiry
);
export default function reducer(state: CurrentUserState = INITIAL_STATE, action: CurrentUserActions): CurrentUserState {
  switch (action.type) {
    case SET_CURRENT_USER:
    case SYNC_CURRENT_USER:
      return action.payload;
    case CLEAR_CURRENT_USER:
      return INITIAL_STATE;
    case CHECK_CURRENT_JWT_EXPIRY:
      if (state.expiry && action.payload.isAfter(state.expiry)) {
        return INITIAL_STATE;
      }
      return state;
    default: return state;
  }
}

const setCurrentUser = (authResponse: AuthResponse): ThunkAction => {
  return (dispatch: Dispatch<CurrentUserActions>) => {
    const claims = JSON.parse(Base64.decode(authResponse.access_token.split('.')[1]));
    console.log(claims);
    const currentUser: CurrentUserState = {
      tokens: authResponse,
      expiry: moment.unix(claims['exp']),
      authorities: claims['authorities'],
      orgz_id: claims['orgz_id'],
      user_id: claims['user_id'],
      user_name: claims['user_name'],
      user_given_name: claims['user_given_name'],
      agreed: claims['agreed'],
    };
    console.log(claims);
    LocalStorageUtils.saveCurrentUser(currentUser);
    dispatch({
      type: SET_CURRENT_USER,
      payload: currentUser
    });
  }
};

const syncCurrentUser = (currentUser: ?CurrentUserState): SyncCurrentUserAction => {
  return {
    type: SYNC_CURRENT_USER,
    payload: currentUser || INITIAL_STATE
  };
}

const clearCurrentUser = (): ThunkAction => {
  return (dispatch: Dispatch<CurrentUserActions>) => {
    LocalStorageUtils.clearCurrentUser();
    dispatch({ type: CLEAR_CURRENT_USER });
  }
};

const checkCurrentJwtExpiry = (now: moment): CheckCurrentJwtExpiry => {
  return {
    type: CHECK_CURRENT_JWT_EXPIRY,
    payload: now
  };
};

export const actionCreators = {
  setCurrentUser,
  syncCurrentUser,
  clearCurrentUser,
  checkCurrentJwtExpiry
};