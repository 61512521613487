import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0%;
  width: 100%;
`;

export const Row = styled.div`
  margin-top: 34px;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
`;

export const FlexRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 120px;
  > * {
    display: inline-block;
  }
`;

export const SpinnerContainer = styled.div`
  flex: 1 1 0%;
  position: relative;
  height: 100%;
`;

export const Divider = styled.div`
  width: 1px;
  height: 98px;
  background-color: #d0dcea;
`;

export const StatPiece = styled.div`
  flex: 1 1 0%;
  span {
    display: block;
    text-align: center;
  }
  > .stat-heading {
    font-size: 20px;
    font-weight: 500;
    color: ${props => props.color || '#576881'};
  }
  > .stat-data {
    margin-top: 12px;
    font-size: 55px;
    font-weight: 500;
    color: ${props => props.color || '#576881'};
  }
`;