// @flow
import type { AppState } from "../../types";
import type { Dispatch } from "redux";
import type { ClearCurrentUserAction } from "../auth/currentuser";
import type { ClearPatientSelectionAction } from "./patientlist";
import type { AnlzRequestListState, AnlzRequest } from "../../types/analysis";
// module imports
import { ThunkAction } from "redux-thunk";
import _ from "lodash";
import { downloadAnlzResourceRest } from "../../utils/rest";
import { CLEAR_PATIENT_SELECTION } from "./patientlist";
import { CLEAR_CURRENT_USER } from "../auth/currentuser";

// server actions
const REFETCH_ANLZ_REQUESTS: "sd/a/tempanlzrequestlist/REFETCH_ANLZ_REQUESTS" =
  "sd/a/tempanlzrequestlist/REFETCH_ANLZ_REQUESTS";
const REFETCH_ANLZ_REQUESTS_DONE: "sd/a/tempanlzrequestlist/REFETCH_ANLZ_REQUESTS_DONE" =
  "sd/a/tempanlzrequestlist/REFETCH_ANLZ_REQUESTS_DONE";

type RefetchAnlzRequestsAction = {
  type: typeof REFETCH_ANLZ_REQUESTS,
};

type RefetchAnlzRequestsDoneAction = {
  type: typeof REFETCH_ANLZ_REQUESTS_DONE,
};

type TempAnlzRequestListActions =
  | RefetchAnlzRequestsAction
  | RefetchAnlzRequestsDoneAction;

const INITIAL_STATE: AnlzRequestListState = {
  refetch: false,
};

export default function reducer(
  state = { refetch: false },
  action: TempAnlzRequestListActions
) {
  switch (action.type) {
    case REFETCH_ANLZ_REQUESTS:
      return {
        ...state,
        refetch: true,
      };
    case REFETCH_ANLZ_REQUESTS_DONE:
      return {
        ...state,
        refetch: false,
      };
    default:
      return state;
  }
}

const refetchAnlzRequests = (): RefetchAnlzRequestsAction => {
  return {
    type: REFETCH_ANLZ_REQUESTS,
  };
};

const refetchAnlzRequestsDone = (): RefetchAnlzRequestsDoneAction => {
  return {
    type: REFETCH_ANLZ_REQUESTS_DONE,
  };
};

export const actionCreators = {
  refetchAnlzRequests,
  refetchAnlzRequestsDone,
};
