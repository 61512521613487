// @flow
import styled from 'styled-components';
import { AnchorButton, Button } from '../../common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  min-height: 82px;
  border-bottom: solid 2px #d0dcea;
  text-align: center;
  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
  }
  > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const Title = styled.p`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0;
  span {
    color: #fc5882;
  }
`;

export const FormContainer = styled(Container)`
  padding: 15px;
  font-size: 15px;
  min-height: 225px;
`;

export const SelectHeading = styled.span`
  font-size: 15px;
  color: #435268;
  font-weight: 400;
  margin: 15px 0 0 15px;
  &:not(:first-child) {
    margin-top: 34px;
  }
`;

export const SelectedFiles = styled.span`
  font-size: 15px;
  color: #000;
  font-weight: 400;
  margin: 15px 0 0 15px;
  white-space: pre;
`;

export const ButtonContainer = styled.div`
  height: 45px;
  margin-top: 40px;
  width: 100%;
  flex: 0 0 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const CancelButton = styled(AnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #94aabf;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const ConfirmMessage = styled.p`
  margin: 0;
  padding: 90px 30px 33px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`;

export const FinePrintMessage = styled.span`
  font-size: 14px;
  text-align: center;
  display: block;
  color: #fc5882;
`;

export const ConfirmButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #576881;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;