// @flow
// type imports
import type { Survey } from '../../../types/analysis';
// module imports
import * as React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { Query } from '@apollo/client/react/components';
import BaseSelect from 'react-select';
import { PUB_SURVEY_QUERY } from '../../../utils/graphql/queries';
import { styledSelect, Spinner, Radio } from '../../common';
import {
  Container,
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  TitleContainer,
  Title,
  FormContainer,
  SurveyDescription,
  SurveyDetail,
  RadioGroup
} from './styled';
import { loadUserPreferences } from '../../../utils/localStorage';

type Props = {
  trigger: React.Node,
  children?: React.Node,
  onClose?: () => any,
  open?: boolean,
  formWidth?: number,
  onConfirm?: (survey: Survey, surveyMethod: 'web' | 'kakao') => any,
  renderTitle?: () => React.Node,
  patientTel?: ?string,
  userEmail: string,
  mode: 'start' | 'download'
};

type State = {
  selectedSurvey: Survey | null,
  surveyMethod: 'kakao' | 'web',
};

const Select = styledSelect(BaseSelect);

export const TooltipSpan = styled.span`
  white-space: pre-wrap;
  text-align: ${props => props.textAlign || 'center'};
  font-size: ${props => props.fontSize || 14}px;
`;

class SurveySelectForm extends React.Component<Props, State> {
  defaultSurveyMethod = loadUserPreferences(this.props.userEmail)['surveyMethod'] || 'web';
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSurvey: null,
      surveyMethod: props.patientTel ? (this.defaultSurveyMethod || 'web') : 'web'
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({ surveyMethod: this.props.patientTel ? (this.defaultSurveyMethod || 'web') : 'web' })
    }
  }

  render() {
    const {
      onConfirm,
      trigger,
      renderTitle,
      formWidth,
      onClose,
      patientTel,
      open
    } = this.props;
    const { surveyMethod } = this.state;
    return (
      <Popup
        trigger={trigger}
        on={!!trigger ? 'click' : undefined}
        open={open}
        closeOnDocumentClick
        onClose={() => {
          if (onClose) onClose();
        }}
        onOpen={() => console.log('on open')}
        modal
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          margin: 0,
          zIndex: 10000,
          flexDirection: 'column'
        }}
        contentStyle={{
          backgroundColor: '#fff',
          boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
          border: 'none',
          width: `${formWidth || 320}px`,
          minHeight: '195px',
          padding: 0
        }}
      >
        {(close) => {
          return (
            <Container>
              <TitleContainer>
                <Title>
                  {renderTitle ? renderTitle() : (
                    <React.Fragment>작성할 <span>설문유형</span>을 <span>선택</span>해주세요.</React.Fragment>
                  )}
                </Title>
              </TitleContainer>
              <FormContainer>
                <FormContainer>
                  <Query
                    query={PUB_SURVEY_QUERY}
                    context={{
                      dataSourceType: 'api'
                    }}
                    onCompleted={(data) => {
                      if (data && data.pubSurveySummaries) {
                        this.setState({
                          selectedSurvey: data.pubSurveySummaries[0]
                        });
                      }
                    }}
                    fetchPolicy='network-only'
                  >
                    {({ loading, data, error }) => {
                      console.log(error)
                      if (loading) return <Spinner />;
                      const { selectedSurvey, surveyMethod } = this.state;
                      if (!loading && data && data.pubSurveySummaries) {
                        return (
                          <React.Fragment>
                            <Select
                              options={data.pubSurveySummaries}
                              placeholder=''
                              isClearable={false}
                              value={selectedSurvey}
                              getOptionValue={(option) => option.id}
                              getOptionLabel={(option) => option.name}
                              onChange={(option) => this.setState({ selectedSurvey: option })}
                            />
                            <SurveyDescription>{selectedSurvey && selectedSurvey.name}</SurveyDescription>
                            <SurveyDetail>{selectedSurvey && selectedSurvey.description}</SurveyDetail>
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment>
                          <Select
                            options={[]}
                            placeholder=''
                            isClearable={false}
                            value={selectedSurvey}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(option) => this.setState({ selectedSurvey: option })}
                          />
                          <SurveyDescription>{selectedSurvey && selectedSurvey.description.split('(')[0]}</SurveyDescription>
                          <SurveyDetail>{selectedSurvey && selectedSurvey.detail}</SurveyDetail>
                        </React.Fragment>
                      );
                    }}
                  </Query>
                  {this.props.mode === 'start' ? (
                    <RadioGroup>
                      <Radio
                        name='surveyMethod'
                        value='web'
                        width={120}
                        checked={surveyMethod === 'web'}
                        onChange={(event) => this.setState({ surveyMethod: 'web' })}
                        required
                      >
                        웹으로 작성
                      </Radio>
                      <Radio
                        name='surveyMethod'
                        disabled={!patientTel}
                        title={!patientTel ? '대상자 전화번호가 등록되어 있지 않습니다.' : ''}
                        width={120}
                        value='kakao'
                        checked={surveyMethod === 'kakao'}
                        onChange={(event) => this.setState({ surveyMethod: 'kakao' })}
                        required
                      >
                        카카오톡 전송
                      </Radio>
                    </RadioGroup>
                  ) : null}
                </FormContainer>
                <ButtonContainer>
                  <CancelButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      close();
                    }}
                    href='#'
                  >
                    <span>취소</span>
                  </CancelButton>
                  <ConfirmButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      if (onConfirm && this.state.selectedSurvey) onConfirm(this.state.selectedSurvey, this.state.surveyMethod);
                      close();
                    }}
                    href='#'
                  >
                    <span>확인</span>
                  </ConfirmButton>
                </ButtonContainer>
              </FormContainer>
            </Container>
          );
        }}
      </Popup>
    );
  }
}

export { SurveySelectForm };