// @flow
// type imports
import type { AppState } from '../../../types';
import type { DateRangeInputState } from '../../../types/common';
// module imports
import * as React from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Container,
  CalendarButton
} from './styled';
import { DateRangePicker } from './DateRangePicker';
import { actionCreators as dateRangeActions } from '../../../ducks/common/daterangeinput';
import commonImages from '../../../resources/images/common';
import moment from 'moment';
import Popup from 'reactjs-popup';

type OwnProps = {|
  name: string,
  parentStateSelector: (state: AppState) => any,
  onChange?: (startDate: moment, endDate: moment) => void
|};

type StateProps = DateRangeInputState;

type ActionProps = typeof dateRangeActions;

type Props = OwnProps & StateProps & ActionProps;

class DateRangeInputComponent extends React.Component<Props> {
  _inputRef: ?HTMLInputElement;
  _containerRef: ?HTMLDivElement;

  onPickerSubmit(startDate: moment, endDate: moment) {
    this.props.setDateRange(this.props.name, startDate, endDate);
    // trigger daterangechanged handler
    if (this.props.onChange) {
      this.props.onChange(startDate, endDate);
    }
  }

  render() {
    const {
      startDate,
      endDate,
    } = this.props;
    return (
      <Popup
        closeOnDocumentClick
        trigger={
          <Container ref={ref => this._containerRef = ref }>
            <Input
              ref={ref => this._inputRef = ref}
              type='text'
              value={`${startDate.format('YYYY.MM.DD')} - ${endDate.format('YYYY.MM.DD')}`}
              readOnly
            />
            <CalendarButton
              href='#'
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              <img src={commonImages.cal} alt='Calendar' />
              <img src={commonImages.calHover} alt='Calendar' className='hover-img'/>
            </CalendarButton>
          </Container>
        }
        offsetY={8}
        overlayStyle={{
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}
        position='bottom right'
        contentStyle={{
          zIndex: 10001,
          backgroundColor: '#fff',
          boxShadow: '4px 11px 11px 0 rgba(0, 0, 0, 0.1)',
          border: 'none',
          width: '317px',
          height: '345px',
          padding: 0
        }}
        arrow={false}
      >
        {(close) => (
          <DateRangePicker
            currentYear={moment().year()}
            startDate={startDate}
            endDate={endDate}
            selectedMonth={
              (startDate.format('YYYY.MM.DD') === moment(startDate).startOf('month').format('YYYY.MM.DD')
              && endDate.format('YYYY.MM.DD') === moment(endDate).endOf('month').format('YYYY.MM.DD')
              && startDate.month() === endDate.month()) ?
              startDate.month() + 1 :
              null
            }
            onSubmit={(startDate: moment, endDate: moment) => {
              this.onPickerSubmit(startDate, endDate);
              close();
            }}
            onCancel={() => close()}
          />
        )}
      </Popup>
      
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const parentState = ownProps.parentStateSelector(state);
  if (parentState.hasOwnProperty(`${ownProps.name}DateRange`)) {
    const dateRangeState: DateRangeInputState = parentState[`${ownProps.name}DateRange`];
    return {
      startDate: dateRangeState.startDate,
      endDate: dateRangeState.endDate,
    };
  }
  return {
    startDate: moment().date(1),
    endDate: moment().add(1, 'months').date(0)
  };
};

export const DateRangeInput: React.ComponentType<OwnProps> = connect(mapStateToProps, dateRangeActions)(DateRangeInputComponent);