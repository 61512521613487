// @flow
// type imports
import type { AppState } from '../../../types';
import type { AccountListState, User } from '../../../types/accounts';
// module imports
import moment from 'moment';
import * as React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Query, Mutation } from '@apollo/client/react/components';
import { accountListActions, accountFormActions } from '../../../ducks/accounts';
import { EMPS_LIST_QUERY, EMPS_SEARCH_QUERY } from '../../../utils/graphql/queries';
import { DELETE_EMP_MUTATION } from '../../../utils/graphql/mutations';
import analysisImages from '../../../resources/images/analysis';
import commonImages from '../../../resources/images/common';
import { AccountForm } from '../AccountForm';
import {
  TableControlButton,
  TablePagingInfo,
  RegisterButton,
  DeleteButton,
  RightButtonsContainer,
  DeleteConfirmMessage,
  RefreshButton
} from './styled';
import {
  styledTable,
  checkableTable,
  Spinner,
  ControlledPanel,
  SearchBar,
  ConfirmPopup,
  selectableTable
} from '../../common';

const CheckableTable = compose(styledTable, selectableTable, checkableTable)(ReactTable);

type StateProps = AccountListState;

type ActionProps = typeof accountListActions & {
  addUser: typeof accountFormActions.addUser,
  editUser: typeof accountFormActions.editUser,
};

type Props = StateProps & ActionProps;

type State = {
  queryToExecute: any,
  lastSearchKeyword: string
}

class AccountListPanelComponent extends React.Component<Props, State> {
  _listPageSize: number = 10;
  _refetch: () => Promise<any>;

  constructor(props) {
    super(props);
    this.state = {
      queryToExecute: EMPS_LIST_QUERY,
      lastSearchKeyword: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.refetch && !prevProps.refetch) {
      this._refetch().then((result) => {
        const {
          nowPage,
          totalPages
        } = result.data.empsByOrgzWithoutMe.pageInfo;
        this.props.refetchUsersDone();
        this.props.setPagingInfo(nowPage, totalPages);
      });
    }
  }
  
  handleSearchSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    //this.props.onPatientSearch();
  }
  render() {
    const {
      currentPage,
      numPages,
      checkedUsers,
      selectedUser,
    } = this.props;
    console.log(checkedUsers);
    return (
      <Query
        query={this.state.queryToExecute}
        variables={(this.state.queryToExecute === EMPS_LIST_QUERY) ? {
          page: currentPage,
          size: 10
        } : {
          value: this.state.lastSearchKeyword,
          page: currentPage,
          size: 10
        }}
        onCompleted={(data) => {
          if (data && data.empsByOrgzWithoutMe) {
            const {
              nowPage,
              totalPages
            } = data.empsByOrgzWithoutMe.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          } else if (data && data.empsByEmailOrNameWithoutMe) {
            const {
              nowPage,
              totalPages
            } = data.empsByEmailOrNameWithoutMe.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          }
        }}
        fetchPolicy='network-only'
        context={{
          dataSourceType: 'auth'
        }}
      >
        {({loading, error, data, refetch}) => {
          this._refetch = refetch;
          let employees = []
          let pageInfo = { nowPage: currentPage, totalPages: numPages};
          if (data && data.empsByOrgzWithoutMe) {
            employees = data.empsByOrgzWithoutMe.employees;
            pageInfo = data.empsByOrgzWithoutMe.pageInfo;
          } else if (data && data.empsByEmailOrNameWithoutMe) {
            employees = data.empsByEmailOrNameWithoutMe.employees;
            pageInfo = data.empsByEmailOrNameWithoutMe.pageInfo;
          }
          return (
            <ControlledPanel>
              <ControlledPanel.Header>
                <SearchBar
                  searchText={this.props.filter.keyword}
                  placeholder='이름 또는 아이디(이메일)을 입력하세요'
                  showAllImgSrc={analysisImages.allPatients}
                  onTextChange={(event: SyntheticEvent<HTMLInputElement>) => this.props.setFilter('keyword', event.currentTarget.value)}
                  onSearchSubmit={(event: SyntheticEvent<HTMLFormElement>) => {
                    this.props.setPagingInfo(0, 0);
                    this.setState({ queryToExecute: EMPS_SEARCH_QUERY, lastSearchKeyword: this.props.filter.keyword });
                  }}
                  onShowAll={(event) => {
                    event.preventDefault();
                    this.props.clearFilter('keyword');
                    this.props.setPagingInfo(0, 0);
                    this.setState({ queryToExecute: EMPS_LIST_QUERY, lastSearchKeyword: '' });
                  }}
                />
              </ControlledPanel.Header>
              <ControlledPanel.Content>
                <CheckableTable
                  data={employees} // data anlzSrcs
                  isRowCheckable={(rowData) => true}
                  idKey='email'
                  loading={loading}
                  page={pageInfo.nowPage}
                  pageSize={this._listPageSize}  // props pageSize
                  minRows={0}
                  manual
                  LoadingComponent={Spinner}
                  pages={pageInfo.totalPages}
                  columns={[{
                      id: 'created',
                      Header: '가입일',
                      minWidth: 155,
                      accessor: rowData => moment(rowData.created).format('YYYY.MM.DD'),
                    }, {
                      id: 'name',
                      minWidth: 200,
                      Header: '이름',
                      accessor: rowData => rowData.name,
                    }, {
                      id: 'role',
                      Header: '권한',
                      minWidth: 155,
                      Cell: (rowInfo) => {
                        switch (rowInfo.value) {
                          case 'U_ADMIN': return '관리자';
                          case 'U_NORMAL': return '일반';
                          case 'U_SUBJECT': return '대상자관리';
                          default: return rowInfo.value;
                        }
                      },
                      accessor: rowData => rowData.role,
                    }, {
                      id: 'email',
                      Header: 'ID',
                      minWidth: 435,
                      accessor: rowData => rowData.email,
                    }
                  ]}
                  showPagination={false}
                  resizable={false}
                  multiSort={false}
                  sortable={false}
                  checkedRows={checkedUsers}
                  selectedRow={selectedUser}
                  onRowsCheck={(rowIds: string[]) => {
                    if (checkedUsers.length > 0) {
                      // deleting only works for one user at a time
                      this.props.uncheckUsers(checkedUsers);
                    }
                    this.props.checkUsers(rowIds);
                  }}
                  onRowsUncheck={(rowIds: string[]) => {
                    this.props.uncheckUsers(rowIds);
                  }}
                  onRowSelect={(rowInfo: any) => {
                    const user: User = rowInfo.original;
                    this.props.selectUser(user);
                  }}
                  noDataText={(this.state.lastSearchKeyword !== '') ? '검색결과가 없습니다' : ''}
                ></CheckableTable>
              </ControlledPanel.Content>
              <ControlledPanel.Footer>
                <TableControlButton onClick={() => this.props.prevPage()}>
                  <img src={commonImages.prev} alt='Previous' />
                  <img className='hover-img' src={commonImages.prevHover} alt='Previous' />
                </TableControlButton>
                <TablePagingInfo><span className='current-page'>{currentPage + 1}</span>/{numPages}</TablePagingInfo>
                <TableControlButton onClick={() => this.props.nextPage()}>
                  <img src={commonImages.next} alt='Next' />
                  <img className='hover-img' src={commonImages.nextHover} alt='Next' />
                </TableControlButton>
                <RightButtonsContainer>
                  {(checkedUsers.length > 0) ?
                    <Mutation
                      mutation={DELETE_EMP_MUTATION}
                      context={{
                        dataSourceType: 'auth'
                      }}
                    >
                      {(deleteAccount, { loading, error }) => {
                        return (
                          <ConfirmPopup
                            trigger={
                              <DeleteButton
                                className='with-hover-overlay'
                                disabled={loading}
                                onClick={(event) => {
                                  event.preventDefault();
                                }}
                              >
                                <span>삭제</span>
                              </DeleteButton>
                            }
                            onConfirm={() => {
                              const email = checkedUsers[0];
                              deleteAccount({ variables: { email }}).then((result) => { // delete only one checked row for now
                                console.log(result);
                                if (selectedUser && email === selectedUser.email) this.props.clearUserSelection();
                                if (result && result.data && result.data.deleteEmpOrRoleByEmail) {
                                  this.props.uncheckUsers([email]);
                                }
                                refetch({
                                  page: currentPage,
                                  size: 10
                                });
                              }).catch((error) => {
                                console.log(error);
                              })
                            }}
                          >
                            <DeleteConfirmMessage>계정을 <span>삭제</span>하시겠습니까?</DeleteConfirmMessage>
                          </ConfirmPopup>
                        )
                      }}
                    </Mutation> : null
                  }
                  {(selectedUser) ? (
                    <DeleteButton
                      className='with-hover-overlay'
                      disabled={loading}
                      onClick={(event) => {
                        event.preventDefault();
                        this.props.editUser(selectedUser);
                      }}
                    >
                      <span>수정</span>
                    </DeleteButton>
                  ) : null}
                  <RegisterButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.addUser();
                    }}
                    href='#'
                  >
                    <span>계정등록</span>
                  </RegisterButton>
                  <RefreshButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      refetch();
                    }}
                    href='#'
                  >
                    <img src={commonImages.refresh} alt='refresh'/>
                  </RefreshButton>
                  <AccountForm />
                </RightButtonsContainer>
              </ControlledPanel.Footer>
            </ControlledPanel>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return state.accounts.accountList
}

export const AccountListPanel = connect(mapStateToProps, {
  ...accountListActions,
  addUser: accountFormActions.addUser,
  editUser: accountFormActions.editUser,
})(AccountListPanelComponent);