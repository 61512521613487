// @flow
// type imports
import type {AppState} from '../../../types';
import type {Patient} from '../../../types/analysis';
// module imports
import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import BaseTable from 'react-table';
import {MultiStepFileForm, styledTable, Checkbox, EllipsisSpan} from '../../common';
import {neuroInContractTypes, REST_API_ROOT} from '../../../utils/rest';
import commonImages from '../../../resources/images/common';
import {loadUserPreferences} from '../../../utils/localStorage';
import type {CurrentUserState} from "../../../types/auth";

const Table = styledTable(BaseTable);

export const FileNameSpan = styled(EllipsisSpan)`
  text-align: center;
  width: 0;
  flex: 1 1 0%;
`;

type Props = {
  selectedPatient: Patient | null,
  userEmail: string,
  onSubmitDone: (error: any) => void,
  currentUser: CurrentUserState
};

const AnlzSourceForm = (props: Props) => {

  const {selectedPatient, onSubmitDone, userEmail, currentUser} = props;
  const [useEdf, setUseEdf] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      const res = await neuroInContractTypes(currentUser.tokens.access_token)
      setUseEdf(res);
    };
    getData();
  }, []);

  return (
    <MultiStepFileForm
      disabled={!selectedPatient}
      renderFileOptions={(formState, setState) => {
        if (formState.selectedFiles) {
          return (
            <Table
              data={formState.selectedFiles}
              resolveData={data => _.map(_.range(data.length), (idx) => ({
                file: data[idx],
                index: idx
              }))}
              fontSize={'15px'}
              headerHeight={'40px'}
              manual
              columns={useEdf ? [{
                id: 'anlz_algo',
                Header: 'Filtered EDF 생성',
                accessor: rowData => rowData.index,
                minWidth: 130,
                Cell: rowInfo => (formState.fileOptions[rowInfo.value].type === 'EEG' && rowInfo.original.file.name.endsWith('.ytdf')) ? (
                  <Checkbox
                    checked={formState.fileOptions[rowInfo.value].anlz_algo === 'ICA'}
                    onChange={(event) => setState({
                      fileOptions: _.map(formState.fileOptions, (option, idx: number) => (
                        (idx === rowInfo.value) ? {
                          ...option,
                          anlz_algo: formState.fileOptions[rowInfo.value].anlz_algo === 'ICA' ? 'NONE' : 'ICA'
                        } : option
                      ))
                    })}
                    stopClickPropagation
                    checkedImgSrc={commonImages.checked}
                    uncheckedImgSrc={commonImages.check}
                  />
                ) : null
              }, {
                id: 'fileType',
                Header: '유형',
                minWidth: 50,
                Cell: rowInfo => (rowInfo.value.type || '-') == 'ECG' ? 'HRV' : rowInfo.value.type,
                accessor: rowData => formState.fileOptions[rowData.index],
              }, {
                id: 'fileName',
                Header: '파일명',
                minWidth: 280,
                accessor: rowData => <FileNameSpan>{rowData.file.name}</FileNameSpan>,
                Cell: rowInfo => rowInfo.value
              }] : [{
                id: 'fileType',
                Header: '유형',
                minWidth: 50,
                Cell: rowInfo => (rowInfo.value.type || '-') == 'ECG' ? 'HRV' : rowInfo.value.type,
                accessor: rowData => formState.fileOptions[rowData.index],
              }, {
                id: 'fileName',
                Header: '파일명',
                minWidth: 280,
                accessor: rowData => <FileNameSpan>{rowData.file.name}</FileNameSpan>,
                Cell: rowInfo => rowInfo.value
              }]}
              showPagination={false}
              resizable={false}
              multiSort={false}
              sortable={false}
              minRows={0}
            />
          );
        }
        return null;
      }}
      getFileOptions={() => {
        return [];
      }}
      getDefaultFileOptions={(files: FileList) => {
        const convertToEDF = loadUserPreferences(userEmail)['convertToEDF'];
        return _.map(_.range(files.length), (index) => {
          const filenamePieces = files[index].name.split('.');
          const extension = filenamePieces && filenamePieces[filenamePieces.length - 1];
          let type = '';
          if (extension === 'ytdf') {
            // previous ytdf files are guaranteed to be EEG
            type = 'EEG';
          }
          return {anlz_algo: convertToEDF ? 'ICA' : 'NONE', type};
        });
      }}
      endpointUrl={(selectedPatient) ? `${REST_API_ROOT}/subject/${selectedPatient.id}/anlz/source` : ''}
      accept='.ytdf,.bmp,.tdt,.edf'
      onSubmitDone={onSubmitDone}
      parentStateSelector={(state: AppState) => state.analysis}
    >
      <span>업로드</span>
    </MultiStepFileForm>
  );
};

export {AnlzSourceForm};