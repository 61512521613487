// @flow
import * as React from "react";
import { connect } from "react-redux";
import type { AppState } from "../../../types";
import { Page, PanelGrid } from "../../components/common";
import { StatsChartPanel } from "../../components/dashboard";
import { fetchHasSaleContract } from "../../utils/rest";

class DashboardComponent extends React.Component<{}> {
  constructor(props) {
    super(props);

    this.state = {
      showNotices: false,
    };
  }
  componentDidMount() {
    const getData = async () => {
      const res = await fetchHasSaleContract(
        this.props.currentUser.tokens.access_token
      );
      this.setState({ showNotices: res });
    };
    getData();
  }

  render() {
    return (
      <Page
        title="Dashboard"
        subtitle="스테이션 및 대시보드 사용 통계를 한 눈에 볼 수 있습니다"
        contentMinWidth={1295}
      >
        <PanelGrid.Grid>
          {/* <PanelGrid.Row style={{ height: '266px' }}>
            <PanelGrid.Col minWidth={635}>
              <AnalysisPanel />
            </PanelGrid.Col>
            <PanelGrid.Col minWidth={510}>
              <PatientPanel />
            </PanelGrid.Col>
          </PanelGrid.Row> */}
          <PanelGrid.Row style={{ height: "931px" }}>
            <PanelGrid.Col minWidth={1175}>
              <StatsChartPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
        </PanelGrid.Grid>
      </Page>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export const Dashboard: React.ComponentType<{}> =
  connect(mapStateToProps)(DashboardComponent);
