// @flow
import styled from 'styled-components';
import { AnchorButton, Button } from '../../common';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-bottom: 15px;
`;

export const FormTitleContainer = styled.div`
  height: 62px;
  flex: 0 0 62px;
  border-bottom: solid 2px #d0dcea;
  text-align: center;
  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
  }
  * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const FormTitle = styled.span`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

export const Form = styled.form`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

export const FormBody = styled.div`
  width: 100%;
  flex: 1 1 0%;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: row;
    height: 38px;
    align-items: center;
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
`;

export const FormRow = styled.div`
  > label {
    &:first-child {
      width: 90px;
      flex: 0 0 90px;
    }
    &:not(:first-child) {
      margin-left: 8px;
    }
    &.required::after {
      content: '*';
      color: #fc5882;
    }
  }
  * {
    font-size: 15px;
    font-weight: 500;
    color: #435268;
  }
  > input {
    border: solid 1px #d0dcea;
    &[type="text"], &[type="email"], &[type="password"] {
      height: 38px;
      background-color: #fff;
      padding-left: 15px;
      margin-left: 15px;
      min-width: 70px;
      flex: 0 1 188px;
      &:focus {
        outline: none;
        border: solid 1px #435268;
      }
    }
  }
`;

export const FormFooterContainer = styled.div`
  height: 45px;
  width: 100%;
  position: relative;
  flex: 0 0 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 15px;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const CancelButton = styled(AnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #94aabf;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const AcceptButton = styled(Button)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #576881;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;