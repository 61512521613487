// @flow
import styled from 'styled-components';
import { HoverStyledButton, HoverStyledAnchorButton, EllipsisSpan, AnchorButton } from '../../common';

export const Container = styled.div`
  padding: 30px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const TableContainer = styled.div`
  width: 100%;
  flex: 1 1 0%;
  min-height: 0;
`;

export const TitleContainer = styled.div`
  margin-bottom: 15px;
`;

export const FileDownloadLinkButton = styled(HoverStyledAnchorButton)`
  height: 23px;
  width: 23px;
  margin: 0 5px 0 20px;
  > img {
    height: 23px;
    width: 23px;
    object-fit: contain;
  }
`;

export const DefaultCellSpan = styled(EllipsisSpan)`
  flex: ${props => props.flex || '1 1 0%'};
  padding: 0 8px;
`;

export const NoEllipsisCellSpan = styled.span`
  flex: ${props => props.flex || '1 1 0%'};
  padding: 0 8px;
`;

export const Title = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: #435268;
`;

export const TableControlButton = styled(HoverStyledButton)`
  height: 45px;
  width: 45px;
  background-color: #fff;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
`;

export const TablePagingInfo = styled.span`
  font-size: 18px;
  line-height: 1.17;
  color: #586881;
  min-width: 40px;
  text-align: center;
  .current-page {
    color: #00bbf9;
  }
`;

export const RightButtonsContainer = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const RefreshButton = styled(AnchorButton)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 45px;
  > img {
    width: 22px;
    height: 20px;
    line-height: 45px;
    object-fit: contain;
  }
`;
