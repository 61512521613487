import React from "react";
import ReactExport from "react-export-excel";
import {connect} from "react-redux";
import moment from "moment";
import type {BillingDetailInfo} from "../../../types/billing";
import {AnchorButton} from "../../common/Button";
import {UtilBox} from "../styled";
import styled from 'styled-components';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


type OwnProps = {
    monthlyBillingDetailInfo: MonthlyBillingDetailInfo,
};

type Props = OwnProps;


export const ExcelDownloadButton = styled(AnchorButton)`
  margin-right: 24px;
  margin-bottom: 21px;
  background-color: #576881;
  text-align: center;
  display: inline-block;
  border-radius: 1.5px;
  height: 30px;
  width: 100px;
  > span {
    font-size: 12px;
    color: #e7ecf1;
    font-weight: 500;
  }
`;


class BillingDetailExcelDownloadComponent extends React.Component<Props> {

    constructor(props: Props) {super(props);}

    render() {
        console.log(this.props.monthlyBillingDetailInfo);

        if(this.props.monthlyBillingDetailInfo === null || this.props.monthlyBillingDetailInfo === undefined)
            return null;

        if(!this.props.monthlyBillingDetailInfo.contractDetail) return null;

        console.log(this.props.monthlyBillingDetailInfo);
        let monthlyBillingDetailInfo = this.props.monthlyBillingDetailInfo;

        return (
            <ExcelFile element={<ExcelDownloadButton
                className='with-hover-overlay'
                onClick={(event) => {
                    event.preventDefault();
                }}
                href='#'
            >
                <span>Excel 다운로드</span>
            </ExcelDownloadButton>}>
                <ExcelSheet data={[monthlyBillingDetailInfo]} name="BillingRecord">
                    <ExcelColumn label="납부 횟수" value="paymentRound"/>
                    <ExcelColumn label="사용 건수" value="numberOfUses"/>
                    <ExcelColumn label="청구 건수" value="numberOfClaims"/>
                    <ExcelColumn label="청구 금액" value="claimAmountWithVat"/>

                    <ExcelColumn label="월 임대료" value="rentWithVat"/>
                    <ExcelColumn label="계약 해지 위약금" value="unsubscriptionPenaltyWithVat"/>
                    <ExcelColumn label="기기 회수비" value="withdrawCostWithVat"/>
                    <ExcelColumn label="총 금액(VAT 포함)" value="billedAmountWithVat"/>
                </ExcelSheet>

                <ExcelSheet data={[monthlyBillingDetailInfo.contractDetail]} name="ContractInfo">
                    <ExcelColumn label="계약 코드" value="contractCode"/>
                    <ExcelColumn label="스테이션 번호" value="gs1Code"/>
                    <ExcelColumn label="기관 코드" value="customerCode"/>
                    <ExcelColumn label="기관 이름" value="customerName"/>
                    <ExcelColumn label="파트너 사" value="mgmtOfficeName"/>
                    <ExcelColumn label="담당자 이름" value="managerName"/>
                    <ExcelColumn label="담당자 이메일" value="managerEmail"/>
                    <ExcelColumn label="계약 시작 일" value="startDate"/>
                    <ExcelColumn label="계약 종료 일" value="endDate"/>
                </ExcelSheet>

                <ExcelSheet data={monthlyBillingDetailInfo.monthlyBillLines} name="AnalysisReport">
                    <ExcelColumn label="ID" value="billLineId"/>
                    <ExcelColumn label="분석 파일 생성일시" value="exportTime"/>
                    <ExcelColumn label="대상자 코드" value="subjectCode"/>
                    <ExcelColumn label="EEG 레코드 파일" value="recordingFile"/>
                    <ExcelColumn label="분석 파일" value="exportedFile"/>
                    <ExcelColumn label="금액(VAT 포함)" value="price"/>

                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export const BillingDetailExcelDownload = BillingDetailExcelDownloadComponent;