// @flow
// module imports
import * as React from 'react';
import { Query } from '@apollo/client/react/components';
import { PlainPanel, Spinner } from '../../common';
import { Container, FlexRow, StatPiece, Divider, SpinnerContainer } from './styled';
import { STAT_SUBJT_QUERY } from '../../../utils/graphql/queries';

class PatientPanel extends React.Component<{}> {
  refetchData: ?(variables ? : any) => Promise<mixed>;
  componentDidMount() {
    if (this.refetchData) {
      this.refetchData();
    }
  }

  render() {
    return (
      <Query
        query={STAT_SUBJT_QUERY}
        pollInterval={5000}
        context={{
          dataSourceType: 'api'
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (!this.refetchData) {
            this.refetchData = refetch;
          }
          return (
            <PlainPanel
              title='대상자 통계'
              headerBorderColor='#4a90e2'
            >
              <Container>
                <FlexRow>
                  {(loading) ? <SpinnerContainer><Spinner /></SpinnerContainer> : (
                    <React.Fragment>
                      <StatPiece color='#4a90e2'>
                        <span className="stat-heading">
                          오늘 신규
                        </span>
                        <span className="stat-data">
                          {(data && data.statSbjt) ? data.statSbjt.newSbjts : '-'}
                        </span>
                      </StatPiece>
                      <Divider />
                      <StatPiece>
                        <span className="stat-heading">
                          전체
                        </span>
                        <span className="stat-data">
                          {(data && data.statSbjt) ? data.statSbjt.totalSbjts : '-'}
                        </span>
                      </StatPiece>
                    </React.Fragment>
                  )}
                </FlexRow>
              </Container>
            </PlainPanel>
          )
        }}
      </Query>
    );
  }
}

export { PatientPanel };