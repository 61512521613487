// @flow
// type imports
import type { Patient, AnlzSource } from '../../../types/analysis';
// module imports
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import analysisImages from '../../../resources/images/analysis';
import {
  SourceInfoContainer,
  SourceFileInfoCell,
  SourceFileInfoContainer,
  SourceFileName,
  SourceFileSize,
  SourcePropertiesCell,
  SourcePropertiesContainer,
  SourcePropertiesList,
  SourcePropertyName,
  SourcePropertyData,
  SourcePropertyDataLong
} from './styled';

type Props = {
  selectedPatient: Patient,
  selectedAnlzSource: AnlzSource
}

const formatDuration = (recLengthSec: number) => {
  const recTotalTimeDur = moment.duration(recLengthSec, 'seconds');
  let hour = recTotalTimeDur.hours();
  let min = recTotalTimeDur.minutes();
  let sec = recTotalTimeDur.seconds();
  hour = `${(hour < 10) ? '0' : ''}${hour}`;
  min = `${(min < 10) ? '0' : ''}${min}`;
  sec = `${(sec < 10) ? '0' : ''}${sec}`;
  return `${hour}:${min}:${sec}`;
};

const formatFileSize = (size: number) => {
  let dispSize = size;
  let unitIdx = 0;
  const sizeUnit = ['B', 'KB', 'MB', 'GB']
  while (dispSize >= 1000) {
    dispSize = dispSize / 1000;
    unitIdx += 1;
  }
  return `${dispSize.toFixed(3)} ${sizeUnit[unitIdx]}`
}

export const AnlzSourceInfo = ({ selectedPatient, selectedAnlzSource }: Props) => {
  const {
    detail
  } = selectedAnlzSource || {};
  const recTimeMoment = moment(selectedAnlzSource && selectedAnlzSource.recorded);
  console.log(detail);
  return (
    <SourceInfoContainer>
      <div>
        <SourceFileInfoCell>
          <SourceFileInfoContainer>
            <img src={analysisImages.file} alt='Source file info'/>
            <SourceFileName>{selectedAnlzSource ? selectedAnlzSource.visualFile : '-'}</SourceFileName>
            <SourceFileSize>{selectedAnlzSource ? !_.isNil(selectedAnlzSource.fileSize) && formatFileSize(selectedAnlzSource.fileSize) : '-'}</SourceFileSize>
          </SourceFileInfoContainer>
        </SourceFileInfoCell>
        <SourcePropertiesCell>
          <SourcePropertiesContainer>
            <SourcePropertiesList>
              <li>
                <SourcePropertyName longName>Recording Date</SourcePropertyName>
                <SourcePropertyData longName>{recTimeMoment.format('YYYY-MM-DD')}</SourcePropertyData>
              </li>
              <li>
                <SourcePropertyName longName>Recording Start Time</SourcePropertyName>
                <SourcePropertyData longName>{recTimeMoment.format('HH:mm:ss')}</SourcePropertyData>
              </li>
              <li>
                <SourcePropertyName longName>Recording Time</SourcePropertyName>
                <SourcePropertyData longName>{(detail) ? formatDuration(detail.recLengthSec) : '-'}</SourcePropertyData>
              </li>
              <li>
                <SourcePropertyName longName>Module SN.</SourcePropertyName>
                <SourcePropertyData longName>{(detail && detail.moduleSn) ? detail.moduleSn : '-'}</SourcePropertyData>
              </li>
            </SourcePropertiesList>
            <SourcePropertiesList>
              <li>
                <SourcePropertyName>Session Name</SourcePropertyName>
                <SourcePropertyDataLong>{(detail) ? detail.sessionName : '-'}</SourcePropertyDataLong>
              </li>
              <li>
                <SourcePropertyName>Eye Status</SourcePropertyName>
                <SourcePropertyData>{(detail) ? (_.capitalize(detail.eyeStatus) === 'Close' ? 'Closed' : _.capitalize(detail.eyeStatus)) : '-'}</SourcePropertyData>
              </li>
              <li>
                <SourcePropertyName>Handedness</SourcePropertyName>
                <SourcePropertyData>{_.capitalize(selectedPatient.handedness)}</SourcePropertyData>
              </li>
              <li>
                <SourcePropertyName>Station SN.</SourcePropertyName>
                <SourcePropertyData>{(detail && detail.stationSn) ? detail.stationSn : '-'}</SourcePropertyData>
              </li>
            </SourcePropertiesList>
          </SourcePropertiesContainer>
        </SourcePropertiesCell>
      </div>
    </SourceInfoContainer>
  );
}