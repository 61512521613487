// @flow
import type { Survey } from "../types/analysis";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

export const REST_API_ROOT = `${
  process.env.REACT_APP_SCAN_API_HOST || "http://192.168.3.66:4020"
}/rest/1.0`;

export const BILLING_API_ROOT = `${
  process.env.REACT_APP_BILLING_API_HOST || "http://192.168.3.66:6002"
}`;
export const CONTRACT_API_ROOT = `${
  process.env.REACT_APP_CONTRACT_API_HOST || "http://192.168.3.66:6001"
}`;
export const SCAN2_API_ROOT = `${process.env.REACT_APP_SCAN2_API_HOST || ""}`;

const buildAnlzResourceDownloadLink = (sbjtId: string, actualName: string) => {
  return `${REST_API_ROOT}/subject/${sbjtId}/anlz?fileName=${actualName}`;
};

const buildSurveyResultDownloadLink = (
  pubSurveyId: string,
  sbjtIds: string[]
) => {
  return `${REST_API_ROOT}/survey/${pubSurveyId}/result?subjectIds=${sbjtIds.join(
    ","
  )}`;
};

export const downloadAnlzResourceRest = (
  sbjtId: string,
  actualName: string,
  visualName: string,
  accessToken: string
) => {
  axios({
    url: buildAnlzResourceDownloadLink(sbjtId, actualName),
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(new Blob([response.data]), visualName);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", visualName);
      link.click();
      window.URL.revokeObjectURL(url);
    }
  });
};

export const readAnlzResourceRest = async (
  sbjtId: string,
  actualName: string,
  accessToken: string
) => {
  const response = await axios({
    url: buildAnlzResourceDownloadLink(sbjtId, actualName),
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return new Blob([response.data]);
};

export const downloadBillingDetailExcelRest = (
  billCode,
  orgzName,
  month,
  year,
  accessToken
) => {
  const response = axios
    .get(
      `${BILLING_API_ROOT}/api/v2/bills/pdf/details/${billCode}?orgz_name=${orgzName}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      }
    )
    .then((response) => {
      const filename = `${billCode}_${year}_${month}.pdf`;
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(new Blob([response.data]), filename);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
        window.URL.revokeObjectURL(url);
      }
    });
};

export const downloadSurveyResultRest = async (
  survey: Survey,
  sbjtIds: string[],
  accessToken: string
) => {
  return axios({
    url: buildSurveyResultDownloadLink(survey.id, sbjtIds),
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((response) => {
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(
        new Blob([response.data]),
        `SurveyResult_${survey.name}_${moment().format(
          "YYYYMMDD_HH_mm_ss"
        )}.xls`
      );
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `SurveyResult_${survey.name}_${moment().format(
          "YYYYMMDD_HH_mm_ss"
        )}.xls`
      );
      link.click();
      window.URL.revokeObjectURL(url);
    }
  });
};

export const neuroInContractTypes = async (accessToken) => {
  const response = await axios.get(`${CONTRACT_API_ROOT}/api/v2/contracts`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  console.log(response.data);
  return !!response.data.find(
    (data) => data.serviceType === "NEURO_GUIDE" && data.status === "ACTIVE"
  );
};

export const requestTemperamentAnalysis = (sourceId, accessToken) => {
  return axios.post(
    `${SCAN2_API_ROOT}/nxt/analysis`,
    { sourceId: sourceId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const fetchTemperamentAnalysisRecords = (accessToken) => {
  return axios.get(`${SCAN2_API_ROOT}/nxt/analysis`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const fetchTemperamentAnalysisAuthority = (accessToken) => {
  return axios.get(`${SCAN2_API_ROOT}/nxt/org/authority`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const downloadTemperamentAnalysisResult = async (
  resourceLocation,
  accessToken
) => {
  return axios
    .get(`${SCAN2_API_ROOT}/resources?location=${resourceLocation}`, {
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `기질분석_${moment().format("YYYYMMDD_HH_mm_ss")}.xls`
      );
      link.click();
      window.URL.revokeObjectURL(url);
    });
};

export const fetchHasSaleContract = async (accessToken) => {
  const response = await axios.get(`${CONTRACT_API_ROOT}/api/v2/contracts`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  console.log(response.data);
  return !!response.data.find(
    (data) => data.type === "SALE" && data.status === "ACTIVE"
  );
};

export const toCamelCase = (data: any): any => {
  if (Array.isArray(data)) {
    return _.map(data, (item) => toCamelCase(item));
  } else if (typeof data === "object") {
    return _.reduce(
      data,
      (prev, curr, key) => ({
        ...prev,
        [_.camelCase(key)]: toCamelCase(curr),
      }),
      {}
    );
  }
  return data;
};

export const insertCommas = (numberStr: string) =>
  numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const TALK_API_HOST = `https://talkapi.lgcns.com`;
