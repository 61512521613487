// @flow
// type imports
import type { ClearCurrentUserAction } from '../auth/currentuser';
import type { CustomerListState, Customer } from '../../types/customers';
// module imports
import moment from 'moment';
import { CLEAR_CURRENT_USER } from '../auth/currentuser';

// client actions
const SET_FILTER: 'sdbo/c/customerlist/SET_FILTER' = 'sdbo/c/customerlist/SET_FILTER';
const CLEAR_FILTER: 'sdbo/c/customerlist/CLEAR_FILTER' = 'sdbo/c/customerlist/CLEAR_FILTER';
const CLEAR_ALL_FILTERS: 'sdbo/c/customerlist/CLEAR_ALL_FILTERS' = 'sdbo/c/customerlist/CLEAR_ALL_FILTERS';
const SET_PAGING_INFO: 'sdbo/c/customerlist/SET_PAGING_INFO' = 'sdbo/c/customerlist/SET_PAGING_INFO';
const NEXT_PAGE: 'sdbo/c/customerlist/NEXT_PAGE' = 'sdbo/c/customerlist/NEXT_PAGE';
const PREV_PAGE: 'sdbo/c/customerlist/PREV_PAGE' = 'sdbo/c/customerlist/PREV_PAGE';
const SELECT_CUSTOMER: 'sdbo/c/customerlist/SELECT_CUSTOMER' = 'sdbo/c/customerlist/SELECT_CUSTOMER';
export const CLEAR_CUSTOMER_SELECTION: 'sdbo/c/customerlist/CLEAR_SELECTION' = 'sdbo/c/customerlist/CLEAR_SELECTION';


type SetFilterAction = {
  type: typeof SET_FILTER,
  payload: {
    key: 'keyword',
    value: moment | string
  }
};

type ClearFilterAction = {
  type: typeof CLEAR_FILTER,
  payload: 'keyword'
};

type ClearAllFiltersAction = {
  type: typeof CLEAR_ALL_FILTERS
};

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type SelectCustomerAction = {
  type: typeof SELECT_CUSTOMER,
  payload: Customer
};

export type ClearCustomerSelectionAction = {
  type: typeof CLEAR_CUSTOMER_SELECTION
}

type CustomerListActions = (
  SetFilterAction |
  ClearFilterAction |
  ClearAllFiltersAction |
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  SelectCustomerAction |
  ClearCustomerSelectionAction |
  ClearCurrentUserAction
);

const INITIAL_STATE: CustomerListState = {
  currentPage: 0,
  numPages: 0,
  filter: {
    keyword: ''
  },
  selectedCustomer: null
};

export default function reducer(state: CustomerListState = INITIAL_STATE, action: CustomerListActions) {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: action.payload.value
        }
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload]: INITIAL_STATE.filter[action.payload]
        }
      };
    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        filter: INITIAL_STATE.filter
      };
    case SET_PAGING_INFO:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        numPages: action.payload.numPages
      }
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      }
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      }
    case SELECT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload
      };
    case CLEAR_CUSTOMER_SELECTION:
      return {
        ...state,
        selectedCustomer: null
      };
    case CLEAR_CURRENT_USER: return INITIAL_STATE;
    default: return state;
  }
};

const setFilter = (key: 'keyword', value: string): SetFilterAction => {
  return {
    type: SET_FILTER,
    payload: { key, value }
  };
};

const clearFilter = (key: 'keyword'): ClearFilterAction => {
  return {
    type: CLEAR_FILTER,
    payload: key
  };
};

const clearAllFilters = (): ClearAllFiltersAction => {
  return {
    type: CLEAR_ALL_FILTERS
  };
};

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const selectCustomer = (customer: Customer): SelectCustomerAction => {
  return {
    type: SELECT_CUSTOMER,
    payload: customer
  };
};

const clearCustomerSelection = (): ClearCustomerSelectionAction => {
  return {
    type: CLEAR_CUSTOMER_SELECTION
  };
};

export const actionCreators = {
  setFilter,
  clearFilter,
  clearAllFilters,
  setPagingInfo,
  nextPage,
  prevPage,
  selectCustomer,
  clearCustomerSelection
};
