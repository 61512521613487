// @flow
import styled from 'styled-components';
import { AnchorButton, HoverStyledButton } from '../../common';
import { HoverStyledAnchorButton } from '../Button';

export const SearchBarContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const SearchForm = styled.form`
  display: inline-block;
  position: relative;
  height: 60px;
  margin-left: 8px;
  flex: 1 1 100%;
  input[type="text"] {
    width: 100%;
    height: 100%;
    border: none;
    background-color: #fff;
    padding-left: 20px;
    box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
    font-size: 18px;
    font-weight: 500;
    color: #576881;
    &::placeholder {
      font-weight: 500;
      color: #d0dcea;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const ShowAllButton = styled(HoverStyledAnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  position: absolute;
  display: ${props => props.hidden ? 'none' : 'block'};
  top: 0;
  right: 68px;
`;

export const SubmitButton = styled(HoverStyledButton)`
  position: absolute;
  height: 60px;
  padding: 0 17px;
  top: 0;
  right: 0;
`;