import styled from 'styled-components';
import { AnchorButton } from '../../common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  height: 82px;
  flex: 0 0 82px;
  border-bottom: solid 2px #d0dcea;
  text-align: center;
  &::before {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
  }
  > * {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const Title = styled.span`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  span {
    color: #fc5882;
  }
`;

export const FormContainer = styled(Container)`
  padding: 15px;
  font-size: 15px;
  min-height: 225px;
`;

export const SurveyDescription = styled.span`
  margin-top: 28px;
  font-size: 15px;
  padding: 0 15px 0 0;
  color: #435268;
  font-weight: bold;
`;

export const SurveyDetail = styled.p`
  margin: 15px 0 0 0;
  padding: 0 15px 0 0;
  font-size: 15px;
  color: #435268;
  height: 90px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.16);
    transition: all .1s ease-in-out;
    border: 3px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(66,82,110,0.36);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(66,82,110,0.56);
  }
}
`;

export const ButtonContainer = styled.div`
  height: 45px;
  margin-top: 25px;
  width: 100%;
  flex: 0 0 45px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const CancelButton = styled(AnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #94aabf;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const ConfirmButton = styled(AnchorButton)`
  text-align: center;
  border-radius: 1.5px;
  height: 45px;
  flex: 1 1 0%;
  background-color: #576881;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

export const RadioGroup = styled.div`
  flex: 1 1 0%;
  display: flex;
  align-items: center;
`;