// @flow
// type imports
import type { AppState } from '../../../types';
import type { CustomerAccessListState, CustomerAccess } from '../../../types/accesscontrol';
// module imports
import moment from 'moment';
import * as React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Query, Mutation } from '@apollo/client/react/components';
import { customerAccessListActions } from '../../../ducks/accesscontrol';
import { ORGZ_LIST_QUERY, ORGZ_LIST_SEARCH_QUERY } from '../../../utils/graphql/queries';
import { ORGZ_TOGGLE_SVC_MUTATION, REGISTER_ORGZ_ADMIN_MUTATION, NEW_ORGZ_MUTATION, ADD_DEFAULT_BOOKMARKS_MUTATION } from '../../../utils/graphql/mutations';
import analysisImages from '../../../resources/images/analysis';
import commonImages from '../../../resources/images/common';
import { AdminAccountForm } from '../AdminAccountForm';
import { CustomerForm } from '../CustomerForm';
import {
  TableControlButton,
  TablePagingInfo,
  RegisterAdminButton,
  RegisterCustomerButton,
  RightButtonsContainer,
  ConfirmMessage,
  RefreshButton,
  DefaultCellSpan
} from './styled';
import {
  styledTable,
  selectableTable,
  Spinner,
  ControlledPanel,
  SearchBar,
  ConfirmPopup,
  ToggleSwitch
} from '../../common';

const SelectableTable = compose(styledTable, selectableTable)(ReactTable);

type StateProps = CustomerAccessListState;

type ActionProps = typeof customerAccessListActions;

type Props = StateProps & ActionProps;

type State = {
  queryToExecute: any,
  lastSearchKeyword: string,
  hasService: boolean,
  adminEmp: any
}

class CustomerAccessListPanelComponent extends React.Component<Props, State> {
  _listPageSize: number = 10;
  _refetch: () => Promise<any>;

  constructor(props) {
    super(props);
    this.state = {
      queryToExecute: ORGZ_LIST_QUERY,
      lastSearchKeyword: '',
      hasService: false,
      adminEmp: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.refetch && !prevProps.refetch) {
      this._refetch().then((result) => {
        const {
          nowPage,
          totalPages
        } = result.data.orgzs.pageInfo;
        this.props.refetchCustomersDone();
        this.props.setPagingInfo(nowPage, totalPages);
      });
    }
  }
  
  handleSearchSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    //this.props.onPatientSearch();
  }
  render() {
    const {
      currentPage,
      numPages,
      selectedCustomer
    } = this.props;
    return (
      <Query
        query={this.state.queryToExecute}
        variables={(this.state.queryToExecute === ORGZ_LIST_QUERY) ? {
          page: currentPage,
          size: 10
        } : {
          codeOrName: this.state.lastSearchKeyword,
          page: currentPage,
          size: 10
        }}
        onCompleted={(data) => {
          if (data && data.orgzs) {
            const {
              nowPage,
              totalPages
            } = data.orgzs.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          } else if (data && data.orgzsByCodeOrName) {
            const {
              nowPage,
              totalPages
            } = data.orgzsByCodeOrName.pageInfo
            this.props.setPagingInfo(nowPage, totalPages);
          }
        }}
        fetchPolicy='network-only'
        context={{
          dataSourceType: 'auth'
        }}
      >
        {({loading, error, data, refetch}) => {
          this._refetch = refetch;
          let customers = []
          let pageInfo = { nowPage: currentPage, totalPages: numPages};
          if (data && data.orgzs) {
            customers = data.orgzs.orgzs;
            pageInfo = data.orgzs.pageInfo;
          } else if (data && data.orgzsByCodeOrName) {
            customers = data.orgzsByCodeOrName.orgzs;
            pageInfo = data.orgzsByCodeOrName.pageInfo;
          }
          return (
            <ControlledPanel>
              <ControlledPanel.Header>
                <SearchBar
                  searchText={this.props.filter.keyword}
                  placeholder='고객의 기관명 또는 코드를 입력하세요'
                  showAllImgSrc={analysisImages.allPatients}
                  onTextChange={(event: SyntheticEvent<HTMLInputElement>) => this.props.setFilter('keyword', event.currentTarget.value)}
                  onSearchSubmit={(event: SyntheticEvent<HTMLFormElement>) => {
                    this.props.setPagingInfo(0, 0);
                    this.setState({ queryToExecute: ORGZ_LIST_SEARCH_QUERY, lastSearchKeyword: this.props.filter.keyword });
                  }}
                  onShowAll={(event) => {
                    event.preventDefault();
                    this.props.clearFilter('keyword');
                    this.props.setPagingInfo(0, 0);
                    this.setState({ queryToExecute: ORGZ_LIST_QUERY, lastSearchKeyword: '' });
                  }}
                />
              </ControlledPanel.Header>
              <ControlledPanel.Content>
                <SelectableTable
                  data={customers}
                  isRowCheckable={(rowData) => true}
                  loading={loading}
                  page={pageInfo.nowPage}
                  pageSize={this._listPageSize}
                  minRows={0}
                  manual
                  LoadingComponent={Spinner}
                  pages={pageInfo.totalPages}
                  columns={[{
                      id: 'name',
                      Header: '이름',
                      minWidth: 230,
                      accessor: rowData => rowData.name,
                      Cell: (rowInfo) => (
                        <DefaultCellSpan>
                          {rowInfo.value}
                        </DefaultCellSpan>
                      )
                    }, {
                      id: 'code',
                      minWidth: 200,
                      Header: '코드',
                      accessor: rowData => rowData.code,
                      Cell: (rowInfo) => (
                        <DefaultCellSpan>
                          {rowInfo.value}
                        </DefaultCellSpan>
                      )
                    }, {
                      id: 'admin',
                      Header: '어드민 ID',
                      minWidth: 355,
                      accessor: rowData => rowData.adminEmployee ? rowData.adminEmployee.email : '-',
                      Cell: (rowInfo) => (
                        <DefaultCellSpan>
                          {rowInfo.value}
                        </DefaultCellSpan>
                      )
                    }, {
                      id: 'svcToggle',
                      Header: '스캔 서비스',
                      minWidth: 155,
                      Cell: (rowInfo) => {
                        if (rowInfo.original.code === 'Ybrain') return null;
                        return (
                          <Mutation
                            mutation={ORGZ_TOGGLE_SVC_MUTATION}
                            context={{
                              dataSourceType: 'auth'
                            }}
                          >
                            {(toggleSvc, { loading, data }) => {
                              return (
                                <ToggleSwitch
                                  checked={rowInfo.value}
                                  withConfirm
                                  onChange={(e) => {
                                    e.preventDefault();
                                  }}
                                  confirmMessage={<ConfirmMessage>{rowInfo.original.name}의 스캔 서비스를 <span>{(rowInfo.value ? '중단' : '활성화')}</span>하시겠습니까?</ConfirmMessage>}
                                  onConfirm={() => {
                                    toggleSvc({
                                      variables: {
                                        orgzId: rowInfo.original.id,
                                        enable: !rowInfo.value
                                      }
                                    }).then((response) => {
                                      console.log(response);
                                      if (response.data && response.data.enableOrgzSvc && response.data.enableOrgzSvc.id === selectedCustomer) {
                                        this.setState({ hasService: (response.data.enableOrgzSvc.services & 1) !== 0 });
                                      }
                                      refetch();
                                    }).catch((error) => console.log(error));
                                  }}
                                />
                              )
                            }}
                          </Mutation>
                        );
                      },
                      accessor: rowData => ((rowData.services & 1) !== 0),
                    }
                  ]}
                  showPagination={false}
                  resizable={false}
                  multiSort={false}
                  sortable={false}
                  selectedRow={{ id: selectedCustomer }}
                  onRowSelect={(rowInfo: any) => {
                    const customer: CustomerAccess = rowInfo.original;
                    this.setState({ hasService: (customer.services & 1) !== 0, adminEmp: customer.adminEmployee });
                    this.props.selectCustomer(customer.id);
                  }}
                  noDataText={(this.state.lastSearchKeyword !== '') ? '검색결과가 없습니다' : ''}
                />
              </ControlledPanel.Content>
              <ControlledPanel.Footer>
                <TableControlButton onClick={() => this.props.prevPage()}>
                  <img src={commonImages.prev} alt='Previous' />
                  <img className='hover-img' src={commonImages.prevHover} alt='Previous' />
                </TableControlButton>
                <TablePagingInfo><span className='current-page'>{currentPage + 1}</span>/{numPages}</TablePagingInfo>
                <TableControlButton onClick={() => this.props.nextPage()}>
                  <img src={commonImages.next} alt='Next' />
                  <img className='hover-img' src={commonImages.nextHover} alt='Next' />
                </TableControlButton>
                <RightButtonsContainer>
                  {(selectedCustomer !== '' && this.state.hasService && !this.state.adminEmp) ?
                    <Mutation
                      mutation={ADD_DEFAULT_BOOKMARKS_MUTATION}
                      context={{
                        dataSourceType: 'api'
                      }}
                    >
                      {(addDefaultBookmarks, { loading: bkmkLoading, error: bkmkError }) => (
                        <Mutation
                          mutation={REGISTER_ORGZ_ADMIN_MUTATION}
                          context={{
                            dataSourceType: 'auth'
                          }}
                        >
                          {(registerAdmin, { loading, error }) => {
                            return (
                              <AdminAccountForm
                                trigger={
                                  <RegisterAdminButton
                                    className='with-hover-overlay'
                                    disabled={loading}
                                    onClick={(event) => {
                                      event.preventDefault();
                                    }}
                                  >
                                    <span>어드민등록</span>
                                  </RegisterAdminButton>
                                }
                                onSubmit={async (empIn) => {
                                  console.log(empIn);
                                  try {
                                    const registerAdminResponse = await registerAdmin({
                                      variables: {
                                        orgzId: selectedCustomer,
                                        empIn,
                                        signinUrl: process.env.PUBLIC_URL
                                      }
                                    });
                                    console.log(registerAdminResponse);
                                    if (registerAdminResponse.data && registerAdminResponse.data.addEmpAdmin) {
                                      this.setState({ adminEmp: registerAdminResponse.data.addEmpAdmin });
                                    }
                                    const bkmkResult = await addDefaultBookmarks({ variables: {
                                      orgzId: selectedCustomer,
                                      empEmail: empIn.email
                                    } });
                                  } catch (error) {
                                    console.log(error);
                                  } finally {
                                    await refetch();
                                  }
                                }}
                              />
                            )
                          }}
                        </Mutation>
                      )}
                    </Mutation> : null
                  }
                  <Mutation
                    mutation={NEW_ORGZ_MUTATION}
                    context={{
                      dataSourceType: 'auth'
                    }}
                  >
                    {(registerCustomer, { loading, error }) => {
                      return (
                        <CustomerForm
                          trigger={
                            <RegisterCustomerButton
                              className='with-hover-overlay'
                              disabled={loading}
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                              href='#'
                            >
                              <span>고객등록</span>
                            </RegisterCustomerButton>
                          }
                          onSubmit={(orgzIn) => {
                            console.log(orgzIn);
                            registerCustomer({
                              variables: {
                                orgzIn
                              }
                            }).then((response) => {
                              console.log(response);
                              refetch();
                            }).catch((error) => console.log(error.response));
                          }}
                        />
                      )
                    }}
                  </Mutation>
                  <RefreshButton
                    className='with-hover-overlay'
                    onClick={(event) => {
                      event.preventDefault();
                      refetch();
                    }}
                    href='#'
                  >
                    <img src={commonImages.refresh} alt='refresh'/>
                  </RefreshButton>
                </RightButtonsContainer>
              </ControlledPanel.Footer>
            </ControlledPanel>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return state.accesscontrol.customerAccessList;
}

export const CustomerAccessListPanel = connect(mapStateToProps, {
  ...customerAccessListActions,
})(CustomerAccessListPanelComponent);