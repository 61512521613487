// @flow
// type imports
import type { AppState } from '../../../types';
import type { Customer } from '../../../types/customers';
// module imports
import * as React from 'react';
import moment from 'moment';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { PlainPanel, DateRangeInput, Spinner } from '../../common';
import { Container, Row, FlexRow, StatPiece, Divider, SpinnerContainer } from './styled';
import { STAT_BYTIME_ANLZ_BO_QUERY } from '../../../utils/graphql/queries';

type OwnProps = {
  selectedCustomer: Customer
};

type StateProps = {
  startDate: moment,
  endDate: moment
};

type Props = OwnProps & StateProps;

class AnlzDateRangePanelComponent extends React.Component<Props> {
  render() {
    const {
      selectedCustomer,
      startDate,
      endDate
    } = this.props;
    return (
      <Query
        query={STAT_BYTIME_ANLZ_BO_QUERY}
        pollInterval={5000}
        variables={{
          orgzId: selectedCustomer.orgz.id,
          from: startDate.toISOString(true),
          to: endDate.toISOString(true),
        }}
        fetchPolicy='network-only'
        context={{
          dataSourceType: 'api'
        }}
      >
        {({ loading, error, data, refetch }) => {
          return (
            <PlainPanel
              title={`${selectedCustomer.orgz.name}`}
              headerBorderColor='#00bbf9'
            >
              <Container>
                <Row>
                  <DateRangeInput
                    parentStateSelector={(state) => state.customers}
                    name='customerAnalysis'
                  />
                </Row>
                <FlexRow>
                  <React.Fragment>
                    {loading && <SpinnerContainer><Spinner /></SpinnerContainer>}
                    <StatPiece>
                      <span className="stat-heading">
                        분석용 파일
                      </span>
                      <span className="stat-data">
                        {(data && data.boStatAnlzByZTime) ? data.boStatAnlzByZTime.srcs : '-'}
                      </span>
                    </StatPiece>
                    <Divider />
                    <StatPiece>
                      <span className="stat-heading">
                        분석 요청중
                      </span>
                      <span className="stat-data">
                        {(data && data.boStatAnlzByZTime) ? data.boStatAnlzByZTime.reqs : '-'}
                      </span>
                    </StatPiece>
                    <StatPiece>
                      <span className="stat-heading">
                        분석중
                      </span>
                      <span className="stat-data">
                        {(data && data.boStatAnlzByZTime) ? data.boStatAnlzByZTime.procs : '-'}
                      </span>
                    </StatPiece>
                    <StatPiece color='#00bbf9'>
                      <span className="stat-heading">
                        분석완료
                      </span>
                      <span className="stat-data">
                        {(data && data.boStatAnlzByZTime) ? data.boStatAnlzByZTime.cpts : '-'}
                      </span>
                    </StatPiece>
                  </React.Fragment>
                </FlexRow>
              </Container>
            </PlainPanel>
          );
        }}
      </Query>
    )
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return state.customers.customerAnalysisDateRange;
}

export const AnlzDateRangePanel = connect(mapStateToProps, {})(AnlzDateRangePanelComponent);

/*
<Divider />
                    <BillAmount
                      orgzId={selectedCustomer.orgz.id}
                      startDate={startDate}
                      endDate={endDate}
                    />
                    */