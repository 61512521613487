import React from "react";
import styled from "styled-components";

const ContactContainer = styled.div`
  text-align: left;
`;

const Contact = styled.span`
  display: inline-block;
  position: relative;
  color: #576881;
  font-size: 13px;
  font-weight: 400;
  padding: 0 10px;
  &:not(:last-child)::after {
    content: '';
    width: 1px;
    height: 10px;
    position: absolute;
    top: 5px;
    right: 0;
    background-color: #576881;
  }
  a {
    color: #576881;
    &:hover {
      color #435268;
    }
  }
`;

const BillingYbrainInfo = () => {
  return (
    <>
      <ContactContainer>
        <Contact>(주) 와이브레인</Contact>
        <Contact>사업자등록번호: 314-86-43580</Contact>
        <Contact>통신판매업 신고번호: 2020-대전유성-0983</Contact>
        <Contact>대표: 이기원</Contact>
      </ContactContainer>
      <ContactContainer>
        <Contact>영업지점: 대전광역시 유성구 죽동로 280번길 41, 101호</Contact>
        <Contact>
          본사: 경기도 성남시 수정구 창업로 54 판교 제2테크노밸리
        </Contact>
      </ContactContainer>
      <ContactContainer>
        <Contact>전화번호: 031-758-2873</Contact>
        <Contact>
          E-mail: <a href="mailto:cs@ybrain.com">cs@ybrain.com</a>
        </Contact>
      </ContactContainer>
    </>
  );
};

export default BillingYbrainInfo;
