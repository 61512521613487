import React from "react";
import ReactExport from "react-export-excel";
import type { MonthlyTotalBillingInfo } from "../../types/billing";
import styled from "styled-components";
import { AnchorButton } from "../common/Button";
import { Query } from "@apollo/client/react/components";
import {
  AUTH_DATA_YBRAIN_EMPLOYEES,
  BILLING_USAGE_BY_ORGZ,
} from "../../utils/graphql/queries";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type OwnProps = {
  monthlyTotalBillingInfo: MonthlyTotalBillingInfo,
  orgzId: number,
  year: string,
  month: string,
  organization: any,
};

type Props = OwnProps;

export const ExcelDownloadButton = styled(AnchorButton)`
  margin-right: 24px;
  margin-bottom: 21px;
  background-color: #576881;
  text-align: center;
  display: inline-block;
  border-radius: 1.5px;
  height: 30px;
  width: 100px;
  > span {
    font-size: 12px;
    color: #e7ecf1;
    font-weight: 500;
  }
`;

const typeToString = (type) => {
  switch (type) {
    case "SCAN":
      return "EEG";
    case "HRV":
      return "HRV";
    case "NEURO_GUIDE":
      return "NeuroGuide";
    default:
      return type;
  }
};

class BillingExcelDownloadComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Query query={AUTH_DATA_YBRAIN_EMPLOYEES} fetchPolicy="network-only">
        {({ data }) => {
          let employeeMap = {};
          if (data) {
            data.ybrainEmployees.forEach((employee) => {
              employeeMap[employee.id] = employee;
            });
          }
          let monthlyTotalBillingInfo = this.props.monthlyTotalBillingInfo;

          let monthlyBill = monthlyTotalBillingInfo?.monthlyBills[0];
          let billData = [];
          if (this.props.organization && monthlyBill) {
            const orgManager = this.props.organization?.mgmtOfficerDetails;

            billData = monthlyBill.monthlyBillDetails.map((billDetail) => {
              return {
                contractCode: billDetail.contractCode,
                serviceType: typeToString(
                  billDetail.contractRelationInfo.serviceType
                ),
                managerName:
                  employeeMap[billDetail.contractRelationInfo.managerId]
                    ?.name || orgManager?.name,
                managerEmail:
                  employeeMap[billDetail.contractRelationInfo.managerId]
                    ?.email || orgManager?.email,
                billedUsageCount: billDetail.billedUsageCount,
                settledUsageCount: billDetail.settledUsageCount,
                billedAmountWithVat: billDetail.billedAmountWithVat,
              };
            });
          }

          return (
            <ExcelFile
              element={
                <ExcelDownloadButton
                  className="with-hover-overlay"
                  onClick={(event) => {
                    event.preventDefault();
                    console.log([this.props.monthlyTotalBillingInfo]);
                  }}
                  href="#"
                >
                  <span>Excel 다운로드</span>
                </ExcelDownloadButton>
              }
            >
              <ExcelSheet
                data={[this.props.monthlyTotalBillingInfo]}
                name="BillingUsage"
              >
                <ExcelColumn label="전체 사용 건수" value="settledUsageCount" />
                <ExcelColumn label="전체 청구 건수" value="billedUsageCount" />
                <ExcelColumn
                  label="전체 사용 금액"
                  value="settledAmountWithVat"
                />
                <ExcelColumn
                  label="전체 청구 금액"
                  value="billedAmountWithVat"
                />
              </ExcelSheet>

              <ExcelSheet data={billData} name="BillingUsageDetail">
                <ExcelColumn label="계약 코드" value="contractCode" />
                <ExcelColumn label="서비스 타입" value="serviceType" />
                <ExcelColumn label="담당자 이름" value="managerName" />
                <ExcelColumn label="담당자 이메일" value="managerEmail" />
                <ExcelColumn label="사용 건 수" value="settledUsageCount" />
                <ExcelColumn label="청구 건 수" value="billedUsageCount" />
                <ExcelColumn
                  label="청구 요금(VAT 포함)"
                  value="billedAmountWithVat"
                />
              </ExcelSheet>
            </ExcelFile>
          );
        }}
      </Query>
    );
  }
}

export const BillingExcelDownload = BillingExcelDownloadComponent;
