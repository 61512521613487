// @flow
import type { CurrentUserState } from "../types/auth";
import moment from 'moment';

export const loadSavedIdPwd = () => {
  try {
    let savedIdPwd = localStorage.getItem(`savedIdPwd`);
    if (savedIdPwd === null || savedIdPwd === undefined) {
      return {};
    }
    return JSON.parse(savedIdPwd);
  } catch (err) {
    console.log(err);
  }
}

export const saveIdPwd = (id: string, pwd: string) => {
  try {
    const idPwd = JSON.stringify({ id, pwd });
    localStorage.setItem('savedIdPwd', idPwd);
  } catch (err) {
    console.log(err);
  }
}

export const clearIdPwd = () => {
  try {
    localStorage.removeItem('savedIdPwd');
  } catch (err) {
    console.log(err);
  }
}

export const saveIdPwdPref = (save: boolean) => {
  try {
    const saveIdPwdPref = JSON.stringify(save);
    localStorage.setItem('saveIdPwd', saveIdPwdPref);
  } catch (err) {
    console.log(err);
  }
}

export const loadSaveIdPwdPref = () => {
  try {
    let saveIdPwdPrefJson = localStorage.getItem(`saveIdPwd`);
    if (saveIdPwdPrefJson === null || saveIdPwdPrefJson === undefined) {
      return false;
    }
    return JSON.parse(saveIdPwdPrefJson);
  } catch (err) {
    console.log(err);
  }
}

export const loadCurrentUser = (idleTimeoutDuration?: number) => {
  try {
    const currentUserJson = localStorage.getItem('currentUser');
    if (currentUserJson === null || currentUserJson === undefined) {
      return undefined;
    }
    const currentUser = JSON.parse(currentUserJson);
    const lastActivityMoment = moment(localStorage.getItem('lastActivityTimestamp'));
    if (moment().diff(lastActivityMoment, 'seconds') * 1000 > (idleTimeoutDuration || 3600000)) {
      localStorage.removeItem('currentUser');
      return undefined;
    }
    return currentUser;
  } catch (err) {
    return undefined;
  }
};

export const setLastActivityTimestamp = () => {
  try {
    localStorage.setItem('lastActivityTimestamp', moment().toISOString(true));
  } catch (err) {
    console.log(err);
  }
}

export const saveCurrentUser = (currentUser: CurrentUserState) => {
  try {
    const currentUserJson = JSON.stringify(currentUser);
    localStorage.setItem('currentUser', currentUserJson);
  } catch (err) {
    console.log(err);
  }
}

export const clearCurrentUser = () => {
  try {
    localStorage.removeItem('currentUser');
  } catch (err) {
    console.log(err);
  }
}

type UserPreferences = {
  [key: string]: any
};

export const setUserPreferences = (email: string, key: string, value: any) => {
  try {
    let userPrefJson = localStorage.getItem(`pref_${email}`);
    if (userPrefJson === null || userPrefJson === undefined) {
      const userPref = {
        [key]: value
      };
      localStorage.setItem(`pref_${email}`, JSON.stringify(userPref));
    } else {
      const userPref = JSON.parse(userPrefJson);
      localStorage.setItem(`pref_${email}`, JSON.stringify({
        ...userPref,
        [key]: value
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export const loadUserPreferences = (email: string): UserPreferences => {
  try {
    let userPrefJson = localStorage.getItem(`pref_${email}`);
    if (userPrefJson === null || userPrefJson === undefined) {
      return {};
    }
    return JSON.parse(userPrefJson);
  } catch (err) {
    console.log(err);
  }
}