// @flow
// type imports
import type { CurrentUserState } from "../../../types/auth";
import type { AppState } from "../../../types";
import type {
  AnlzRequest,
  AnlzRequestListState,
  Patient,
} from "../../../types/analysis";

// module imports
import _ from "lodash";
import * as React from "react";
import moment from "moment";
import Popup from "reactjs-popup";
import { Query } from "@apollo/client/react/components";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Tooltip from "@material-ui/core/Tooltip";
import { compose } from "redux";
import { connect } from "react-redux";
import ReactTable from "react-table";
import {
  PanelContentContainer,
  SessionListContainer,
  PDFDownloadButton,
  ErrorMsgTooltipSpan,
  DetailContainer,
  ToolbarPaper,
  ToolbarButton,
  Spacer,
  ResultViewButton,
} from "./styled";
import {
  anlzRequestListActions,
  anlzSourceListActions,
  surveySessionListActions,
  tempAnlzRequestListActions,
} from "../../../ducks/analysis";
import {
  ANLZ_REQUESTS_BY_SBJTID_QUERY,
  ANLZ_SRCS_BY_SBJTID_QUERY,
  EMP_ME_QUERY,
} from "../../../utils/graphql/queries";
import analysisImages from "../../../resources/images/analysis";
import {
  selectableTable,
  checkableTable,
  styledTable,
  Spinner,
  PanelGrid,
  EllipsisSpan,
} from "../../common";
import { AnlzRequestDetailPanel } from "../AnlzRequestDetailPanel";
import MoreMenu from "./MoreMenu";
import { useQuery } from "@apollo/client";
import { fetchTemperamentAnalysisRecords } from "../../../utils/rest";
import { TempAnlzRequestDetailPanel } from "../TempAnlzRequestDetailPanel/TempAnlzRequestDetailPanel";
import { Box } from "@material-ui/core";

const SelectableTable = compose(styledTable, selectableTable)(ReactTable);

const ALL_CONTENT_SIZE = 2147483647;

function selectedAnlzReqTitle(anlzRequest: AnlzRequest) {
  if (!anlzRequest) return "";
  const { id, createdTime } = anlzRequest;
  return `${moment(createdTime).format(
    "YYYY-MM-DD HH:mm"
  )} 기질 분석 (id: ${id})`;
}

const DetailPanel = ({ selectedPatient, selectedAnlzRequest }) => {
  if (!selectedAnlzRequest || !selectedPatient) {
    return null;
  }
  return (
    <PanelGrid.Row style={{ minHeight: "540px" }}>
      <PanelGrid.Col minWidth={750}>
        <TempAnlzRequestDetailPanel
          selectedPatient={selectedPatient}
          selectedAnlzRequest={selectedAnlzRequest}
        />
      </PanelGrid.Col>
    </PanelGrid.Row>
  );
};

const TempAnlzRequestListComponent = ({
  selectedPatient,
  currentUser,
  refetch,
  refetchAnlzRequestsDone,
}) => {
  const [tempAnalzRecords, setTempAnalzRecords] = React.useState([]);
  const [sourceById, setSourceById] = React.useState({});
  const [selectedAnlzRequest, setSelectedAnlzRequest] = React.useState();
  const { data: anlzSrcData } = useQuery(ANLZ_SRCS_BY_SBJTID_QUERY, {
    context: {
      dataSourceType: "api",
    },
    skip: !selectedPatient,
    fetchPolicy: "network-only",
    variables: {
      subjectId: selectedPatient && selectedPatient.id,
      page: 0,
      size: ALL_CONTENT_SIZE,
    },
  });
  console.log(anlzSrcData);
  const fetchRecords = async () => {
    try {
      const res = await fetchTemperamentAnalysisRecords(
        currentUser.tokens.access_token
      );
      console.log(res.data);
      setTempAnalzRecords(res.data);
      refetchAnlzRequestsDone();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    fetchRecords();
  }, []);

  React.useEffect(() => {
    if (refetch) {
      fetchRecords();
    }
  }, [refetch]);

  React.useEffect(() => {
    if (anlzSrcData) {
      const sourceById = anlzSrcData.anlzSrcPgBySbjtId.sources.reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {}
      );
      console.log(sourceById);
      setSourceById(sourceById);
    } else {
      setSourceById({});
    }
  }, [anlzSrcData]);

  return (
    <Query
      query={EMP_ME_QUERY}
      fetchPolicy="network-only"
      context={{
        dataSourceType: "auth",
      }}
    >
      {({ loading, error, data: empData, refetch }) => {
        return (
          <>
            <PanelContentContainer padding="13px 0px 45px 0px">
              <SessionListContainer>
                <SelectableTable
                  data={tempAnalzRecords} // data requests
                  loading={!selectedPatient ? false : loading}
                  minRows={0}
                  manual
                  LoadingComponent={Spinner}
                  columns={[
                    {
                      id: "created",
                      Header: "분석 요청일",
                      minWidth: 150,
                      accessor: (rowData) =>
                        moment(rowData.createdTime).format("YYYY-MM-DD HH:mm"),
                    },
                    {
                      id: "summary",
                      minWidth: 400,
                      Header: "요약",
                      Cell: (rowInfo) => (
                        <EllipsisSpan>
                          {`파일: ${sourceById[rowInfo.value]?.visualFile}`}
                        </EllipsisSpan>
                      ),
                      accessor: (rowData) => rowData.sourceId,
                    },
                    {
                      id: "anlzStatus",
                      minWidth: 150,
                      Header: "분석 결과",
                      Cell: (rowInfo) => {
                        const { status, errorMessage } = rowInfo.value;
                        switch (status) {
                          case "CREATED":
                            return "분석 요청중";
                          case "ANALYZING":
                            return "분석중";
                          case "FINISHED":
                            return "분석완료";
                          case "CANCEL":
                            return "분석취소";
                          case "ERROR":
                            return (
                              <Popup
                                trigger={<span>분석실패</span>}
                                position="top center"
                                contentStyle={{
                                  width: "auto",
                                  minWidth: "40px",
                                  maxWidth: "400px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "center",
                                }}
                                arrowStyle={{
                                  boxShadow:
                                    "1.2px 1.2px 1.5px 0 rgba(0,0,0,0.2)",
                                  borderBottom: "1px solid rgb(187,187,187)",
                                  borderRight: "1px solid rgb(187,187,187)",
                                  borderTop: "1px solid transparent",
                                  borderLeft: "1px solid transparent",
                                }}
                                on="hover"
                              >
                                {() => (
                                  <ErrorMsgTooltipSpan>
                                    {errorMessage}
                                  </ErrorMsgTooltipSpan>
                                )}
                              </Popup>
                            );
                          default:
                            return status;
                        }
                      },
                      accessor: (rowData) => ({
                        errorMessage: rowData.result?.errorMessage,
                        status: rowData.status,
                      }),
                    },
                    {
                      id: "view",
                      minWidth: 120,
                      Header: "결과 보기",
                      Cell: (rowInfo) => {
                        return (
                          <>
                            {rowInfo.original.status === "FINISHED" ? (
                              <ResultViewButton
                                onClick={() =>
                                  setSelectedAnlzRequest(rowInfo.original)
                                }
                              >
                                <span>결과보기</span>
                              </ResultViewButton>
                            ) : (
                              "-"
                            )}
                          </>
                        );
                      },
                    },
                    {
                      id: "menu",
                      minWidth: 100,
                      Header: "",
                      Cell: (rowInfo) => (
                        <MoreMenu
                          anlz={{
                            result: rowInfo.value,
                            source: sourceById[rowInfo.value.sourceId],
                          }}
                          patient={selectedPatient}
                          empMe={empData?.empMe}
                          orgId={currentUser.orgz_id}
                        />
                      ),
                      accessor: (rowData) => rowData,
                    },
                  ]}
                  showPagination={false}
                  resizable={false}
                  multiSort={false}
                  sortable={false}
                  noDataText=""
                ></SelectableTable>
              </SessionListContainer>
            </PanelContentContainer>
            <DetailContainer
              width={800}
              variant="temporary"
              anchor="right"
              open={!!selectedAnlzRequest}
              onClose={() => setSelectedAnlzRequest()}
            >
              <ToolbarPaper>
                <Toolbar>
                  <Tooltip title="닫기">
                    <ToolbarButton
                      onClick={() => setSelectedAnlzRequest()}
                      size="small"
                    >
                      <CloseSharpIcon />
                    </ToolbarButton>
                  </Tooltip>
                  <Typography variant="subtitle1" noWrap>
                    {selectedAnlzReqTitle(selectedAnlzRequest)}
                  </Typography>
                  <Spacer />
                </Toolbar>
              </ToolbarPaper>
              <Divider />
              <PanelGrid.Grid>
                <DetailPanel
                  selectedPatient={selectedPatient}
                  selectedAnlzRequest={selectedAnlzRequest}
                />
              </PanelGrid.Grid>
            </DetailContainer>
          </>
        );
      }}
    </Query>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    ...state.analysis.tempAnlzRequestList,
    currentUser: state.auth.currentUser,
  };
};

export const TempAnlzRequestList: React.ComponentType<{}> = connect(
  mapStateToProps,
  tempAnlzRequestListActions
)(TempAnlzRequestListComponent);
