// @flow
import type { AppState } from '../../types';
import type { Dispatch } from 'redux';
import type { CustomerAnlzRequestListState } from '../../types/customers';
// module imports
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import { downloadAnlzResourceRest } from '../../utils/rest';

// client actions
const SET_PAGING_INFO: 'sdbo/c/customeranlzrequestlist/SET_PAGING_INFO' = 'sdbo/c/customeranlzrequestlist/SET_PAGING_INFO';
const NEXT_PAGE: 'sdbo/c/customeranlzrequestlist/NEXT_PAGE' = 'sdbo/c/customeranlzrequestlist/NEXT_PAGE';
const PREV_PAGE: 'sdbo/c/customeranlzrequestlist/PREV_PAGE' = 'sdbo/c/customeranlzrequestlist/PREV_PAGE';
const CHECK_ANLZ_REQUESTS: 'sdbo/c/customeranlzrequestlist/CHECK_ANLZ_REQUESTS' = 'sdbo/c/customeranlzrequestlist/CHECK_ANLZ_REQUESTS';
const UNCHECK_ANLZ_REQUESTS: 'sdbo/c/customeranlzrequestlist/UNCHECK_ANLZ_REQUESTS' = 'sdbo/c/customeranlzrequestlist/UNCHECK_ANLZ_REQUESTS';
// server actions
const DOWNLOAD_ANLZ_RESOURCE: 'sdbo/c/customeranlzrequestlist/DOWNLOAD_ANLZ_RESOURCE' = 'sdbo/c/customeranlzrequestlist/DOWNLOAD_ANLZ_RESOURCE';

type SetPagingInfoAction = {
  type: typeof SET_PAGING_INFO,
  payload: {
    currentPage: number,
    numPages: number
  }
};

type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type DownloadAnlzResourceAction = {
  type: typeof DOWNLOAD_ANLZ_RESOURCE,
}

type CheckAnlzRequestsAction = {
  type: typeof CHECK_ANLZ_REQUESTS,
  payload: string[]
};

type UncheckAnlzRequestsAction = {
  type: typeof UNCHECK_ANLZ_REQUESTS,
  payload: string[]
};

type AnlzRequestListActions = (
  SetPagingInfoAction |
  NextPageAction |
  PrevPageAction |
  CheckAnlzRequestsAction |
  UncheckAnlzRequestsAction |
  DownloadAnlzResourceAction
);

const INITIAL_STATE: CustomerAnlzRequestListState = {
  currentPage: 0,
  numPages: 0,
  checkedAnlzRequests: [],
};

export default function reducer(state: CustomerAnlzRequestListState = INITIAL_STATE, action: AnlzRequestListActions): CustomerAnlzRequestListState {
  switch (action.type) {
    case SET_PAGING_INFO:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        numPages: action.payload.numPages
      };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage + 1 < state.numPages) ? state.currentPage + 1 : state.currentPage,
      };
    case PREV_PAGE:
      return {
        ...state,
        currentPage: (state.currentPage > 0) ? state.currentPage - 1 : state.currentPage,
      };
    case CHECK_ANLZ_REQUESTS:
      return {
        ...state,
        checkedAnlzRequests: _.union(state.checkedAnlzRequests, action.payload)
      };
    case UNCHECK_ANLZ_REQUESTS:
      return {
        ...state,
        checkedAnlzRequests: _.difference(state.checkedAnlzRequests, action.payload)
      };
    case DOWNLOAD_ANLZ_RESOURCE: return state;
    default: return state;
  }
};

const setPagingInfo = (currentPage: number, numPages: number): SetPagingInfoAction => {
  return {
    type: SET_PAGING_INFO,
    payload: {
      currentPage,
      numPages
    }
  };
};

const nextPage = (): NextPageAction => {
  return {
    type: NEXT_PAGE
  };
};

const prevPage = (): PrevPageAction => {
  return {
    type: PREV_PAGE
  };
};

const checkAnlzRequests = (requestIds: string[]): CheckAnlzRequestsAction => {
  return {
    type: CHECK_ANLZ_REQUESTS,
    payload: requestIds
  };
};

const uncheckAnlzRequests = (requestIds: string[]): UncheckAnlzRequestsAction => {
  return {
    type: UNCHECK_ANLZ_REQUESTS,
    payload: requestIds
  };
};

const downloadAnlzResource = (sbjtId: string, actualName: string, visualName: string): ThunkAction => {
  return (dispatch: Dispatch<AnlzRequestListActions>, getState: () => AppState) => {
    const { access_token } = getState().auth.currentUser.tokens;
    downloadAnlzResourceRest(sbjtId, actualName, visualName, access_token);
  }
}

export const actionCreators = {
  setPagingInfo,
  nextPage,
  prevPage,
  downloadAnlzResource,
  checkAnlzRequests,
  uncheckAnlzRequests
};