// @flow
import styled from 'styled-components';
import { HoverStyledAnchorButton } from '../Button';

export const Container = styled.div`
  width: 100%;
  position: relative;
  height: 60px;
  > img {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;



export const CalendarContainer = styled.div`
  width: 252px;
  position: relative;
  height: 31px;
  > img {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  a {
    cursor: pointer;
    padding: 7px;
    width: 30px;
    height: 30px;
    &:hover > i.arrow {
      border-color: #00bbf9;
    }
    i.arrow {
      width: 0;
      height: 0;
      border: solid #576881;
      border-width: 0 2px 2px 0;
      padding: 5px;
      &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
      &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
`;

export const CalendarInput = styled.input` 
  background-color: #E5EBF0;
  border: none;
  font-family: AppleSDGothicNeo;
  font-size: 26px;
  font-weight: bold;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  
  color: #576881;
  font-size: 20px;
  &:focus {
    outline: none;
  }
`;

export const Input = styled.input`
  border: solid 1px #d0dcea;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  color: #576881;
  font-size: 20px;
  &:focus {
    outline: none;
  }
`;

export const DateRangePickerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const DateRangePickerHeader = styled.div`
  height: 57px;
  border-bottom: solid 1px #d0dcea;
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-top: 20px;
  font-weight: 500;
`;

export const DateRangePickerInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  > * {
    display: inline-block;
  }
  > div {
    flex: 1 1 130px;
    height: 37px;
  }
  i.dash {
    width: 6px;
    height: 2px;
    background-color: #d0dcea;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

export const DateRangePickerInput = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: solid 1px #d0dcea;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #435268;
  &::placeholder {
    color: #d0dcea;
  }
  &:focus {
    outline: none;
  }
`;

export const MonthPickerContainer = styled.table`
  width: 277px;
  margin-top: 10px;
  border-collapse: collapse;
  table-layout: fixed;
  border: solid 1px #d0dcea;
  thead th {
    height: 37px;
    box-sizing: border-box;
    border: solid 1px #d0dcea;
  }
  tbody td {
    box-sizing: border-box;
    height: 37px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    border: solid 1px #d0dcea;
  }
`;

export const YearPickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  * {
    display: inline-block;
  }
  a {
    cursor: pointer;
    padding: 7px;
    width: 30px;
    height: 30px;
    &:hover > i.arrow {
      border-color: #00bbf9;
    }
    i.arrow {
      width: 0;
      height: 0;
      border: solid #576881;
      border-width: 0 2px 2px 0;
      padding: 5px;
      &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
      &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
  
  > span {
    font-size: 15px;
    color: #435268;
    min-width: 48px
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }
`;


export const CalendarPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;
  text-align: center;
  &:hover > i.arrow {
    border-color: #00bbf9;
  }
  i.arrow {
    width: 0;
    height: 0;
    border: solid #576881;
    border-width: 0 2px 2px 0;
    padding: 5px;
    
    display: inline-block;
    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    &.right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
`;

export const YearMonthPickerContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 262px;
  height: 31px;
    
  > span {
    font-size: 15px;
    color: #435268;
    min-width: 48px
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }
   > img {
    position: absolute;
    top: 15px;
    right: 15px;
  }

`;

export const MonthPickerButton = styled.td`
  color: ${props => props.isActive ? '#fff' : '#435268'};
  background-color: ${props => props.isActive ? '#00bbf9' : 'transparent'};
`;

export const DateRangePickerFooterContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
  a, button {
    cursor: pointer;
    display: inline-block;
    flex: 1 1 100%;
    margin-left: 8px;
    height: 45px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const CancelButton = styled.a`
  padding-top: 14px;
  background-color: #94aabf;
`
export const SubmitButton = styled.button`
  background-color: #576881;
  position: relative;
  border: none;
  &:disabled {
    background-color: #576881;
    opacity: 0.6;
    cursor: default;
  }
`;

export const CalendarButton = styled(HoverStyledAnchorButton)`
  position: absolute;
  height: 100%;
  width: 60px;
  right: 0;
  text-align: center;
`;

export const CalendarButton2 = styled(HoverStyledAnchorButton)`
  margin-left: 44px;
  margin-right: 16px;
  letter-spacing: -0.68px;
  color: #576881;
`;