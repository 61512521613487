// @flow
import * as React from 'react';
import {
  Page,
  PanelGrid
} from '../../components/common';
import { CustomerAccessListPanel } from '../../components/accesscontrol';

class AccessControl extends React.Component<{}> {
  render() {
    return (
      <Page
        title='Access Control'
        subtitle='고객 및 고객별 어드민계정을 관리하고 확인할 수 있습니다'
        contentMinWidth={1295}
      >
        <PanelGrid.Grid>
          <PanelGrid.Row style={{ height: '608px'}}>
            <PanelGrid.Col minWidth={1175}>
              <CustomerAccessListPanel />
            </PanelGrid.Col>
          </PanelGrid.Row>
        </PanelGrid.Grid>
      </Page>
    )
  }
}

export { AccessControl };