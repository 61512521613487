// @flow
// type imports
import type { AppState } from '../../../types';
import type { FileFormState } from '../../../types/common';
// module imports
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { actionCreators as fileFormActions } from '../../../ducks/common/fileform';
import { Button } from '../../common';
import { Spinner } from '../Spinner';

const FormContainer = styled.form`
  display: inline-block;
  position: relative;
`;

const HiddenFileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  right: 0;
`;

const UploadButton = styled(Button)`
  background-color: #576881;
  text-align: center;
  border-radius: 1.5px;
  box-shadow: 4px 11px 11px 0 rgba(0, 0, 0, 0.07);
  height: 45px;
  width: 100px;
  > span {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
`;

type OwnProps = {
  disabled: boolean,
  name: string,
  endpointUrl: string,
  parentStateSelector: (state: AppState) => any,
  onSubmitDone: (error: any) => void,
  accept?: string,
  children?: React.Node
};

type StateProps = FileFormState;

type ActionProps = typeof fileFormActions;

type Props = OwnProps & StateProps & ActionProps;

class FileFormComponent extends React.Component<Props> {
  _form: ?HTMLFormElement;
  _fileInput: ?HTMLInputElement;

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedFiles && nextProps.selectedFiles.length === 1 && this._form && !nextProps.loading) {
      if (typeof(Event) === 'function') {
        this._form.dispatchEvent(new Event('submit'));
      } else {
        const submitEvent = document.createEvent('Event');
        submitEvent.initEvent('submit', true, true);
        this._form.dispatchEvent(submitEvent);
      }
    } else if (this.props.loading && !nextProps.loading) {
      this.props.onSubmitDone(nextProps.error);
      if (this._form) {
        this._form.reset();
      }
    }
  }

  render() {
    const {
      disabled,
      name,
      loading,
      endpointUrl,
      accept,
      children
    } = this.props;
    return (
      <FormContainer
        ref={ref => this._form = ref}
        onSubmit={(event) => {
          console.log('file form submit')
          event.preventDefault();
          this.props.uploadFile(name, endpointUrl)
        }}
      >
        <HiddenFileInput
          ref={ref => this._fileInput = ref}
          onChange={(event) => {
            console.log(event);
            this.props.fileFormUpdate(name, event.target.files);
          }}
          type='file'
          tabIndex={-1}
          accept={accept}
        />
        <UploadButton
          className='with-hover-overlay'
          href='#'
          disabled={disabled || loading}
          onClick={(event) => {
            if (this._fileInput) {
              this._fileInput.click();
            }
            event.preventDefault();
          }}
        >
          {(loading) ? <Spinner /> : children}
        </UploadButton>
      </FormContainer>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const parentState = ownProps.parentStateSelector(state);
  if (parentState.hasOwnProperty(ownProps.name)) {
    const fileFormState: FileFormState = parentState[ownProps.name];
    return fileFormState;
  }
  return {
    selectedFiles: null,
    loading: false,
    error: null
  };
};

export const FileForm: React.ComponentType<OwnProps> = connect(mapStateToProps, fileFormActions)(FileFormComponent);
